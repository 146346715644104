import { IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { PanelList } from '../panel-list';
import { getDataSourceName } from './data-sources.helpers';

type DataSourcesListProps = {
  availableDataSources: IntegrationType[];
  selectedDataSource: IntegrationType | null;
  setSelectedDataSource: (dataSource: IntegrationType | null) => void;
  setDisplayIntegrations: (display: boolean) => void;
  handleDisplayIntegrations: () => void;
};

type DataSource = {
  id: IntegrationType | null;
  name: string;
};
const dataSources: DataSource[] = [
  { id: null, name: getDataSourceName(null) },
  { id: IntegrationType.JIRA, name: getDataSourceName(IntegrationType.JIRA) },
  { id: IntegrationType.ADO, name: getDataSourceName(IntegrationType.ADO) },
  { id: IntegrationType.JDC, name: getDataSourceName(IntegrationType.JDC) },
  { id: IntegrationType.GITHUB, name: getDataSourceName(IntegrationType.GITHUB) },
];
export function DataSourcesList({
  selectedDataSource,
  setSelectedDataSource,
  availableDataSources,
  setDisplayIntegrations,
  handleDisplayIntegrations,
}: DataSourcesListProps) {
  const handleSelectDataSource = (dataSource: DataSource) => {
    setSelectedDataSource(dataSource.id ?? null);
    setDisplayIntegrations(false);
  };

  return (
    <PanelList
      title="Integrations"
      items={[
        dataSources[0],
        ...dataSources.filter((dataSource) => availableDataSources.includes(dataSource.id as IntegrationType)),
      ]}
      selectedItem={dataSources.find((dataSource) => dataSource.id === selectedDataSource) ?? null}
      onSelect={handleSelectDataSource}
      onAddNew={handleDisplayIntegrations}
      getItemId={(dataSource) => dataSource.id ?? ''}
      getItemDisplayText={(dataSource) => dataSource.name}
    />
  );
}
