import { Sprint, SprintBurnsAndFlows } from '../../api/sprints-client/sprints-client.type';
import { DayCount } from '../../helpers';
import { generateDateStringArray } from '../../helpers/string-helpers/string-helpers';

/**
 * Generates a title string for tasks based on sprint, day, and filter information
 *
 * @param sprint - The sprint object containing start date and other sprint details
 * @param day - The day number within the sprint as a string
 * @param sprintBurnsAndFlows - Object containing sprint burn and flow data
 * @param filter - Optional filter string to show specific task status (backlog, blocked, wip, etc)
 * @returns A formatted title string showing tasks for the given day and filter, or empty string if required params missing
 */
const makeTitle = (
  sprint: Sprint | null,
  day: string | null,
  sprintBurnsAndFlows: SprintBurnsAndFlows | null,
  filter: string | null,
): string => {
  if (sprint && day && sprintBurnsAndFlows) {
    const dayCount = DayCount(sprint, sprintBurnsAndFlows);

    const filterTextMap: { [key: string]: string } = {
      backlog: 'Ready',
      blocked: 'Blocked',
      wip: 'WIP',
      review: 'In Review',
      test: 'In Test',
      deployable: 'To Deploy',
      done: 'Done',
    };

    const dateString = generateDateStringArray(sprint.start_date, dayCount, dayCount)[Number(day)]?.label;
    if (filter) {
      return `"${filterTextMap[filter]}" tasks on day ${dateString}`;
    }
    return `Tasks on day ${dateString}`;
  }
  return '';
};

export { makeTitle };
