import { styled } from '@linaria/react';

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

const ChartBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
`;

export { ChartBox, ChartContainer };
