import { Box } from '@mantine/core';
import { forwardRef } from 'react';
import { secondaryBase } from '../../styles/design-tokens';

type Props = {
  name: string;
  variant?: 'filled' | 'outlined';
  color?: string;
  size?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Icon = forwardRef<HTMLDivElement, Props>(
  ({ name, variant = 'outlined', color = secondaryBase, size = 24, onClick, style, ...props }: Props, ref) => {
    const variantClass = variant === 'outlined' ? 'material-icons-outlined' : 'material-icons';

    const styles = {
      color,
      fontSize: size,
      cursor: onClick ? 'pointer' : 'default',
      ...style,
    };

    return (
      <Box {...props} ref={ref} className={variantClass} onClick={onClick} style={styles}>
        {name}
      </Box>
    );
  },
);
