import { Box, Text } from '@mantine/core';
import { memo, useState } from 'react';

export const TruncatedText = memo(
  ({
    text,
    maxLength = 100,
    onToggle,
  }: {
    text: string;
    maxLength?: number;
    onToggle: (isExpanded: boolean) => void;
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const shouldTruncate = text.length > maxLength;
    const displayText = isExpanded || !shouldTruncate ? text : `${text.slice(0, maxLength)}...`;

    const handleClick = () => {
      if (shouldTruncate) {
        setIsExpanded(!isExpanded);
        onToggle(!isExpanded);
      }
    };

    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 8,
          cursor: shouldTruncate ? 'pointer' : 'default',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
        onClick={handleClick}
      >
        <Text size="sm" style={{ width: '100%' }}>
          {displayText}
        </Text>
      </Box>
    );
  },
);

TruncatedText.displayName = 'TruncatedText';
