import { styled } from '@linaria/react';
import { SegmentedControl, Table, Text, Tooltip } from '@mantine/core';
import { format } from 'date-fns';
import { useState } from 'react';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { RiskBadge } from './risk-analysis-task-table';
import { RiskAnalysisInitiative } from './risk-analysis.client.type';
import { getRiskCategory } from './risk-analysis.helpers';
import { TruncatedText } from './truncated-text';

export function RiskAnalysisInitiativeTable({ initiatives }: { initiatives: RiskAnalysisInitiative[] }) {
  const portfolio = useGlobalStore((store) => store.portfolio);
  const [activeFilter, setActiveFilter] = useState<string>('active');

  const filteredInitiatives = initiatives.filter((initiative) => {
    const now = new Date();
    const startDate = initiative.start_date ? new Date(initiative.start_date) : null;
    const endDate = initiative.end_date ? new Date(initiative.end_date) : null;

    const isActive = (startDate && (!endDate || endDate > now) && startDate <= now) || initiative.is_active;
    return activeFilter === 'active' ? isActive : !isActive;
  });

  return (
    <TableContainer>
      <HeaderContainer>
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>Initiatives</div>
        <SegmentedControl
          value={activeFilter}
          onChange={(value) => setActiveFilter(value)}
          data={[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ]}
          size="sm"
        />
      </HeaderContainer>

      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Initiative Name</Table.Th>
            <Table.Th>Start Date</Table.Th>
            <Table.Th>End Date</Table.Th>
            <Table.Th ta="center">Risk Category</Table.Th>
            <Table.Th ta="center">Risk Score</Table.Th>
            <Table.Th>Flora Summary</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {filteredInitiatives.map((initiative) => {
            const riskCategory = getRiskCategory(initiative.flora_annotations?.risk_score);
            return (
              <Table.Tr key={initiative.id}>
                <Table.Td>
                  <Text
                    fw={500}
                    component="a"
                    href={`strategy/${portfolio?.id}/${initiative.id}`}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {initiative.name}
                  </Text>
                </Table.Td>
                <Table.Td>
                  {initiative.start_date ? format(new Date(initiative.start_date), 'MMM d, yyyy') : 'No date'}
                </Table.Td>
                <Table.Td>
                  {initiative.end_date ? format(new Date(initiative.end_date), 'MMM d, yyyy') : 'No date'}
                </Table.Td>
                <Table.Td ta="center">
                  <RiskBadge color={riskCategory.color}>{riskCategory.label}</RiskBadge>
                </Table.Td>
                <Table.Td ta="center">{initiative.flora_annotations?.risk_score?.toFixed(0) ?? 'N/A'}</Table.Td>
                <Table.Td>
                  <Tooltip label={initiative.flora_annotations?.executive_summary} multiline>
                    <TruncatedText
                      text={initiative.flora_annotations?.executive_summary ?? 'No summary'}
                      onToggle={() => {}}
                    />
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </TableContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const TableContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 16px;
  background-color: white;
  border-radius: 8px;

  .mantine-Table-root {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .mantine-Table-thead {
    position: sticky;
    top: -16px;
    background-color: white;
    z-index: 1;
  }

  .mantine-Table-th,
  .mantine-Table-td {
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    padding: 12px 16px;
    vertical-align: top;
    max-width: 0;
  }

  /* Fixed column widths */
  .mantine-Table-th:nth-of-type(1),
  .mantine-Table-td:nth-of-type(1) {
    width: 20%; /* Initiative Name */
  }

  .mantine-Table-th:nth-of-type(2),
  .mantine-Table-td:nth-of-type(2) {
    width: 10%; /* Start Date */
  }

  .mantine-Table-th:nth-of-type(3),
  .mantine-Table-td:nth-of-type(3) {
    width: 10%; /* End Date */
  }

  .mantine-Table-th:nth-of-type(4),
  .mantine-Table-td:nth-of-type(4) {
    width: 15%; /* Risk Category */
  }

  .mantine-Table-th:nth-of-type(5),
  .mantine-Table-td:nth-of-type(5) {
    width: 10%; /* Risk Score */
  }

  .mantine-Table-th:nth-of-type(6),
  .mantine-Table-td:nth-of-type(6) {
    width: 35%; /* Flora Summary */
  }
`;
