import { styled } from '@linaria/react';
import { Button, FocusTrap, Modal, Space, TextInput } from '@mantine/core';
import { KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useIntegrationUrl } from '../../../api/integrations-client/integrations-client.hooks';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { UserContext } from '../../../contexts';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { newCOLORS } from '../../../styles/colors';

type Props = {
  isOpened: boolean;
  close: () => void;
};

export function UrlModal({ isOpened, close }: Props) {
  const { user } = useContext(UserContext);
  const organizationId = useOrganizationId();

  const [url, setUrl] = useState<string>('');

  const { url: integrationUrl, query } = useIntegrationUrl({
    queryKey: ['integrationUrl', ExternalService.Jira] as const,
    enabled: false,
  });

  useEffect(() => {
    if (integrationUrl) {
      trackEvent(AnalyticsEventType.IntegrationsViewed, { userContext: user });
      close();

      const receivedUrl = new URL(integrationUrl);
      const params = new URLSearchParams(receivedUrl.search);
      params.set('state', `${url},${organizationId || ''}`);
      const updatedQueryString = params.toString();

      window.location.href = `${receivedUrl.origin}${receivedUrl.pathname}?${updatedQueryString}`;
    }
  }, [integrationUrl, url, user, organizationId, close]);

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      query.refetch();
    }
  }

  return (
    <StyledModal size="auto" opened={isOpened} onClose={close} title="Atlassian Configuration">
      <FocusTrap active>
        <TextInput
          styles={{ input: { border: `1px solid ${newCOLORS.indigo} !important` } }}
          data-autofocus
          placeholder="For example, https://foo.atlassian.net"
          label="Enter the base url of the Jira instance you are trying to integrate with"
          withAsterisk
          onKeyDown={handleKeyDown}
          onChange={(event) => {
            setUrl(event.currentTarget.value);
          }}
        />
      </FocusTrap>
      <Space h="md" />
      <Button
        onClick={() => {
          query.refetch();
        }}
        tt={'uppercase'}
      >
        Proceed
      </Button>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .mantine-Modal-header {
    min-height: 30px;
    height: 30px;
  }
`;
