import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { CollapseState, TaskRecord } from './tasks-table.type';

/**
 * Custom hook for managing collapse state of task groups.
 *
 * @param {Record<string, TaskRecord[]>} groups - Object containing groups of TaskRecords
 * @returns {Object} Object with functions and state for managing collapse state
 */
const useCollapseState = (
  groups: Record<string, TaskRecord[]>,
): {
  collapseState: CollapseState;
  setCollapseState: React.Dispatch<React.SetStateAction<CollapseState>>;
  toggleCollapseState: (value: boolean) => void;
  expandAll: () => void;
  collapseAll: () => void;
} => {
  const [collapseState, setCollapseState] = useState<CollapseState>({});
  const collapseStateInitial = Object.fromEntries(Object.entries(groups).map(([key]) => [key, false]));

  useDeepCompareEffect(() => {
    setCollapseState(collapseStateInitial);
  }, [collapseStateInitial]);

  const toggleCollapseState = (value: boolean) =>
    setCollapseState(Object.fromEntries(Object.entries(collapseState).map(([key]) => [key, value])));

  const expandAll = () => toggleCollapseState(false);
  const collapseAll = () => toggleCollapseState(true);

  return { collapseState, setCollapseState, toggleCollapseState, expandAll, collapseAll };
};

export { useCollapseState };
