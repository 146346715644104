import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useState } from 'react';
import { Text } from '../../../ui-library/typography/typography';
import { AddNewIntegrationModalTerms } from './add-new-integration-modal-terms';
import { AuthFormType } from './add-new-integration.types';

type Props = {
  type: AuthFormType;
};

export const AddNewIntegrationConsent = ({ type }: Props) => {
  const [isOpenTerms, setIsOpenTerms] = useState(false);

  const header = type === AuthFormType.Basic ? 'Password Storage Information' : 'API Key Storage Information';
  const label = type === AuthFormType.Basic ? 'password' : 'API Key';

  return (
    <Flex direction="column" gap={16}>
      <Text size="small" lineHeight="tight" weight="bold">
        {header}
      </Text>
      <Text size="small" lineHeight="tight">
        Your {label} is securely encrypted and never stored in plain text. By using this app, you consent to its secure
        storage. For details, see our <Link onClick={() => setIsOpenTerms(true)}>Terms & Conditions</Link>.
      </Text>
      <AddNewIntegrationModalTerms isOpen={isOpenTerms} onClose={() => setIsOpenTerms(false)} />
    </Flex>
  );
};

const Link = styled.a`
  color: var(--secondary-base);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
