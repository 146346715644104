import dayjs from 'dayjs';
import { ProcessAnalysisInitiativeChartData } from '../../../api/portfolio-client/portfolio-client.type';
import { TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';
import { ITEM_COLORS } from '../process-analysis.helpers';
import { MetricType, Tab } from '../process-analysis.type';
import { InitiativeSectionSelectionAggregated } from './initiative-sections.type';

/**
 * Handles the change of the selected entity
 *
 * @param checked - whether checked or not
 * @param header  - the header of the entity
 * @param selectedEntities - list of already selected entities
 * @param setSelectedEntities - function to set the selected entities
 * @returns void
 */
function handleChangeEntity(
  checked: boolean,
  selectedItem: { id: string; name: string },
  selectedEntities: { id: string; name: string }[],
  setSelectedEntities: (selectedEntities: { id: string; name: string }[]) => void,
): void {
  if (checked) {
    setSelectedEntities([...selectedEntities, selectedItem]);
  } else {
    setSelectedEntities(selectedEntities.filter((entity) => entity.id !== selectedItem.id));
  }
}

/**
 * Handles the change of the selected trend
 *
 * @param checked - whether checked or not
 * @param header - the header of the trend
 * @param color - color of the trend
 * @param selectedTrends - list of already selected trends
 * @param setSelectedTrends - function to set the selected trends
 * @returns void
 */
function handleChangeTrend(
  checked: boolean,
  selectedItem: { id: string; name: string },
  selectedTrends: { id: string; name: string }[],
  setSelectedTrends: (selectedTrends: { id: string; name: string }[]) => void,
): void {
  if (checked) {
    setSelectedTrends([...selectedTrends, selectedItem]);
  } else {
    setSelectedTrends(selectedTrends.filter((trend) => trend.id !== selectedItem.id));
  }
}

/** matches selected entities with a color based on the position in the list of all entities
 *
 * @param selectedEntities: {id: string, name: string}[] - list of selected entities
 * @param allEntities: {id: string, name: string}[] - list of all entities
 * @returns an object with the selected entities as keys and the color as values
 */
function matchEntitiesWithColor(
  selectedEntities: { id: string; name: string }[],
  allEntities: { id: string; name: string }[],
): { [key: string]: string } {
  return selectedEntities
    .filter((se) => !!se)
    .reduce((acc: { [key: string]: string }, entity: { id: string; name: string }) => {
      const index = allEntities.findIndex((e) => e.id === entity.id);
      if (index === -1) {
        return acc;
      }
      acc[entity.id] = ITEM_COLORS[index % ITEM_COLORS.length];
      return acc;
    }, {});
}

/**
 * Returns the default selection for the initiative sections context.
 *
 * @returns {Record<Tab, InitiativeSectionSelectionAggregated>} - An object with the default selection for the initiative sections.
 */
const getDefaultInitiativeSectionSelection = (): InitiativeSectionSelectionAggregated => {
  const selection = {
    metric: MetricType.Tasks,
    initiatives: [],
    selectedInitiatives: [],
    selectedTrends: [],
    initiativesData: null,
  };

  return {
    [Tab.Portfolios]: selection,
    [Tab.Teams]: selection,
  };
};

/**
 * Sorts the initiative headers in the given initiatives data.
 *
 * @param {ProcessAnalysisInitiativeChartData | undefined} initiativesData - The ProcessAnalysisInitiativeChartData to sort.
 * @returns {value: id, name: string}[] - A sorted list of initiative headers.
 */
const sortInitiatives = (
  initiativesData: ProcessAnalysisInitiativeChartData | undefined,
): { id: string; name: string }[] => {
  return initiativesData ? initiativesData.initiatives.sort((a, b) => a.name.localeCompare(b.name)) : [];
};

/**
 * Returns an array of chart labels based on the provided measure values and time allocation.
 *
 * @param {TimeAllocationType} timeAllocation - The time allocation for the chart labels.
 * @return {string[]} An array of chart labels in the format determined by the time allocation.
 */
function sortAndFormatLabels(labels: string[], timeAllocation: TimeAllocationType): string[] {
  const sortedLabels = labels
    .map((label) => {
      return dayjs(label, ['MMM YY', 'YYYY-MM'], true);
    })
    .sort((a, b) => a.diff(b));

  const customFormat = timeAllocation === TimeAllocationType.Monthly ? 'YYYY MMM' : 'YYYY MMM D';

  return sortedLabels.map((label) => label.format(customFormat).toLocaleUpperCase());
}

export {
  getDefaultInitiativeSectionSelection,
  handleChangeEntity,
  handleChangeTrend,
  matchEntitiesWithColor,
  sortAndFormatLabels,
  sortInitiatives,
};
