import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSprint, fetchSprintTaskFlowHistory } from '../../api/sprints-client/sprints-client';
import { TaskFlow } from '../../components/task-flow/task-flow';

export const TaskFlowHistory = () => {
  const navigate = useNavigate();
  const { sprintId } = useParams<{ sprintId: string }>();

  const sprintQuery = useQuery({
    queryKey: ['sprint', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Unable to resolve SprintId or SprintID is missing');
      }
      return fetchSprint(sprintId);
    },
    enabled: !!sprintId,
  });

  const taskFlowQuery = useQuery({
    queryKey: ['fetchSprintTaskFlowHistory', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('SprintId is required');
      }
      return fetchSprintTaskFlowHistory(sprintId);
    },
    enabled: !!sprintId,
  });

  useEffect(() => {
    if (taskFlowQuery.error) {
      const errorState = { errorMessage: (taskFlowQuery.error as Error).message, errorStatus: 500 };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
    if (sprintQuery.error) {
      console.error('There was an issue fetching sprint data:', sprintQuery.error);
    }
  }, [sprintQuery.error, taskFlowQuery.error, navigate]);

  const sprint = sprintQuery.data || null;
  const taskFlowHistory = taskFlowQuery.data?.jira_history || null;

  return (
    !sprintQuery.isPending &&
    !taskFlowQuery.isPending &&
    taskFlowHistory &&
    sprint && (
      <TaskFlow
        taskFlowData={taskFlowHistory}
        startDate={new Date(sprint.start_date)}
        endDate={new Date(sprint.end_date)}
      />
    )
  );
};
