import { Icon } from '@iconify/react/dist/iconify.js';
import { Box, Card, Group, Text, Title } from '@mantine/core';
import type { MeasureDetail } from '../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../styles/colors';

type MeasureInfoBoxProps = {
  measure: MeasureDetail;
};

export function MeasureInfoBox({ measure }: MeasureInfoBoxProps) {
  return (
    <Card
      style={{
        width: '98.5%',
        border: `1px solid ${newCOLORS.lightGray}`,
        borderRadius: '10px',
        height: '25vh',
        marginBottom: '20px',
        padding: '20px',
      }}
    >
      <Group grow style={{ height: '100%', alignItems: 'flex-start' }}>
        <Box
          style={{
            flexGrow: 0,
            width: '45%',
            marginRight: '-20px',
            borderRight: `1px solid ${newCOLORS.lightGray}`,
            height: '100%',
          }}
        >
          <Title
            order={4}
            style={{
              fontSize: '2.0rem',
              paddingBottom: '10px',
              fontWeight: 'bold',
              marginBottom: '20px',
              borderBottom: `1px solid ${newCOLORS.lightGray}`,
            }}
          >
            <Icon icon="material-symbols:measuring-tape" style={{ fontSize: '2.0rem', color: newCOLORS.darkGray }} />{' '}
            How should I use this measure?
          </Title>
          <Text style={{ textAlign: 'left', color: newCOLORS.darkGray }}>{measure.purpose}</Text>
        </Box>
        <Box style={{ minWidth: '55%', marginLeft: '-30px !important', height: '100%' }}>
          <Title
            order={4}
            style={{
              fontSize: '2.0rem',
              paddingBottom: '10px',
              paddingLeft: '20px',
              fontWeight: 'bold',
              marginBottom: '20px',
              borderBottom: `1px solid ${newCOLORS.lightGray}`,
            }}
          >
            <Icon icon="material-symbols:calculate" style={{ fontSize: '2.0rem', color: newCOLORS.darkGray }} /> How it
            is calculated
          </Title>
          <Text style={{ textAlign: 'left', paddingLeft: '20px', color: newCOLORS.darkGray }}>
            {measure.description}
          </Text>
        </Box>
      </Group>
    </Card>
  );
}
