import { styled } from '@linaria/react';
import { forwardRef, HTMLAttributes } from 'react';
import { inkDark } from '../../styles/design-tokens';
import { Icon } from '../icon/icon';
import { getIconSize, getRadius, getSize } from './avatar.helpers';
import { Size } from './avatar.type';

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: Size;
  radius?: Size;
  src?: string;
  interactive?: boolean;
}

export const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ size = 'md', radius = 'sm', src, interactive = true, ...props }, ref) => (
    <Container ref={ref} size={size} radius={radius} interactive={interactive} {...props}>
      {src ? (
        <Image src={src} alt="avatar" />
      ) : (
        <Icon
          name="account_circle"
          color={inkDark}
          size={getIconSize(size)}
          style={{ cursor: interactive ? 'pointer' : 'default' }}
        />
      )}
    </Container>
  ),
);

const Container = styled.div<{ size: Size; radius: Size; interactive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sky-light);
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  border-radius: ${({ radius }) => getRadius(radius)};
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'default')};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;
