import { styled } from '@linaria/react';
import { Checkbox, Group, Select } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { baseBlack, redBase, skyDark } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { formatTimeToDisplay, formatTimeToLocal, formatTimeToUTC } from '../data-management.helpers';
import { useEditBoardSetting } from './board-settings-client.hooks';
import { BoardSetting, BoardSettingMethodology, EditBoardSettingPayload } from './board-settings-client.types';

dayjs.extend(utc);

type BoardSettingsMetadataProps = {
  selectedBoardSetting: BoardSetting | null;
  isEditing: boolean;
};

export function BoardSettingsMetadata({ selectedBoardSetting, isEditing }: BoardSettingsMetadataProps) {
  const { editBoardSetting, isEditing: isEditingBoardSetting } = useEditBoardSetting();
  const [startTime, setStartTime] = useState(formatTimeToLocal(selectedBoardSetting?.working_hours_start));
  const [endTime, setEndTime] = useState(formatTimeToLocal(selectedBoardSetting?.working_hours_end));
  const [timeError, setTimeError] = useState<string | null>(null);

  const validateTimes = (start: string, end: string): boolean => {
    if (!start || !end) return true;
    const startMinutes = dayjs(`2000-01-01T${start}`).hour() * 60 + dayjs(`2000-01-01T${start}`).minute();
    const endMinutes = dayjs(`2000-01-01T${end}`).hour() * 60 + dayjs(`2000-01-01T${end}`).minute();
    return startMinutes < endMinutes;
  };

  const handleChange = async (key: keyof EditBoardSettingPayload, value: string | boolean | null) => {
    if (!isEditing || isEditingBoardSetting || !selectedBoardSetting?.id) return;
    await editBoardSetting(selectedBoardSetting.id, { [key]: value });
  };

  const handleTimeChange = async (key: 'working_hours_start' | 'working_hours_end', value: string) => {
    if (!isEditing || isEditingBoardSetting || !selectedBoardSetting?.id) return;
    setTimeError(null);
    if (key === 'working_hours_start') {
      setStartTime(value);
      if (!validateTimes(value, endTime)) {
        setTimeError('Start time cannot be greater than end time');
      }
    } else {
      setEndTime(value);
      if (!validateTimes(startTime, value)) {
        setTimeError('Start time cannot be greater than end time');
      }
    }
  };

  const handleTimeSubmit = async (key: 'working_hours_start' | 'working_hours_end', value: string) => {
    if (!isEditing || isEditingBoardSetting || !selectedBoardSetting?.id || !value) return;

    if (key === 'working_hours_start' && !validateTimes(value, endTime)) {
      setTimeError('Start time cannot be greater than end time');
      return;
    }
    if (key === 'working_hours_end' && !validateTimes(startTime, value)) {
      setTimeError('Start time cannot be greater than end time');
      return;
    }

    const utcTime = formatTimeToUTC(value);
    await editBoardSetting(selectedBoardSetting.id, { [key]: utcTime });
    setTimeError(null);
  };

  const displayTime = (time: string | undefined) => {
    return formatTimeToDisplay(formatTimeToLocal(time));
  };

  const methodologyOptions = Object.values(BoardSettingMethodology).map((value) => ({
    value: value,
    label: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
  }));

  return (
    <BoardSettingsInfoContainer>
      <BoardSettingsInfoGroup>
        <Group gap="8px">
          <Checkbox
            disabled={!isEditing || isEditingBoardSetting}
            checked={selectedBoardSetting?.points_for_bug_cards || false}
            onChange={(event) => handleChange('points_for_bug_cards', event.currentTarget.checked)}
          />
          <CheckboxText $isEditing={isEditing} $checked={selectedBoardSetting?.points_for_bug_cards}>
            Does your team assign points to bugs?
          </CheckboxText>
        </Group>
        <Group gap="8px">
          <Checkbox
            disabled={!isEditing || isEditingBoardSetting}
            checked={selectedBoardSetting?.label_for_card_mandatory || false}
            onChange={(event) => handleChange('label_for_card_mandatory', event.currentTarget.checked)}
          />
          <CheckboxText $isEditing={isEditing} $checked={selectedBoardSetting?.label_for_card_mandatory}>
            Does your team expect every card to have a label?
          </CheckboxText>
        </Group>
      </BoardSettingsInfoGroup>
      {isEditing ? (
        <Group gap="16px" style={{ position: 'relative' }}>
          <div>
            <TimeInput
              value={startTime}
              radius="md"
              w="200px"
              label="Working Hours START"
              rightSection={<Icon name="access_time" size={16} color={skyDark} />}
              disabled={isEditingBoardSetting}
              onChange={(event) => handleTimeChange('working_hours_start', event.currentTarget.value)}
              onBlur={(event) => handleTimeSubmit('working_hours_start', event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleTimeSubmit('working_hours_start', event.currentTarget.value);
                  event.currentTarget.blur();
                }
              }}
              onClick={(event) => event.currentTarget.showPicker()}
              style={{ cursor: 'pointer' }}
              error={!!timeError}
            />
          </div>
          <div>
            <TimeInput
              value={endTime}
              radius="md"
              w="200px"
              label="Working Hours END"
              rightSection={<Icon name="access_time" size={16} color={skyDark} />}
              disabled={isEditingBoardSetting}
              onChange={(event) => handleTimeChange('working_hours_end', event.currentTarget.value)}
              onBlur={(event) => handleTimeSubmit('working_hours_end', event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleTimeSubmit('working_hours_end', event.currentTarget.value);
                  event.currentTarget.blur();
                }
              }}
              onClick={(event) => event.currentTarget.showPicker()}
              style={{ cursor: 'pointer' }}
              error={!!timeError}
            />
          </div>
          {timeError && <ErrorText>{timeError}</ErrorText>}
        </Group>
      ) : (
        <BoardSettingsInfoGroup>
          <Group gap="8px">
            <Text>Working Hours START:</Text>
            <Text weight="bold">{displayTime(selectedBoardSetting?.working_hours_start)}</Text>
          </Group>
          <Group gap="8px">
            <Text>Working Hours END:</Text>
            <Text weight="bold">{displayTime(selectedBoardSetting?.working_hours_end)}</Text>
          </Group>
        </BoardSettingsInfoGroup>
      )}
      <BoardSettingsInfoGroup>
        <Group gap="8px">
          <Text weight="medium">Methodology:</Text>
          <Select
            data={methodologyOptions}
            value={selectedBoardSetting?.methodology || BoardSettingMethodology.UNKNOWN}
            onChange={(value) => handleChange('methodology', value)}
            placeholder="Select methodology"
            radius="xl"
            disabled={!isEditing || isEditingBoardSetting}
          />
        </Group>
      </BoardSettingsInfoGroup>
    </BoardSettingsInfoContainer>
  );
}

const BoardSettingsInfoContainer = styled.div`
  display: flex;
  gap: 64px;
`;

const BoardSettingsInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CheckboxText = styled(Text)<{ $isEditing?: boolean; $checked?: boolean }>`
  color: ${({ $isEditing, $checked }) => (!$isEditing && !$checked ? skyDark : baseBlack)};
`;

const ErrorText = styled(Text)`
  color: ${redBase};
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 12px;
`;
