import { NavigateFunction } from 'react-router-dom';
import { CostByInitiativeResponse } from '../../api/financials-client/financials-client.type';
import { UNCHECKED_COLOR } from '../allocation-of-costs/allocation-of-costs';
import { MAX_SELECTED_INITIATIVES } from './cost-by-initiative.constants';
import { ShowCostByInitiativeChartBar } from './cost-by-initiative.type';

/**
 * Helper function to initialize the showCostByInitiativeChartBar state
 * @param data
 * @param setShowCostByInitiativeChartBar
 * @param availableColors
 */
const initShowCostByInitiativeChartBar = (
  data: CostByInitiativeResponse[],
  setShowCostByInitiativeChartBar: React.Dispatch<React.SetStateAction<ShowCostByInitiativeChartBar[]>>,
  availableColors: string[],
) => {
  setShowCostByInitiativeChartBar(
    [...data]
      .sort((a, b) => b.cost_to_date - a.cost_to_date)
      .map(
        (item) =>
          ({
            id: item.id,
            show: availableColors.length > 0 && item.cost_to_date > 0,
            color: availableColors.length > 0 && item.cost_to_date > 0 ? availableColors.pop() : UNCHECKED_COLOR,
          }) as ShowCostByInitiativeChartBar,
      ),
  );
};

/**
 * Helper function to update the showCostByInitiativeChartBar state when a checkbox is clicked
 * @param checked
 * @param id
 * @param currentColor
 * @param availableColors
 * @param setShowError
 * @param setAvailableColors
 * @param setShowCostByInitiativeChartBar
 */
const updateShowCostByInitiativeChartBar = (
  checked: boolean,
  id: string,
  currentColor: string,
  availableColors: string[],
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
  setAvailableColors: React.Dispatch<React.SetStateAction<string[]>>,
  setShowCostByInitiativeChartBar: React.Dispatch<React.SetStateAction<ShowCostByInitiativeChartBar[]>>,
) => {
  if (availableColors.length == 0 && checked == true) {
    setShowError(true);
  } else {
    setShowError(false);
  }

  if (checked == false && currentColor !== UNCHECKED_COLOR) {
    setAvailableColors((prevColors) => [...prevColors, currentColor]);
  }

  const newColor = checked && availableColors.length > 0 ? availableColors.pop() : UNCHECKED_COLOR;

  setShowCostByInitiativeChartBar((prevShowBar) => {
    return prevShowBar.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          show: checked,
          color: checked ? newColor || UNCHECKED_COLOR : UNCHECKED_COLOR,
        };
      } else {
        return item;
      }
    });
  });
};

/**
 * Helper function to navigate to the strategy page
 * @param navigate
 */
const onAddInitiativeClick = (navigate: NavigateFunction) => {
  const href = '/application/strategy';
  navigate(href);
};

/**
 * Counts the number of initiatives that are marked as shown.
 *
 * @param {ShowCostByInitiativeChartBar[]} showCostByInitiativeChartBar - Array of initiatives with visibility status.
 * @returns {number} The count of initiatives that are currently set to be displayed.
 */
const countSelectedInitiatives = (showCostByInitiativeChartBar: ShowCostByInitiativeChartBar[]): number => {
  return showCostByInitiativeChartBar.filter((item) => item.show).length;
};

/**
 * Determines if the maximum number of initiatives are selected.
 *
 * @param {ShowCostByInitiativeChartBar[]} showCostByInitiativeChartBar - Array of initiatives with visibility status.
 * @returns {boolean} True if the number of selected initiatives equals the maximum allowed (12), otherwise false.
 */
const isMaxSelected = (showCostByInitiativeChartBar: ShowCostByInitiativeChartBar[]): boolean => {
  return countSelectedInitiatives(showCostByInitiativeChartBar) === MAX_SELECTED_INITIATIVES;
};

export { initShowCostByInitiativeChartBar, isMaxSelected, onAddInitiativeClick, updateShowCostByInitiativeChartBar };
