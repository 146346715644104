import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FlowByPaceDataOverTime,
  FlowByPhaseDataOverTime,
} from '../../../api/work-periods-client/work-periods-client.type';
import { CumulativeFlowChart } from '../../../components/burn-flow/burn-flow-chart';
import { ViewByType } from '../../../components/burn-flow/burn-flow-chart.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDefaultDay, useWorkPeriodLength } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { ContentTag, HeadingTag } from '../../../styles/shared-styled-components';
import { spliceByDay } from '../../sprint-assessment/sprint-assessment.helpers';
import { Mode, WorkPeriodType } from '../process-analysis.type';
import { phaseMetadata } from './assessment-view.data';
import { calculateMeasurePercentage, formatFlowByPhaseData } from './assessment-view.helpers';
import { AssessmentChart, Metric } from './assessment-view.type';
import { PhaseCard } from './cards/phase-card';
import { ChartContainer } from './chart-container/chart-container';
import { calculateDate } from './section-phase.helpers';

type Props = {
  data?: { flow_by_phase?: FlowByPhaseDataOverTime; flow_by_pace?: FlowByPaceDataOverTime };
};

export const SectionPhase = ({ data }: Props) => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriodLength = useWorkPeriodLength();
  const defaultDay = useDefaultDay();
  const navigate = useNavigate();
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);
  const [activeDay, setActiveDay] = useState<string | null>(defaultDay);
  const endDate = useProcessAnalysisStore((state) => state.endDate);

  useEffect(() => {
    setActiveDay(defaultDay);
  }, [defaultDay]);

  const sprintBurnsAndFlows = formatFlowByPhaseData(data?.flow_by_phase, data?.flow_by_pace);

  const title = (
    <>
      <LargeHeadingTag>
        <LargeHeadingTagAnchor
          onClick={() => navigate('/application/process-analysis/task-flow')}
          style={{ textDecoration: 'underline' }}
        >
          Flow of work, by phase
        </LargeHeadingTagAnchor>
      </LargeHeadingTag>
      <ContentTag>How each planned task is flowing through the phases of work in this work period.</ContentTag>
    </>
  );

  return (
    <ChartContainer
      chartId={AssessmentChart.Phase}
      title={title}
      day={activeDay}
      setDay={setActiveDay}
      showDaySelect={workPeriodType === WorkPeriodType.Defined}
      metric={metric}
      setMetric={setMetric}
    >
      <DayDetailsBox>
        {activeDay && <LargeHeadingTag>{`Day ${activeDay} of ${workPeriodLength}`}</LargeHeadingTag>}
        <Cards>
          {phaseMetadata.map((item) => (
            <PhaseCard
              key={item.id}
              label={item.label}
              description={item.description}
              legend
              color={item.color}
              suffix={'%'}
              value={calculateMeasurePercentage(
                item.id,
                metric === Metric.Tasks
                  ? sprintBurnsAndFlows.flows_tasks.data_points
                  : sprintBurnsAndFlows.flows_points.data_points,
                Number(activeDay),
              )}
              date={
                workPeriodType === WorkPeriodType.Defined
                  ? calculateDate(workPeriod, activeDay, workPeriodLength)
                  : dayjs(endDate).format('YYYY-MM-DD')
              }
            />
          ))}
        </Cards>
      </DayDetailsBox>
      <CumulativeFlowChart
        mode={Mode.WorkPeriod}
        cumulative_flow_points={spliceByDay(sprintBurnsAndFlows.flows_points, activeDay)}
        cumulative_flow_tasks={spliceByDay(sprintBurnsAndFlows.flows_tasks, activeDay)}
        sprintBurnsAndFlows={sprintBurnsAndFlows}
        legend={false}
        header={false}
        dataType={metric as unknown as ViewByType}
      />
    </ChartContainer>
  );
};

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const LargeHeadingTagAnchor = styled.span`
  color: ${newCOLORS.indigo};
  text-decoration: underline;
  cursor: pointer;
`;

const DayDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
