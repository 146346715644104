import { styled } from '@linaria/react';
import { Checkbox, LoadingOverlay, Select, Title } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { Fragment, useContext, useEffect, useMemo, useRef } from 'react';
import { icons } from '../../../assets/icons/icons';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useEntities } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { SelectContainer, SelectLabel, selectData } from '../../strategy/initiative-performance/collapsible-section';
import { RowStart, TD } from '../key-measures/key-measures-over-time';
import { Pills } from '../pills/pills';
import { ProcessAnalysisChart } from '../process-analysis-chart';
import { ProcessAnalysisContext } from '../process-analysis.context';
import { ITEM_COLORS, getPills } from '../process-analysis.helpers';
import { Entities, MetricType, Section } from '../process-analysis.type';
import { useInitiativeCompletionData } from './initiative-completion.hooks';
import { DropShadowContainer } from './initiative-overview';
import {
  handleChangeEntity,
  handleChangeTrend,
  matchEntitiesWithColor,
  sortInitiatives,
} from './initiative-sections.helpers';

export function InitiativeCompletion() {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  const {
    [Section.InitiativeCompletion]: {
      metric,
      initiatives,
      selectedInitiatives,
      selectedTrends,
      initiativesData,
      setMetric,
      setInitiatives,
      setSelectedInitiatives,
      setSelectedTrends,
      setInitiativesData,
    },
  } = useContext(ProcessAnalysisContext);

  const handleDownload = () => canvasHtmlDownload(pills.join('_'), downloadRef, exceptionRef);

  const { data: initiativeCompletions, isFetching } = useInitiativeCompletionData();
  const initiativeIdsAndNames = sortInitiatives(initiativeCompletions);

  const memoizedCompletions = useMemo(() => initiativeCompletions, [initiativeCompletions]);
  const prevDataRef = useRef(initiativesData);

  useEffect(() => {
    if (memoizedCompletions && !isEqual(prevDataRef.current, memoizedCompletions)) {
      prevDataRef.current = memoizedCompletions;
      setInitiativesData(memoizedCompletions);
      setInitiatives(initiativeIdsAndNames);
      setSelectedInitiatives(initiativeIdsAndNames.length ? [initiativeIdsAndNames[0]] : []);
      setSelectedTrends([]);
    }
  }, [
    memoizedCompletions,
    initiativeIdsAndNames,
    setInitiatives,
    setInitiativesData,
    setSelectedInitiatives,
    setSelectedTrends,
  ]);

  const handleRemoveInitiative = (initiativeId: string) => {
    setInitiatives(initiatives.filter(({ id }) => id !== initiativeId));
    setSelectedInitiatives(selectedInitiatives.filter(({ id }) => id !== initiativeId));
    setSelectedTrends(selectedTrends.filter(({ id }) => id !== initiativeId));
  };

  const initiativeIdSet = new Set(initiatives.map((i) => i.id));
  const initiativeOptions = initiativeIdsAndNames.filter(({ id }) => !initiativeIdSet.has(id));

  return (
    <DropShadowContainer ref={downloadRef}>
      <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
          <Pills pillText={pills} />
          <SelectContainer style={{ marginLeft: 'auto' }}>
            <SelectLabel>Value</SelectLabel>
            <Select
              label=""
              size="xs"
              style={{ width: 140 }}
              value={metric}
              data={selectData}
              onChange={(value) => setMetric(value as MetricType)}
              allowDeselect={false}
            />
          </SelectContainer>
          <div ref={exceptionRef}>
            {<DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />}
          </div>
        </div>
        <Title>Initiative Completion</Title>
      </div>
      {initiativeCompletions ? (
        <ContentContainer>
          <LegendContainer>
            <LegendScrollContainer>
              {initiatives.length > 0 ? (
                <LegendTable>
                  <thead>
                    <tr>
                      <StickyTH>Initiatives</StickyTH>
                      <StickyTH>Line</StickyTH>
                      <StickyTH>Trend</StickyTH>
                      <StickyTH></StickyTH>
                    </tr>
                    <tr style={{ height: '16px' }}></tr>
                  </thead>
                  <tbody>
                    {initiatives.map(({ id, name }, index) => {
                      const color = ITEM_COLORS[index % ITEM_COLORS.length];
                      return (
                        <Fragment key={index}>
                          <tr>
                            <RowStart color={color}>{name}</RowStart>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={new Set(selectedInitiatives.map((i) => i.id)).has(id)}
                                onChange={(event) =>
                                  handleChangeEntity(
                                    event.currentTarget.checked,
                                    { id, name },
                                    selectedInitiatives,
                                    setSelectedInitiatives,
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <Checkbox
                                color={findClosestMantineColor(color)}
                                checked={new Set(selectedTrends.map((i) => i.id)).has(id)}
                                onChange={(event) =>
                                  handleChangeTrend(
                                    event.currentTarget.checked,
                                    { id, name },
                                    selectedTrends,
                                    setSelectedTrends,
                                  )
                                }
                                size="xs"
                                style={{ paddingBottom: '2px' }}
                              />
                            </TD>
                            <TD>
                              <DeleteIcon
                                src={icons.iconDelete}
                                onClick={() => handleRemoveInitiative(id)}
                                alt="delete"
                              />
                            </TD>
                          </tr>
                          <tr style={{ height: '4px' }}></tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </LegendTable>
              ) : (
                <NoInitiativesPlaceholder>
                  {initiativeIdsAndNames.length > 0
                    ? 'No initiatives selected. Please select an initiative from the dropdown below.'
                    : `No initiatives found for the selected ${activeTab.slice(0, -1)}.`}
                </NoInitiativesPlaceholder>
              )}
            </LegendScrollContainer>
            {initiativeOptions.length > 0 && (
              <DropdownContainer>
                <ComboboxSelect
                  options={initiativeOptions.map(({ id, name }) => ({ value: id, label: name }))}
                  onChange={(value) => {
                    const newSelection = initiativeIdsAndNames.find(({ id }) => id === value);
                    setInitiatives(newSelection ? [...initiatives, newSelection] : initiatives);
                  }}
                  placeholder="Select initiative"
                />
              </DropdownContainer>
            )}
          </LegendContainer>
          <ChartContainer>
            <ProcessAnalysisChart
              initiativeData={initiativeCompletions}
              metric={metric}
              entitiesWithColor={matchEntitiesWithColor(selectedInitiatives, initiatives) as Entities}
              entityTrendsWithColor={matchEntitiesWithColor(selectedTrends, initiatives) as Entities}
              type={'completion'}
            />
          </ChartContainer>
        </ContentContainer>
      ) : null}
    </DropShadowContainer>
  );
}

export const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;
const TH = styled.th`
  color: ${newCOLORS.gray};
  font-size: 14px;
`;

export const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
`;

export const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const DeleteIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
`;

export const NoInitiativesPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 16px;
  text-align: center;
  color: ${newCOLORS.gray};
  font-style: italic;
`;
