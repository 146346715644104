export enum BoardSettingMethodology {
  KANBAN = 'kanban',
  SAFE = 'safe',
  SCRUM = 'scrum',
  UNKNOWN = 'unknown',
}

type Time24Hour = `${number}:${number}`;

type BoardSetting = {
  id: string;
  name: string;
  organization_id: string;
  label_for_card_mandatory: boolean;
  methodology: BoardSettingMethodology;
  points_for_bug_cards: boolean;
  working_hours_end: Time24Hour;
  working_hours_start: Time24Hour;
};

type CreateBoardSettingPayload = {
  organization_id: string;
  name?: string;
  label_for_card_mandatory?: boolean;
  methodology?: BoardSettingMethodology;
  points_for_bug_cards?: boolean;
  working_hours_end?: Time24Hour;
  working_hours_start?: Time24Hour;
};

type EditBoardSettingPayload = {
  name?: string;
  label_for_card_mandatory?: boolean;
  methodology?: BoardSettingMethodology;
  points_for_bug_cards?: boolean;
  working_hours_end?: Time24Hour;
  working_hours_start?: Time24Hour;
};

export type { BoardSetting, CreateBoardSettingPayload, EditBoardSettingPayload };
