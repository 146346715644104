import dayjs from 'dayjs';
import { capitalizeFirstLetter, isString, sanitizeString } from '../../helpers/string-helpers/string-helpers';
import { Grouping, SortedColumn, TaskRecord, TaskType } from './tasks-table.type';

/**
 * Formats the given records by sorting 'initiatives' arrays within each record.
 *
 * @param {TaskRecord[]} records - Array of TaskRecord objects to be formatted.
 * @return {TaskRecord[]} - Formatted array of TaskRecord objects.
 */
const formatRecords = (records: TaskRecord[]): TaskRecord[] => {
  return records.map((record) => ({
    ...record,
    initiatives: sortEntities(record.initiatives),
  }));
};

/**
 * Returns the text representation of story points.
 *
 * @param {number | null} points - The number of story points.
 * @returns {string} The text representation of story points.
 */
const getStoryPointsText = (points: number | null): string => {
  if (points === null) {
    return 'No point assigned';
  }

  const pluralSuffix = points === 1 ? '' : 's';

  return `${points} point${pluralSuffix}`;
};

/**
 * Returns the text representation of the task type.
 *
 * @param {TaskType | null} type - the task type
 * @returns {string} the capitalized text representation of the task type
 */
const getTaskTypeText = (type: TaskType | null): string => {
  if (!type) {
    return 'No type';
  }

  return capitalizeFirstLetter(type);
};

/**
 * Sorts the given array of records based on the specified sorting criteria.
 *
 * @param {TaskRecord[]} records - The array of records to be sorted.
 * @param {Object} options - The sorting options.
 * @param {string} options.sortBy - The column to sort by ('name' | 'epics' | 'initiatives' | 'points' | 'type' | 'created').
 * @param {boolean} options.sortDesc - Indicates whether to sort in descending order.
 * @returns {TaskRecord[]} - The sorted array of records.
 */
const sortRecords = (
  records: TaskRecord[],
  { sortBy, sortDesc }: { sortBy: SortedColumn; sortDesc: boolean },
): TaskRecord[] => {
  return records.toSorted((a, b) => {
    if (['name', 'title'].includes(sortBy)) {
      const field = sortBy as 'name' | 'title';
      const aValue = sanitizeString(a[field]);
      const bValue = sanitizeString(b[field]);

      return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (sortBy === 'epics') {
      const aValue = isString(a.epic?.name) ? sanitizeString(a.epic?.name) : '';
      const bValue = isString(b.epic?.name) ? sanitizeString(b.epic?.name) : '';

      if (aValue === '' && bValue === '') {
        return 0;
      }

      if (aValue === '') {
        return 1;
      }

      if (bValue === '') {
        return -1;
      }

      return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (['initiatives'].includes(sortBy)) {
      const field = sortBy as 'initiatives';
      const aValue = isString(a[field][0]?.name) ? sanitizeString(a[field][0]?.name) : '';
      const bValue = isString(b[field][0]?.name) ? sanitizeString(b[field][0]?.name) : '';

      if (aValue === '' && bValue === '') {
        return 0;
      }

      if (aValue === '') {
        return 1;
      }

      if (bValue === '') {
        return -1;
      }

      return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (sortBy === 'points') {
      const aValue = a.points;
      const bValue = b.points;

      if (aValue === null && bValue === null) {
        return 0;
      }

      if (aValue === null) {
        return 1;
      }

      if (bValue === null) {
        return -1;
      }

      return sortDesc ? bValue - aValue : aValue - bValue;
    }

    if (sortBy === 'type') {
      const aValue = a.type;
      const bValue = b.type;

      if (aValue === null && bValue === null) {
        return 0;
      }

      if (aValue === null) {
        return 1;
      }

      if (bValue === null) {
        return -1;
      }

      return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (sortBy === 'created') {
      const aValue = dayjs(a.created_date);
      const bValue = dayjs(b.created_date);

      return sortDesc ? bValue.diff(aValue) : aValue.diff(bValue);
    }

    return 0;
  });
};

/**
 * Sorts the entities array based on the name property after sanitizing the strings.
 *
 * @param {T[]} entities - An array of objects with an id and name property to be sorted.
 * @return {T[]} The sorted array of entities.
 */
const sortEntities = <T extends { id: string; name: string }>(entities: T[]): T[] => {
  if (entities.length > 1) {
    return entities.toSorted((a, b) => sanitizeString(a.name).localeCompare(sanitizeString(b.name)));
  }

  return entities;
};

/**
 * Retrieves the group keys based on the provided grouping and task record.
 *
 * @param {Grouping | null} grouping - The grouping to use for retrieving the group key.
 * @param {TaskRecord} record - The task record used for retrieving the group key.
 * @returns {string[]} The retrieved group keys based on the provided grouping and task record.
 */
const getGroupKeys = (grouping: Grouping | null, record: TaskRecord): string[] => {
  if (!grouping) {
    return [];
  }

  if (grouping === Grouping.Initiative) {
    const data = removeDuplicates(record[grouping] || []);
    const keys = data.map((item) => item.name || 'unmapped');

    return keys.length > 0 ? keys : ['unmapped'];
  }

  if (grouping === Grouping.Epic) {
    return record.epic ? [record.epic.name || 'unmapped'] : ['unmapped'];
  }

  const value = record[grouping as 'points' | 'type'];

  return value || value === 0 ? [String(value)] : ['unmapped'];
};

/**
 * Calculates and groups tasks based on the provided grouping.
 *
 * @param {Grouping | null} grouping - The grouping used for grouping the tasks.
 * @param {TaskRecord[]} tasks - The array of tasks to be grouped.
 * @return {Record<string, TaskRecord[]>} The grouped tasks based on the provided grouping.
 */
const getGroups = (grouping: Grouping | null, tasks: TaskRecord[]): Record<string, TaskRecord[]> => {
  const groups = tasks.reduce(
    (acc, task) => {
      const groupKeys = getGroupKeys(grouping, task);

      groupKeys.forEach((groupKey) => {
        if (!acc[groupKey]) {
          acc[groupKey] = [task];
        } else {
          acc[groupKey].push(task);
        }
      });

      return acc;
    },
    {} as Record<string, TaskRecord[]>,
  );

  return groups;
};

/**
 * Calculates the mapped percentage based on the grouping and the tasks.
 *
 * @param {Grouping | null} grouping - The grouping for filtering the tasks.
 * @param {TaskRecord[]} tasks - The array of tasks to be filtered.
 * @returns {number} The mapped percentage calculated from the filtered tasks.
 */
const getMappedPercentage = (grouping: Grouping | null, tasks: TaskRecord[]): number => {
  if (!grouping || !tasks.length) {
    return 0;
  }

  const mappedTasks = tasks.filter((task) => {
    if (grouping === Grouping.Initiative) {
      const initiatives = task.initiatives || [];
      return initiatives.length > 0;
    }

    if (grouping === Grouping.Epic) {
      return task.epic !== null;
    }

    return task[grouping as 'points' | 'type'] !== null;
  });

  return Math.round((mappedTasks.length / tasks.length) * 100);
};

/**
 * Retrieves the information label associated with the provided grouping.
 *
 * @param {Grouping | null} grouping - The grouping to retrieve the label for
 * @returns {string} The label associated with the provided grouping, or an empty string if the grouping is null
 */
const getInformationLabel = (grouping: Grouping | null): string => {
  if (!grouping) {
    return '';
  }

  const labels = {
    [Grouping.Initiative]: 'Mapped to Initiative',
    [Grouping.Epic]: 'Mapped to Epic',
    [Grouping.Points]: 'Assigned Points',
    [Grouping.Type]: 'Assigned Type',
  };

  return labels[grouping];
};

/**
 * Returns the appropriate title for the given grouping and group key.
 *
 * @param {Grouping | null} grouping - The grouping to determine the title for.
 * @param {string} groupKey - The key to determine the title for.
 * @return {string} The appropriate title for the given grouping and group key.
 */
const getGroupTitle = (grouping: Grouping | null, groupKey: string): string => {
  if (!grouping) {
    return 'Unmapped';
  }

  const unmappedLabels = {
    [Grouping.Initiative]: 'Unmapped',
    [Grouping.Epic]: 'Unmapped',
    [Grouping.Points]: 'No points',
    [Grouping.Type]: 'No type',
  };

  if (groupKey === 'unmapped') {
    return unmappedLabels[grouping];
  }

  const mappedLabels = {
    [Grouping.Initiative]: `${groupKey} Initiative`,
    [Grouping.Epic]: `${groupKey} Epic`,
    [Grouping.Points]: `${groupKey} point${groupKey === '1' ? '' : 's'}`,
    [Grouping.Type]: capitalizeFirstLetter(groupKey),
  };

  return mappedLabels[grouping];
};

/**
 * Sorts the given array of group keys.
 *
 * @param {Grouping} grouping - The grouping to sort group keys for.
 * @param {string[]} groupKeys - the array of group keys to be sorted
 * @returns {string[]} the sorted array of group keys
 */
const sortGroupKeys = (grouping: Grouping, groupKeys: string[]): string[] => {
  return groupKeys.toSorted((a, b) => {
    if (a === 'unmapped' && b === 'unmapped') {
      return 0;
    }

    if (a === 'unmapped') {
      return 1;
    }

    if (b === 'unmapped') {
      return -1;
    }

    if (grouping === Grouping.Points) {
      return Number(a) - Number(b);
    }

    return sanitizeString(a).localeCompare(sanitizeString(b));
  });
};

/**
 * Removes duplicates from an array of objects based on the 'id' property.
 *
 * @param {T[]} data - array of objects with 'id' and 'name' properties
 * @return {T[]} array of objects with duplicates removed
 */
const removeDuplicates = <T extends { id: string; name: string }>(data: T[]): T[] => {
  return data.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.id === item.id);

    return existingItem ? acc : [...acc, item];
  }, [] as T[]);
};

export {
  formatRecords,
  getGroupKeys,
  getGroups,
  getGroupTitle,
  getInformationLabel,
  getMappedPercentage,
  getStoryPointsText,
  getTaskTypeText,
  removeDuplicates,
  sortEntities,
  sortGroupKeys,
  sortRecords,
};
