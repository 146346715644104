import { Fragment } from 'react/jsx-runtime';
import { TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { KeyMeasuresOverTime } from '../key-measures/key-measures-over-time';
import { MonthlyAverages } from '../key-measures/monthly-averages';
import { Tab } from '../process-analysis.type';
import { WorkloadDistribution } from '../workload-distribution/workload-distribution';
import { useKeyMeasuresOverTimeData } from './comparison-view.hooks';

export function ComparisonView() {
  const { data: monthlyAveragesData, isFetching: isFetchingMonthlyAveragesData } = useKeyMeasuresOverTimeData(
    TimeAllocationType.Monthly,
  );
  const { data: keyMeasuresOverTimeData, isFetching: isFetchingKeyMeasuresOverTimeData } = useKeyMeasuresOverTimeData();
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  return (
    <Fragment>
      <MonthlyAverages tableData={monthlyAveragesData || {}} dataIsLoading={isFetchingMonthlyAveragesData} />
      {activeTab === Tab.Teams ? <WorkloadDistribution /> : null}
      <KeyMeasuresOverTime
        tableData={keyMeasuresOverTimeData || {}}
        dataIsLoading={isFetchingKeyMeasuresOverTimeData}
      />
    </Fragment>
  );
}
