import { Portfolio } from '../../../../api/portfolio-client/portfolio-client.type';
import { PanelList } from '../panel-list';
import { usePortfolioCreate } from './portfolios.hooks';

type PortfolioListProps = {
  portfolios: Portfolio[];
  selectedPortfolio: Portfolio | null;
  setSelectedPortfolioId: (portfolioId: string) => void;
};

export function PortfoliosList({ portfolios, selectedPortfolio, setSelectedPortfolioId }: PortfolioListProps) {
  const { createNewPortfolio, isCreating } = usePortfolioCreate();

  const handleCreateClick = async () => {
    const newId = await createNewPortfolio();
    setSelectedPortfolioId(newId);
  };

  return (
    <PanelList
      title="Portfolios"
      items={portfolios}
      selectedItem={selectedPortfolio}
      onSelect={(portfolio) => setSelectedPortfolioId(portfolio.id)}
      onAddNew={handleCreateClick}
      isLoading={isCreating}
      getItemId={(portfolio) => portfolio.id}
      getItemDisplayText={(portfolio) => portfolio.name}
    />
  );
}
