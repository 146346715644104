import { PanelList } from '../panel-list';
import { useCreateBoardSetting } from './board-settings-client.hooks';
import { BoardSetting } from './board-settings-client.types';

type BoardSettingsListProps = {
  boardSettings: BoardSetting[];
  selectedBoardSetting: BoardSetting | null;
  setSelectedBoardSettingId: (boardSettingId: string) => void;
  setIsEditing: (isEditing: boolean) => void;
};

export function BoardSettingsList({
  boardSettings,
  selectedBoardSetting,
  setSelectedBoardSettingId,
  setIsEditing,
}: BoardSettingsListProps) {
  const { useCreateBoardSettingHook: createBoardSetting, isCreating } = useCreateBoardSetting();

  const handleCreateClick = async () => {
    const newBoardSetting = await createBoardSetting();
    setSelectedBoardSettingId(newBoardSetting.id);
  };

  return (
    <PanelList
      title="Board Settings"
      items={boardSettings}
      selectedItem={selectedBoardSetting}
      onSelect={(boardSetting) => {
        setSelectedBoardSettingId(boardSetting.id);
        setIsEditing(false);
      }}
      onAddNew={handleCreateClick}
      isLoading={isCreating}
      getItemId={(boardSetting) => boardSetting.id}
      getItemDisplayText={(boardSetting) => boardSetting.name}
    />
  );
}
