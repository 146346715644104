import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, NumberInput, Select } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spending } from '../../api/financials-client/financials-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { TeamContext } from '../../contexts';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import {
  SmallerHeading,
  StandardText,
  StyledButton,
  UppercaseCallout,
} from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { checkDisabled, getDatumBudget } from './edit-financial-data.helper';
import { CollapseStates, EditFinancialDataProps } from './edit-financial-data.type';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const EditFinancialDataForm = ({
  setSpending,
  spending,
  persistedSpending,
  projects,
  year,
  setYear,
}: EditFinancialDataProps) => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const teamContext = useContext(TeamContext);

  const navigate = useNavigate();
  const today: Date = new Date();
  const [team, setTeam] = useState<ProjectsResponse | null>(null);
  const [currentFinancialDatum, setCurrentFinancialDatum] = useState<Spending | null>();
  const { portfolioId = '' } = useParams<{ portfolioId: string }>();
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const [collapseStates, setCollapseStates] = useState<CollapseStates>({});

  useEffect(() => {
    setDisableAdd(team ? false : true);
  }, [team]);

  useEffect(() => {
    if (portfolio) {
      setTeam(null);
      navigate(`/application/financials/portfolio/${portfolio.id}/add-edit-financials`);
    }
  }, [portfolio, navigate]);

  useEffect(() => {
    const initialStates = projects.reduce(
      (acc, project) => ({
        ...acc,
        [project.id]: false,
      }),
      {},
    );
    setCollapseStates(initialStates);
  }, [projects]);

  const toggleCollapse = (teamId: string) => {
    setCollapseStates((prev) => ({
      ...prev,
      [teamId]: !prev[teamId],
    }));
  };

  const calculateProjectTotal = (projectId: string, month: number) => {
    const subprojectSpending = spending.filter(
      (item) => item.project_id === projectId && item.month === month && item.subproject_id !== null,
    );
    const subprojectTotal = subprojectSpending.reduce((total, item) => total + (item.budget || 0), 0);
    const projectSpending = spending.filter(
      (item) => item.project_id === projectId && item.month === month && item.subproject_id === null,
    );
    const projectTotal = projectSpending.reduce((total, item) => total + (item.budget || 0), 0);
    if (projectTotal != subprojectTotal) {
      return projectTotal;
    }
    return subprojectTotal;
  };

  const getSelectedTeams = () => {
    const selectedTeamsWithPersistedSpending = new Array(...new Set(persistedSpending.map((item) => item.project_id)));
    const selectedTeamsWithSpending = new Array(...new Set(spending.map((item) => item.project_id)));

    const selectedTeams = [];
    for (const project of projects.sort((a, b) => a.name.localeCompare(b.name))) {
      if (selectedTeamsWithPersistedSpending.includes(project.id) && selectedTeamsWithSpending.includes(project.id)) {
        selectedTeams.push(project);
      }
    }

    if (
      teamContext.team &&
      teamContext.team.id !== 'aggregate' &&
      !selectedTeams.find((tm) => tm.id === teamContext?.team?.id)
    ) {
      const addedTeam = projects.find((project) => project.id === teamContext?.team?.id);
      if (addedTeam) {
        selectedTeams.push(addedTeam);
      }
    } else {
      for (const selectedTeamWithSpending of selectedTeamsWithSpending) {
        if (
          !selectedTeamsWithPersistedSpending.includes(selectedTeamWithSpending) &&
          !selectedTeams.map((tm) => tm.id).includes(selectedTeamWithSpending)
        ) {
          const addedTeam: ProjectsResponse | undefined = projects.find(
            (project) => project.id === selectedTeamWithSpending,
          );
          if (addedTeam) {
            selectedTeams.push(addedTeam);
          }
        }
      }
    }

    return selectedTeams;
  };

  const updateFinancialData = (value: number | '', teamId: string, subprojectId: string | null, month: number) => {
    const newFinancialData = [];

    let financialDatum: Spending | undefined = undefined;

    for (const datum of spending) {
      const newDatum = { ...datum, autofilled: false } as Spending;
      if (subprojectId) {
        if (datum.subproject_id === subprojectId && datum.month === month) {
          newDatum.budget = value === '' ? null : value;
          if (newDatum.valid === false) {
            newDatum.valid = value === '' ? false : true;
          }
          financialDatum = newDatum;
        }
      } else {
        if (datum.project_id === teamId && datum.month === month && datum.subproject_id === null) {
          newDatum.budget = value === '' ? null : value;
          if (newDatum.valid === false) {
            newDatum.valid = value === '' ? false : true;
          }
          financialDatum = newDatum;
        }
      }
      newFinancialData.push(newDatum);
    }

    if (!financialDatum) {
      for (let i = 1; i < 13; i++) {
        // we can now have empty months so we need to check better
        let needsNewDatum = true;
        for (const datum of newFinancialData) {
          if (subprojectId) {
            if (datum.subproject_id === subprojectId && datum.month === i) {
              needsNewDatum = false;
            }
          } else {
            if (datum.project_id === teamId && datum.month === i && datum.subproject_id === null) {
              needsNewDatum = false;
            }
          }
        }

        if (needsNewDatum) {
          const datum = {
            year,
            month: i,
            budget: null,
            portfolio_id: portfolioId,
            project_id: teamId,
            subproject_id: subprojectId ? subprojectId : null,
            autofilled: false,
          } as Spending;
          if (datum.month === month) {
            datum.budget = value === '' ? null : value;
            financialDatum = datum;
          }
          newFinancialData.push(datum);
        }
      }
    }

    setSpending(newFinancialData);
    setCurrentFinancialDatum(financialDatum);
  };

  const handleAutoFill = () => {
    if (!currentFinancialDatum) {
      return;
    }
    const { project_id, year, budget } = currentFinancialDatum;
    const newFinancialData = spending.map((item) => {
      if (
        item.project_id === project_id &&
        item.year === year &&
        item.budget === null &&
        !checkDisabled(year, today, item.month - 1)
      ) {
        return {
          ...item,
          budget,
          autofilled: true,
        };
      }
      return item;
    });
    setSpending(newFinancialData);
  };

  const handleUndoAutoFill = () => {
    const revertedData = spending.map((item) => {
      if (item.autofilled) {
        return {
          ...item,
          budget: null,
          autofilled: false,
        };
      }
      return item;
    });

    setSpending(revertedData);
  };

  const onAddClicked = () => {
    setDisableAdd(true);
    if (team) {
      const newData = [];
      for (let i = 1; i < 13; i++) {
        newData.push({
          year,
          month: i,
          budget: null,
          portfolio_id: portfolioId,
          project_id: team.id,
        });
      }
      const updatedFinancialData = [...spending, ...newData];
      setSpending(updatedFinancialData);
    }
  };

  const yearsArray: string[] = Array.from({ length: new Date().getFullYear() - 1999 }, (_, index) =>
    (new Date().getFullYear() - index).toString(),
  );

  return (
    <EditFinancialDataContainer>
      <EditFinancialDataHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SmallerHeading>Development spend</SmallerHeading>
          <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Year</StandardText>
          <Select
            label=""
            placeholder="Pick year"
            data={yearsArray}
            size="xs"
            style={{ width: 120 }}
            value={String(year)}
            onChange={(value) => setYear(Number(value))}
            allowDeselect={false}
          />
        </div>
        <StandardText>in $USD</StandardText>
      </EditFinancialDataHeader>

      <EditFinancialDataContent>
        <EditFormLabels>
          <UppercaseCallout style={{ color: newCOLORS.black, width: 160 }}>Team</UppercaseCallout>
          <EditFormLabelsMonths style={{ width: 'calc(100% - 200px)' }}>
            {MONTHS.map((month) => (
              <UppercaseCallout
                key={month}
                style={{
                  color: checkDisabled(year, today, MONTHS.indexOf(month)) ? newCOLORS.gray : newCOLORS.darkGray,
                  width: 80,
                  textAlign: 'center',
                }}
              >
                {month}
              </UppercaseCallout>
            ))}
          </EditFormLabelsMonths>
        </EditFormLabels>

        {getSelectedTeams().map((team) => (
          <TeamSection key={team.id}>
            <TeamHeader onClick={() => toggleCollapse(team.id)}>
              <CollapseIcon>
                <Icon
                  icon={collapseStates[team.id] ? 'mdi:chevron-down' : 'mdi:chevron-right'}
                  width={20}
                  height={20}
                  color={newCOLORS.indigo}
                />
              </CollapseIcon>
              <TeamName>{team.name}</TeamName>
              <MonthlyInputs>
                {Array.from({ length: 12 }, (_, monthIndex) => (
                  <NumberInput
                    key={`${team.id}-${monthIndex}`}
                    hideControls
                    size="xs"
                    style={{ width: '8.33%' }}
                    disabled={checkDisabled(year, today, monthIndex)}
                    value={calculateProjectTotal(team.id, monthIndex + 1)}
                    onChange={(value) => updateFinancialData(value as number, team.id, null, monthIndex + 1)}
                  />
                ))}
              </MonthlyInputs>
            </TeamHeader>

            <Collapse in={collapseStates[team.id]}>
              <SubprojectsContainer>
                {team.subprojects.map((subproject) => (
                  <SubprojectRow key={subproject.id}>
                    <SubprojectName>{subproject.name}</SubprojectName>
                    <MonthlyInputs>
                      {Array.from({ length: 12 }, (_, monthIndex) => (
                        <NumberInput
                          key={`${subproject.id}-${monthIndex}`}
                          hideControls
                          size="xs"
                          style={{ width: '8.33%' }}
                          disabled={checkDisabled(year, today, monthIndex)}
                          value={getDatumBudget(spending, team, subproject, monthIndex)}
                          onChange={(value) =>
                            updateFinancialData(value as number, team.id, subproject.id, monthIndex + 1)
                          }
                        />
                      ))}
                    </MonthlyInputs>
                  </SubprojectRow>
                ))}
              </SubprojectsContainer>
            </Collapse>
          </TeamSection>
        ))}

        <AutofillContainer>
          {currentFinancialDatum &&
          currentFinancialDatum.budget !== null &&
          spending.some(
            (item) =>
              item.budget === null &&
              item.project_id === currentFinancialDatum.project_id &&
              !checkDisabled(year, today, item.month - 1),
          ) ? (
            <AutofillButton onClick={handleAutoFill}>+ Add this amount to each open month</AutofillButton>
          ) : null}

          {currentFinancialDatum &&
          currentFinancialDatum.budget !== null &&
          spending.some(
            (item) =>
              item.autofilled === true &&
              item.project_id === currentFinancialDatum.project_id &&
              !checkDisabled(year, today, item.month - 1),
          ) ? (
            <AutofillButton onClick={handleUndoAutoFill}>- Undo this change to each open month</AutofillButton>
          ) : null}
        </AutofillContainer>

        {getSelectedTeams().length < projects.length && (
          <AddTeam key={getSelectedTeams().length}>
            <Select
              label=""
              placeholder="Select Team"
              data={projects
                .filter((project) => !getSelectedTeams().includes(project))
                .map((project) => ({ value: project.id, label: project.name }))}
              size="xs"
              style={{ width: 150 }}
              value={team?.id}
              onChange={(value) => setTeam(projects.find((project) => project.id == value) as ProjectsResponse)}
            />
            <StyledButton
              size={'small'}
              type={'primary'}
              firstColor={newCOLORS.indigo}
              secondColor={newCOLORS.white}
              disable={disableAdd}
              style={{ marginLeft: 8 }}
              onClick={() => onAddClicked()}
            >
              Add
            </StyledButton>
          </AddTeam>
        )}
      </EditFinancialDataContent>
    </EditFinancialDataContainer>
  );
};

const EditFinancialDataContainer = styled.div`
  padding: 16px 24px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const EditFinancialDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const EditFinancialDataContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditFormLabels = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const EditFormLabelsMonths = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddTeam = styled.div`
  display: flex;
  align-items: center;
`;

const AutofillContainer = styled.div`
  display: flex;
  padding-left: 160px;
`;

const AutofillButton = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  color: ${newCOLORS.indigo};
  &:hover {
    text-decoration: underline;
  }
`;

const TeamSection = styled.div`
  margin-bottom: 8px;
  border: 1px solid ${newCOLORS.lightGray};
  border-radius: 4px;
`;

const TeamHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background: ${newCOLORS.white};

  &:hover {
    background: ${newCOLORS.lightGray};
  }
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const TeamName = styled.div`
  width: 130px;
  font-weight: 500;
  color: ${newCOLORS.darkerGray};
`;

const MonthlyInputs = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
`;

const SubprojectsContainer = styled.div`
  padding: 8px 12px 12px 40px;
  background: ${newCOLORS.lightGray3};
`;

const SubprojectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SubprojectName = styled.div`
  width: 128px;
  font-size: 14px;
  color: ${newCOLORS.darkGray};
`;
