import { styled } from '@linaria/react';
import { Divider, Group } from '@mantine/core';
import { IntegrationType, Subproject } from '../../../api/integrations-client/integrations-client.type';
import { skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Select } from '../../../ui-library/select/select';
import { Text } from '../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from './data-management-header';
import { getDataSourceIcon } from './data-management.helpers';
import { getDataSourceName } from './data-sources-tab/data-sources.helpers';

type SubprojectInfoPanelProps = {
  subproject: Subproject;
  setSelectedSubprojectId: (id: string | null) => void;
  integrationSource: IntegrationType | null;
};

export function SubprojectInfoPanel({
  subproject,
  setSelectedSubprojectId,
  integrationSource,
}: SubprojectInfoPanelProps) {
  return (
    <SubprojectInfoPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          {subproject.name}
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedSubprojectId(null);
          }}
        />
      </HeaderContainer>
      <Divider style={{ marginTop: 30, marginBottom: 24 }} />
      <Text size="small">Assigned Workflow</Text>
      <Select placeholder="Select a workflow" value={''} options={[]} onChange={() => {}} size="md" />
      <Text size="small" style={{ marginTop: 24 }}>
        Assigned Practices
      </Text>
      <Select placeholder="Select a practice" value={''} options={[]} onChange={() => {}} size="md" />
      {integrationSource && (
        <>
          <Divider style={{ marginTop: 24, marginBottom: 24 }} />
          <Text weight="bold" size="large">
            Integration Source
          </Text>
          <Group style={{ marginTop: 24 }}>
            <img src={getDataSourceIcon(integrationSource)} width={24} height={24} alt={integrationSource} />
            <Text weight="medium" size="small">
              {getDataSourceName(integrationSource)}
            </Text>
          </Group>
        </>
      )}
    </SubprojectInfoPanelContainer>
  );
}

const SubprojectInfoPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
