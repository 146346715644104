enum MessageStatus {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type Message = {
  status: MessageStatus;
  text: string;
};

type State = {
  message: Message | null;
};

export { MessageStatus };
export type { Message, State };
