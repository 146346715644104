import { QueryObserverResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import {
  createBoardSetting,
  deleteBoardSetting,
  editBoardSetting,
  getBoardSettings,
  mapSubprojectsToBoardSetting,
  removeSubprojectsFromBoardSetting,
} from './board-settings-client';
import { BoardSetting, EditBoardSettingPayload } from './board-settings-client.types';

/**
 * Hook to fetch board settings for an organization
 *
 * @param {Object} params - The parameters object
 * @param {string | null} params.organization_id - ID of the organization to fetch board settings for
 * @returns {Object} Object containing:
 *   - data: The board settings data
 *   - query: The React Query result object
 */
const useBoardSettings = ({
  organization_id,
}: {
  organization_id: string | null;
}): { data: BoardSetting[] | undefined; query: QueryObserverResult<BoardSetting[]> } => {
  const query = useQuery({
    queryKey: ['board-settings', organization_id],
    queryFn: () => getBoardSettings(organization_id),
    enabled: !!organization_id,
  });

  return { data: query.data, query };
};

/**
 * Hook for creating new board settings
 * @returns Object containing:
 * - useCreateBoardSettingHook: Function to create a new board setting and return it
 * - isCreating: Boolean indicating if creation is in progress
 */

const useCreateBoardSetting = (): {
  useCreateBoardSettingHook: () => Promise<BoardSetting>;
  isCreating: boolean;
} => {
  const queryClient = useQueryClient();
  const create = useMutation({
    mutationFn: createBoardSetting,
  });
  const organization = useGlobalStore((state) => state.organization);

  const useCreateBoardSettingHook = async (): Promise<BoardSetting> => {
    const result = await create.mutateAsync(
      {
        name: 'New Board Setting',
        organization_id: organization?.id ?? '',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['board-settings'] });
        },
      },
    );
    return result;
  };

  return {
    useCreateBoardSettingHook,
    isCreating: create.isPending,
  };
};

/**
 * Hook for deleting a board setting
 * @returns Object containing:
 * - deleteBoardSetting: Function to delete a board setting and return it
 * - isDeleting: Boolean indicating if deletion is in progress
 */
const useDeleteBoardSetting = (): {
  deleteBoardSetting: (id: string) => Promise<void>;
  isDeleting: boolean;
} => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteBoardSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['board-settings'] });
      queryClient.invalidateQueries({ queryKey: ['integration-entries'] });
    },
  });

  return {
    deleteBoardSetting: (id: string) => mutation.mutateAsync(id),
    isDeleting: mutation.isPending,
  };
};

const useEditBoardSetting = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: EditBoardSettingPayload }) => editBoardSetting(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['board-settings'] });
    },
  });

  return {
    editBoardSetting: (id: string, payload: EditBoardSettingPayload) => mutation.mutateAsync({ id, payload }),
    isEditing: mutation.isPending,
  };
};

/**
 * Hook for adding a subproject to a board setting
 * @returns Object containing:
 * - addSubprojectToBoardSetting: Function to add a subproject to a board setting and return it
 * - isAdding: Boolean indicating if addition is in progress
 */
const useAddSubprojectToBoardSetting = (): {
  addSubprojectToBoardSetting: (boardSettingId: string, subprojectId: string) => Promise<void>;
  isAdding: boolean;
} => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ boardSettingId, subprojectId }: { boardSettingId: string; subprojectId: string }) =>
      mapSubprojectsToBoardSetting(boardSettingId, [subprojectId]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['board-settings'] });
      queryClient.invalidateQueries({ queryKey: ['integration-entries'] });
    },
  });

  return {
    addSubprojectToBoardSetting: (boardSettingId: string, subprojectId: string) =>
      mutation.mutateAsync({ boardSettingId, subprojectId }),
    isAdding: mutation.isPending,
  };
};

/**
 * Hook for removing a subproject from a board setting
 * @returns Object containing:
 * - removeSubprojectFromBoardSetting: Function to remove a subproject from a board setting and return it
 * - isRemoving: Boolean indicating if removal is in progress
 */
const useRemoveSubprojectFromBoardSetting = (): {
  removeSubprojectFromBoardSetting: (boardSettingId: string, subprojectId: string) => Promise<void>;
  isRemoving: boolean;
} => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ boardSettingId, subprojectId }: { boardSettingId: string; subprojectId: string }) =>
      removeSubprojectsFromBoardSetting(boardSettingId, [subprojectId]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['board-settings'] });
      queryClient.invalidateQueries({ queryKey: ['integration-entries'] });
    },
  });

  return {
    removeSubprojectFromBoardSetting: (boardSettingId: string, subprojectId: string) =>
      mutation.mutateAsync({ boardSettingId, subprojectId }),
    isRemoving: mutation.isPending,
  };
};

export {
  useAddSubprojectToBoardSetting,
  useBoardSettings,
  useCreateBoardSetting,
  useDeleteBoardSetting,
  useEditBoardSetting,
  useRemoveSubprojectFromBoardSetting,
};
