import { Chart as ChartJS } from 'chart.js';
import { CostVsBudgetChartData } from '../../api/financials-client/financials-client.type';
import { newCOLORS } from '../../styles/colors';
import { buildTotalLinkUrl } from './cost-vs-output-tooltip.helpers';
let hideTooltipTimeout: NodeJS.Timeout | undefined;

export const getOrCreateTooltip = (chart: ChartJS) => {
  if (!chart.canvas.parentNode) {
    return;
  }
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 1)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.addEventListener('mouseenter', () => {
      clearTimeout(hideTooltipTimeout);
      if (tooltipEl) {
        tooltipEl.style.pointerEvents = 'auto';
      }
    });

    tooltipEl.addEventListener('mouseleave', () => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    });
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: { chart: ChartJS; tooltip: any },
  chartData: CostVsBudgetChartData[] | null,
  portfolioId: string | undefined,
  teamId: string | undefined,
  boardId: string | undefined,
  boardName: string | undefined,
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  clearTimeout(hideTooltipTimeout);

  const activePointExists = !!chart.getActiveElements().length;

  if (tooltip.opacity === 0 || !activePointExists) {
    hideTooltipTimeout = setTimeout(() => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    }, 2500);
    return;
  }

  const currentDataIndex = tooltip.dataPoints[0]?.dataIndex;
  if (currentDataIndex === undefined || chartData === null) {
    return;
  }

  const currentChartData = chartData[currentDataIndex];

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    const titleString = tooltip.dataPoints[0].dataset.label;
    const titleRow = document.createElement('tr');
    titleRow.style.borderWidth = '0';

    const titleTH = document.createElement('td');
    titleTH.style.borderWidth = '0';
    titleTH.style.fontSize = '17px';

    const text = document.createTextNode(titleString);
    titleTH.appendChild(text);
    titleRow.appendChild(titleTH);
    tableHead.appendChild(titleRow);

    const tableBody = document.createElement('tbody');

    const monthYearRow = document.createElement('tr');
    monthYearRow.style.fontSize = '12px';
    const monthYearData = document.createElement('td');
    monthYearData.style.minWidth = '130px';
    monthYearData.style.paddingRight = '50px';
    const monthStr = new Date(currentChartData.year, currentChartData.month - 1).toLocaleString('default', {
      month: 'long',
    });
    const mydText = document.createTextNode(`${monthStr} ${currentChartData.year}`.toUpperCase());

    monthYearData.appendChild(mydText);
    monthYearRow.appendChild(monthYearData);

    let taskDataTextContent = '';
    if (titleString === 'Cost' || titleString === 'Daily Burn' || titleString === 'Cost Per Task') {
      taskDataTextContent = 'Cost';
    } else if (titleString === 'Velocity' || titleString === 'Points Committed') {
      taskDataTextContent = 'Points';
    } else if (titleString === 'Throughput' || titleString === 'Tasks Committed') {
      taskDataTextContent = 'Tasks';
    }
    const tasksData = document.createElement('td');
    const tasksDataText = document.createTextNode(taskDataTextContent.toUpperCase());
    tasksData.appendChild(tasksDataText);
    monthYearRow.appendChild(tasksData);

    tableHead.appendChild(monthYearRow);

    const tr = document.createElement('tr');
    tr.style.backgroundColor = 'inherit';
    tr.style.borderWidth = '0';

    const th = document.createElement('td');
    th.style.borderWidth = '0';
    const thText = document.createTextNode('Total');

    const td = document.createElement('td');
    th.style.borderWidth = '0';
    let totalText = `${new Intl.NumberFormat().format(Math.round(tooltip.dataPoints[0].raw))}`;

    const factor = titleString.toLowerCase().replace(/ /g, '_');
    const yearMonthString = `${currentChartData.year}-${String(currentChartData.month).padStart(2, '0')}`;

    const baseLinkUrl = `/application/financials/portfolio/${portfolioId}/tasks/?measure=cost_vs_output&date=${encodeURIComponent(
      yearMonthString,
    )}&factor=${encodeURIComponent(factor)}`;

    const totalLinkUrl = buildTotalLinkUrl(baseLinkUrl, teamId, boardId);

    // don't bother building the anchor if the totalLinkUrl is empty
    if (totalLinkUrl) {
      const totalAnchor = document.createElement('a');
      if (titleString !== 'Cost' && titleString !== 'Daily Burn') {
        totalAnchor.setAttribute('href', totalLinkUrl);
        totalAnchor.style.color = newCOLORS.lightPurple;
        totalAnchor.style.textDecoration = 'underline';
        totalAnchor.style.textDecorationStyle = 'dashed';
      }
      if (tooltip.dataPoints[0].dataset.yAxisID === 'y') {
        totalText = `$${totalText}`;
      }
      const tdText = document.createTextNode(totalText);
      th.appendChild(thText);
      totalAnchor.appendChild(tdText);
      td.appendChild(totalAnchor);
    }
    tr.appendChild(th);
    tr.appendChild(td);
    tableBody.appendChild(tr);

    currentChartData.projects.forEach((project) => {
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const th = document.createElement('td');
      th.style.borderWidth = '0';
      let thText = null;

      let linkUrl = baseLinkUrl + `&projectId=${project.id}`;
      if (boardId && boardId !== 'aggregate' && boardName) {
        thText = document.createTextNode(boardName);
        linkUrl += `&subprojectId=${boardId}`;
      } else {
        thText = document.createTextNode(project.name);
      }

      const td = document.createElement('td');
      th.style.borderWidth = '0';

      let value = 0;

      if (titleString === 'Cost') {
        value = project.cost;
      } else if (titleString === 'Daily Burn') {
        value = project.daily_burn;
      } else if (titleString === 'Cost Per Task') {
        value = Math.round(project.cost / project.completed_tasks);
      } else if (titleString === 'Cost Per Point') {
        value = Math.round(project.cost / project.completed_points);
      } else if (titleString === 'Velocity') {
        value = project.completed_points;
      } else if (titleString === 'Points Committed') {
        value = project.committed_points;
      } else if (titleString === 'Throughput') {
        value = project.completed_tasks;
      } else if (titleString === 'Tasks Committed') {
        value = project.committed_tasks;
      }
      let valueText = `${value}`;
      let linkableValue = valueText !== '0';
      if (value == null || !Number.isFinite(value)) {
        valueText = ' -';
        linkableValue = false;
      } else if (tooltip.dataPoints[0].dataset.yAxisID === 'y') {
        valueText = `$${new Intl.NumberFormat().format(value)}`;
      }

      th.appendChild(thText);
      const tdText = document.createTextNode(valueText);

      if (linkableValue && titleString !== 'Cost' && titleString !== 'Daily Burn') {
        const anchor = document.createElement('a');
        anchor.setAttribute('href', linkUrl);
        anchor.style.color = newCOLORS.lightPurple;
        anchor.style.textDecoration = 'underline';
        anchor.style.textDecorationStyle = 'dashed';
        anchor.appendChild(tdText);
        td.appendChild(anchor);
      } else {
        td.appendChild(tdText);
      }
      tr.appendChild(th);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    if (tooltipEl) {
      const tableRoot = tooltipEl.querySelector('table');

      if (tableRoot) {
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  if (tooltipEl) {
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.left = positionX + tooltip.caretX - 125 + 'px';
    tooltipEl.style.top = positionY + 10 + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.zIndex = '400';
  }
};
