import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { Fragment, useEffect, useRef, useState } from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryClipContainer, VictoryLabel, VictoryStack } from 'victory';
import {
  Sprint,
  SprintBurnsAndFlows,
  SprintMetricsCumulativeFlow,
  SprintMetricsCumulativeFlowDataPoint,
  SprintMetricsFlowOfWorkTaskDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import { WorkPeriodBurnsAndFlows } from '../../containers/process-analysis/assessment-view/assessment-view.type';
import { Mode } from '../../containers/process-analysis/process-analysis.type';
import { generateDownloadFileName, handleSVGasImageDownload } from '../../helpers/image-downloader/image-downloader';
import { cumulativeFlowData } from '../../helpers/sprint-metrics-chart-data-factory/sprint-metrics-chart-data-factory';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { BurnFlowChartLegend } from '../burn-flow-chart-legend/burn-flow-chart-legend';
import { LineScatterDataPoint } from '../line-scatter-chart/line-scatter-chart.type';
import { calculateWeekendAreas, getDataPoints } from './burn-flow-chart.helpers';
import { CumulativeFlowAggregatedDataType, ViewByType } from './burn-flow-chart.type';

export type CumulativeFlowChartProps = {
  mode: Mode;
  cumulative_flow_points: SprintMetricsCumulativeFlow;
  cumulative_flow_tasks: SprintMetricsCumulativeFlow;
  sprint?: Sprint;
  legend?: boolean;
  header?: boolean;
  dataType?: ViewByType;
  sprintBurnsAndFlows: SprintBurnsAndFlows | WorkPeriodBurnsAndFlows;
};

export const CumulativeFlowChart = ({
  mode,
  cumulative_flow_points,
  cumulative_flow_tasks,
  sprint,
  legend = true,
  header = true,
  dataType,
  sprintBurnsAndFlows,
}: CumulativeFlowChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);
  const [chartData, setChartData] = useState<CumulativeFlowAggregatedDataType[]>([]);
  const [maximumY, setMaximumY] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight > 100 ? window.innerHeight - 100 : window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const cumulativeData = cumulativeFlowData(
      dataType === ViewByType.tasks ? cumulative_flow_tasks : cumulative_flow_points,
    );
    const cumulativeFlowAggregatedData = [
      {
        title: 'Work Done',
        data: cumulativeData.deployed,
        color: `${newCOLORS.green}`,
      },
      {
        title: 'Staging Ready',
        data: cumulativeData.ready_for_deploy,
        color: `${newCOLORS.aqua}`,
      },
      {
        title: 'In Test',
        data: cumulativeData.in_test,
        color: `${newCOLORS.pink}`,
      },
      {
        title: 'In Review',
        data: cumulativeData.in_review,
        color: `${newCOLORS.lightYellow}`,
      },
      {
        title: 'Work in Progress',
        data: cumulativeData.in_progress,
        color: `${newCOLORS.darkYellow}`,
      },
      {
        title: 'Blocked',
        data: cumulativeData.blocked,
        color: `${newCOLORS.coral}`,
      },
      {
        title: 'Committed Work',
        data: cumulativeData.current_sprint,
        color: `${newCOLORS.indigo}`,
      },
    ];

    setChartData(cumulativeFlowAggregatedData);

    // flatten the normalized data and sum by date in order to determine the maximum Y value in the chart
    const potentialMaximums = (cumulativeFlowAggregatedData || [])
      .flatMap(({ data }) => data)
      .reduce((dateBucket: { [key: string]: number }, dataPoint: LineScatterDataPoint) => {
        const { x, y } = dataPoint;
        if (y) {
          dateBucket[x] = (dateBucket[x] || 0) + y;
        }
        return dateBucket;
      }, {});
    const maxY = Object.values(potentialMaximums).length > 0 ? Math.max(...Object.values(potentialMaximums)) : 0;
    setMaximumY(maxY);
  }, [dataType, cumulative_flow_points, cumulative_flow_tasks]);

  const getDisplayDateXAxisValues = (dataPoints: SprintMetricsCumulativeFlowDataPoint[]): string[] => {
    const dateValues = dataPoints.map((point: SprintMetricsCumulativeFlowDataPoint) => {
      const displayDate = dayjs(point.date).format('MM/DD');
      return displayDate;
    });
    return dateValues;
  };
  const xAxisTickValues =
    dataType === ViewByType.tasks
      ? getDisplayDateXAxisValues(sprintBurnsAndFlows.flows_tasks.data_points)
      : getDisplayDateXAxisValues(sprintBurnsAndFlows.flows_points.data_points);

  const weekendAreas = calculateWeekendAreas(
    getDataPoints(sprintBurnsAndFlows, mode) as SprintMetricsFlowOfWorkTaskDataPoint[],
    Math.ceil(maximumY / 10) * 10,
  );

  return (
    <Fragment>
      {header && sprint && (
        <CumulativeFlowHeadingContainer>
          <ChartHeading>
            <TitleHeadingTag>Burn Flow</TitleHeadingTag>
          </ChartHeading>
          <BurnFlowHeadingText>
            <Icon
              style={{ marginLeft: 'auto', paddingRight: '10px', cursor: 'pointer' }}
              width="32"
              height="32"
              onClick={() =>
                handleSVGasImageDownload(
                  chartRef,
                  0,
                  generateDownloadFileName(`BurnFlow${dataType}`, sprint.name, sprint.end_date),
                  `Burn Flow ${dataType}`,
                )
              }
              icon="material-symbols:image-outline"
            />
          </BurnFlowHeadingText>
        </CumulativeFlowHeadingContainer>
      )}

      {cumulative_flow_points.data_points.length > 0 && cumulative_flow_tasks.data_points.length > 0 && (
        <ChartWithLegend ref={chartRef}>
          <Chart legend={legend}>
            <VictoryChart
              domainPadding={0}
              height={viewportHeight}
              width={viewportHeight * 2.42}
              padding={{ top: 10, bottom: 80, left: 75, right: 75 }}
            >
              {
                <VictoryAxis
                  tickCount={12}
                  orientation="bottom"
                  label={mode === Mode.Sprint ? 'Day of Sprint' : undefined}
                  style={{
                    grid: { stroke: 'transparent' },
                    axis: { stroke: `${newCOLORS.gray}`, margin: 30 },
                    tickLabels: { fontSize: 21 },
                    axisLabel: { fontSize: 26 },
                  }}
                  tickValues={xAxisTickValues}
                  axisLabelComponent={<VictoryLabel dy={25} />}
                />
              }
              {
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  orientation="left"
                  label={dataType === ViewByType.points ? 'Story Points' : 'Number of Tasks'}
                  tickFormat={(tick: number) => `${tick}`}
                  style={{
                    grid: { stroke: `${newCOLORS.gray}` },
                    axis: { stroke: `${newCOLORS.gray}` },
                    tickLabels: { fontSize: 21 },
                    axisLabel: { fontSize: 26 },
                  }}
                  axisLabelComponent={<VictoryLabel dy={-25} />}
                />
              }
              {weekendAreas.map((area, index) => (
                <VictoryArea
                  key={index}
                  style={{
                    data: {
                      fill: newCOLORS.lighterGray,
                      fillOpacity: 0.6,
                    },
                  }}
                  data={area}
                />
              ))}
              <VictoryStack>
                {chartData.map((object) => (
                  <VictoryArea
                    domain={{ y: [0, maximumY + 5] }} // pad the maximum Y value to give a little breathing room at the top of the chart
                    key={object.title}
                    interpolation="monotoneX"
                    data={object.data}
                    style={{
                      data: {
                        fill: `${object.color}`,
                        fillOpacity: 0.7,
                        stroke: `${object.color}`,
                        strokeWidth: 2,
                      },
                    }}
                    groupComponent={<VictoryClipContainer clipPadding={{ top: 5, bottom: 5 }} />}
                  />
                ))}
              </VictoryStack>
            </VictoryChart>
          </Chart>

          {legend && (
            <Legend>
              <BurnFlowChartLegend />
            </Legend>
          )}
        </ChartWithLegend>
      )}
    </Fragment>
  );
};

const CumulativeFlowHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const ChartHeading = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const BurnFlowHeadingText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ChartWithLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Chart = styled.div<{ legend: boolean }>`
  width: ${(props: { legend: boolean }) => (props.legend ? '85%' : '100%')};
`;

const Legend = styled.div`
  width: 15%;
  height: 40%;
`;
