import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getInitiative, getInitiativeTasks, getInitiatives } from './initiative-client';
import {
  ExtendedInitiative,
  Initiative,
  InitiativeTaskResponse,
  InitiativeTasksConfig,
} from './initiative-client.type';

/**
 * Method to fetch initiatives for a portfolio
 *
 * @param portfolioId portfolio id
 * @param options query options
 * @returns an object with initiatives and query manager
 */
const useInitiatives = (
  portfolioId: string | undefined,
  options?: UseQueryOptions<Initiative[]>,
): {
  initiatives: Initiative[] | undefined;
  query: QueryObserverResult<Initiative[]>;
} => {
  const query = useQuery({
    queryKey: ['initiatives', portfolioId],
    queryFn: () => (portfolioId ? getInitiatives(portfolioId) : Promise.reject('No portfolio selected')),
    ...options,
  });

  return { initiatives: query.data, query };
};

/**
 * Query manager for fetching an extended initiative.
 *
 * @param {string | undefined} portfolioId - The ID of the portfolio
 * @param {string | undefined} initiativeId - The ID of the initiative
 * @param {UseQueryOptions<ExtendedInitiative>} options - Additional options for the query
 * @returns {{ initiative: ExtendedInitiative | undefined; query: QueryObserverResult<ExtendedInitiative> }} The extended initiative and its query result
 */
const useInitiative = (
  portfolioId: string | undefined,
  initiativeId: string | undefined,
  options?: UseQueryOptions<ExtendedInitiative>,
): { initiative: ExtendedInitiative | undefined; query: QueryObserverResult<ExtendedInitiative> } => {
  const query = useQuery({
    queryKey: ['initiative', portfolioId, initiativeId],
    queryFn: () =>
      portfolioId && initiativeId ? getInitiative(portfolioId, initiativeId) : Promise.reject('Cannot get initiative'),
    ...options,
  });

  return { initiative: query.data, query };
};

/**
 * Query manager for fetching initiative tasks.
 *
 * @param {InitiativeTasksConfig} config - Configuration object containing parameters for fetching initiative tasks
 * @param {UseQueryOptions<InitiativeTaskResponse[]>} options - Additional options for the query
 * @return {{ data: InitiativeTaskResponse[] | undefined; query: QueryObserverResult<InitiativeTaskResponse[]> }} Object containing data and query result
 */
const useInitiativeTasks = (
  { portfolioId, initiativeId, filter, date, epicId, projectId }: InitiativeTasksConfig,
  options?: UseQueryOptions<InitiativeTaskResponse[]>,
): { data: InitiativeTaskResponse[] | undefined; query: QueryObserverResult<InitiativeTaskResponse[]> } => {
  const query = useQuery({
    queryKey: ['initiative-tasks', portfolioId, initiativeId, filter, date, epicId, projectId],
    queryFn: () =>
      portfolioId && initiativeId && filter
        ? getInitiativeTasks({
            portfolioId,
            initiativeId,
            filter,
            date: date || undefined,
            epicId: epicId || undefined,
            projectId: projectId || undefined,
          })
        : Promise.reject('Cannot get initiative tasks'),
    ...options,
  });

  return { data: query.data, query };
};

export { useInitiative, useInitiativeTasks, useInitiatives };
