import { AppShell } from '@mantine/core';
import { ReactNode } from 'react';
import { HeaderBar } from '../header/header';

type MenuDrivenContainerProps = {
  children: ReactNode;
  header?: string | ReactNode;
};

export const MenuDrivenContainerNoNavbar = ({ children, header }: Partial<MenuDrivenContainerProps>) => {
  return (
    <AppShell
      layout="alt"
      header={{ height: 76 }}
      padding={16}
      withBorder={false}
      transitionDuration={0}
      style={{
        overflowX: 'hidden',
        maxWidth: '100vw',
      }}
    >
      <HeaderBar header={header} />
      <AppShell.Main
        style={{
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '100%',
          transition: 'width 300ms ease',
          overflowX: 'hidden',
        }}
      >
        {children}
      </AppShell.Main>
    </AppShell>
  );
};
