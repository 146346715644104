import { Stepper } from '@mantine/core';
import { Dispatch, MouseEventHandler, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useDataInitializer } from '../app/app.hooks';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { AddNewIntegration } from './add-new-integration/add-new-integration';
import { IntegrationCards } from './integration-cards';
import { IntegrationIcon } from './integration-icon';
import { integrationViews } from './integrations.data';
import { useRedirect } from './integrations.hooks';
import styles from './integrations.module.css';
import { IntegrationView } from './integrations.type';
import { SuccessHeader } from './views/success-view';

type IntegrationsProps = {
  view?: string;
};

const showIntegrationsV2 = import.meta.env.VITE_FEATURE_FLAG_INTEGRATIONS_V2 === 'true';

export function Integrations({ view = 'base' }: IntegrationsProps) {
  useDocumentTitle('Integrations - Bloomfilter');
  useDataInitializer();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { redirect } = useRedirect();

  const currentView = integrationViews.find((integration) => integration.name === view);

  if (view === 'base' && showIntegrationsV2) {
    return <AddNewIntegration />;
  }

  return (
    <MenuDrivenContainer>
      {!redirect && (
        <IntegrationsHeader
          activeStep={activeStep}
          view={currentView}
          showAlternateHeader={currentView?.showAlternateHeaderOnSteps.includes(activeStep)}
        />
      )}
      <IntegrationsContent activeStep={activeStep} setActiveStep={setActiveStep} view={currentView} />
    </MenuDrivenContainer>
  );
}

const IntegrationsHeader = ({
  activeStep,
  view,
  showAlternateHeader,
}: {
  activeStep: number;
  view?: IntegrationView;
  showAlternateHeader?: boolean;
}) => {
  if (showAlternateHeader) {
    return <SuccessHeader />;
  }

  if (view) {
    return <Header integrationSteps={view.steps} activeStep={activeStep} icon={view.icon} view={view} />;
  }

  return <Header integrationSteps={[]} activeStep={activeStep} />;
};

const IntegrationsContent = ({
  activeStep,
  setActiveStep,
  view,
}: {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  view?: IntegrationView;
}) => {
  if (view) {
    return <view.component activeStep={activeStep} setActiveStep={setActiveStep} />;
  }

  return <IntegrationCards setActiveStep={setActiveStep} />;
};

type HeaderProps = {
  integrationSteps?: string[];
  activeStep?: number;
  icon?: React.ReactNode;
  view?: IntegrationView;
};

function Header({ integrationSteps, activeStep, icon, view }: HeaderProps) {
  const navigate = useNavigate();

  return (
    <header id={styles.integrationsHeader}>
      <div id={styles.integrationsTitle}>
        <h2 className={styles.title} onClick={() => navigate('/application/integrations')}>
          {`Integration setup${icon ? ':' : ''}`}
        </h2>
        {view && <IntegrationIcon service={view?.name} width={50} height={50} size="3x" />}
      </div>
      <div>{integrationSteps?.length ? <Menu steps={integrationSteps} activeStep={activeStep} /> : null}</div>
    </header>
  );
}

type MenuProps = {
  steps: string[];
  activeStep?: number;
  onClickStep?: MouseEventHandler;
};

function Menu({ steps, activeStep, onClickStep }: MenuProps) {
  return (
    <Stepper active={activeStep || 0} color="violet.5">
      {steps.map((step) => (
        <Stepper.Step key={step} label={step} onClick={onClickStep} />
      ))}
    </Stepper>
  );
}
