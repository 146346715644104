import { ChartData, Chart as ChartJS, TooltipItem } from 'chart.js';
import { useRef } from 'react';
import { getElementAtEvent, Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { InitiativeTasksFilters } from '../../api/initiative-client/initiative-client.type';
import { ProcessAnalysisInitiativeChartData } from '../../api/portfolio-client/portfolio-client.type';
import { TimeAllocationType } from '../../api/work-periods-client/work-periods-client.type';
import { simpleLinearRegression } from '../../helpers/math-helpers/math-helpers';
import { extractYearMonth } from '../../helpers/string-helpers/string-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { sortAndFormatLabels } from './initiative-sections/initiative-sections.helpers';
import { createLine, getTooltipLabel } from './process-analysis-chart.helpers';
import { Entities, MetricType } from './process-analysis.type';

type ProcessAnalysisInitiativeChartProps = {
  initiativeData: ProcessAnalysisInitiativeChartData;
  metric: MetricType;
  entitiesWithColor: Entities;
  entityTrendsWithColor: Entities;
  type: 'completion' | 'focus';
};

export const ProcessAnalysisChart = ({
  initiativeData,
  metric,
  entitiesWithColor,
  entityTrendsWithColor,
  type,
}: ProcessAnalysisInitiativeChartProps) => {
  const { portfolios = [] } = useGlobalStore();
  const chartRef = useRef<ChartJS<'line'>>(null);
  const navigate = useNavigate();
  const axesTicks = type === 'focus' ? { min: 0, max: 100, callback: (value: string | number) => `${value}` } : {};
  const options = {
    maintainAspectRatio: false,
    pointStyle: true,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    animation: {
      duration: 0, // Set to 0 to disable all animations
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          callback: function (this: any, value: string | number, index: number): string {
            return this.getLabelForValue(index || value);
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'left' as const,
        suggestedMax: type === 'focus' ? 100 : 10,
        title: {
          display: true,
          text: type === 'completion' ? (metric === MetricType.Tasks ? 'Tasks Completed' : 'Points Completed') : '%',
          font: {
            size: 16,
          },
        },
        ticks: {
          ...axesTicks,
          font: {
            size: 14,
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'line'>) => getTooltipLabel(tooltipItem, initiativeData),
        },
      },
    },
  };

  const formatData = (): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels: sortAndFormatLabels(initiativeData.labels, TimeAllocationType.Monthly),
      datasets: [],
    };

    for (const entity in entitiesWithColor) {
      chartData.datasets.push(
        createLine(
          entity,
          Object.values(initiativeData.initiatives.find((initiative) => initiative.id === entity)?.tasks ?? {}),
          entitiesWithColor[entity] ?? newCOLORS.black,
          false,
        ),
      );
    }

    for (const entity in entityTrendsWithColor) {
      const entityData = initiativeData.initiatives.find((initiative) => initiative.id === entity)?.tasks ?? {};
      const filteredData = Object.values(entityData).filter((value) => value !== null);
      const xAxis = Array.from({ length: filteredData.length }, (_value, index) => index);

      if (xAxis.length > 1 && filteredData.length > 1) {
        const line = simpleLinearRegression(xAxis, filteredData);
        if (line) {
          const { slope, intercept } = line;
          const trendData = xAxis.map((x) => slope * x + intercept);
          chartData.datasets.push(
            createLine(`${entity} Trend`, trendData, entityTrendsWithColor[entity] ?? newCOLORS.black, true),
          );
        }
      }
    }

    return chartData;
  };

  const data = formatData();

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!chartRef.current) {
      return;
    }

    const pointElement = getElementAtEvent(chartRef.current, event)[0];

    if (!pointElement || !data.labels || pointElement.index === undefined || !data.labels[pointElement.index]) {
      return;
    }

    const { year, month } = extractYearMonth(data.labels[pointElement.index] as string);
    const { label: initiativeId } = data.datasets[pointElement.datasetIndex];

    if (!portfolios.length) {
      return;
    }

    const portfolioId = portfolios[0];

    if (initiativeId && portfolioId && year && month) {
      navigate(
        `../strategy/${portfolioId}/${initiativeId}/tasks?filter=${InitiativeTasksFilters.Discrete}&date=${year}-${month}`,
      );
    }
  };

  return <Line data={data} options={options} ref={chartRef} onClick={onClick} />;
};
