import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Button, Divider, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { deduplicateComboboxGroups } from '../../../helpers/general-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { setIsDirtyWorkPeriods } from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { WorkPeriodType } from '../process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer.hooks';
import { getBoardOptions, getWorkPeriodTypeOptions } from './work-period-form.helpers';
import {
  useBoardManagement,
  useWorkPeriodOptions,
  useWorkPeriodSelection,
  useWorkPeriodsManagement,
} from './work-period-form.hooks';

export function WorkPeriodForm() {
  const teams = useGlobalStore((state) => state.teams);
  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);
  const workPeriodOptions = useWorkPeriodOptions();

  const { workPeriodForm, handleSubmitWorkPeriodForm } = useWorkPeriodForm();
  const boardId = workPeriodForm.getValues().workPeriodBoardId;
  const workPeriodType = workPeriodForm.getValues().workPeriodType;

  useBoardManagement();
  useWorkPeriodsManagement(boardId);
  useWorkPeriodSelection(workPeriodForm);

  return (
    <form onSubmit={workPeriodForm.onSubmit(() => handleSubmitWorkPeriodForm(workPeriodForm))}>
      <Panel>
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodBoardId')}
          onChange={(value) => {
            workPeriodForm.setFieldValue('workPeriodBoardId', value);
            setIsDirtyWorkPeriods(true);
          }}
          data={deduplicateComboboxGroups(getBoardOptions(teams))}
          allowDeselect={false}
        />
        <Select
          required
          {...workPeriodForm.getInputProps('workPeriodType')}
          onChange={(value) => {
            workPeriodForm.setFieldValue('workPeriodType', value as WorkPeriodType);
            setIsDirtyWorkPeriods(true);
          }}
          data={getWorkPeriodTypeOptions()}
          allowDeselect={false}
        />
        <Divider orientation="vertical" />
        {workPeriodType === WorkPeriodType.Defined && workPeriods.length > 0 && (
          <Select
            {...workPeriodForm.getInputProps('workPeriodId')}
            onChange={(value) => {
              workPeriodForm.setFieldValue('workPeriodId', value);
              setIsDirtyWorkPeriods(true);
            }}
            data={workPeriodOptions}
            allowDeselect={false}
          />
        )}
        {workPeriodType === WorkPeriodType.Custom && (
          <>
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('startDate')}
              maxDate={workPeriodForm.values.endDate}
              pointer
              miw="fit-content"
            />
            <DatePickerInput
              required
              leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
              leftSectionPointerEvents="none"
              {...workPeriodForm.getInputProps('endDate')}
              minDate={workPeriodForm.values.startDate}
              pointer
              miw="fit-content"
            />
          </>
        )}
        <Button
          disabled={!workPeriodForm.isDirty() || !workPeriodForm.isValid()}
          color={newCOLORS.darkIndigo}
          type="submit"
          miw={120}
        >
          Apply Filter
        </Button>
      </Panel>
    </form>
  );
}

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
`;
