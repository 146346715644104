import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { HistoricalBurnsResultDataPoint } from '../../api/projects-client/projects-client.type';
import { SprintMetricsBurnUpDataPoint } from '../../api/sprints-client/sprints-client.type';
import { Mode } from '../../containers/process-analysis/process-analysis.type';
import { newCOLORS } from '../../styles/colors';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';
import { BurnUpChartLegend } from '../burn-up-chart-legend/burn-up-chart-legend';

type BurnUpChartProps = {
  mode: Mode;
  chartRef?: React.RefObject<HTMLDivElement>;
  burnsUp: SprintMetricsBurnUpDataPoint[];
  historicalBurnsBand: HistoricalBurnsResultDataPoint;
  viewByType: ViewByType;
  dayOfSprint: number;
  showHistoricalBurns?: boolean;
};

export const BurnUpChart = ({
  mode,
  chartRef,
  burnsUp,
  historicalBurnsBand,
  viewByType,
  dayOfSprint,
  showHistoricalBurns = true,
}: BurnUpChartProps) => {
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);

  // make sure the historicalBurnsBand is not longer than the burn up lines
  if (burnsUp.length) {
    historicalBurnsBand.splice(burnsUp.length);
  }

  const historicalBurnsBandByDates = burnsUp.length
    ? getDatesForHistoricalBurnsBand(historicalBurnsBand, burnsUp[0].date)
    : [];

  const partialBurnsUpByDayOfSprint = burnsUp.map((dp, index) =>
    index < dayOfSprint
      ? dp
      : { ...dp, points_complete: null, tasks_complete: null, points_total: null, tasks_total: null },
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight > 100 ? window.innerHeight - 100 : window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // y-axis domain
  const maximumYBurns = burnsUp.reduce((max, item) => {
    if (viewByType === ViewByType.points) {
      return Math.max(max, item.points_complete, item.points_total);
    }
    return Math.max(max, item.tasks_complete, item.tasks_total);
  }, 0);
  const maximumYBand = Math.max(...historicalBurnsBand.map((dp) => dp.y));
  const maximumY = Math.max(maximumYBurns, maximumYBand);

  const maximumYDomain = Math.ceil(maximumY / 10) * 10;

  // x-axis tick values && weekend VictoryArea data
  const xAxisTickValues = burnsUp.map((dp) => dp.date);
  const weekendHolidayVictoryArea = getWeekendHolidayVictoryAreaData(burnsUp, maximumYDomain);

  // data to form area curve when points are added to the sprint
  const totalPointsAddedAreaData = partialBurnsUpByDayOfSprint.map((dp, _, array) => {
    if (viewByType === ViewByType.points) {
      return { x: dp.date, y: dp.points_total, y0: array[0].points_total };
    } else {
      return { x: dp.date, y: dp.tasks_total, y0: array[0].tasks_total };
    }
  });

  return (
    <Fragment>
      <ChartWithLegend ref={chartRef}>
        <BurnUpChartLegend showHistoricalBurns={showHistoricalBurns} />
        <div>
          <VictoryChart
            domainPadding={0}
            height={viewportHeight}
            width={viewportHeight * 2.42}
            padding={{ top: 10, bottom: 80, left: 75, right: 75 }}
          >
            {
              // vertical bars, corresponding to x-axis
              <VictoryAxis
                orientation="bottom"
                label={mode === Mode.Sprint ? 'Day of Sprint' : undefined}
                style={{
                  grid: { stroke: 'transparent' },
                  axis: {
                    stroke: `${newCOLORS.gray}`,
                    margin: 30,
                  },
                  tickLabels: { fontSize: 21 },
                  axisLabel: { fontSize: 26 },
                }}
                tickValues={xAxisTickValues}
                axisLabelComponent={<VictoryLabel dy={25} />}
                fixLabelOverlap
              />
            }
            {
              // horizontal bars, corresponding to y-axis
              <VictoryAxis
                dependentAxis
                crossAxis
                orientation="left"
                label={viewByType}
                domain={[0, maximumYDomain === 0 ? maximumYDomain + 5 : maximumYDomain]}
                tickFormat={(tick: number) => `${tick}`}
                style={{
                  grid: {
                    stroke: `${newCOLORS.gray}`,
                  },
                  axis: { stroke: 'transparent' },
                  tickLabels: { fontSize: 21 },
                  axisLabel: { fontSize: 26 },
                }}
                axisLabelComponent={<VictoryLabel dy={-25} />}
              />
            }
            {weekendHolidayVictoryArea.map((area, index) => (
              <VictoryArea
                key={index}
                style={{
                  data: { fill: newCOLORS.lighterGray, fillOpacity: 0.6 },
                }}
                data={area}
              />
            ))}
            {historicalBurnsBandByDates.length > 0 ? (
              <VictoryArea
                style={{ data: { fill: newCOLORS.lightBlue, fillOpacity: 0.6 } }}
                data={historicalBurnsBandByDates}
                interpolation="monotoneX"
              />
            ) : null}
            <VictoryArea
              style={{
                data: {
                  fill: newCOLORS.indigo,
                  fillOpacity: 0.3,
                  stroke: 'none',
                },
              }}
              standalone={false}
              data={totalPointsAddedAreaData}
              interpolation="monotoneX"
            />
            <VictoryLine
              style={{ data: { stroke: newCOLORS.indigo, strokeWidth: 4 } }}
              standalone={false}
              data={partialBurnsUpByDayOfSprint.map((dp) => ({
                x: dp.date,
                y: viewByType === ViewByType.points ? dp.points_total : dp.tasks_total,
              }))}
              interpolation="monotoneX"
            />
            {burnsUp.length > 0 ? (
              burnsUp.length === 1 ? (
                <VictoryScatter
                  style={{ data: { fill: newCOLORS.green } }}
                  size={5}
                  data={burnsUp.map((dp) => ({
                    x: dp.date,
                    y: viewByType === ViewByType.points ? dp.points_total : dp.tasks_total,
                  }))}
                />
              ) : (
                <VictoryLine
                  style={{ data: { stroke: newCOLORS.green, strokeWidth: 4 } }}
                  standalone={false}
                  data={partialBurnsUpByDayOfSprint.map((dp) => ({
                    x: dp.date,
                    y: viewByType === ViewByType.points ? dp.points_complete : dp.tasks_complete,
                  }))}
                  interpolation="monotoneX"
                  groupComponent={<VictoryClipContainer clipPadding={{ top: 5, bottom: 5 }} />}
                />
              )
            ) : (
              <VictoryScatter style={{ data: { fill: newCOLORS.green } }} size={5} data={[{ x: 1, y: 0 }]} />
            )}
          </VictoryChart>
        </div>
      </ChartWithLegend>
    </Fragment>
  );
};

const getWeekendHolidayVictoryAreaData = (burnsUp: SprintMetricsBurnUpDataPoint[], height: number) => {
  const weekendHolidayVictoryAreaData: any[] = [];

  let oneSegmentData: any[] = [];
  let beforeDayStatus = false;
  burnsUp.forEach((point: SprintMetricsBurnUpDataPoint) => {
    if (point.weekend_or_holiday === true) {
      oneSegmentData.push({ x: point.date, y: height });
    }
    if (point.weekend_or_holiday === false && beforeDayStatus === true) {
      oneSegmentData.push({ x: point.date, y: height });
      weekendHolidayVictoryAreaData.push(oneSegmentData);
      oneSegmentData = [];
    }
    beforeDayStatus = point.weekend_or_holiday;
  });
  if (oneSegmentData.length) {
    weekendHolidayVictoryAreaData.push(oneSegmentData);
  }

  return weekendHolidayVictoryAreaData;
};

function getDatesForHistoricalBurnsBand(historicalBurnsBand: HistoricalBurnsResultDataPoint, startingDate: string) {
  const date = dayjs(startingDate + '/' + new Date().getFullYear());

  return historicalBurnsBand.map((dp) => ({
    x: date.add(dp.x, 'day').format('MM/DD'),
    y: dp.y,
    y0: dp.y0,
  }));
}

const ChartWithLegend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
