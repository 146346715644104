import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { AnalyzeDataResult, UseAIResult } from './agent-client.class';
import { AnalyzeDataResponse, UseAIResponse } from './agent-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/';

/**
 * Checks whether or not a user can use AI per configuration
 *
 * @param {string} organizationId - The ID of the organization to check.
 * @returns UseAIResult(data)
 */
async function canUseAI(organizationId: string): Promise<UseAIResult> {
  const apiURL = baseURL + 'agents/use-ai/';
  return await axiosInstance
    .get<UseAIResponse>(apiURL, { params: { organization_id: organizationId } })
    .then((response) => new UseAIResult(response.data))
    .catch((error) => error.response.data);
}

//////////////
//
// Bottom two end points are non-existent as of now in the BE
// and I could not find any reference to them in the FE, so
// leaving them as such for now.
//
/////////////

/**
 * Analyzes data within a message built for the agent
 * @param type: the type of page asking for the analysis
 * @param message: the message to provide to the agent
 * @returns UseAIResult(data)
 */
async function analyzeData(type: string, message: string): Promise<AnalyzeDataResult> {
  const apiURL = baseURL + 'agents/analyze-data/';
  const requestBody = { type, message };
  return await axiosInstance
    .post<AnalyzeDataResponse>(apiURL, requestBody)
    .then((response) => {
      return new AnalyzeDataResult(response.data);
    })
    .catch((error) => {
      const errorMessage = error.response.data;
      return errorMessage;
    });
}

/**
 * Asks a question for the agent.
 * @param type: the type of page asking the question
 * @param message: the message to provide to the agent
 * @param threadId: the thread id for context
 * @returns UseAIResult(data)
 */
async function askQuestion(type: string, message: string, threadId: string) {
  const apiURL = baseURL + 'agents/analyze-data/';
  const requestBody = { type, message, threadId };
  return await axiosInstance
    .post<AnalyzeDataResponse>(apiURL, requestBody)
    .then((response) => {
      return new AnalyzeDataResult(response.data);
    })
    .catch((error) => {
      const errorMessage = error.response.data;
      return errorMessage;
    });
}

export { analyzeData, askQuestion, canUseAI };
