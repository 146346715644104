import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '../logger/logger';
import { State } from './integrations-store.types';

const useIntegrationsStore = create<State>()(
  devtools(
    logger((_) => ({
      integration: null,
      integrations: [],
    })),
  ),
);

export { useIntegrationsStore };
