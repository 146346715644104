import { styled } from '@linaria/react';
import { Combobox, Flex, InputBase, useCombobox } from '@mantine/core';
import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { Indicator } from '../../../components/indicator/indicator';
import { getIndicatorProps, getSelectData } from './available-integrations-select.helpers';
import { availableIntegrations } from './integrations-list.data';
import { Integration } from './integrations-list.type';

type AvailableIntegrationsSelectProps = {
  activeIntegration: Integration;
  setActiveIntegration: (integration: Integration) => void;
  integrationEntries: IntegrationEntry[];
};

export const AvailableIntegrationsSelect = ({
  activeIntegration,
  setActiveIntegration,
  integrationEntries,
}: AvailableIntegrationsSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = getSelectData(availableIntegrations, integrationEntries).map(({ label, value, indicatorProps }) => (
    <StyledComboboxOption value={value} key={label}>
      <Indicator value={indicatorProps.type} tooltip={indicatorProps.tooltip} />
      {label}
    </StyledComboboxOption>
  ));

  const indicatorProps = getIndicatorProps(activeIntegration, integrationEntries);

  return (
    <SelectContainer>
      <SelectLabel>Available Integration</SelectLabel>
      <Combobox
        width={180}
        store={combobox}
        onOptionSubmit={(value) => {
          setActiveIntegration(availableIntegrations.find((i) => i.name === value) as Integration);
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <StyledInputBase
            onClick={() => combobox.toggleDropdown()}
            onBlur={() => combobox.closeDropdown()}
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            component="button"
            type="button"
            pointer
          >
            <Flex align="center" gap={8}>
              <Indicator value={indicatorProps.type} tooltip={indicatorProps.tooltip} />
              {activeIntegration.title}
            </Flex>
          </StyledInputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 40px;
`;

const SelectLabel = styled.div`
  color: #25292d;
  text-align: right;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledInputBase = styled(InputBase)`
  width: 180px;
`;

const StyledComboboxOption = styled(Combobox.Option)`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  padding: 10px;
`;
