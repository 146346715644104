import { Flex, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { checkValidEmail } from '../../../../helpers';
import { useIntegrationsStore } from '../../../../store/integrations-store/integrations-store';
import { baseWhite } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { ValidationError } from '../../../onboarding/sign-up/sign-up.type';
import { AddNewIntegrationConsent } from '../add-new-integration-consent';
import { StyledForm } from '../add-new-integration.styled';
import { AuthFormType, BasicAuthForm as BasicAuthFormType } from '../add-new-integration.types';
interface Props {
  onSubmit: (values: BasicAuthFormType) => void;
}

export const BasicAuthForm = ({ onSubmit }: Props) => {
  const integration = useIntegrationsStore((state) => state.integration);

  const form = useForm<BasicAuthFormType>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (checkValidEmail(value) ? null : ValidationError.InvalidEmail),
    },
  });

  if (!integration) {
    return null;
  }

  return (
    <StyledForm onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap={24}>
        <Divider />
        <TextInput
          required
          mb="md"
          label="Email"
          placeholder="Enter your email address"
          {...form.getInputProps('email')}
        />
        <PasswordInput
          required
          mb="md"
          label="Password"
          placeholder="Enter your password"
          {...form.getInputProps('password')}
        />
        <Divider />
        <AddNewIntegrationConsent type={AuthFormType.Basic} />
        <Divider />
      </Flex>
      <Button
        type="submit"
        fullWidth
        radius="xl"
        rightSection={<Icon name="arrow_forward" color={baseWhite} size={18} />}
      >
        Continue
      </Button>
    </StyledForm>
  );
};
