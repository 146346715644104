import { ChartDataset, Point, TooltipItem } from 'chart.js';
import { ProcessAnalysisInitiativeChartData } from '../../api/portfolio-client/portfolio-client.type';
import { newCOLORS } from '../../styles/colors';

/**
 * Helper method to format the data for the line chart
 *
 * @param data data to be plotted
 * @param color color of the line
 * @param trendLineOrNot boolean to check if the line is a trend line or not
 * @returns an object with the formatted data
 */
const createLine = (
  measureName: string,
  data: number[],
  color: string,
  trendLineOrNot: boolean,
  yAxisID?: 'y' | 'y1',
): ChartDataset<'line', (number | Point | null)[]> => {
  return {
    data: data,
    label: measureName,
    borderColor: color,
    backgroundColor: color,
    pointRadius: trendLineOrNot ? 0 : 3,
    pointBackgroundColor: newCOLORS.white,
    pointHitRadius: 5,
    borderDash: trendLineOrNot ? [10, 5] : [],
    pointHoverRadius: trendLineOrNot ? 0 : 5,
    borderWidth: 2,
    tension: 0.1,
    yAxisID: yAxisID ? yAxisID : 'y',
  };
};

/**
 * Sort the response object by date keys
 *
 * @param obj a response object
 * @returns a sorted object by date keys
 */
const sortObjectByDateKeys = (obj: { [key: string]: number }): { [key: string]: number } => {
  return Object.keys(obj)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .reduce<{ [key: string]: number }>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

/**
 * Formats the tooltip label for a chart data point
 *
 * @param {TooltipItem<'line'>} tooltipItem - The tooltip item containing dataset information
 * @param {ProcessAnalysisInitiativeChartData} initiativeData - Data about initiatives to look up names
 * @returns {string} Formatted tooltip label with initiative name and value
 */
const getTooltipLabel = (
  tooltipItem: TooltipItem<'line'>,
  initiativeData: ProcessAnalysisInitiativeChartData,
): string => {
  const { label } = tooltipItem.dataset;
  const isTrend = label?.includes('Trend');
  const id = isTrend ? label?.split(' ')[0] : label;

  if (!initiativeData?.initiatives) {
    return id ?? 'Unknown';
  }

  const initiative = initiativeData.initiatives.find((initiative) => initiative.id === id);
  const initiativeName = initiative?.name ?? id;
  const name = isTrend ? `${initiativeName} Trend` : initiativeName;

  return `${name}: ${tooltipItem.formattedValue}`;
};

export { createLine, getTooltipLabel, sortObjectByDateKeys };
