import { Integration } from '../../../api/integrations-client/integrations-client.type';

import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { integrationNameMapping } from './add-new-integration.constants';

/**
 * Gets the display name for an integration, using a mapping if available
 *
 * @param {Integration} integration - The integration object containing name and other properties
 * @returns {string} The mapped display name if available, otherwise returns the original integration name
 */
const getIntegrationName = (integration: Integration): string => {
  const name = integration.name as ExternalService;

  return integrationNameMapping[name] ?? integration.name;
};

/**
 * Formats a logo string into a data URL for SVG images
 *
 * @param {string} logo - The logo string in Python byte string format (e.g. "b'base64string'")
 * @returns {string | undefined} A data URL containing the base64-encoded SVG image or undefined if the logo is not valid
 */
const formatLogoSrc = (logo: string): string | undefined => {
  const base64String = parseLogoSrc(logo);

  if (!base64String) {
    return undefined;
  }

  return `data:image/svg+xml;base64,${base64String}`;
};

/**
 * Parses a logo string by removing Python byte string markers and validates it as base64
 *
 * @param {string} logo - The logo string in Python byte string format (e.g. "b'base64string'")
 * @returns {string | null} The extracted base64 string if valid, null if invalid or malformed
 */
const parseLogoSrc = (logo: string): string | null => {
  try {
    // Only process strings that look like Python byte strings
    if (!logo.startsWith("b'") || !logo.endsWith("'")) {
      return null;
    }
    const base64String = logo.slice(2, -1);
    window.atob(base64String);
    return base64String;
  } catch (error) {
    return null;
  }
};

/**
 * Encodes email and password for the "Basic" integration authorization.
 * The algorithm works in 3 steps:
 * 1. Combines email and password with colon separator (`email:password`)
 * 2. Handles Unicode characters by:
 *    - First encoding special chars to UTF-8 (encodeURIComponent)
 *    - Then decoding back to handle the UTF-8 bytes (decodeURIComponent)
 * 3. Encodes the resulting string to Base64 using btoa()
 *
 * @param {string} email - The user's email address
 * @param {string} password - The user's password
 * @returns {string} Base64 encoded auth string
 */
const encodeBasicAuth = (email: string, password: string): string => {
  return window.btoa(decodeURIComponent(encodeURIComponent(`${email}:${password}`)));
};

export { encodeBasicAuth, formatLogoSrc, getIntegrationName };
