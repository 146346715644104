import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { RiskAnalysisTask } from './risk-analysis.client.type';
import { useWeeklyRiskScores } from './risk-analysis.hooks';
import { ChartContainer } from './task-type-chart';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface RiskScoreChartProps {
  tasks: RiskAnalysisTask[];
}

export function RiskScoreChart({ tasks }: RiskScoreChartProps) {
  const weeklyAverages = useWeeklyRiskScores(tasks);

  const chartData = {
    labels: weeklyAverages.map((point) => format(point.date, 'MM/dd/yyyy')),
    datasets: [
      {
        label: 'Average Risk Score',
        data: weeklyAverages.map((point) => ({
          x: point.date,
          y: point.averageScore,
        })),
        borderColor: '#2E90FA',
        backgroundColor: '#2E90FA',
        tension: 0.3,
        pointRadius: 0,
        pointHoverRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: 'index',
        callbacks: {
          title: (context: any) => {
            return `Week of ${format(context[0].raw.x, 'MMM d, yyyy')}`;
          },
          label: (context: any) => {
            return `Risk Score: ${context.raw.y.toFixed(1)}`;
          },
        },
      },
    },
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: '#EAECF0',
        },
        ticks: {
          padding: 8,
          color: '#475467',
          font: {
            size: 12,
          },
          callback: (value: number) => value.toFixed(1),
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          padding: 8,
          color: '#475467',
          font: {
            size: 12,
          },
          maxRotation: 0,
          minRotation: 0,
        },
        title: {
          display: true,
          text: 'Task Created Date',
          color: '#475467',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <ChartContainer>
      <Line data={chartData} options={options as any} />
    </ChartContainer>
  );
}
