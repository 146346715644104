import dayjs from 'dayjs';
import { useEffect } from 'react';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import {
  useAvailableMeasures,
  useTimeAllocations as useTimeAllocationsHook,
  useWorkPeriodsTasksSingular,
} from '../../api/work-periods-client/work-periods-client.hooks';
import {
  EntityType,
  Measure,
  UseWorkPeriodsMeasurePayloadSingular,
  WorkPeriodScope,
} from '../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import {
  setAvailableMeasures,
  setTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.actions';
import {
  useDateRange,
  useEntities,
  useTimeAllocation,
  useTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.hooks';
import { useFlowFilters } from './assessment-view/assessment-view.hooks';
import { getEntityType, getTimeAllocationEndDate } from './process-analysis.helpers';
import { Tab, WorkPeriodType } from './process-analysis.type';

/**
 * Custom hook for fetching a list of available measures for the process analysis page.
 * Stores the result in the process analysis store.
 *
 * @returns {{ isFetching: boolean }} - An object containing an isFetching flag.
 */
const useAvailableMeasuresData = (): { isFetching: boolean } => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const { data, query } = useAvailableMeasures({
    payload: { scope: WorkPeriodScope.ProcessAnalysis, portfolio_id: portfolio?.id },
    options: {
      enabled: !!portfolio?.id,
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (data) {
      const measures = data[WorkPeriodScope.ProcessAnalysis]?.measures || [];

      setAvailableMeasures(measures);
    }
  }, [data]);

  return { isFetching: query.isFetching };
};

const useTimeAllocationsData = (): { isFetching: boolean } => {
  const { startDate, endDate } = useDateRange();
  const timeAllocationType = useTimeAllocation();

  const { data, query } = useTimeAllocationsHook({
    payload: {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
      time_allocation_type: timeAllocationType,
    },
    options: {
      enabled: !!startDate && !!endDate,
      staleTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    if (data) {
      setTimeAllocations(data);
    }
  }, [data]);
  return { isFetching: query.isFetching };
};

/**
 * Custom hook to fetch tasks data for a specific measure for Portfolio/Team/Board level.
 *
 * @param {Measure} measure - The measure for which to fetch tasks data.
 * @param {string | null} taskFilter - The filter for the tasks data. Either contains a date or date and factor.
 * @param {boolean | null} isCustom - A boolean indicating whether the measure is custom.
 * @returns {MinifiedTask[] | undefined} data - The array of fetched tasks, or undefined if not yet loaded.
 * @returns {boolean} isFetching - A boolean indicating whether the data is currently being fetched.
 */
const useTasksData = (
  measure: Measure,
  taskFilter?: string | null,
  isCustom?: boolean | null,
): { data: MinifiedTask[] | undefined; isFetching: boolean } => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const timeAllocations = useTimeAllocations();
  const measures = [measure];
  const filters = useFlowFilters(measures);

  const payload = useTasksPayload(measures, activeTab);
  if (isCustom) {
    payload.is_custom = true;
  }

  if (measure === Measure.BulkStatusChanges && taskFilter) {
    payload.measure_filter = taskFilter;
  } else if ((activeTab === Tab.WorkPeriods && measure === Measure.FlowByPhase) || measure === Measure.FlowByPace) {
    if (filters !== null) {
      Object.assign(payload, filters);
    }
  } else if (taskFilter != null) {
    const matchingAllocation = timeAllocations.find((allocation) => allocation.id === taskFilter);
    if (activeTab === Tab.WorkPeriods) {
      payload.start_date = taskFilter;
      payload.end_date = taskFilter;
    } else if (matchingAllocation) {
      payload.start_date = matchingAllocation.start.split('T')[0];
      // in some cases, we need to subtract one day from the end date to get the correct date range
      // otherwise the end date may overlap with the start date of the next allocation
      payload.end_date = getTimeAllocationEndDate(payload.start_date, matchingAllocation.end.split('T')[0]);
    }
  }
  const { data, query } = useWorkPeriodsTasksSingular({
    payload,
    options: {
      enabled: !!payload.subproject_id || !!payload.portfolio_id || !!payload.project_id,
    },
  });
  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to generate a payload for fetching tasks data.
 *
 * @param {Measure[]} measures - The list of measures for the payload.
 * @param {Tab} activeTab - The active tab for the payload.
 * @returns {UseWorkPeriodsMeasurePayloadSingular} The payload object for fetching tasks data.
 */
const useTasksPayload = (measures: Measure[], activeTab: Tab): UseWorkPeriodsMeasurePayloadSingular => {
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const timeAllocation = useTimeAllocation();

  // if work period
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);

  const entityParams = {
    [EntityType.Portfolio]: 'portfolio_id',
    [EntityType.Project]: 'project_id',
    [EntityType.Subproject]: 'subproject_id',
  };

  if (activeTab !== Tab.WorkPeriods) {
    return {
      [entityParams[getEntityType(activeTab)]]: entities.length > 0 ? entities[0] : null,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
      measure_name: measures,
      time_allocation_type: timeAllocation,
    };
  } else {
    const defaultPayload = {
      subproject_id: workPeriodBoardId,
      measure_name: measures,
    };

    const customPayload =
      workPeriodType === WorkPeriodType.Defined && workPeriod
        ? {
            work_period_id: workPeriod?.id,
            start_date: dayjs(workPeriod?.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(workPeriod?.end_date).format('YYYY-MM-DD'),
          }
        : {
            start_date: dayjs(startDate).format('YYYY-MM-DD'),
            end_date: dayjs(endDate).format('YYYY-MM-DD'),
          };

    return {
      ...defaultPayload,
      ...customPayload,
    } as UseWorkPeriodsMeasurePayloadSingular;
  }
};

export { useAvailableMeasuresData, useTasksData, useTimeAllocationsData };
