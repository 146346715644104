import { useStrategyStore } from './strategy-store';
import { Message } from './strategy-store.type';

/**
 * Sets the message in the Strategy store.
 *
 * @param {Message | null} message - The message to set, or null to clear
 * @return {void}
 */
const setMessage = (message: Message | null): void => {
  useStrategyStore.setState({ message }, false, '[Strategy Store] setMessage');
};

export { setMessage };
