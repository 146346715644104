import {
  CustomMeasureDataResponse,
  Measure,
  MeasureDataResponse,
} from '../../api/work-periods-client/work-periods-client.type';
import { sortObjectByDateKeys } from '../../containers/process-analysis/process-analysis-chart.helpers';
import { round } from '../math-helpers/math-helpers';

function convertAndRoundMeasureValues(
  measureData: MeasureDataResponse | CustomMeasureDataResponse,
  measure: Measure,
  timeBasedMeasures: Measure[],
  precision = 0,
): number[] {
  const sortedValues = sortObjectByDateKeys(measureData[measure] ?? {});

  return Object.values(sortedValues).map((value) => {
    if (value !== null && value !== undefined) {
      return timeBasedMeasures.includes(measure) ? round(value, precision) : value;
    } else {
      return value;
    }
  });
}

export { convertAndRoundMeasureValues };
