import { useSearchParams } from 'react-router-dom';
import { removeDuplicates } from '../../containers/tasks-table/tasks-table.helpers';
import { TaskRecord, TaskType } from '../../containers/tasks-table/tasks-table.type';
import { formatDate } from '../../helpers';
import { FinancialsTasksScope } from './financials-tasks.type';

/**
 * Generates a title based on the financials tasks scope.
 *
 * @param {FinancialsTasksScope} scope - The scope of the financials tasks
 * @returns {string} The generated title string
 */
const makeTitle = (scope: FinancialsTasksScope): string => {
  const { factor, factorOption, date, name } = scope || {};
  const titleComponents: string[] = [];

  if (name) {
    titleComponents.push(name);
  } else if (factorOption) {
    const formattedFactorOption = factorOption
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    titleComponents.push(formattedFactorOption);
  } else if (factor) {
    const formattedFactor = factor
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    titleComponents.push(formattedFactor);
  }

  titleComponents.push('Tasks');

  if (date) {
    const [year, month] = date.split('-');
    const monthName = new Date(parseInt(year), parseInt(month) - 1, 1).toLocaleString('default', { month: 'long' });
    titleComponents.push(`${monthName} ${year}`);
  }

  return titleComponents.join(' - ');
};

/**
 * Formats an array of process tasks into an array of task records for the table.
 *
 * @param {TaskRecord[]} tasks - The array of process tasks to be formatted
 * @return {TaskRecord[]} The formatted array of task records
 */
const formatTasks = (tasks: TaskRecord[]): TaskRecord[] => {
  return tasks.map(
    (task) =>
      ({
        id: task.id,
        name: task.name,
        title: task.title,
        url: task.url,
        created_date: task.created_date,
        points: task.points,
        type: task.type as TaskType,
        epic: task.epic,
        initiatives: removeDuplicates(task.initiatives),
        transitions: task.transitions,
      }) as TaskRecord,
  );
};

/**
 * Generates and returns a FinancialsTasksScope object based on the search parameters.
 *
 * @param {string} portfolioId - The ID of the portfolio
 * @return {FinancialsTasksScope}
 */
const useFinancialsTasksScope = (portfolioId: string | undefined): FinancialsTasksScope => {
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('projectId');
  const subprojectId = searchParams.get('subprojectId');
  const date = searchParams.get('date');
  const factor = searchParams.get('factor');
  const factorOption = searchParams.get('factorOption');
  const name = searchParams.get('name');

  // currently not expected to get anything, but leaving here in case for future use
  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');

  if (date && !startDate && !endDate) {
    const [year, month] = date.split('-');
    const firstDay = new Date(parseInt(year), parseInt(month) - 1, 1);
    const lastDay = new Date(parseInt(year), parseInt(month), 0);

    startDate = formatDate(firstDay);
    endDate = formatDate(lastDay);
  }

  return {
    portfolioId,
    projectId,
    subprojectId,
    date,
    factor,
    factorOption,
    startDate,
    endDate,
    name,
  };
};

export { formatTasks, makeTitle, useFinancialsTasksScope };
