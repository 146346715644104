import { Fragment } from 'react/jsx-runtime';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { InitiativeCompletion } from './initiative-sections/initiative-completion';
import { InitiativeFocus } from './initiative-sections/initiative-focus';
import { InitiativeOverview } from './initiative-sections/initiative-overview';
import { MeasureComparison } from './measure-comparison/measure-comparison';
import { Tab } from './process-analysis.type';
import { WorkloadDistribution } from './workload-distribution/workload-distribution';

export function SingularView() {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  return (
    <Fragment>
      <MeasureComparison />
      {activeTab === Tab.Portfolios ? <WorkloadDistribution /> : null}
      {activeTab !== Tab.Boards ? (
        <Fragment>
          <InitiativeOverview />
          <InitiativeCompletion />
          <InitiativeFocus />
        </Fragment>
      ) : null}
    </Fragment>
  );
}
