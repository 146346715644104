import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  EntityType,
  MeasureDataByEntity,
  MeasureDataResponse,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useDateRange, useTimeAllocation } from '../../../store/process-analysis-store/process-analysis-store.hooks';

/**
 * Hook to fetch workload distribution data
 *
 * @param {string} portfolioId selected portfolio id
 * @param {string[]} projectIds selected project ids
 * @param {boolean} portfolioEnabled whether it is enabled for the given portfolio or not
 * @returns {Object} returns a object containing data
 */
function useWorkloadDistributionData(
  portfolioId: string,
  projectIds: string[],
  portfolioEnabled = true,
): {
  portfolioData: MeasureDataResponse | undefined;
  projectData: MeasureDataByEntity | undefined;
  isFetching: boolean;
} {
  const { startDate, endDate } = useDateRange();
  const timeAllocation = useTimeAllocation();
  const measure = 'workload_distribution_capacity';

  const portfolioPayload = {
    entity: EntityType.Portfolio,
    ids: [portfolioId],
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: [measure],
    time_allocation_type: timeAllocation,
    is_custom: true,
    transformer: 'cumulative_singular_value',
  };
  const projectPayload = {
    entity: EntityType.Project,
    ids: projectIds,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: [measure],
    time_allocation_type: timeAllocation,
    is_custom: true,
    transformer: 'cumulative_singular_value',
  };

  const { data: portfolioData, isFetching: isFetchingPortfolioData } = useWorkPeriodsMeasureMultiple({
    payload: portfolioPayload,
    options: { enabled: portfolioEnabled && Boolean(portfolioId) },
  });
  const { data: projectData, isFetching: isFetchingProjectData } = useWorkPeriodsMeasureMultiple({
    payload: projectPayload,
    options: { enabled: portfolioEnabled && Boolean(portfolioId) },
  });
  return {
    portfolioData: portfolioData?.[portfolioId],
    projectData: projectData,
    isFetching: isFetchingPortfolioData || isFetchingProjectData,
  };
}

export { useWorkloadDistributionData };
