import { styled } from '@linaria/react';
import { Badge } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { fetchSprintSummary } from '../../api/sprints-client/sprints-client';
import { AISprintSummary, Sprint } from '../../api/sprints-client/sprints-client.type';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';

export const SprintSummaryContainer = ({
  sprintId,
  sprint,
}: {
  sprintId: string | undefined;
  sprint: Sprint | null;
}) => {
  const [sprintSummary, setSprintSummary] = useState<AISprintSummary>();

  const sprintSummaryQuery = useQuery({
    queryKey: ['sprint_summary', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Unable to resolve SprintId or SprintID is missing');
      }
      return fetchSprintSummary(sprintId);
    },
    enabled: !!sprintId && !!sprint && !sprint.oversized,
  });

  useEffect(() => {
    if (sprintSummaryQuery.data) {
      setSprintSummary(sprintSummaryQuery.data);
    }
  }, [sprintSummaryQuery.data]);

  return (
    <SprintSummaryBox>
      <BetaHeading>
        <LargeHeadingTag>AI Summary</LargeHeadingTag>
        <Badge size="lg" variant="light" style={{ fontFamily: 'Figtree' }}>
          Beta
        </Badge>
      </BetaHeading>
      <CenterContentTag>{sprintSummary?.summary}</CenterContentTag>
    </SprintSummaryBox>
  );
};

const SprintSummaryBox = styled.div`
  text-align: center;
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
`;

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const CenterContentTag = styled(ContentTag)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: auto;
`;

const BetaHeading = styled.div`
  display: flex;
  justify-content: center;
  gap: 7px;
`;
