import { Group, ScrollArea, Stack } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useContext, useRef, useState } from 'react';
import { useUpdateUser } from '../../../../api/user-client/user-client.hooks';
import { UserInfo } from '../../../../api/user-client/user-client.type';
import { UserContext } from '../../../../contexts';
import { setSignUpStep } from '../../../../store/auth-store/auth-store.actions';
import { SignUpStep } from '../../../../store/auth-store/auth-store.type';
import { baseWhite, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3 } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { Legal } from './legal';

export const TermsAndConditions = () => {
  const { user, setUser } = useContext(UserContext);
  const viewport = useRef<HTMLDivElement>(null);
  const [bottomReached, setBottomReached] = useState(false);

  const updateUserMutation = useUpdateUser({
    onSuccess: (user: UserInfo) => {
      setUser(user);
      setSignUpStep(SignUpStep.CreateOrganization);
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const handleScroll = ({ y }: { x: number; y: number }) => {
    if (!viewport.current) {
      return;
    }

    const { scrollHeight, clientHeight } = viewport.current;
    const isAtBottom = Math.ceil(y + clientHeight) >= scrollHeight;

    if (isAtBottom && !bottomReached) {
      setBottomReached(true);
    }
  };

  const handleAccept = async () => {
    if (!user?.name) {
      Sentry.captureException(new Error('Username is required for T&C acceptance'));
      return;
    }

    await updateUserMutation.mutateAsync({ name: user.name, terms_and_conditions_accepted: true });
  };

  return (
    <Layout>
      <Stack gap={32}>
        <Group gap={16}>
          <Icon name="article" size={32} />
          <H3>Terms & Conditions</H3>
        </Group>
        <ScrollArea h="calc(100vh - 450px)" onScrollPositionChange={handleScroll} viewportRef={viewport}>
          <Legal />
        </ScrollArea>
        <Divider />
        <Button
          onClick={handleAccept}
          w="100%"
          radius="xl"
          rightSection={<Icon name="arrow_forward" size={18} color={bottomReached ? baseWhite : skyDark} />}
          disabled={!bottomReached}
        >
          Accept and Proceed
        </Button>
      </Stack>
    </Layout>
  );
};
