import { styled } from '@linaria/react';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { baseWhite, skyDark } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from './data-management-header';
import { usePortfolioProjects } from './portfolios-tab/portfolios.hooks';

type ProjectInfoPanelProps = {
  project: ProjectsResponse;
  portfolios: Portfolio[];
  currentPortfolio: Portfolio | null;
  setSelectedProject: ((project: ProjectsResponse | null) => void) | ((project: string | null) => void);
};

export function ProjectInfoPanel({ project, portfolios, currentPortfolio, setSelectedProject }: ProjectInfoPanelProps) {
  const projectPortfolios = portfolios.filter((portfolio) => portfolio.projects.some((p) => p.id === project.id));
  const { removeProjectFromPortfolio, isUpdating } = usePortfolioProjects();

  const handleRemoveProjectFromPortfolio = async (portfolio: Portfolio, project_id: string) => {
    await removeProjectFromPortfolio(portfolio, project_id);
    if (currentPortfolio?.id === portfolio.id) {
      setSelectedProject(null);
    }
  };

  return (
    <ProjectInfoPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          {project.name}
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedProject(null);
          }}
        />
      </HeaderContainer>
      <Divider style={{ marginTop: 30, marginBottom: 16 }} />
      <Text>Related Portfolios</Text>
      <PortfolioList>
        {projectPortfolios.map((portfolio) => (
          <div key={portfolio.id}>
            <Button
              key={portfolio.id}
              variant="secondary"
              radius="xl"
              size="sm"
              style={{
                width: 'fit-content',
                cursor: isUpdating ? 'not-allowed' : 'pointer',
                opacity: isUpdating ? 0.5 : 1,
              }}
              rightSection={
                <Icon
                  name="clear"
                  size={16}
                  color={baseWhite}
                  style={{ cursor: isUpdating ? 'not-allowed' : 'pointer', opacity: isUpdating ? 0.5 : 1 }}
                />
              }
              onClick={() => !isUpdating && handleRemoveProjectFromPortfolio(portfolio, project.id)}
            >
              {portfolio.name}
            </Button>
          </div>
        ))}
      </PortfolioList>
    </ProjectInfoPanelContainer>
  );
}

const ProjectInfoPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const PortfolioList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding-right: 8px;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
