import { Issue } from '../issues-client/issues-client.class';
import { IssueDetailsTask } from '../issues-client/issues-client.type';
import { SubprojectResponse } from '../projects-client/projects-client.type';

enum HistoricalSprintDataKind {
  velocity = 'Velocity',
  throughput = 'Throughput',
  leadTime = 'Lead Time',
  reactionTime = 'Reaction Time',
  cycleTime = 'Cycle Time',
}

type SprintSummary = {
  id: string;
  name: string;
  subproject_id: string;
  subproject_name: string;
  start_date: string;
  end_date: string;
  health: number;
  is_active: boolean;
};

type HistoricalSprintDataSprintTime = {
  start_date: string;
  end_date: string;
  amount: number;
};

type HistoricalSprintData = {
  title: HistoricalSprintDataKind;
  description: string;
  data_points: HistoricalSprintDataSprintTime[];
  average: number;
  stdev?: number;
  trend_value?: string;
  trend_direction?: string;
  trend_spread?: string;
  bands?: number[];
};

type AgingWIPDataPoint = {
  name: string;
  days: number;
};

type AgingWIPData = {
  section: string;
  data_points: AgingWIPDataPoint[];
};

type AgingWIPResponse = {
  title: string;
  description: string;
  data: AgingWIPData[];
};

type BaseSprintMetrics = {
  title?: string;
  description?: string;
};

/**
 * Burn Up
 */
type SprintMetricsBurnUpDataPoint = {
  date: string;
  tasks_complete: number;
  tasks_total: number;
  points_complete: number;
  points_total: number;
  weekend_or_holiday: boolean;
  notable_tasks?: Object[];
};

type SprintMetricsBurnUp = BaseSprintMetrics & {
  data_points: SprintMetricsBurnUpDataPoint[];
  stdev: number;
};

/**
 * Cumulative Flow
 */
type SprintMetricsCumulativeFlowDataPoint = {
  date: string;
  done: number;
  deployable: number;
  test: number;
  review: number;
  wip: number;
  blocked: number;
  backlog: number;
};

type SprintMetricsCumulativeFlow = BaseSprintMetrics & {
  data_points: SprintMetricsCumulativeFlowDataPoint[];
};

/**
 * Burn Flow Of Work
 */
type SprintMetricsFlowOfWorkTaskDataPoint = {
  date: string;
  notable_tasks: Object[];
  weekend_or_holiday: boolean;
  original_plan: number;
  tasks_complete: number;
  tasks_complete_ideal: number;
  tasks_doing: number;
  tasks_remaining: number;
  tasks_total: number;
};

type SprintMetricsFlowOfWorkPointDataPoint = {
  date: string;
  notable_tasks: Object[];
  weekend_or_holiday: boolean;
  original_plan: number;
  points_complete: number;
  points_complete_ideal: number;
  points_remaining: number;
  points_total: number;
  points_doing: number;
};

type SprintMetricsFlowOfWorkStat = {
  at_risk: number;
  burnt: number;
  done: number;
  off_track: number;
  on_track: number;
  scope_increase: number;
  wip: number;
};

type SprintMetricsBurnFlowOfWork = BaseSprintMetrics & {
  description: string;
  average: number;
  std_dev: number;
  labels: string[];
  data_points: SprintMetricsFlowOfWorkTaskDataPoint[] | SprintMetricsFlowOfWorkPointDataPoint[];
  stats: {
    [key: string]: SprintMetricsFlowOfWorkStat;
  };
};

type SprintStatusPercentagesStatuses = {
  backlog: number;
  blocked: number;
  deployable: number;
  done: number;
  review: number;
  test: number;
  wip: number;
};

type SprintStatusPercentages = {
  [key: string]: {
    points: SprintStatusPercentagesStatuses;
    tasks: SprintStatusPercentagesStatuses;
  };
};

type Sprint = SubprojectResponse & {
  id: string;
  name: string;
  project_id: string;
  subproject_id: string;
  subproject_name: string;
  health: number;
  start_date: string;
  end_date: string;
  velocity: number;
  throughput?: number;
  issues: Issue[];
  working_days: string;
  is_dangerous: boolean;
  goal?: string;
  current_sprint?: boolean;
  oversized: boolean;
  sprint_review_video_link: string;
  metrics?: SprintMetrics;
  burns?: SprintBurnsAndFlows;
  projections?: SprintProjections;
};

type SprintBurnsAndFlows = {
  burns_up: SprintMetricsBurnUp;
  flows_tasks: SprintMetricsCumulativeFlow;
  flows_points: SprintMetricsCumulativeFlow;
  flow_of_work_points_by_pace: SprintMetricsBurnFlowOfWork;
  flow_of_work_tasks_by_pace: SprintMetricsBurnFlowOfWork;
};

type SprintMetrics = {
  complexity?: number;
  bug_or_tech_debt_share?: number;
  scope_increase?: number;
  memoized_scope_increase?: number;
  ready_tasks_share?: number;
  dependency_share?: number;
  tasks_linked_to_epic_share?: number;
  historical_health: HistoricalHealth;
  status_percentages: SprintStatusPercentages;
  in_progress_tasks_share?: number;
  carry_over_tasks?: number;
  tasks_completed_in_current_sprint_count?: number;
  average_days_open_for_change_requests: number;
  percentage_of_declined_change_requests: number;
};

type AISprintSummary = {
  summary: string;
};

type Projection = {
  prediction: number;
  accuracy: number;
};

type MidSprintProjections = {
  [key: string]: { [key: string]: number; past_sprint: number };
};

type SprintProjections = {
  projected_carryover_tasks: Projection;
  projected_carryover_points: Projection;
  projected_midsprint_added_tasks: MidSprintProjections;
  projected_midsprint_added_points: MidSprintProjections;
};

type SprintMeasureTasks = {
  name: string;
  tasks: IssueDetailsTask[];
};
type SprintMeasureData = {
  amount: number;
  tasks: SprintMeasureTasks[];
};

type ChangeRequest = {
  title: string;
  body: string;
  number: number;
  additions: number;
  deletions: number;
  commits: number;
  net_lines_of_change: number;
  created_at: string;
  html_url: string;
};

type SprintMeasure = {
  measure: SprintMeasureData;
};

type SprintAuditLogEntry = {
  date: string;
  identifier: string;
  id: string;
  type: 'task' | 'sprint';
  message: string;
};

type HistoricalHealth = {
  total_sprints: number;
  data: HistoricalHealthDatum[];
};

type HistoricalHealthDatum = {
  date: string;
  improved_over: number;
};

type TaskFlowHistoryResponse = {
  jira_history: TaskFlowHistoryType;
};

type TaskFlowHistoryType = {
  [key: string]: TaskFlowHistoryOnDate;
};

type AuditLogResponse = {
  audit_log: SprintAuditLogEntry[];
};

type BloomfilterStatuses = 'backlog' | 'blocked' | 'wip' | 'review' | 'test' | 'deployable' | 'done';

type TaskFlowHistoryOnDate = {
  [key in BloomfilterStatuses]: TaskFlowHistoryTask[];
};

type TaskFlowHistoryTask = {
  name: string;
  code: string;
  points: string;
};

type SubprojectSprint = [string, string];

type SubprojectSprints = {
  sprints: SubprojectSprint[];
};

type SprintAssessmentData = {
  burns: SprintBurnsAndFlows | undefined;
  metrics: SprintMetrics | undefined;
  projections: SprintProjections | undefined;
};

export { HistoricalSprintDataKind };
export type {
  AgingWIPResponse,
  AISprintSummary,
  AuditLogResponse,
  BloomfilterStatuses,
  ChangeRequest,
  HistoricalHealth,
  HistoricalSprintData,
  HistoricalSprintDataSprintTime,
  Projection,
  Sprint,
  SprintAssessmentData,
  SprintAuditLogEntry,
  SprintBurnsAndFlows,
  SprintMeasure,
  SprintMeasureData,
  SprintMeasureTasks,
  SprintMetrics,
  SprintMetricsBurnFlowOfWork,
  SprintMetricsBurnUp,
  SprintMetricsBurnUpDataPoint,
  SprintMetricsCumulativeFlow,
  SprintMetricsCumulativeFlowDataPoint,
  SprintMetricsFlowOfWorkPointDataPoint,
  SprintMetricsFlowOfWorkStat,
  SprintMetricsFlowOfWorkTaskDataPoint,
  SprintProjections,
  SprintStatusPercentages,
  SprintStatusPercentagesStatuses,
  SprintSummary,
  SubprojectSprints,
  TaskFlowHistoryOnDate,
  TaskFlowHistoryResponse,
  TaskFlowHistoryTask,
  TaskFlowHistoryType,
};
