import dayjs from 'dayjs';
import {
  InitiativesFocusResponse,
  ProcessAnalysisInitiativeChartData,
} from '../../../api/portfolio-client/portfolio-client.type';
import { useWorkPeriodsMeasureSingular } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  TimeAllocationType,
  UseWorkPeriodsMeasurePayloadSingular,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { Tab } from '../process-analysis.type';

/**
 * Fetches initiative focus data for either the selected portfolios or teams based on the active tab.
 *
 * @returns {{ data: ProcessAnalysisInitiativeChartData | undefined; isFetching: boolean }} - Object containing the initiative focus data and a boolean indicating whether the data is being fetched
 */
const useInitiativeFocusData = (): {
  data: ProcessAnalysisInitiativeChartData | undefined;
  isFetching: boolean;
} => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const portfolioIds = useProcessAnalysisStore((state) => state.portfolioIds);
  const teamIds = useProcessAnalysisStore((state) => state.teamIds);
  const { startDate, endDate } = useDateRange();

  const payload: UseWorkPeriodsMeasurePayloadSingular = {
    measure_name: [Measure.InitiativesFocus],
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    time_allocation_type: TimeAllocationType.Monthly,
    queryKey: ['initiative-focus', activeTab, portfolioIds, teamIds, startDate, endDate],
    enabled: activeTab === Tab.Portfolios || activeTab === Tab.Teams,
  };

  const { data, query } = useWorkPeriodsMeasureSingular<InitiativesFocusResponse>({
    payload:
      activeTab === Tab.Portfolios
        ? { ...payload, portfolio_id: portfolioIds[0] }
        : { ...payload, project_id: teamIds[0] },
  });

  const initiatives = data?.initiatives_focus?.initiatives ? Object.values(data.initiatives_focus.initiatives) : [];
  const labels = data?.initiatives_focus?.labels ? data.initiatives_focus.labels : [];

  return { data: { initiatives, labels }, isFetching: query.isPending };
};

export { useInitiativeFocusData };
