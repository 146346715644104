import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProject, fetchProjectMetrics } from '../../api/projects-client/projects-client';
import {
  Project,
  ProjectMetricsResponse,
  Subproject,
  SubprojectMetricsData,
  SubprojectMetricsResponse,
} from '../../api/projects-client/projects-client.type';
import { HealthGauge } from '../../components/health-gauge/health-gauge';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { ProjectStatisticsGrid } from '../../components/project-statistics-grid/project-statistics-grid';
import { SubprojectSelector } from '../../components/subproject-selector/subproject-selector';
import { ProjectContext } from '../../contexts';
import { filterSubprojects } from '../../contexts/project.helpers';
import { useRoles } from '../../helpers/auth-helpers/auth.hooks';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { HealthBarContainer, SubtitleHeadingTag, TitleHeadingTag } from '../../styles/shared-styled-components';
import { IntegrationsContext } from '../integrations/context/integrations.context';
import { IntegrationState } from '../integrations/context/integrations.context.type';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { SidebarContext } from '../side-bar/side-bar.context';
import styles from './project-details.module.css';

export const ProjectDetails = () => {
  useDocumentTitle('Project Details - Bloomfilter');
  const { isAdmin } = useRoles();
  const { project, subprojects, setProject, setSubprojects } = useContext(ProjectContext);
  const { viewAreaWidth } = useContext(SidebarContext);
  const { projectsState } = useContext(IntegrationsContext);
  const [selectedSubproject, setSelectedSubproject] = useState<Subproject>();
  const [selectedMetrics, setSelectedMetrics] = useState<SubprojectMetricsResponse>();
  const [metricsResponse, setMetricsResponse] = useState<ProjectMetricsResponse>();
  const { projectId = '', subprojectId = '' } = useParams<{ projectId: string; subprojectId: string }>();
  const navigate = useNavigate();

  const metricsQuery = useQuery<ProjectMetricsResponse, Error>({
    queryKey: ['projectMetrics', projectId, selectedSubproject?.id] as const,
    queryFn: async () => {
      if (!projectId) {
        throw new Error('Project/subproject ID is required');
      }
      const data = await fetchProjectMetrics(projectId, selectedSubproject?.id);
      return data;
    },
    enabled: !metricsResponse && Boolean(projectId && selectedSubproject?.id),
    gcTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (metricsQuery.data) {
      setMetricsResponse(metricsQuery.data);
    } else if (metricsQuery.isError) {
      setMetricsResponse({} as ProjectMetricsResponse);
    }
  }, [metricsQuery.data, metricsQuery.isError]);

  const projectQuery = useQuery<Project, Error>({
    queryKey: ['project', projectId] as const,
    queryFn: async () => {
      if (!projectId) {
        throw new Error('Project ID is required');
      }
      const data = await fetchProject(projectId);
      return data;
    },
    enabled: Boolean(projectId),
    gcTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (projectQuery.data) {
      const filteredProject = filterSubprojects(projectQuery.data);
      setProject(filteredProject);
      setSubprojects(filteredProject.subprojects);
    } else if (projectQuery.isError) {
      const errorState = { errorMessage: projectQuery.error.message, errorStatus: 500 };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
  }, [projectQuery.data, projectQuery.isError, projectQuery.error, navigate, setProject, setSubprojects]);

  const navigateToSelectedObject = (subproject?: Subproject) => {
    if (subproject) {
      navigate(`/application/project/${projectId}/subproject/${subproject?.id}`);
    } else {
      navigate(`/application/project/${projectId}`);
    }
    setSelectedSubproject(subproject);
  };

  useEffect(() => {
    if (subprojects && subprojects.length > 0) {
      if (subprojects.length === 1) {
        setSelectedSubproject(subprojects[0]);
      } else {
        setSelectedSubproject(subprojects.find((x: Subproject) => x.id === (subprojectId || '')) ?? subprojects[0]);
      }
    }
  }, [subprojectId, subprojects]);

  useEffect(() => {
    if (metricsResponse) {
      if (!selectedSubproject) {
        setSelectedMetrics(
          Object.assign({}, metricsResponse, { health: project?.health }) as SubprojectMetricsResponse,
        );
      } else {
        const metric = metricsResponse.metrics?.find(
          (x: SubprojectMetricsData) => x.subproject_id.toString() == selectedSubproject?.id,
        );
        setSelectedMetrics(metric?.details as SubprojectMetricsResponse);
      }
    }
  }, [selectedSubproject, metricsResponse, project, projectQuery.isPending, metricsQuery.isPending]);

  if (!project) {
    return <MenuDrivenContainer />;
  }

  let statisticsDescription =
    'Trends for velocity, throughput, lead, reaction, and cycle times. Click on a chart to see its historical view and average.';
  if (selectedSubproject) {
    statisticsDescription =
      'Trends for throughput, lead, reaction, and cycle times. Click on a chart to see its historical view and average.';
  }

  const isProjectStale = projectsState[project.id] === IntegrationState.STALE;

  return (
    <MenuDrivenContainer header={selectedSubproject ? selectedSubproject.name : project.name}>
      <div className={styles.projectStatisticsHeader}>
        {subprojects && subprojects.length > 1 && (
          <SubprojectSelector
            key={`subproject-selector-${subprojectId}`}
            subprojects={subprojects}
            setSelectedSubproject={navigateToSelectedObject}
            includeAggregate={true}
            defaultValue={subprojectId || ''}
          />
        )}
      </div>
      {isProjectStale && isAdmin && <IntegrationsStaleMessage />}
      <ProjectHeadlineContainer viewAreaWidth={viewAreaWidth}>
        <HealthBarContainer>
          <div style={{ width: 400, height: 225 }}>
            <HealthGauge
              heading="Product Delivery Score"
              tooltip="The Product Delivery Score is the average of the Sprint Performance Scores over a given time period (up to the last six sprints). It helps spot patterns and grading trends across sprints."
              health={selectedMetrics?.health || 0}
              shouldAnimate={!!selectedMetrics?.health}
              shouldFade={true}
              status={selectedMetrics?.health ? 'success' : 'loading'}
            />
          </div>
        </HealthBarContainer>
      </ProjectHeadlineContainer>
      {selectedMetrics && (
        <>
          <div>
            <TitleHeadingTag style={{ fontSize: '20px' }}>Statistics</TitleHeadingTag>
            <SubtitleHeadingTag>{statisticsDescription}</SubtitleHeadingTag>
          </div>

          <ProjectStatisticsGrid
            metricsResponse={selectedMetrics}
            project={project}
            subproject={selectedSubproject}
            navigate={navigate}
          />
        </>
      )}
    </MenuDrivenContainer>
  );
};

const IntegrationsStaleMessage = () => {
  const navigate = useNavigate();

  const link = (
    <PageHeaderMessageLink onClick={() => navigate('/application/integrations-list')}>click here</PageHeaderMessageLink>
  );
  const message = (
    <PageHeaderMessageText>This project has stale data. To ensure refreshed data, {link}.</PageHeaderMessageText>
  );

  return (
    <div style={{ margin: '10px 0 25px 0' }}>
      <PageHeaderMessage message={message} color="yellow" />
    </div>
  );
};

const ProjectHeadlineContainer = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 992 ? 'row' : 'column')};
  align-items: ${(props) => (props.viewAreaWidth < 992 ? 'center' : 'flex-start')};
  gap: 40px;
`;

const PageHeaderMessageText = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const PageHeaderMessageLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
