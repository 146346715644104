import { styled } from '@linaria/react';
import { newCOLORS } from '../../styles/colors';
import { StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  min-width: 800px;
`;

const PageHeading = styled.div`
  color: #444;
  font-family: Figtree;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 32px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
`;

const Header = styled.div`
  color: #444;
  font-family: Figtree;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0px 16px;
`;

const InputLabel = styled(StandardText)`
  margin: 8px 0px;
`;

const Text = styled.div<{ fontSize?: number; fontWeight?: number; active?: boolean; grayed?: boolean }>`
  color: ${(props: { active?: boolean; grayed?: boolean }) =>
    props.active ? newCOLORS.white : props.grayed ? newCOLORS.gray2 : newCOLORS.black2};
  font-family: Figtree;
  font-size: ${(props: { fontSize?: number }) => props.fontSize || 15}px;
  font-style: normal;
  font-weight: ${(props: { fontWeight?: number }) => props.fontWeight || 500};
  line-height: normal;
`;

export { Header, InputLabel, InputWrapper, PageContainer, PageHeading, SectionContainer, Text };
