import { styled } from '@linaria/react';
import { LoadingOverlay, Title } from '@mantine/core';
import { Measure, MeasureDataByEntity, MeasureUnits } from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterUnwantedMeasures,
  useDateRange,
  useEntities,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { calculateAverage } from '../measure-comparison/measure-comparison.helpers';
import { MeasureLabel } from '../measure-label';
import { Pills } from '../pills/pills';
import { getColumnNames, getPills, getUnitsLabel } from '../process-analysis.helpers';
import { Table } from '../table/table';

type Props = {
  tableData: MeasureDataByEntity;
  dataIsLoading: boolean;
};

export function MonthlyAverages({ tableData = {}, dataIsLoading }: Props) {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const rows = availableMeasures
    .filter(filterUnwantedMeasures)
    .map(({ measure_name, measure_title, measure_description, measure_units, is_zero_valid }) => {
      const measureCell = (
        <GridCell key={measure_name}>
          <MeasureLabel label={measure_title} tooltip={measure_description} />
        </GridCell>
      );

      const valueCells = entities.map((id) => {
        const valuesOverTime = tableData[id]?.[measure_name as Measure] || {};
        let value = calculateAverage(valuesOverTime, is_zero_valid);
        if (measure_units === MeasureUnits.Hours && typeof value === 'number') {
          value /= 24;
        }

        return (
          <GridCell key={`${id}-${measure_name}`}>
            {value === null ? '—' : Math.round(value)}
            {value !== null && <UnitLabel>{getUnitsLabel(measure_units)}</UnitLabel>}
          </GridCell>
        );
      });

      return [measureCell, ...valueCells];
    });

  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  return (
    <DropShadowContainer>
      <LoadingOverlay visible={dataIsLoading} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
        <Pills pillText={pills} />
        <Title>Monthly Averages</Title>
      </div>
      <Table
        label="Measures"
        columnHeaders={getColumnNames(portfolios, teams, activeTab, entities) || entities}
        rows={rows}
      />
    </DropShadowContainer>
  );
}

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const GridCell = styled.div`
  display: flex;
  align-items: end;
  gap: 4px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
`;

const UnitLabel = styled.div`
  color: ${newCOLORS.gray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
`;
