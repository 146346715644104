import { styled } from '@linaria/react';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { newCOLORS } from '../../../styles/colors';
import { RiskAnalysisTask } from './risk-analysis.client.type';

ChartJS.register(ArcElement, Tooltip);

const chartColors = [
  newCOLORS.purple,
  newCOLORS.violet,
  newCOLORS.tangerine,
  newCOLORS.magenta,
  newCOLORS.aqua,
  newCOLORS.teal,
  newCOLORS.indigo,
  newCOLORS.darkYellow,
  newCOLORS.orange,
  newCOLORS.lighterGreen,
  newCOLORS.darkerGreen,
  newCOLORS.blue,
];

export function TaskTypeChart({ tasks }: { tasks: RiskAnalysisTask[] }) {
  const taskTypeCounts = tasks.reduce(
    (acc, task) => {
      acc[task.type] = (acc[task.type] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  const taskTypes = Object.keys(taskTypeCounts);
  const counts = taskTypes.map((type) => taskTypeCounts[type]);
  const total = counts.reduce((sum, count) => sum + count, 0);

  const data = {
    labels: taskTypes,
    datasets: [
      {
        data: counts,
        backgroundColor: taskTypes.map((_, index) => chartColors[index % chartColors.length]),
        borderColor: Array(taskTypes.length).fill(newCOLORS.white),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        align: 'center' as const,
        labels: {
          padding: 20,
          font: {
            size: 14,
          },
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const percentage = ((value / total) * 100).toFixed(1);
            return `${value} (${percentage}%)`;
          },
        },
      },
      datalabels: {
        enabled: false,
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
    },
  };
  if (!tasks.length) {
    return <ChartContainer>No data available</ChartContainer>;
  }

  return (
    <ChartContainer>
      <Pie data={data} options={options} />
    </ChartContainer>
  );
}

export const ChartContainer = styled.div`
  height: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1rem;
  overflow: auto;
`;
