import { styled } from '@linaria/react';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bulkUpsertSpending } from '../../api/financials-client/financials-client';
import { useSpending } from '../../api/financials-client/financials-client.hooks';
import { Spending } from '../../api/financials-client/financials-client.type';
import { ProjectsResponse } from '../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../api/summary-client/summary-client';
import { EditFinancialDataForm } from '../../components/edit-financial-data/edit-financial-data-form';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { TeamContext } from '../../contexts';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { newCOLORS } from '../../styles/colors';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const AddEditFinancials = () => {
  useDocumentTitle('Financials - Bloomfilter');
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [projects, setProjects] = useState([] as ProjectsResponse[]);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [spending, setSpending] = useState<Spending[]>([]);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const { team, setTeam } = useContext(TeamContext);
  const [initialTeam] = useState(team);

  const { data, query: getSpendingQuery } = useSpending({
    portfolioId: portfolioId as string,
    year: year,
    options: {
      enabled: !!portfolioId && !!year,
    },
  });

  useEffect(() => {
    if (data) {
      setSpending(data);
    }
  }, [data]);

  useEffect(() => {
    if (spending && spending.length > 0 && spending !== data) {
      setTeam(null);
      setEnableSave(true);
    }
  }, [data, spending, setTeam]);

  const bulkUpsertSpendingQuery = useMutation({
    mutationKey: ['bulkUpsertSpending'],
    mutationFn: (postBody: { spending: Spending[] }) => bulkUpsertSpending(postBody.spending),
    onSuccess: () => {
      getSpendingQuery.refetch();
      let route = `/application/financials/portfolio/${portfolioId}`;
      const nextTeam = getNextContextTeam();
      if (nextTeam) {
        route += `/team/${nextTeam.id}`;
      }
      navigate(`${route}`);
    },
  });

  const getNextContextTeam = useCallback((): ProjectsResponse | null => {
    const existingTeamIds = (data as Spending[]).map((datum) => datum.project_id);
    const newTeamIds = spending.map((datum) => datum.project_id);
    const addedTeams = new Array(...new Set(newTeamIds.filter((id) => !existingTeamIds.includes(id))));
    let addedTeam = null;
    if (addedTeams.length === 1) {
      addedTeam = projects.find((project) => project.id === addedTeams[0]);
    }
    return addedTeam || null;
  }, [data, projects, spending]);

  const getProjectsQuery = useQuery({
    queryKey: ['fetchPortfolioProjects', portfolioId],
    queryFn: () => fetchPortfolioProjects(portfolioId as string),
    enabled: portfolioId !== '',
  } as UseQueryOptions<ProjectsResponse[]>);

  useEffect(() => {
    if (getProjectsQuery.data) {
      setProjects(getProjectsQuery.data);
    }
  }, [getProjectsQuery.data]);

  const handleCancel = () => {
    setTeam(null);
    let route = `/application/financials/portfolio/${portfolioId}`;
    if (initialTeam && data && data.some((item) => item.project_id === initialTeam.id)) {
      route += `/team/${initialTeam.id}`;
    }
    navigate(`${route}`);
  };

  const shouldShowMessage = (): boolean => {
    if (
      team &&
      team.id !== 'aggregate' &&
      !getSpendingQuery.isPending &&
      !spending.some((datum) => datum.project_id === team.id && datum.budget)
    ) {
      return true;
    }
    return false;
  };

  const getMessageText = (): string => {
    if (team && team.id !== 'aggregate') {
      return `The ${team.name} team does not have financial data created. Please enter the monthly budgets below to continue.`;
    }
    return '';
  };

  const header = (
    <FinancialsHeader>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Fragment>
          <H2>{!getSpendingQuery.isPending && spending.length > 0 ? 'Edit' : 'Add'} Financial Data</H2>
          <StyledButton
            size={'medium'}
            type={'primary'}
            firstColor={newCOLORS.indigo}
            secondColor={newCOLORS.white}
            disable={!enableSave}
            style={{ marginLeft: 16 }}
            onClick={() => enableSave && bulkUpsertSpendingQuery.mutate({ spending })}
          >
            Save
          </StyledButton>
          <StyledButton
            size={'medium'}
            type={'secondary'}
            firstColor={newCOLORS.indigo}
            secondColor={newCOLORS.white}
            disable={false}
            style={{ marginLeft: 16 }}
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
        </Fragment>
      </div>
    </FinancialsHeader>
  );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay
        visible={getSpendingQuery.isPending || getProjectsQuery.isPending}
        transitionDuration={3}
        variant="colored"
      />
      <FinancialsContainer>
        {shouldShowMessage() ? <PageHeaderMessage message={getMessageText()} color="red"></PageHeaderMessage> : null}
        <EditFinancialDataForm
          key={`pf-${portfolioId}-financials`}
          spending={spending}
          setSpending={setSpending}
          persistedSpending={data || []}
          projects={projects}
          year={year}
          setYear={setYear}
        />
      </FinancialsContainer>
    </MenuDrivenContainer>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FinancialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
