import { useQuery } from '@tanstack/react-query';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProject } from '../api/projects-client/projects-client';
import { Project, Subproject } from '../api/projects-client/projects-client.type';
import { deleteSelectedSubprojectId, getSelectedSubprojectId } from '../helpers/storage/storage';
import { filterSubprojects } from './project.helpers';

type ProjectContextType = {
  project: Project | null;
  setProject: React.Dispatch<React.SetStateAction<Project | null>>;
};

type SubprojectContextType = {
  subprojects: Subproject[] | null;
  setSubprojects: React.Dispatch<React.SetStateAction<Subproject[] | null>>;
};

export const ProjectContext = createContext({} as ProjectContextType & SubprojectContextType);

export function ProjectProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [subprojects, setSubprojects] = useState<Subproject[] | null>(null);

  useEffect(() => {
    const selectedSubprojectId = getSelectedSubprojectId();
    if (subprojects !== null && !subprojects.map((sp) => sp.id !== selectedSubprojectId)) {
      deleteSelectedSubprojectId();
    }
  }, [subprojects]);

  const projectQuery = useQuery({
    queryKey: ['project', projectId] as const,
    queryFn: async () => {
      if (!projectId) {
        return null;
      }
      if (projectId === project?.id?.toString()) {
        return project;
      }
      return fetchProject(projectId);
    },
    enabled: !project && !!projectId,
  });

  useEffect(() => {
    if (projectQuery.data) {
      const filteredProject = filterSubprojects(projectQuery.data);
      setProject(filteredProject);
      setSubprojects(filteredProject.subprojects);
    }
  }, [projectQuery.data]);

  useEffect(() => {
    if (projectQuery.error) {
      const error = projectQuery.error as Error;
      const errorState = {
        errorMessage: error.message,
      };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
  }, [projectQuery.error, navigate]);

  return (
    <ProjectContext.Provider value={{ project, setProject, subprojects, setSubprojects }}>
      {children}
    </ProjectContext.Provider>
  );
}
