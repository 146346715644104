import { ReactNode, createContext, useState } from 'react';
import { Board, Team } from '../api/projects-client/projects-client.type';
import { BoardContextType, TeamContextType } from './scope.type';

export const TeamContext = createContext({} as TeamContextType);
export const BoardContext = createContext({} as BoardContextType);

export const TeamProvider = ({ children }: { children: ReactNode }) => {
  const [team, setTeam] = useState<Team | null>(null);

  return <TeamContext.Provider value={{ team, setTeam }}>{children}</TeamContext.Provider>;
};

export const BoardProvider = ({ children }: { children: ReactNode }) => {
  const [board, setBoard] = useState<Board | null>(null);

  return <BoardContext.Provider value={{ board, setBoard }}>{children}</BoardContext.Provider>;
};
