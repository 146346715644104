import { Spending } from '../../api/financials-client/financials-client.type';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';
import { Board, ProjectsResponse, Team } from '../../api/projects-client/projects-client.type';

/**
 * Retrieves the budget for a specific project and month.
 *
 * This function searches through financial data to find the budget allocated for a particular project
 * for a specific month. The month indexing is 0-based internally (to align with JavaScript's Date methods),
 * but the data it checks against is 1-based (i.e., January is 1, February is 2, etc.).
 * If no data is found, or if the budget is explicitly set to null for the conditions, it returns an empty string
 * to accomodate mantine's NumberInput component https://mantine.dev/core/number-input/#controlled
 * @param {Spending[]} financialData - An array of spending data
 * @param {ProjectsResponse} selectedTeam - An object containing information about the selected project/team.
 *        This object is of type ProjectsResponse, which is assumed to be a structure holding at least an 'id'
 * @param {Board | null} selectedSubproject - An object containing information about the selected subproject.
 *        This object is of type Board, which is assumed to be a structure holding at least an 'id'
 * @param {number} monthIndex - A 0-based index indicating the month for which the budget is sought
 *        (0 for January, 1 for February, etc.).
 *
 * @returns {number | ''} - The budget amount as a number if it is found and is not null. If the budget is not found,
 *           or is explicitly set to null in the data, the function returns an empty string.
 */
const getDatumBudget = (
  financialData: Spending[],
  selectedTeam: ProjectsResponse,
  selectedSubproject: Board | null,
  monthIndex: number,
): number | '' => {
  let datum: Spending | undefined;
  if (selectedSubproject && selectedSubproject?.id !== 'aggregate') {
    datum = financialData.find(
      (datum) =>
        datum.subproject_id === selectedSubproject.id &&
        datum.project_id === selectedTeam.id &&
        datum.month === monthIndex + 1,
    );
  } else {
    datum = financialData.find(
      (datum) =>
        datum.project_id === selectedTeam.id &&
        (datum.subproject_id === null || datum.subproject_id === undefined) &&
        datum.month === monthIndex + 1,
    );
  }
  if (!datum || datum.budget === null) {
    return '';
  }
  return Number(datum.budget);
};

/**
 * Method to determine the suffix
 * @param team  - The selected team
 * @param portfolio - The selected portfolio
 * @param board - The selected board
 * @returns the intended suffix
 */
const getScopeNameSuffix = (team: Team | null, portfolio: Portfolio | null, board: Board | null): string => {
  let name = '';
  if (board && board.id != 'aggregate') {
    name = board.name;
  } else if (team && team.id != 'aggregate') {
    name = team.name;
  } else if (portfolio) {
    name = portfolio.name.replace('Portfolio', '(all teams)');
  }
  if (name.length > 0) {
    return ` for ${name}`;
  }
  return '';
};

/**
 * Method to determine where the save button should be disabled
 * @param year - The selected year
 * @param today - The current date
 * @param monthIndex - The selected month
 * @returns boolean of whether the button should be disabled
 */
const checkDisabled = (year: number, today: Date, monthIndex: number): boolean => {
  if (year === today.getFullYear() && monthIndex > today.getMonth()) {
    return true;
  } else {
    return false;
  }
};

/**
 * Method to determine if there was any budget entered for any month given a year
 * @param financialData spending data
 * @param selectedTeam team to check the missing budget
 * @param year year to check for
 * @returns boolean of whether there is missing budget or not
 */
const checkWhetherBudgetIsFilled = (
  financialData: Spending[],
  selectedTeam: ProjectsResponse,
  year: number,
): boolean => {
  const filteredData = financialData.filter((entry) => entry.project_id === selectedTeam.id && entry.year === year);

  // When we are entering budget for first time
  if (filteredData.length === 0) {
    return false;
  }

  const monthsWithData = filteredData.map((entry) => entry.month);
  const allMonths = Array.from({ length: 12 }, (_, i) => i + 1); // Array of all months [1, 2, ..., 12]

  const missingMonths = allMonths.filter((month) => !monthsWithData.includes(month));

  if (missingMonths.length > 0) {
    return true;
  } else {
    return false;
  }
};

export { checkDisabled, checkWhetherBudgetIsFilled, getDatumBudget, getScopeNameSuffix };
