enum AuthFormType {
  Basic = 'basic',
  Token = 'token',
}

type BaseUrlForm = {
  baseUrl: string;
};

type BasicAuthForm = {
  email: string;
  password: string;
};

type TokenAuthForm = {
  apiToken: string;
};

export { AuthFormType };
export type { BaseUrlForm, BasicAuthForm, TokenAuthForm };
