import { Integration } from '../../api/integrations-client/integrations-client.type';
import { useIntegrationsStore } from './integrations-store';

/**
 * Sets the integrations in the integrations store.
 *
 * @param {Integration[]} integrations - The integrations to set.
 * @return {void}
 */
const setIntegrations = (integrations: Integration[]): void => {
  useIntegrationsStore.setState({ integrations }, false, '[Integrations Store] setIntegrations');
};

/**
 * Sets the current integration in the integrations store.
 *
 * @param {Integration | null} integration - The integration to set, or null to clear
 * @return {void}
 */
const setIntegration = (integration: Integration | null): void => {
  useIntegrationsStore.setState({ integration }, false, '[Integrations Store] setIntegration');
};

export { setIntegration, setIntegrations };
