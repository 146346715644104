import { styled } from '@linaria/react';
import { Pagination, SegmentedControl, Table, Text, TextInput, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { RiskAnalysisTask } from './risk-analysis.client.type';
import { getRiskCategory } from './risk-analysis.helpers';
import { TruncatedText } from './truncated-text';

const ITEMS_PER_PAGE = 20;
const RISK_CATEGORIES = [
  { value: 'all', label: 'All Risks' },
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' },
];

export function RiskAnalysisTaskTable({ tasks }: { tasks: RiskAnalysisTask[] }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [riskFilter, setRiskFilter] = useState<string>('all');

  const filteredTasks = tasks.filter((task) => {
    const searchMatch = task.code.toLowerCase().includes(searchQuery.toLowerCase());

    if (riskFilter === 'all') {
      return searchMatch;
    }

    const riskCategory = getRiskCategory(task.flora_annotations?.risk_score);
    const riskMatch = riskCategory.label.toLowerCase() === riskFilter;

    return searchMatch && riskMatch;
  });

  const totalPages = Math.ceil(filteredTasks.length / ITEMS_PER_PAGE);
  const paginatedTasks = filteredTasks.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <TableContainer>
      <HeaderContainer>
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>Tasks</div>
        <FiltersGroup>
          <TextInput
            placeholder="Search by task key"
            value={searchQuery}
            onChange={(event) => {
              setSearchQuery(event.currentTarget.value);
              setCurrentPage(1);
            }}
            style={{ width: 200 }}
          />
          <SegmentedControl
            value={riskFilter}
            onChange={(value) => {
              setRiskFilter(value);
              setCurrentPage(1);
            }}
            data={RISK_CATEGORIES}
            size="sm"
          />
        </FiltersGroup>
      </HeaderContainer>

      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Key</Table.Th>
            <Table.Th>Task Name</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Points</Table.Th>
            <Table.Th>Risk Category</Table.Th>
            <Table.Th>Risk Score</Table.Th>
            <Table.Th>Risk Summary</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {paginatedTasks.map((task) => {
            const riskCategory = getRiskCategory(task.flora_annotations?.risk_score);
            return (
              <Table.Tr key={task.id}>
                <Table.Td style={{ maxWidth: 50 }}>
                  <a
                    href={task.url}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(task.url, '_blank');
                    }}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {task.code}
                  </a>
                </Table.Td>
                <Table.Td style={{ maxWidth: 200 }}>{task.name}</Table.Td>
                <Table.Td style={{ maxWidth: 300 }}>
                  <TruncatedText text={task.description || 'No description'} onToggle={() => {}} />
                </Table.Td>
                <Table.Td>{task.points || '-'}</Table.Td>
                <Table.Td>
                  <RiskBadge color={riskCategory.color}>{riskCategory.label}</RiskBadge>
                </Table.Td>
                <Table.Td>{task.flora_annotations?.risk_score?.toFixed(0) || 'N/A'}</Table.Td>

                <Table.Td style={{ maxWidth: 300 }}>
                  <Tooltip
                    multiline
                    w={300}
                    position="left"
                    label={
                      task.flora_annotations?.action_plan ? (
                        <div>
                          <Text fw={600} mb={4}>
                            Action Plan:
                          </Text>
                          <Text size="sm">{task.flora_annotations.action_plan}</Text>
                        </div>
                      ) : (
                        'No action plan available'
                      )
                    }
                  >
                    <div style={{ cursor: 'help' }}>
                      <TruncatedText
                        text={task.flora_annotations?.executive_summary || 'No summary'}
                        onToggle={() => {}}
                      />
                    </div>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <PaginationContainer>
        <Text size="sm" c="dimmed">
          Showing {Math.min(filteredTasks.length, currentPage * ITEMS_PER_PAGE)} of {filteredTasks.length} tasks
        </Text>
        <Pagination value={currentPage} onChange={setCurrentPage} total={totalPages} size="sm" />
      </PaginationContainer>
    </TableContainer>
  );
}

export const TableContainer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;

  .mantine-Table-root {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .mantine-Table-th,
  .mantine-Table-td {
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    padding: 12px 16px;
    vertical-align: top;
    max-width: 0; /* Forces content to wrap */
  }

  /* Fixed column widths */

  .mantine-Table-th:nth-of-type(1),
  .mantine-Table-td:nth-of-type(1) {
    width: 7.5%; /* Task Code */
  }
  .mantine-Table-th:nth-of-type(2),
  .mantine-Table-td:nth-of-type(2) {
    width: 17.5%; /* Task Name */
  }

  .mantine-Table-th:nth-of-type(3),
  .mantine-Table-td:nth-of-type(3) {
    width: 25%; /* Description */
  }

  .mantine-Table-th:nth-of-type(4),
  .mantine-Table-td:nth-of-type(4) {
    width: 7.5%; /* Points */
    text-align: center;
  }

  .mantine-Table-th:nth-of-type(5),
  .mantine-Table-td:nth-of-type(5) {
    width: 10%; /* Risk Category */
    text-align: center;
  }

  .mantine-Table-th:nth-of-type(6),
  .mantine-Table-td:nth-of-type(6) {
    width: 7.5%; /* Risk Score */
    text-align: center;
  }

  .mantine-Table-th:nth-of-type(7),
  .mantine-Table-td:nth-of-type(7) {
    width: 25%; /* Risk Summary */
  }

  /* Style for links in table cells */
  a {
    word-break: break-all;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 0 16px;
`;

export const RiskBadge = styled.span<{ color: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${(props) => `${props.color}15`};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => `${props.color}30`};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const FiltersGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .mantine-SegmentedControl-root {
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
  }

  .mantine-SegmentedControl-control {
    padding: 2px;
  }

  .mantine-SegmentedControl-label {
    padding: 4px 12px;
    font-size: 14px;
  }

  .mantine-SegmentedControl-indicator {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  }
`;
