import { styled } from '@linaria/react';
import { Divider, LoadingOverlay, Select, Title } from '@mantine/core';
import { useContext } from 'react';
import { Measure, MeasureDataByEntity } from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterUnwantedMeasures,
  useAvailableMeasureOptions,
  useDateRange,
  useEntities,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { calculateAverage, calculateTrend, forecastNextValue } from '../measure-comparison/measure-comparison.helpers';
import { MeasureRow } from '../measure-comparison/measure-row';
import { Pills } from '../pills/pills';
import { ProcessAnalysisContext } from '../process-analysis.context';
import {
  ITEM_COLORS,
  getPills,
  getTitle,
  handleChangeEntity,
  handleChangeTrend,
  matchEntitiesWithColor,
} from '../process-analysis.helpers';
import { Section } from '../process-analysis.type';
import { KeyMeasuresChart } from './key-measures-chart';

type KeyMeasureAveragesProps = {
  tableData: MeasureDataByEntity;
  dataIsLoading: boolean;
};

export function KeyMeasuresOverTime({ tableData, dataIsLoading }: KeyMeasureAveragesProps) {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const availableMeasureOptions = useAvailableMeasureOptions();
  const defaultMeasure = availableMeasureOptions[0].value;
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures).filter(filterUnwantedMeasures);

  const {
    [Section.KeyMeasures]: {
      measure,
      selectedEntities,
      selectedTrends,
      setMeasure,
      setSelectedEntities,
      setSelectedTrends,
    },
  } = useContext(ProcessAnalysisContext);

  const pills = getPills(entities, activeTab, portfolios, teams, startDate, endDate);

  /**
   * Handles the change of the selected measure
   *
   * @param value - the value of the data point
   * @returns void
   */
  function handleChangeSelectedMeasure(value: string) {
    setMeasure(value as Measure);
  }

  return (
    <DropShadowContainer>
      <LoadingOverlay visible={dataIsLoading} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '48px' }}>
        <Pills pillText={pills} />
        <Title>Key Measures Over Time</Title>
        <ContentContainer>
          <LegendContainer>
            <Select
              value={measure}
              onChange={(value) => handleChangeSelectedMeasure(value || defaultMeasure)}
              data={availableMeasureOptions}
              comboboxProps={{ position: 'bottom' }}
            />
            <Divider size="sm" style={{ marginTop: '16px', marginBottom: '16px' }} />
            <LegendScrollContainer>
              <LegendTable>
                <thead>
                  <tr>
                    <StickyTH>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</StickyTH>
                    <StickyTH>Line</StickyTH>
                    <StickyTH>Trend</StickyTH>
                  </tr>
                  <tr style={{ height: '16px' }}></tr>
                </thead>
                <tbody>
                  {entities.map((id, index) => {
                    const title = getTitle(portfolios, teams, activeTab, id) || id;
                    const color = ITEM_COLORS[index % ITEM_COLORS.length];
                    const measureData = tableData[id]?.[measure];
                    const measureMetadata = availableMeasures.find((m) => m.measure_name === measure);
                    const trendLine = measureData
                      ? calculateTrend(measureData, measureMetadata?.is_zero_valid ?? true)
                      : null;
                    const forecast = forecastNextValue(trendLine, measureData);
                    return (
                      <MeasureRow
                        key={index}
                        measure={id}
                        title={title}
                        color={color}
                        isZeroValid={measureMetadata?.is_zero_valid ?? true}
                        average={calculateAverage(measureData, measureMetadata?.is_zero_valid ?? true)}
                        trend={trendLine?.slope ?? null}
                        forecast={forecast}
                        selectedMeasures={selectedEntities}
                        selectedTrends={selectedTrends}
                        setSelectedMeasures={(event) => {
                          const { checked } = event.currentTarget;
                          handleChangeEntity(checked, id, selectedEntities, setSelectedEntities);
                        }}
                        setSelectedTrends={(event) => {
                          const { checked } = event.currentTarget;
                          handleChangeTrend(checked, id, selectedTrends, setSelectedTrends);
                        }}
                      />
                    );
                  })}
                </tbody>
              </LegendTable>
            </LegendScrollContainer>
          </LegendContainer>
          <ChartContainer>
            <KeyMeasuresChart
              tableData={tableData}
              measure={measure}
              entitiesWithColor={matchEntitiesWithColor(selectedEntities, entities)}
              entityTrendsWithColor={matchEntitiesWithColor(selectedTrends, entities)}
            />
          </ChartContainer>
        </ContentContainer>
      </div>
    </DropShadowContainer>
  );
}

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  min-height: 500px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const TD = styled.td`
  font-size: 14px;
  border-top: 1px solid ${newCOLORS.lightGray};
  border-bottom: 1px solid ${newCOLORS.lightGray};
  padding: 8px;
`;

const TH = styled.th`
  color: ${newCOLORS.gray};
  font-size: 14px;
`;

const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  &:nth-child(1) {
    width: 160px;
  }
  &:nth-child(2) {
    width: 40px;
  }
  &:nth-child(3) {
    width: 40px;
  }
`;

const RowStart = styled(TD)<{ color: string }>`
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-left: 4px solid ${(props) => props.color};
`;

const RowEnd = styled(TD)`
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-right: 1px solid ${newCOLORS.lightGray};
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
`;

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 300px;
  table-layout: fixed;
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
`;

export { RowEnd, RowStart, TD, TH };
