import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMeasureDetail } from '../../api/work-periods-client/work-periods-client.hooks';
import type { MeasureDetail } from '../../api/work-periods-client/work-periods-client.type';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { formatTasks } from '../../components/financials-tasks/financials-tasks.helpers';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MeasureInfoBox } from '../../components/measure-info-box';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { Title } from '../../components/title/title';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import {
  filterCustomMeasures,
  filterUnwantedMeasures,
  useDateRange,
  useTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.hooks';
import { SubtitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { TasksTable } from '../tasks-table/tasks-table';
import { TaskRecord } from '../tasks-table/tasks-table.type';
import { formatTitle, mapSubprojectIdsToNames } from './process-analysis-tasks.helpers';
import { useTasksData, useTimeAllocationsData } from './process-analysis.hooks';
import { Tab, WorkPeriodType } from './process-analysis.type';
import { useWorkPeriodForm } from './scope-explorer/scope-explorer.hooks';

export const ProcessAnalysisTasks = () => {
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');
  const date = searchParams.get('date') || null;
  const factor = searchParams.get('factor') || null;

  const customMeasures = useProcessAnalysisStore((state) => state.availableMeasures)
    .filter(filterCustomMeasures)
    .filter(filterUnwantedMeasures)
    .map((measure) => measure.measure_name);

  const teams = useGlobalStore((state) => state.teams);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  const { workPeriodType, workPeriod } = useProcessAnalysisStore((state) => ({
    workPeriodType: state.workPeriodType,
    workPeriod: state.workPeriod,
  }));
  const { startDate, endDate } = useDateRange();

  const idNameMap = mapSubprojectIdsToNames(teams);
  const { workPeriodForm } = useWorkPeriodForm();
  const currentBoardId = workPeriodForm.getValues().workPeriodBoardId;
  const currentBoardName = idNameMap[currentBoardId as string];

  useTimeAllocationsData();
  const timeAllocations = useTimeAllocations();
  const foundAllocation = timeAllocations.find((allocation) => allocation.id === date);
  const formattedStartDate = dayjs(startDate).format('MM/DD/YYYY');
  const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');

  const taskFilter = date && factor ? `${date},${factor}` : date || factor;
  const isCustom = customMeasures.includes(measure as Measure);
  const { data: tasksQueryData = [], isFetching } = useTasksData(measure as Measure, taskFilter, isCustom);

  const showLocalError = !isFetching && !tasksQueryData.length;
  const titleMeasure = measure === Measure.FlowByPhase ? factor || '' : measure || '';

  const header = <Title title={formatTitle(titleMeasure, workPeriodType, workPeriod, currentBoardName, activeTab)} />;
  const measureName = searchParams.get('measure');
  const [_, setLoadingMeasure] = useState(false);
  const [measureError, setMeasureError] = useState<string | null>(null);
  const [measureDetails, setMeasureDetails] = useState<MeasureDetail | null>(null);

  const { data } = useMeasureDetail({
    measureName: measureName as string,
    options: {
      enabled: !!measureName && !window.location.pathname.includes('/process/task-details'),
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (data) {
      setLoadingMeasure(true);
      try {
        setMeasureDetails(data);
      } catch (error) {
        setMeasureError('Failed to load measure details');
      } finally {
        setLoadingMeasure(false);
      }
    }
  }, [data]);

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      {!window.location.pathname.includes('/process/task-details') && (
        <>
          {measureError && <PageHeaderMessage message={measureError} color="red" />}
          {measureDetails && <MeasureInfoBox measure={measureDetails} />}
        </>
      )}
      <TasksContainer>
        <SubtitleHeadingTag>
          {date == null ? (
            <>
              {workPeriodType === WorkPeriodType.Defined
                ? `${dayjs(workPeriod?.start_date).format('MM/DD/YYYY')} - ${dayjs(workPeriod?.end_date).format(
                    'MM/DD/YYYY',
                  )}`
                : `${formattedStartDate} - ${formattedEndDate}`}
            </>
          ) : activeTab === Tab.WorkPeriods ? (
            `${dayjs(date).format('MM/DD/YYYY')}`
          ) : (
            `${dayjs(foundAllocation?.start).format('MM/DD/YYYY')} - ${dayjs(foundAllocation?.end)
              .subtract(1, 'day')
              .format('MM/DD/YYYY')}`
          )}
        </SubtitleHeadingTag>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  padding-left: 16px;
`;
