import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSprint } from '../../api/sprints-client/sprints-client';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { fetchTaskAuditLog } from '../../api/tasks-client/tasks-client';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const TaskAuditLog = () => {
  const { taskId = '' } = useParams<{ taskId: string }>();
  const { sprintId = '' } = useParams<{ sprintId: string }>();
  const [sprint, setSprint] = useState<Sprint | null>(null);

  const auditLogQuery = useQuery({
    queryKey: ['taskId', taskId] as const,
    queryFn: async () => {
      if (!taskId) {
        throw new Error('Task ID is required');
      }
      return fetchTaskAuditLog(taskId);
    },
  });

  const sprintQuery = useQuery({
    queryKey: ['sprint', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Unable to resolve SprintId or SprintID is missing');
      }
      return fetchSprint(sprintId);
    },
    enabled: !!sprintId,
  });

  useEffect(() => {
    if (sprintQuery.data) {
      setSprint(sprintQuery.data);
    }
  }, [sprintQuery.data]);

  useEffect(() => {
    if (sprintQuery.error) {
      console.error('There was an issue fetching sprint data:', sprintQuery.error);
    }
    if (auditLogQuery.error) {
      console.error('Error while fetching task audit log:', auditLogQuery.error);
    }
  }, [sprintQuery.error, auditLogQuery.error]);

  return (
    <MenuDrivenContainer header="Task Audit Log">
      <BrandedLoadingOverlay visible={auditLogQuery.isPending} transitionDuration={300} variant="colored" />
      {auditLogQuery.data && <AuditLog sprint={sprint} entries={auditLogQuery.data} isTaskLog={true} />}
    </MenuDrivenContainer>
  );
};
