import { createSearchParams } from 'react-router-dom';
import { Edge, Node as ReactFlowNode } from 'reactflow';
import { GraphData, GraphNode, ProcessMapping, Stages } from '../../api/process-client/process-client.type';
import { isString } from '../../helpers/string-helpers/string-helpers';
import { TaskType } from '../tasks-table/tasks-table.type';
import { ProcessWorkflow } from './context/process.context.type';
import { getStageEdges, getWorkflowEdges } from './helpers/edges.helpers';
import { getStageNodes, getWorkflowNodes } from './helpers/nodes.helpers';

/**
 * Calculates graph nodes and edges based on the provided data and mapping.
 *
 * @param {GraphData | undefined} data - The graph data.
 * @param {ProcessMapping | null} mapping - The stages mapping for the graph.
 * @param {Stages[]} activeStages - Array of active stages.
 * @returns {{ nodes: ReactFlowNode[]; edges: Edge[] }} - The object containing generated nodes and edges.
 */
const calculateGraph = (
  data: GraphData | undefined,
  mapping: ProcessMapping | null,
  activeStages: Stages[],
  isPercentage: boolean,
): { nodes: ReactFlowNode[]; edges: Edge[] } => {
  if (!data || !mapping) {
    return {
      nodes: [],
      edges: [],
    };
  }
  const stages = getStages(mapping);

  const stageNodes = getStageNodes(stages, mapping);
  const stageEdges = getStageEdges(stages, data, mapping, isPercentage);

  const workflowNodes = getWorkflowNodes(data, activeStages, mapping, isPercentage);
  const workflowEdges = getWorkflowEdges(workflowNodes, data);

  return {
    nodes: [...stageNodes, ...workflowNodes],
    edges: [...stageEdges, ...workflowEdges],
  };
};

/**
 * Filters the process mapping by removing any BF stages that do not have customer stages.
 *
 * @param {ProcessMapping} mapping - The original process mapping.
 * @returns {ProcessMapping | null} - The filtered process mapping, otherwise null.
 */
const filterProcessMapping = (mapping?: ProcessMapping): ProcessMapping | null => {
  if (!mapping) {
    return null;
  }

  const filteredMapping = Object.keys(mapping.bf_to_customer).reduce((acc, stage) => {
    const customerStages = mapping.bf_to_customer[stage];

    if (customerStages.length > 0) {
      return {
        ...acc,
        [stage]: customerStages,
      };
    }

    return acc;
  }, {});

  return {
    ...mapping,
    bf_to_customer: filteredMapping,
  };
};

/**
 * Returns the title of a given stage.
 *
 * @param {Stages} stage - The stage for which to retrieve the title.
 * @returns {string} The title of the stage.
 */
const getStageTitle = (stage: Stages): string => {
  const titles = {
    [Stages.Backlog]: 'Backlog',
    [Stages.Blocked]: 'Blocked',
    [Stages.InProgress]: 'In Progress',
    [Stages.InReview]: 'In Review',
    [Stages.InTest]: 'In Test',
    [Stages.Deployable]: 'Deployable',
    [Stages.Done]: 'Done',
  };

  return titles[stage];
};

/**
 * Returns an array of the bloomfilter stages.
 *
 * @param {ProcessMapping} mapping - The process mapping.
 * @returns {Array} An array of the bloomfilter stages.
 */
const getStages = (mapping: ProcessMapping): Stages[] => {
  return Object.values(Stages).filter((stage) => stage in mapping.bf_to_customer);
};

/**
 * Returns the index of a stage in the stages array.
 *
 * @param {Stages} stage - The stage to find the index of.
 * @param {ProcessMapping} mapping - The process mapping.
 * @returns {number} The index of the stage in the stages array.
 */
const getStageIndex = (stage: Stages, mapping: ProcessMapping): number => getStages(mapping).indexOf(stage);

/**
 * Finds a graph node by a specific order.
 *
 * @param {GraphNode[]} nodes - An array of graph nodes to search through.
 * @param {number} order - The order value to match.
 * @returns {GraphNode | undefined} - The graph node with the specified order, or undefined if not found.
 */
const findByOrder = (nodes: GraphNode[], order: number): GraphNode | undefined =>
  nodes.find((node) => node.order === order);

/**
 * Finds a graph node by a specific name.
 *
 * @param {GraphNode[]} nodes - The array of GraphNodes to search through.
 * @param {string} name - The name of the node to find.
 * @returns {GraphNode | undefined} - The graph node with the specified name, or undefined if not found.
 */
const findByName = (nodes: GraphNode[], name: string): GraphNode | undefined =>
  nodes.find((node) => node.name === name);

/**
 * Determines if the given array of stages represents a complex view.
 *
 * @param {Stages[]} stages - The array of stages to check.
 * @param {ProcessMapping} mapping - The process mapping.
 * @returns {boolean} Returns true if the array of stages represents a complex view, otherwise false.
 */
const isComplexView = (stages: Stages[], mapping: ProcessMapping): boolean => {
  if (!stages.length) {
    return false;
  }

  const referenceStages = getStages(mapping);

  return referenceStages.length === stages.length && referenceStages.every((stage) => stages.includes(stage));
};

/**
 * Retrieves the search parameters for the given team and workflow within the specified date range.
 *
 * @param {ProcessWorkflow} workflow - The workflow details
 * @param {string | null} teamId - The ID of the team
 * @param {string | null} startDate - The start date of the search
 * @param {string | null} endDate - The end date of the search
 * @return {URLSearchParams | null} The URLSearchParams object if the workflow is valid, otherwise null
 */
const getSearchParams = (
  workflow: ProcessWorkflow,
  teamId: string | null,
  boardId: string | null,
  taskType: TaskType | null,
  epicId: string | null,
  startDate: string | null,
  endDate: string | null,
): URLSearchParams | null => {
  const { source, destination, status, mapping } = workflow;
  const isValidWorkflow =
    isString(source) && isString(destination) && isString(status) && Object.keys(mapping).length > 0;
  const isValidInput = isString(teamId) && isString(startDate) && isString(endDate);

  if (!isValidWorkflow || !isValidInput) {
    return null;
  }

  let params: Record<string, string> = {
    teamId,
    workflow: encodeURIComponent(JSON.stringify(workflow)),
    startDate,
    endDate,
  };

  if (taskType && isString(taskType)) {
    params = { ...params, taskType };
  }

  if (boardId && isString(boardId)) {
    params = { ...params, boardId };
  }

  if (epicId && isString(epicId)) {
    params = { ...params, epicId };
  }

  return createSearchParams(params);
};

export {
  calculateGraph,
  filterProcessMapping,
  findByName,
  findByOrder,
  getSearchParams,
  getStageIndex,
  getStages,
  getStageTitle,
  isComplexView,
};
