import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
  getIntegrationEntries,
  useIntegrationEntries,
} from '../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { GlobalContext, UserContext } from '../../../contexts';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { sendGlobalMessage } from '../../global-message/global-message.helpers';
import { ErrorSource, GlobalMessageCategory, GlobalMessageState } from '../../global-message/global-message.type';
import { availableIntegrations } from '../integrations-list/integrations-list.data';
import { Integration } from '../integrations-list/integrations-list.type';
import { useRedirect } from '../integrations.hooks';
import { getIntegrationState, getProjectsState } from './integrations.context.helpers';
import { IntegrationState, IntegrationsContextType } from './integrations.context.type';

const IntegrationsContext = createContext({} as IntegrationsContextType);

const IntegrationsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(UserContext);
  const { globalMessage, setGlobalMessage } = useContext(GlobalContext);
  const [activeIntegration, setActiveIntegration] = useState<Integration>(availableIntegrations[0]);
  const [initJDC, setInitJDC] = useState<boolean>(false);

  const organizationId = useOrganizationId();
  const { redirect } = useRedirect();

  const { data: integrationEntries, query } = useIntegrationEntries<IntegrationEntry[]>({
    queryKey: ['integration-entries', organizationId] as const,
    queryFn: async () => {
      if (!organizationId) {
        throw new Error('Missing organization ID');
      }
      return getIntegrationEntries(organizationId);
    },
    select: (data: IntegrationEntry[]) => data.filter((integration) => integration.projects.length > 0),
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(user) && !!organizationId && !redirect,
  });

  const state = query.isFetching ? null : getIntegrationState(integrationEntries);
  const projectsState = getProjectsState(integrationEntries);

  useEffect(() => {
    if (state === IntegrationState.ERROR) {
      return sendGlobalMessage({
        type: GlobalMessageCategory.APP_ERROR,
        source: ErrorSource.INTEGRATIONS,
        state: GlobalMessageState.ERROR,
      });
    }

    if (globalMessage && 'source' in globalMessage && globalMessage.source === ErrorSource.INTEGRATIONS) {
      setGlobalMessage(null);
    }
  }, [setGlobalMessage, state, globalMessage]);

  return (
    <IntegrationsContext.Provider
      value={{ state, projectsState, query, activeIntegration, setActiveIntegration, initJDC, setInitJDC }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export { IntegrationsContext, IntegrationsProvider };
