import { styled } from '@linaria/react';
import { useState } from 'react';
import { getIntegrationEntries } from '../../../../api/integrations-client/integrations-client';
import { useIntegrationEntries } from '../../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry, IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { usePortfolios } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { useProjects } from '../../../../api/projects-client/projects-client.hooks';
import { BrandedLoadingOverlay } from '../../../../components/loader/branded-loader';
import { useOrganizationId } from '../../../../helpers/auth-helpers/auth.hooks';
import { ProjectInfoPanel } from '../project-info-panel';
import { SubprojectInfoPanel } from '../subproject-info-panel';
import { DataSourcesList } from './data-sources-list';
import { DataSourcesPanel } from './data-sources-panel';
import { IntegrationsPanel } from './integrations-panel';

export function DataSources() {
  const organizationId = useOrganizationId();
  const { portfolios } = usePortfolios({
    queryKey: ['portfolios'] as const,
  });
  const allPortfolios = portfolios.filter((portfolio) => portfolio.organization.id === organizationId);
  const { projects } = useProjects({
    queryKey: ['projects', organizationId] as const,
    organizationId,
  });

  const { data: integrationEntries = [], query } = useIntegrationEntries<IntegrationEntry[]>({
    queryKey: ['integration-entries', organizationId] as const,
    queryFn: async () => {
      if (!organizationId) {
        throw new Error('Organization ID is required');
      }
      return getIntegrationEntries(organizationId);
    },
    select: (data) => data.filter((integration) => integration.projects.length > 0),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const [selectedDataSource, setSelectedDataSource] = useState<IntegrationType | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedSubprojectId, setSelectedSubprojectId] = useState<string | null>(null);
  const [displayIntegrations, setDisplayIntegrations] = useState(false);

  const handleDisplayIntegrations = () => {
    setSelectedDataSource(null);
    setSelectedProjectId(null);
    setSelectedSubprojectId(null);
    setDisplayIntegrations(true);
  };

  const selectedProject = projects?.find((project) => project.id === selectedProjectId);
  const selectedSubproject = integrationEntries
    .flatMap((entry) => entry.subprojects)
    .find((subproject) => subproject.id === selectedSubprojectId);
  const selectedSubprojectIntegration = selectedSubproject
    ? integrationEntries.find((entry) => entry.subprojects.some((sub) => sub.id === selectedSubproject.id))
    : null;

  return (
    <Container>
      <BrandedLoadingOverlay visible={query.isFetching} transitionDuration={30} variant="colored" />
      <DataSourcesList
        availableDataSources={integrationEntries.map((entry) => entry.integration_type)}
        selectedDataSource={selectedDataSource}
        setSelectedDataSource={setSelectedDataSource}
        setDisplayIntegrations={setDisplayIntegrations}
        handleDisplayIntegrations={handleDisplayIntegrations}
      />
      {displayIntegrations ? (
        <IntegrationsPanel setDisplayIntegrations={setDisplayIntegrations} />
      ) : (
        <DataSourcesPanel
          selectedDataSource={selectedDataSource}
          integrationEntries={integrationEntries}
          selectedProjectId={selectedProjectId}
          selectedSubprojectId={selectedSubprojectId}
          setSelectedProjectId={setSelectedProjectId}
          setSelectedSubprojectId={setSelectedSubprojectId}
        />
      )}
      {selectedSubprojectId && selectedSubproject && (
        <SubprojectInfoPanel
          subproject={selectedSubproject}
          setSelectedSubprojectId={setSelectedSubprojectId}
          integrationSource={selectedSubprojectIntegration?.integration_type ?? null}
        />
      )}
      {selectedProjectId && selectedProject && !selectedSubproject && (
        <ProjectInfoPanel
          project={selectedProject}
          portfolios={allPortfolios}
          currentPortfolio={null}
          setSelectedProject={setSelectedProjectId}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;
