import { styled } from '@linaria/react';
import { useState } from 'react';
import { usePortfolios } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { useProjects } from '../../../../api/projects-client/projects-client.hooks';
import { ProjectsResponse } from '../../../../api/projects-client/projects-client.type';
import { useOrganizationId } from '../../../../helpers/auth-helpers/auth.hooks';
import { ProjectInfoPanel } from '../project-info-panel';
import { EditPortfolioPanel } from './edit-portfolio-panel';
import { PortfolioPanel } from './portfolio-panel';
import { PortfoliosList } from './portfolios-list';

export function Portfolios() {
  const organizationId = useOrganizationId();
  const { portfolios } = usePortfolios({
    queryKey: ['portfolios'] as const,
  });
  const allPortfolios = portfolios.filter((portfolio) => portfolio.organization.id === organizationId);

  const { projects: allProjects } = useProjects({
    queryKey: ['projects', organizationId] as const,
    organizationId,
  });

  const [selectedPortfolioId, setSelectedPortfolioId] = useState<string | null>(allPortfolios[0]?.id ?? null);
  const [selectedProject, setSelectedProject] = useState<ProjectsResponse | null>(null);
  const [isEditingPortfolio, setIsEditingPortfolio] = useState(false);

  const portfolio = allPortfolios.find((p) => p.id === selectedPortfolioId);
  const portfolioProjects = portfolio?.projects.map((project) => allProjects?.find((p) => p.id === project.id)) ?? [];

  const handleProjectSelect = (project_id: string) => {
    if (project_id === selectedProject?.id) {
      setSelectedProject(null);
    } else {
      const project = allProjects?.find((p) => p.id === project_id);
      setSelectedProject(project ?? null);
    }
    setIsEditingPortfolio(false);
  };

  const handleIsEditingPortfolio = (isEditing: boolean) => {
    setIsEditingPortfolio(isEditing);
    setSelectedProject(null);
  };

  const handlePortfolioSelect = (portfolio_id: string) => {
    setSelectedPortfolioId(portfolio_id);
    setIsEditingPortfolio(false);
    setSelectedProject(null);
  };

  return (
    <PortfolioContainer>
      <PortfoliosList
        portfolios={allPortfolios}
        selectedPortfolio={portfolio ?? null}
        setSelectedPortfolioId={handlePortfolioSelect}
      />
      <PortfolioPanel
        portfolio={portfolio ?? null}
        portfolioProjects={portfolioProjects.filter((project): project is ProjectsResponse => project !== undefined)}
        selectedProject={selectedProject}
        onProjectSelect={handleProjectSelect}
        isEditingPortfolio={isEditingPortfolio}
        setIsEditingPortfolio={handleIsEditingPortfolio}
      />
      {isEditingPortfolio && portfolio && (
        <EditPortfolioPanel
          allProjects={allProjects ?? []}
          portfolioProjects={portfolioProjects.filter((project): project is ProjectsResponse => project !== undefined)}
          setIsEditingPortfolio={handleIsEditingPortfolio}
          portfolio={portfolio}
        />
      )}
      {selectedProject && (
        <ProjectInfoPanel
          project={selectedProject}
          portfolios={allPortfolios}
          currentPortfolio={portfolio ?? null}
          setSelectedProject={setSelectedProject}
        />
      )}
    </PortfolioContainer>
  );
}

const PortfolioContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;
