import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { fetchIssueDetailsWithId } from '../../api/issues-client/issues-client';
import { IssueDetailsResult } from '../../api/issues-client/issues-client.class';
import { fetchSprintGithubMeasures, fetchSprintMeasures } from '../../api/sprints-client/sprints-client';
import { ChangeRequest, SprintMeasureData, SprintMeasureTasks } from '../../api/sprints-client/sprints-client.type';
import { UserInfo } from '../../api/user-client/user-client.type';
import { ExtendedIssueCard } from '../../components/extended-issue-card/extended-issue-card';
import { UserContext } from '../../contexts/user';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { GithubRelatedChangeRequestGroups } from '../github-measure-details/github-measure-details';
import { RelatedTasksGroup } from '../issue-details-related-tasks-group/issue-details-related-tasks-group';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { PillarIssueInput } from '../sprint-assessment/sprint-assessment.type';
import { IssueHistoryState } from './issue-details.type';

const SPRINTMEASURES = ['strategy', 'complexity', 'quality', 'scope', 'readiness', 'independence'];
const GITHUBMEASURES = ['percentage_of_declined_change_requests', 'average_days_open_for_change_requests'];

dayjs.extend(utc);

export const IssueDetailsView = () => {
  const location = useLocation();
  let historyState: IssueHistoryState;
  let props: PillarIssueInput;

  if (location.state && Object.prototype.hasOwnProperty.call(location.state, 'props')) {
    historyState = location.state.historyState as IssueHistoryState;
    props = location.state.props as PillarIssueInput;
  } else {
    historyState = location.state as IssueHistoryState;
    props = {} as PillarIssueInput;
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { user } = useContext(UserContext);
  const [selectedIssueDetails, setSelectedIssueDetails] = useState<IssueDetailsResult>();
  const [lastUpdated, setLastUpdated] = useState<string>();
  const { issueId = '' } = useParams<{ issueId: string }>();
  const [sprintMeasure, setSprintMeasures] = useState<SprintMeasureData>();
  const [sprintChangeRequests, setSprintChangeRequests] = useState<ChangeRequest[]>();
  const memoizedHealthAtLocalTZ = dayjs.utc(new Date().getTime()).local().toString();

  let allTasks = {} as SprintMeasureData;
  let githubChangeRequests = [] as ChangeRequest[];

  if (lastUpdated === undefined) {
    setLastUpdated(formatDate(memoizedHealthAtLocalTZ, timezone, DateTemplate.MonthDayYear));
  }

  const { data: issueData, isPending } = useQuery({
    queryKey: ['issueDetails', issueId] as const,
    queryFn: async () => {
      if (!issueId) {
        throw new Error('Issue ID is required');
      }
      const data = await fetchIssueDetailsWithId(issueId);
      if (data == null) {
        const temp = new IssueDetailsResult();
        temp.title = props.title;
        temp.description = props.description;
        temp.calculation = props.calculation || '';
        temp.solution = props.solution || '';
        temp.summary = props.summary;
        temp.amount = props.amount ? props.amount.toString() : '0';
        return temp;
      }
      return data;
    },
    enabled: !!issueId,
  });

  useEffect(() => {
    if (issueData) {
      setSelectedIssueDetails(issueData);
    }
  }, [issueData]);

  const currentSprint = historyState?.sprint?.id;

  const { data: sprintMeasureData } = useQuery({
    queryKey: ['sprint_measures', currentSprint] as const,
    queryFn: async () => {
      if (!currentSprint || !SPRINTMEASURES.includes(props.measure)) {
        throw new Error('Unable to resolve SprintId or SprintID is missing');
      }
      const data = await fetchSprintMeasures(currentSprint, props.measure);
      return data;
    },
    enabled: !!currentSprint && SPRINTMEASURES.includes(props.measure),
  });

  useEffect(() => {
    if (sprintMeasureData) {
      setSprintMeasures(sprintMeasureData);
    }
  }, [sprintMeasureData]);

  if (historyState && props.title != undefined && sprintMeasure) {
    allTasks = sprintMeasure;
  }

  const { data: githubMeasureData } = useQuery({
    queryKey: ['github_measures', currentSprint] as const,
    queryFn: async () => {
      if (!currentSprint || !GITHUBMEASURES.includes(props.measure)) {
        throw new Error('Unable to resolve SprintId or SprintID is missing');
      }
      const data = await fetchSprintGithubMeasures(currentSprint, props.measure);
      return data;
    },
    enabled: !!currentSprint && GITHUBMEASURES.includes(props.measure),
  });

  useEffect(() => {
    if (githubMeasureData) {
      setSprintChangeRequests(githubMeasureData);
    }
  }, [githubMeasureData]);

  if (historyState && props.title != undefined && sprintChangeRequests) {
    githubChangeRequests = sprintChangeRequests;
  }

  if (!selectedIssueDetails || !historyState || isPending) {
    return <MenuDrivenContainer />;
  }

  return (
    <MenuDrivenContainer header={selectedIssueDetails.title}>
      <ExtendedIssueCard
        historyState={historyState}
        user={user ? user : ({} as UserInfo)}
        issue={selectedIssueDetails}
        onClick={(_issue) => {}}
      />
      {selectedIssueDetails.tasks.length > 0 ? (
        <RelatedTasksGroup
          project={historyState.project}
          selectedIssueDetails={selectedIssueDetails}
          tasks={selectedIssueDetails.tasks}
          title={'Related Tasks'}
          title_category={'task'}
        />
      ) : allTasks.tasks ? (
        allTasks.tasks.map((group: SprintMeasureTasks) => {
          return (
            <Fragment key={group.name}>
              <RelatedTasksGroup
                project={historyState.project}
                selectedIssueDetails={selectedIssueDetails}
                tasks={group.tasks}
                title={group.name}
                title_category={props.title.toLowerCase() == 'strategy' ? 'epic' : 'task'}
              />
            </Fragment>
          );
        })
      ) : null}
      {githubChangeRequests.length > 0 ? (
        <GithubRelatedChangeRequestGroups
          title={'Related Pull Requests'}
          changeRequests={sprintChangeRequests as ChangeRequest[]}
          project={historyState.project}
          selectedIssueDetails={selectedIssueDetails}
        />
      ) : null}
    </MenuDrivenContainer>
  );
};
