import { AppShell } from '@mantine/core';
import { ReactNode, useContext } from 'react';
import { HeaderBar } from '../header/header';
import { SideBar } from '../side-bar/side-bar';
import { SidebarContext } from '../side-bar/side-bar.context';

type MenuDrivenContainerProps = {
  children: ReactNode;
  header?: string | ReactNode;
};

export const MenuDrivenContainer = ({ children, header }: Partial<MenuDrivenContainerProps>) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const navbarWidth = sidebarOpen ? 280 : 70;

  return (
    <AppShell
      layout="alt"
      header={{ height: 76 }}
      navbar={{
        width: navbarWidth,
        breakpoint: 'never',
        collapsed: { mobile: false, desktop: false },
      }}
      padding={16}
      withBorder={false}
      transitionDuration={0}
      style={{
        overflowX: 'hidden',
        maxWidth: '100vw',
      }}
    >
      <HeaderBar header={header} />
      <SideBar />
      <AppShell.Main
        style={{
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '100%',
          height: 'calc(100vh - 76px)',
          minWidth: 600,
          transition: 'width 300ms ease',
          overflowX: 'hidden',
        }}
      >
        {children}
      </AppShell.Main>
    </AppShell>
  );
};
