/**
 * Builds the total link URL for the tooltip.
 * @param portfolioId - The ID of the portfolio.
 * @param teamId - The ID of the team.
 * @param boardId - The ID of the board.
 * @param yearMonthString - The year and month string.
 * @param factor - The factor.
 * @param measure - The measure.
 * @returns The total link URL.
 */
function buildTotalLinkUrl(baseLinkUrl: string, teamId: string | undefined, boardId: string | undefined) {
  // portfolio selected & no team or board selected
  if (teamId && teamId === 'aggregate') {
    return baseLinkUrl;
  }

  // team selected & no board selected
  if (teamId && teamId !== 'aggregate' && boardId && boardId === 'aggregate') {
    return (baseLinkUrl += `&projectId=${teamId}`);
  }

  // board selected & no 'total' available OR some unknown case
  return '';
}

export { buildTotalLinkUrl };
