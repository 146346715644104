import { Divider, Flex } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useReducer } from 'react';
import { setProjectBoard, setStatusMapping } from '../../../api/integrations-client/integrations-client';
import { useBoardStatuses } from '../../../api/integrations-client/integrations-client.hooks';
import { ExternalService, Methodology } from '../../../api/integrations-client/integrations-client.type';
import { ConfigurationState } from '../../../api/projects-client/projects-client.type';
import { Button } from '../../../components/button/button';
import { UserContext } from '../../../contexts/user';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { getUTCTime } from '../../../helpers/timezone/timezone';
import { useRedirect } from '../integrations.hooks';
import { StepProps } from '../integrations.type';
import { SettingsView } from '../views/settings-view';
import { StatusView } from '../views/status-view';
import { SuccessView } from '../views/success-view';
import { SummaryView } from '../views/summary-view';
import styles from '../views/views.module.css';
import { Boards, Projects } from './index';
import { jiraReducer } from './jira-reducer';
import { JiraState } from './jira.type';

const initialState: JiraState = {
  initialized: false,
  project: null,
  board: null,
  bloomfilterStatuses: null,
  settings: {
    devTeamSize: '',
    methodology: Methodology.Scrum,
    pointBugs: false,
    requireLabels: false,
    projectUrl: '',
    workingHoursStart: '09:00',
    workingHoursEnd: '17:00',
  },
  options: {
    projects: [],
    boards: [],
    allBoards: [],
    statuses: [],
  },
};

/** The container for the Jira integration.
 *  This is the parent component for the Jira integration.
 *
 *  This component is responsible for rendering the correct view based on the activeStep prop,
 *  as well as navigating between views.
 *  This component is also responsible for managing the state of the entire Jira integration workflow.
 *
 * @param props: { activeStep: number, setActiveStep: Dispatch<SetStateAction<number>>}
 * @returns JSX.Element
 */
export function Jira({ activeStep = 0, setActiveStep }: StepProps) {
  const organizationId = useOrganizationId();
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(jiraReducer, initialState);
  const { systemAccessId } = state.options;

  const { data: boardStatusesData, query: getJiraStatusesQuery } = useBoardStatuses({
    queryKey: [
      'board-statuses',
      {
        service: ExternalService.Jira,
        project_id: state.project?.id,
        board_id: state.board?.id,
        system_access_id: systemAccessId,
      },
    ] as const,
    enabled: !!state.project?.id && !!state.board?.id && !!user && !!systemAccessId,
  });

  useEffect(() => {
    if (boardStatusesData) {
      dispatch({
        type: 'settings',
        payload: {
          projectUrl: boardStatusesData.project_url,
        },
      });
      dispatch({
        type: 'options',
        payload: {
          statuses: boardStatusesData.statuses,
        },
      });
    }
  }, [boardStatusesData]);

  const saveProjectBoardsMutation = useMutation({
    mutationKey: ['setProjectBoard', state, user] as const,
    mutationFn: () => {
      const { project, board, settings, options } = state;
      const { mappings } = options;
      const { devTeamSize, requireLabels, pointBugs, workingHoursStart, workingHoursEnd } = settings;

      let methodology = board.type;

      // There is a jira methodology which is called simple yet follows scrum
      if (methodology === 'simple') {
        methodology = Methodology.Scrum;
      }

      if (!project || !board || !settings || !organizationId) {
        throw new Error('Invalid project board data');
      }

      return setProjectBoard(
        project,
        board,
        ExternalService.Jira,
        devTeamSize as number,
        methodology,
        organizationId,
        requireLabels,
        pointBugs,
        getUTCTime(workingHoursStart),
        getUTCTime(workingHoursEnd),
        ConfigurationState.InitiallyConfigured,
        mappings,
      );
    },
    onSuccess: () => {
      saveProjectStatusesMutation.mutate();
    },
  });

  const saveProjectStatusesMutation = useMutation({
    mutationKey: ['setStatusMapping', state, user] as const,
    mutationFn: () => {
      const { project, board, bloomfilterStatuses, settings } = state;
      if (!project || !board || !bloomfilterStatuses || !settings || !organizationId) {
        throw new Error('Invalid project board data');
      }

      return setStatusMapping(
        ExternalService.Jira,
        project,
        board,
        organizationId,
        bloomfilterStatuses.backlogStatuses,
        bloomfilterStatuses.inProgressStatuses,
        bloomfilterStatuses.blockedStatuses,
        bloomfilterStatuses.inReviewStatuses,
        bloomfilterStatuses.inQAStatuses,
        bloomfilterStatuses.readyForDeploymentStatuses,
        bloomfilterStatuses.doneStatuses,
        settings.projectUrl,
        ConfigurationState.InitiallyConfigured,
      );
    },
  });

  const { redirect } = useRedirect();

  const views = [
    <Projects key="projects" state={state} dispatch={dispatch} user={user} />,
    <Boards key="boards" state={state} dispatch={dispatch} />,
    <StatusView key="statuses" integration={ExternalService.Jira} state={state} dispatch={dispatch} />,
    <SettingsView key="settings" state={state} dispatch={dispatch} />,
    <SummaryView key="summary" integration={ExternalService.Jira} state={state} setActiveStep={handleChangeView} />,
    <SuccessView key="success" />,
  ];

  /** Changes the view to the view specified by the view parameter.
   * Scrolls the window to the top of the page when the view changes.
   *
   * @param view: number | ((current: number) => number) - the index of the view to change to.
   * Can be a number or a function that returns a number
   */
  function handleChangeView(view: number | ((current: number) => number)) {
    setTimeout(() => window.scrollTo(0, 0));
    const step = typeof view !== 'number' ? view(activeStep) : view;
    switch (step) {
      case 0:
        trackEvent(AnalyticsEventType.JiraProjectsViewed, { userContext: user });
        break;
      case 1:
        trackEvent(AnalyticsEventType.JiraBoardsViewed, { userContext: user });
        break;
      case 2:
        trackEvent(AnalyticsEventType.JiraStatuesViewed, { userContext: user });
        break;
      case 3:
        trackEvent(AnalyticsEventType.JiraSettingsViewed, { userContext: user });
        break;
      case 4:
        trackEvent(AnalyticsEventType.JiraSummaryViewed, { userContext: user });
        break;
      case 5:
        trackEvent(AnalyticsEventType.JiraIntegrationSuccessful, { userContext: user });
        break;
    }
    setActiveStep(view);
  }

  /** Determines whether the next button should be disabled or not.
   * The next button may need to be disabled based on criteria specific to each step.
   *
   * @param activeStep: number- the current step in the workflow
   * @returns boolean- true if the next button should be disabled, false otherwise
   */
  function isNextButtonDisabled(activeStep: number) {
    switch (activeStep) {
      case 0:
        return !state.project;
      case 1:
        return state.options.statuses?.length ? false : state.board ? getJiraStatusesQuery.isFetching : true;
      case 3:
        return state.settings.devTeamSize === '';
      default:
        return false;
    }
  }

  function handleSubmit() {
    saveProjectBoardsMutation.mutate();
    nextStep();
  }

  const nextStep = () => handleChangeView((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => handleChangeView((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      {views[activeStep]}
      {!redirect && (
        <Fragment>
          <Divider my="sm" />
          <div id={styles.backNextContainer}>
            {activeStep < 5 ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {activeStep !== 0 ? (
                  <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                    Back
                  </Button>
                ) : null}
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    loading={activeStep === 1 && getJiraStatusesQuery.isFetching}
                    disabled={isNextButtonDisabled(activeStep)}
                    onClick={activeStep < 4 ? nextStep : handleSubmit}
                    size="lg"
                  >
                    {activeStep < 4 ? 'Next' : "Let's go!"}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      )}
    </Flex>
  );
}
