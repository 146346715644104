import { styled } from '@linaria/react';
import { Progress } from '@mantine/core';
import { useState } from 'react';
import {
  ExtendedInitiative,
  InitiativeStatus,
  RelativeBusinessValueUnits,
} from '../../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../../assets/icons/icons';
import { newCOLORS } from '../../../../styles/colors';
import { toLocalDate } from '../../initiatives-list/initiatives-list.helpers';
import { CollapsibleSection } from '../collapsible-section';
import { InitiativeMetricType } from '../initiative-performance.type';
import { Legend } from '../legend';
import { SectionStatusChart } from './section-status-chart';
import { getLegendItems } from './section-status.helpers';

type Props = {
  initiative: ExtendedInitiative;
};

const dateFormat = 'MM/DD/YYYY';

export function SectionStatus({
  initiative: {
    status,
    start_date,
    end_date,
    percent_elapsed,
    percent_progress,
    costs_to_date,
    relative_business_value: bv,
    relative_business_value_unit: bv_unit,
    total_subtasks_over_time: total_tasks,
    completed_subtasks_over_time: completed_tasks,
  },
}: Props) {
  const [metric, setMetric] = useState<InitiativeMetricType>(InitiativeMetricType.Tasks);

  return (
    <CollapsibleSection
      titleElement={
        status?.by_tasks?.status && status?.by_points?.status ? (
          <Title status={metric === InitiativeMetricType.Tasks ? status.by_tasks.status : status.by_points.status} />
        ) : (
          <TitleContainer>Status</TitleContainer>
        )
      }
      title="Status"
      metric={metric}
      setMetric={setMetric}
    >
      <DataContainer>
        <DataRow
          data={[
            { label: 'Start Date', value: start_date ? toLocalDate(start_date).format(dateFormat) : '' },
            { label: 'End Date', value: toLocalDate(end_date).format(dateFormat) },
          ]}
        />
        <DataRow
          data={[
            {
              label: 'Cost to Date',
              value: costs_to_date?.initiative ? `$${costs_to_date.initiative.toLocaleString()}` : '-',
            },
            {
              label: 'Business Value',
              value: bv
                ? bv_unit == RelativeBusinessValueUnits.dollars
                  ? `$${bv.toLocaleString()}`
                  : bv.toLocaleString()
                : '-',
            },
          ]}
        />
        <InitiativeProgress
          icon={icons.iconTimeElapsedPink}
          color={newCOLORS.magenta}
          title="Time Elapsed"
          value={percent_elapsed}
          description="initiative time elapsed"
        />
        <InitiativeProgress
          icon={icons.iconCompletion}
          color={newCOLORS.darkGreen}
          title="Completion"
          value={
            status && status.by_tasks && status.by_points
              ? metric === InitiativeMetricType.Tasks
                ? status.by_tasks.percent_subtasks_complete
                : status.by_points.percent_subtasks_complete
              : percent_progress
          }
          description={`completed ${
            status && status.by_tasks && status.by_points
              ? metric === InitiativeMetricType.Tasks
                ? 'tasks'
                : 'points'
              : 'tasks'
          } out of total ${
            status && status.by_tasks && status.by_points
              ? metric === InitiativeMetricType.Tasks
                ? 'tasks'
                : 'points'
              : 'tasks'
          }`}
        />
      </DataContainer>
      <ChartContainer>
        <SectionStatusChart total_tasks={total_tasks} completed_tasks={completed_tasks} metric={metric} />
        <Legend items={getLegendItems(metric)} />
      </ChartContainer>
    </CollapsibleSection>
  );
}

const statusMapping = {
  [InitiativeStatus.OnTrack]: {
    text: 'On Track',
    icon: icons.iconCheckmark,
  },
  [InitiativeStatus.AtRisk]: {
    text: 'At Risk',
    icon: icons.iconYellowAlert,
  },
  [InitiativeStatus.OffTrack]: {
    text: 'Off Track',
    icon: icons.iconRedWarning,
  },
};

const Title = ({ status }: { status: InitiativeStatus }) => {
  return (
    <TitleContainer>
      {statusMapping[status] ? `Status: ${statusMapping[status].text}` : 'Unknown Status or Empty Initiative'}
      {statusMapping[status] && <img src={statusMapping[status].icon} width={20} height={20} />}
    </TitleContainer>
  );
};

const DataRow = ({ data }: { data: { label: string; value: string }[] }) => {
  const [first, second] = data;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
        <DataRowItem item={first} />
        <DataRowItem item={second} />
      </div>
      <hr style={{ background: newCOLORS.lightGray, height: '1px', marginBottom: 0 }} />
    </div>
  );
};

const DataRowItem = ({ item }: { item: { label: string; value: string } }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <DataLabel>{item.label}</DataLabel>
    <DataValue>{item.value}</DataValue>
  </div>
);

type InitiativeProgressProps = {
  icon: string;
  color: string;
  title: string;
  description: string;
  value: number;
};

const InitiativeProgress = ({ icon, color, title, description, value }: InitiativeProgressProps) => (
  <InitiativeProgressContainer>
    <img src={icon} width={24} height={24} />
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '228px',
        height: '45px',
      }}
    >
      <DataValue style={{ color: newCOLORS.black }}>{`${title} - ${value}%`}</DataValue>
      <HelperText>{description}</HelperText>
      <Progress color={color} value={value} style={{ width: '100%', marginTop: '4px' }} />
    </div>
  </InitiativeProgressContainer>
);

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 300px;
  gap: 16px;
`;

const DataLabel = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const DataValue = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

const HelperText = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const InitiativeProgressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 32px;
`;
