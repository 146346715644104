import { styled } from '@linaria/react';
import { Select } from '@mantine/core';
import { useState } from 'react';
import { IssueDetailsTask } from '../../api/issues-client/issues-client.type';
import { MinifiedTask, Task } from '../../api/tasks-client/task-client.type';
import { newCOLORS } from '../../styles/colors';
import { SortType } from '../issue-details/issue-details.type';

export type TaskListHeaderProps = {
  projectName?: string;
  tasks: MinifiedTask[];
  setTasks: (tasks: MinifiedTask[]) => void;
};

export const TaskListHeader = ({ tasks, setTasks, projectName }: TaskListHeaderProps) => {
  const sortTypes = [SortType.Name, SortType.CreatedDate];
  const [sortType, setSortType] = useState<string>(sortTypes[0]);

  const sortedTasks = (type: string): MinifiedTask[] => {
    return sortTasks(type, tasks);
  };

  const sortClickHandler = (type: string) => {
    setSortType(type);
    setTasks(sortedTasks(type));
  };

  const LOOKBACK_PERIOD = [
    { value: SortType.Name, label: SortType.Name },
    { value: SortType.CreatedDate, label: SortType.CreatedDate },
    { value: SortType.Points, label: SortType.Points },
  ];

  return (
    <RightHeadingContainer>
      <RelatedTasksTitle>
        {projectName ? `${projectName} ` : ''}Tasks {`(${tasks.length})`}
      </RelatedTasksTitle>
      <DropDownContainer>
        <SortHeaderTag>Sort</SortHeaderTag>
        <Select data={LOOKBACK_PERIOD} value={sortType} onChange={(type) => sortClickHandler(type as string)} />
      </DropDownContainer>
    </RightHeadingContainer>
  );
};

const RelatedTasksTitle = styled.h3`
  font-size: 19px;
  font-weight: 900;
  color: ${newCOLORS.darkerGray};
  margin-bottom: 0;
`;

const RightHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SortHeaderTag = styled.div`
  font-weight: 600;
  cursor: default;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  color: ${newCOLORS.darkGray};
`;

const sortTasksByName = (
  lhs: Task | IssueDetailsTask | MinifiedTask,
  rhs: Task | IssueDetailsTask | MinifiedTask,
): number => {
  if (lhs.name === rhs.name) {
    return 0;
  }
  return lhs.name < rhs.name ? -1 : 1;
};

const sortTasksByCreatedDate = (
  lhs: Task | IssueDetailsTask | MinifiedTask,
  rhs: Task | IssueDetailsTask | MinifiedTask,
): number => {
  if (lhs.created_date === rhs.created_date) {
    return 0;
  }
  return lhs.created_date < rhs.created_date ? -1 : 1;
};

const sortTasksByPoints = (
  lhs: Task | IssueDetailsTask | MinifiedTask,
  rhs: Task | IssueDetailsTask | MinifiedTask,
): number => {
  if (lhs.points === rhs.points) {
    return 0;
  } else if (lhs.points == 0 && rhs.points == null) {
    return -1;
  }
  return lhs.points > rhs.points ? -1 : 1;
};

export const sortTasks = <T extends Task[] | IssueDetailsTask[] | MinifiedTask[]>(
  type: string,
  unsortedTasks: T,
): T => {
  const tasksCopy = [...unsortedTasks] as T;
  switch (type) {
    case SortType.Name:
      return tasksCopy.sort(sortTasksByName) as T;
    case SortType.CreatedDate:
      return tasksCopy.sort(sortTasksByCreatedDate) as T;
    case SortType.Points:
      return tasksCopy.sort(sortTasksByPoints) as T;
    default:
      return tasksCopy.sort(sortTasksByName) as T;
  }
};
