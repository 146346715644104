import { styled } from '@linaria/react';
import { TextInput } from '@mantine/core';
import { useState } from 'react';
import { Subproject } from '../../../../api/integrations-client/integrations-client.type';
import { primaryBase, secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { useAddSubprojectToBoardSetting, useRemoveSubprojectFromBoardSetting } from './board-settings-client.hooks';
import { BoardSetting } from './board-settings-client.types';
import { getProjectGroups } from './board-settings.helpers';

type EditBoardSettingsPanelProps = {
  selectedBoardSetting: BoardSetting;
  subprojects: Subproject[];
  setIsEditing: (isEditing: boolean) => void;
};

export function EditBoardSettingsPanel({
  selectedBoardSetting,
  subprojects,
  setIsEditing,
}: EditBoardSettingsPanelProps) {
  const [search, setSearch] = useState('');
  const { addSubprojectToBoardSetting, isAdding } = useAddSubprojectToBoardSetting();
  const { removeSubprojectFromBoardSetting, isRemoving } = useRemoveSubprojectFromBoardSetting();

  const handleBoardToggle = async (subproject: Subproject) => {
    if (isAdding || isRemoving) return;
    if (subproject.subproject_settings_id === selectedBoardSetting.id) {
      await removeSubprojectFromBoardSetting(selectedBoardSetting.id, subproject.id);
    } else {
      await addSubprojectToBoardSetting(selectedBoardSetting.id, subproject.id);
    }
  };

  const filteredSubprojects = subprojects.filter((subproject) =>
    subproject.name.toLowerCase().includes(search.toLowerCase().trim()),
  );

  const projectGroups = getProjectGroups(filteredSubprojects);

  return (
    <EditBoardSettingsPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          Add Boards
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsEditing(false);
          }}
        />
      </HeaderContainer>
      <Divider style={{ marginTop: 30, marginBottom: 12 }} />
      <TextInput
        placeholder="Search for Project or Board"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        leftSection={<Icon name="search" size={16} color={skyDark} />}
        style={{ width: 250 }}
        radius="xl"
        size="xs"
      />
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <ProjectListContainer>
        {Object.entries(projectGroups).map(([projectId, { name: projectName, subprojects: projectSubprojects }]) => (
          <div key={projectId}>
            <Text weight="medium">{projectName}</Text>
            {projectSubprojects.map((subproject) => {
              const isSelected = subproject.subproject_settings_id === selectedBoardSetting.id;
              const isSelectedOtherBoard =
                subproject.subproject_settings_id && subproject.subproject_settings_id !== selectedBoardSetting.id;
              return (
                <BoardRowContainer key={subproject.id}>
                  <Text>{subproject.name}</Text>
                  {isSelectedOtherBoard ? (
                    <Button
                      variant="outline"
                      size="xs"
                      radius="xl"
                      disabled={isAdding || isRemoving}
                      rightSection={<Icon name="close" size={16} color={skyDark} />}
                      onClick={() => removeSubprojectFromBoardSetting(selectedBoardSetting.id, subproject.id)}
                    >
                      <Text size="tiny">{subproject.subproject_settings_name}</Text>
                    </Button>
                  ) : (
                    <Icon
                      name={isSelected ? 'check_circle' : 'add_circle_outline'}
                      size={20}
                      color={isSelected ? secondaryBase : primaryBase}
                      style={{
                        cursor: isAdding || isRemoving ? 'not-allowed' : 'pointer',
                        opacity: isAdding || isRemoving ? 0.5 : 1,
                        marginRight: 16,
                      }}
                      onClick={() => !isAdding && !isRemoving && handleBoardToggle(subproject)}
                    />
                  )}
                </BoardRowContainer>
              );
            })}
          </div>
        ))}
      </ProjectListContainer>
    </EditBoardSettingsPanelContainer>
  );
}

const EditBoardSettingsPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ProjectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
`;

const BoardRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
`;
