import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';
import { ExternalService } from '../../../../api/integrations-client/integrations-client.type';
import { inkLight } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { IntegrationIcon } from '../../../integrations/integration-icon';
type IntegrationCardProps = {
  icon: string;
  title: string;
  description: string;
  isUpcoming: boolean;
};

export function IntegrationCard({ icon, title, description, isUpcoming }: IntegrationCardProps) {
  const navigate = useNavigate();
  return (
    <CardContainer
      onClick={!isUpcoming ? () => navigate(`/application/integrations`) : undefined}
      style={{ cursor: !isUpcoming ? 'pointer' : 'default' }}
    >
      <HeaderContainer>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {isUpcoming ? (
            <img src={icon} alt={title} width={32} height={32} />
          ) : Object.values(ExternalService).includes(icon as ExternalService) ? (
            <IntegrationIcon
              service={icon as ExternalService}
              width={32}
              height={32}
              size={icon === ExternalService.Github ? '2x' : undefined}
            />
          ) : (
            <img src={icon} alt={title} width={32} height={32} />
          )}
          <Text weight="bold" size="large">
            {title}
          </Text>
        </div>
        {isUpcoming && (
          <UpcomingChip>
            <Text size="tiny" color={inkLight}>
              Coming Soon
            </Text>
          </UpcomingChip>
        )}
      </HeaderContainer>
      <Text>{description}</Text>
    </CardContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const UpcomingChip = styled.div`
  border: 1px solid ${inkLight};
  padding: 4px 16px;
  border-radius: 16px;
`;

const CardContainer = styled.div`
  max-width: 290px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
`;
