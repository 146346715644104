import { icons } from '../../../assets/icons/icons';
import iconADO from './iconADO.svg';
import iconJiraCloud from './iconJiraCloud.svg';
import iconJiraDataCenter from './iconJiraDataCenter.svg';

const iconGithub = icons.iconGithub;

export const iconsIntegrations = {
  iconADO,
  iconJiraCloud,
  iconJiraDataCenter,
  iconGithub,
};
