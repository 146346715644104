import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Fragment, useMemo, useState } from 'react';
import { getInitiativeFocus } from '../../../../api/initiative-client/initiative-client';
import { ExtendedInitiative, FocusData } from '../../../../api/initiative-client/initiative-client.type';
import { ProjectsResponse as Team } from '../../../../api/projects-client/projects-client.type';
import { icons } from '../../../../assets/icons/icons';
import { getHealthScoreGrade } from '../../../../helpers/project-metrics-helper/project-metrics-helper';
import { newCOLORS } from '../../../../styles/colors';
import { toLocalDate } from '../../initiatives-list/initiatives-list.helpers';
import { CollapsibleSection } from '../collapsible-section';
import { CollapsibleTile } from '../collapsible-tile';
import { getColoredEntities } from '../initiative-performance.helpers';
import { ColoredEntity, InitiativeMetricType } from '../initiative-performance.type';
import { Legend } from '../legend';
import { SectionTeamsBarChart } from './section-teams-bar-chart';
import { SectionTeamsLineChart } from './section-teams-line-chart';
import { getCost, sortTeams } from './section-teams.helpers';

type Props = {
  initiative: ExtendedInitiative;
  portfolioId?: string;
};

const getLegendItems = (entities: ColoredEntity[]) =>
  entities.map(({ name, color }: { name: string; color: string }) => ({ title: name, color }));

const fetchInitiativeFocus = (initiativeId: string, portfolioId?: string): Promise<FocusData> =>
  portfolioId ? getInitiativeFocus(portfolioId, initiativeId) : Promise.reject('Cannot get initiative focus');

export function SectionTeams({
  initiative: {
    id: initiativeId,
    projects: teams = [],
    epics = [],
    initial_contributions,
    costs_to_date,
    contributing_teams_over_time,
  },
  portfolioId,
}: Props) {
  const [metric, setMetric] = useState<InitiativeMetricType>(InitiativeMetricType.Tasks);
  const coloredTeams = useMemo(() => getColoredEntities(teams.map(({ id, name }) => ({ id, name }))), [teams]);

  const focusQuery = useQuery({
    queryKey: ['getInitiativeFocus', initiativeId] as const,
    queryFn: () => fetchInitiativeFocus(initiativeId, portfolioId),
    enabled: Boolean(portfolioId),
  });

  const sortedTeams = sortTeams(teams, epics);

  return (
    <CollapsibleSection
      titleElement={<Title amountTeams={teams.length} />}
      title="Contributing Teams"
      metric={metric}
      setMetric={setMetric}
    >
      <TeamSummaryContainer>
        {(sortedTeams || []).map((team) => {
          const cost = getCost(team.id, costs_to_date);
          const initialContribution = Object.keys(initial_contributions || {}).includes(team.id)
            ? initial_contributions[team.id]
            : undefined;
          return (
            <TeamScore
              key={team.id}
              team={team}
              cost={cost}
              initialContribution={initialContribution}
              isOpened={false}
            />
          );
        })}
      </TeamSummaryContainer>
      <ChartsContainer>
        <ChartContainer>
          <SectionTeamsBarChart
            metric={metric}
            contributingTeamsData={contributing_teams_over_time}
            coloredTeams={coloredTeams}
          />
          <Legend items={getLegendItems(coloredTeams)} />
        </ChartContainer>
        <ChartContainer>
          {focusQuery.isSuccess && focusQuery.data && (
            <Fragment>
              <SectionTeamsLineChart
                metric={metric}
                focusData={focusQuery.data?.focus_by_team_over_time || []}
                coloredTeams={coloredTeams}
              />
              <Legend items={getLegendItems(coloredTeams)} />
            </Fragment>
          )}
        </ChartContainer>
      </ChartsContainer>
    </CollapsibleSection>
  );
}

const Title = ({ amountTeams = 0 }: { amountTeams?: number }) => {
  return (
    <TitleContainer>
      <img src={icons.iconContributingTeams} width={20} height={20} />
      {`Contributing Teams (${amountTeams})`}
    </TitleContainer>
  );
};

const TeamScore = ({
  team,
  cost,
  initialContribution,
  isOpened,
}: {
  team: Team;
  cost?: number;
  initialContribution?: string | null;
  isOpened: boolean;
}) => {
  const score = (
    <Fragment>
      <ScoreLabel>Team performance score (current)</ScoreLabel>
      <ScoreValue style={{ color: team.health >= 87 ? newCOLORS.green : newCOLORS.orange }}>
        {getHealthScoreGrade(team.health)}
      </ScoreValue>
    </Fragment>
  );

  const data = [
    {
      label: 'Cost to date (work for this initiative)',
      value: cost ? `$${cost.toLocaleString()}` : '-',
    },
    {
      label: 'Contributing since (date of first task)',
      value: initialContribution ? toLocalDate(dayjs(initialContribution).toISOString()).format('MM/DD/YYYY') : '-',
    },
  ];

  return (
    <CollapsibleTile
      title={team.name}
      url={`/application/project/${team.id}`}
      score={score}
      data={data}
      isOpened={isOpened}
    />
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TeamSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  min-width: 300px;
`;

const ScoreLabel = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`;

const ScoreValue = styled.div`
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ChartsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 32px;
`;
