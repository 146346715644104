import { IntegrationEntry } from '../../../api/integrations-client/integrations-client.type';
import { IndicatorProps, IndicatorType } from '../../../components/indicator/indicator.type';
import { getIntegrationStatus } from '../integrations-list/integrations-list.helpers';
import { IntegrationStatus } from '../integrations-list/integrations-list.type';
import { IntegrationState } from './integrations.context.type';

/**
 * Returns the integration state based on the statuses of the provided integrations.
 *
 * @param {IntegrationEntry[]} integrations - an array of integration entries
 * @returns {IntegrationState} the state of the integrations
 */
const getIntegrationState = (integrations: IntegrationEntry[] = []): IntegrationState => {
  if (!integrations.length) {
    return IntegrationState.BLANK;
  }

  if (integrations.some((integration) => getIntegrationStatus(integration) === IntegrationStatus.Expired)) {
    return IntegrationState.ERROR;
  }

  if (integrations.some((integration) => getIntegrationStatus(integration) === IntegrationStatus.Stale)) {
    return IntegrationState.STALE;
  }

  return IntegrationState.OPERATIONAL;
};

/**
 * Generates the state of projects based on the provided integrations.
 *
 * @param {IntegrationEntry[]} integrations - The list of integration entries
 * @return {Record<string, IntegrationStatus>} The object with the state of each project
 */
const getProjectsState = (integrations: IntegrationEntry[] = []): Record<string, IntegrationState> => {
  const projectsState = integrations.reduce(
    (result, integration) => {
      integration.projects.forEach((project) => {
        result[project.id] = getIntegrationState([integration]);
      });

      return result;
    },
    {} as Record<string, IntegrationState>,
  );

  return projectsState;
};

/**
 * Retrieves the integration indicator based on the provided integration state.
 *
 * @param {IntegrationState} integrationState - the state of the integration
 * @returns {IndicatorProps} indicator props object
 */
const getIntegrationIndicatorProps = (integrationState: IntegrationState): IndicatorProps => {
  const indicators = {
    [IntegrationState.BLANK]: {
      type: IndicatorType.BLANK,
      tooltip: 'Not Integrated',
    },
    [IntegrationState.OPERATIONAL]: {
      type: IndicatorType.SUCCESS,
      tooltip: 'Operational',
    },
    [IntegrationState.STALE]: { type: IndicatorType.WARNING, tooltip: 'Data Stale' },
    [IntegrationState.ERROR]: { type: IndicatorType.ERROR, tooltip: 'Token Expired' },
  };

  return indicators[integrationState];
};

export { getIntegrationIndicatorProps, getIntegrationState, getProjectsState };
