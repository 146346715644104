import { styled } from '@linaria/react';
import { Box, Flex, Image, SimpleGrid } from '@mantine/core';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { HEIGHT_BREAKPOINTS } from '../../styles/breakpoints';
import { secondaryLight, skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Logo } from '../../ui-library/logo/logo';
import { Text } from '../../ui-library/typography/typography';
import imageCreateAccount from './assets/imageCreateAccount.svg';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <Container>
      <Header>
        <Logo width={36} height={36} textProps={{ width: 100, height: 20 }} />
        <a href="mailto: support@bloomfilter.app" target="_blank" rel="noreferrer" aria-label="Get help">
          <Icon name="help_outline" color={secondaryLight} size={24} />
        </a>
      </Header>
      <Content cols={2}>
        <Section>
          <Image src={imageCreateAccount} alt="create account" style={{ flex: 'none' }} className="responsive-image" />
        </Section>
        <Section>
          <Box w="100%" maw={420}>
            {children}
          </Box>
        </Section>
      </Content>
      <Footer>
        <Text size="small" color={skyDark}>
          © {dayjs().year()} Bloomfilter. All rights reserved.
        </Text>
      </Footer>
    </Container>
  );
};

const Container = styled(Flex)`
  box-sizing: border-box;
  height: 100vh;
  min-height: 600px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: var(--sky-lighter);
  padding: 24px 32px;
  box-sizing: border-box;
  gap: 16px;
  overflow: hidden;

  @media (max-width: 1064px) {
    overflow-x: auto;
    min-width: 1064px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.lg}) {
    padding: 20px 28px;
    gap: 14px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    padding: 16px 24px;
    gap: 12px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.xs}) {
    padding: 12px 16px;
    gap: 8px;
    overflow-y: auto;
  }
`;

const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled(SimpleGrid)`
  box-sizing: border-box;
  margin: 0 auto;
  background-color: var(--base-white);
  box-shadow: var(--box-shadow-small-soft);
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-width: 1000px;
  max-width: 1456px;
  min-height: 550px;
  height: calc(100vh - 140px);
  max-height: 1200px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.lg}) {
    margin: 0 150px;
    height: calc(100vh - 120px);
    max-height: 800px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    margin: 0 100px;
    height: calc(100vh - 100px);
    max-height: 650px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    margin: 0 50px;
    height: calc(100vh - 90px);
    max-height: 600px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.xs}) {
    margin: 0 25px;
    height: 550px;
    overflow-y: auto;
  }

  /* Add styles for the responsive image */
  :global(.responsive-image) {
    width: 100%;
    max-width: 600px;
    height: auto;

    @media (max-height: ${HEIGHT_BREAKPOINTS.lg}) {
      max-width: 550px;
    }

    @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
      max-width: 500px;
    }

    @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
      max-width: 450px;
    }

    @media (max-height: ${HEIGHT_BREAKPOINTS.xs}) {
      max-width: 400px;
    }
  }
`;

const Section = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 728px;
  padding: 32px;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-height: ${HEIGHT_BREAKPOINTS.lg}) {
    padding: 28px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    padding: 24px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.xs}) {
    padding: 16px;
  }
`;

const Footer = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  height: 32px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    padding-top: 2px;
    height: 24px;
  }
`;
