import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isLoggingEnabled, logger } from '../logger/logger';
import { State } from './strategy-store.type';

const useStrategyStore = create<State>()(
  devtools(
    logger((_) => ({
      message: null,
    })),
    {
      name: 'Bloomfilter - Strategy Store',
      enabled: isLoggingEnabled,
    },
  ),
);

export { useStrategyStore };
