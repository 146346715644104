import { Divider, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { createOrganization } from '../../../../api/organization-client/organization-client';
import { useOrganizations } from '../../../../api/organization-client/organization-client.hooks';
import { OrganizationResponse } from '../../../../api/organization-client/organization-client.type';
import { usePortfolios } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { setOrganizationName, setSignUpStep } from '../../../../store/auth-store/auth-store.actions';
import { SignUpStep } from '../../../../store/auth-store/auth-store.type';
import { setOrganization, setPortfolio } from '../../../../store/global-store/global-store.actions';
import { baseWhite, secondaryBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { H3, Text } from '../../../../ui-library/typography/typography';
import { Layout } from '../../layout';
import { ApiError } from './create-organization.type';

export const CreateOrganization = () => {
  const form = useForm({
    initialValues: {
      name: '',
    },
  });

  const { name } = form.getValues();

  const { query: organizationsQuery } = useOrganizations({ enabled: false });
  const { query: portfolioQuery } = usePortfolios({ enabled: false });

  const createOrg = useMutation({
    mutationKey: ['create-organization'] as const,
    mutationFn: () => createOrganization({ name }),
    onSuccess: async () => {
      setOrganizationName(name);

      const organization = await handleSetOrganization();
      await handleSetPortfolio(organization);

      setSignUpStep(SignUpStep.Success);
    },
    onError: ({ error }: ApiError) => {
      if (error?.message) {
        form.setFieldError('name', error.message);
      } else {
        form.setFieldError('name', 'An error occurred while creating your organization');
      }
    },
  });

  const handleSetOrganization = async () => {
    const { data: organizations } = await organizationsQuery.refetch();
    const organization = organizations?.find((org) => org.name === name);

    if (organization) {
      setOrganization(organization);
    }

    return organization;
  };

  const handleSetPortfolio = async (organization?: OrganizationResponse) => {
    const { data: portfolios } = await portfolioQuery.refetch();
    const portfolio = portfolios?.find((portfolio) => portfolio.organization?.id === organization?.id);

    if (portfolio) {
      setPortfolio(portfolio);
    }

    return portfolio;
  };

  const handleSubmit = async () => {
    createOrg.mutate();
  };

  return (
    <Layout aria-label="Create Organization Form">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={32}>
          <Group gap={16}>
            <Icon name="add_business" size={32} />
            <H3 id="form-title">Create Organization</H3>
          </Group>
          <Text>Organizations are spaces where you can access and view data from your integrations.</Text>
          <Divider />
          <TextInput
            label="Organization Name"
            placeholder="Enter your organization"
            {...form.getInputProps('name')}
            w="100%"
            required
            aria-labelledby="form-title"
          />
          <Divider />
          <Button
            type="submit"
            loaderProps={{ size: 'xs', color: secondaryBase }}
            w="100%"
            radius="xl"
            rightSection={<Icon name="arrow_forward" size={18} color={name ? baseWhite : skyDark} />}
            disabled={!name}
          >
            Create Organization
          </Button>
        </Stack>
      </form>
    </Layout>
  );
};
