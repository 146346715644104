import { Navigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../../constants';
import { getRequestToken } from '../../../helpers/storage/storage';
import { useAuthStore } from '../../../store/auth-store/auth-store';
import { SignUpStep } from '../../../store/auth-store/auth-store.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { CheckYourEmail } from './check-your-email/check-your-email';
import { CreateAccount } from './create-account/create-account';
import { CreateOrganization } from './create-organization/create-organization';
import { Success } from './success/success';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions';

export const SignUp = () => {
  const signUpInitialized = useAuthStore((state) => state.signUpInitialized);
  const step = useAuthStore((state) => state.signUpStep);
  const organization = useGlobalStore((state) => state.organization);
  const isAuthenticated = Boolean(getRequestToken());

  if (isAuthenticated && organization && !signUpInitialized) {
    return <Navigate to={DEFAULT_ROUTE} replace />;
  }

  switch (step) {
    case SignUpStep.CreateAccount:
      return <CreateAccount />;
    case SignUpStep.TermsAndConditions:
      return <TermsAndConditions />;
    case SignUpStep.CheckYourEmail:
      return <CheckYourEmail />;
    case SignUpStep.CreateOrganization:
      return <CreateOrganization />;
    case SignUpStep.Success:
      return <Success />;
    default:
      return null;
  }
};
