import { ComboboxItem } from '@mantine/core';
import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useWorkPeriods } from '../../../api/work-periods-client/work-periods-client.hooks';
import { WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';

import { deduplicateItems } from '../../../helpers/general-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  resetLocalMessage,
  setLocalMessage,
  setWorkPeriod,
  setWorkPeriodBoardId,
  setWorkPeriods,
} from '../../../store/process-analysis-store/process-analysis-store.actions';
import { WorkPeriodType } from '../process-analysis.type';
import { workPeriodFormActions } from './scope-explorer.hooks';
import { WorkPeriodFormType } from './scope-explorer.type';
import { getBoardIds, getWorkPeriodOptions, sortWorkPeriods } from './work-period-form.helpers';

/**
 * Manages board selection and synchronization between global store and form state.
 * Monitors teams changes, validates selected board ID, and keeps form state in sync.
 * When teams change, ensures a valid board is selected or defaults to first available.
 *
 * @returns {void}
 */
const useBoardManagement = (): void => {
  const teams = useGlobalStore((state) => state.teams);
  const boardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  useDeepCompareEffect(() => {
    if (!teams.length) {
      setWorkPeriodBoardId(null);
      workPeriodFormActions.setFieldValue('workPeriodBoardId', null);
      workPeriodFormActions.resetDirty();
      return;
    }

    const boardIds = getBoardIds(teams);
    const isValidBoard = boardId && boardIds.includes(boardId);

    if (!isValidBoard || !boardId) {
      const defaultBoardId = boardIds[0] || null;
      setWorkPeriodBoardId(defaultBoardId);
      workPeriodFormActions.setFieldValue('workPeriodBoardId', defaultBoardId);
      workPeriodFormActions.resetDirty();
    }
  }, [teams]);
};

/**
 * Manages work periods data fetching and state for a selected board.
 * Fetches work periods when board is selected, updates global store with sorted data,
 * and handles empty states with user messages.
 *
 * @param boardId - The ID of the currently selected board, can be null
 * @returns {void}
 */
const useWorkPeriodsManagement = (boardId: string | null): void => {
  const { data: workPeriodsData } = useWorkPeriods({
    payload: { subproject_id: boardId },
    options: {
      enabled: !!boardId,
      staleTime: 1000 * 60 * 5,
      queryKey: ['work-periods', 'subproject_id', boardId] as const,
    },
  });

  useEffect(() => {
    if (!workPeriodsData) {
      return;
    }

    if (workPeriodsData.length === 0) {
      setWorkPeriods([]);
      setLocalMessage('There are no work periods in this board');
      return;
    }

    const sortedWorkPeriods = sortWorkPeriods(workPeriodsData);
    setWorkPeriods(sortedWorkPeriods);
    resetLocalMessage();
  }, [workPeriodsData]);
};

/**
 * Hook that provides work period options for use in a combobox/select component.
 *
 * This hook retrieves the work periods from the process analysis store and converts them
 * into a format suitable for use as combobox/select options using the getWorkPeriodOptions helper.
 *
 * @returns {ComboboxItem[]} An array of work period options formatted as ComboboxItems
 */
const useWorkPeriodOptions = (): ComboboxItem[] => {
  const workPeriods = useProcessAnalysisStore((state) => deduplicateItems(state.workPeriods));

  return getWorkPeriodOptions(workPeriods);
};

/**
 * Hook that manages work period selection and persistence across sessions.
 *
 * Handles:
 * - Tracking and validating selected work period
 * - Saving/restoring selection to localStorage per board
 * - Falling back to stored preference or first available option if current selection invalid
 * - Keeping form and global state in sync
 *
 * @param workPeriodForm - Form instance managing work period selection
 * @returns {void}
 */
const useWorkPeriodSelection = (workPeriodForm: WorkPeriodFormType): void => {
  const boardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriods = useProcessAnalysisStore((state) => state.workPeriods);
  const workPeriodOptions = useWorkPeriodOptions();

  useDeepCompareEffect(() => {
    const currentWorkPeriod = workPeriodForm.getValues().workPeriodId;
    const isValidWorkPeriod = workPeriodOptions.find((wp) => wp.value === currentWorkPeriod);

    if (workPeriodOptions.length > 0 && !isValidWorkPeriod) {
      selectWorkPeriod(workPeriodOptions[0].value, workPeriods);

      if (workPeriodType === WorkPeriodType.Defined) {
        workPeriodFormActions.resetDirty();
      }
    } else {
      selectWorkPeriod(currentWorkPeriod, workPeriods);
    }
  }, [boardId, workPeriodOptions]);
};

/**
 * Selects a work period and updates both the global state and form state.
 *
 * @param {string | null} workPeriodId - The ID of the work period to select, or null to clear selection
 * @param {WorkPeriod[]} workPeriods - Array of available work periods to select from
 * @returns {void}
 */
const selectWorkPeriod = (workPeriodId: string | null, workPeriods: WorkPeriod[]): void => {
  const selectedWorkPeriod = workPeriods.find((wp) => wp.id === workPeriodId) || null;
  setWorkPeriod(selectedWorkPeriod);
  workPeriodFormActions.setFieldValue('workPeriodId', workPeriodId);
};

export { useBoardManagement, useWorkPeriodOptions, useWorkPeriodSelection, useWorkPeriodsManagement };
