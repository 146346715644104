import { useEffect } from 'react';
import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProjectMetricsDetails } from '../../api/projects-client/projects-client';
import {
  ProjectMetricsDetailsEndpointDescription,
  ProjectMetricsDetailsResult,
  Subproject,
} from '../../api/projects-client/projects-client.type';
import { DownloadPNGButton } from '../../components/download-png-button';
import { LineScatterChart } from '../../components/line-scatter-chart/line-scatter-chart';
import { LineScatterDataLine, LineScatterDataPoint } from '../../components/line-scatter-chart/line-scatter-chart.type';
import { SubprojectSelector } from '../../components/subproject-selector/subproject-selector';
import { TrendsTrailingCard } from '../../components/trends-trailiing-card/trends-trailiing-card';
import { ProjectContext } from '../../contexts';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import { SubtitleHeadingTag, TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import styles from './project-metrics-details.module.css';
import { ProjectMetricsDetailsHistoryState } from './project-metrics-details.type';

export const ProjectMetricsDetails = () => {
  useEffect(() => {
    dayjs.extend(customParseFormat);
  }, []);

  const { subprojects } = useContext(ProjectContext);
  const {
    projectId = '',
    subprojectId = '',
    desc = '',
  } = useParams<{ projectId: string; subprojectId: string; desc: string }>();
  const [metric, setMetric] = useState<ProjectMetricsDetailsResult>(null);
  const location = useLocation();
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const projectIdNum = projectId;
  const historyState = location.state as ProjectMetricsDetailsHistoryState;
  const [selectedSubproject, setSelectedSubproject] = useState<Subproject>();
  const navigate = useNavigate();

  const navigateToSelectedObject = (subproject?: Subproject) => {
    if (subproject) {
      navigate(`/application/project/${projectId}/subproject/${subproject?.id}/metrics/${desc}`, {
        state: Object.assign({}, historyState, { subproject }),
      });
    } else {
      navigate(`/application/project/${projectId}/metrics/${desc}`, {
        state: Object.assign({}, historyState, { subproject: undefined }),
      });
    }
    setSelectedSubproject(subproject);
  };

  useEffect(() => {
    if (subprojects) {
      if (subprojects.length === 1) {
        setSelectedSubproject(subprojects[0]);
      } else {
        setSelectedSubproject(subprojects.find((x: Subproject) => x.id === (subprojectId || '')) ?? undefined);
      }
    }
  }, [subprojectId, subprojects]);

  const endpointDescriptionForKind = (kind: string): ProjectMetricsDetailsEndpointDescription => {
    switch (kind) {
      case 'velocity':
        return ProjectMetricsDetailsEndpointDescription.Velocity;
      case 'throughput':
        return ProjectMetricsDetailsEndpointDescription.Throughput;
      case 'lead':
        return ProjectMetricsDetailsEndpointDescription.LeadTime;
      case 'reaction':
        return ProjectMetricsDetailsEndpointDescription.ReactionTime;
      default:
        return ProjectMetricsDetailsEndpointDescription.CycleTime;
    }
  };

  const getTrendsContentForDesc = (desc: ProjectMetricsDetailsEndpointDescription): string => {
    switch (desc) {
      case ProjectMetricsDetailsEndpointDescription.Velocity:
        return 'points completed per sprint';
      case ProjectMetricsDetailsEndpointDescription.Throughput:
        return 'tasks completed per sprint';
      case ProjectMetricsDetailsEndpointDescription.LeadTime:
        return 'days to complete';
      case ProjectMetricsDetailsEndpointDescription.ReactionTime:
        return 'days to start';
      default:
        return 'days to complete';
    }
  };

  const { data } = useQuery<ProjectMetricsDetailsResult | null>({
    queryKey: ['projectMetricsDetails', projectId, selectedSubproject] as const,
    queryFn: async () => {
      if (!projectId) {
        return null;
      }
      const result = await fetchProjectMetricsDetails(
        projectIdNum,
        (subprojectId || '') ?? null,
        endpointDescriptionForKind(desc),
      );
      return result;
    },
    enabled: metric === null,
  });

  useEffect(() => {
    setMetric(data ?? null);
  }, [data]);

  const chartData = (): LineScatterDataPoint[] => {
    if (!metric) {
      return [];
    }
    return Object.entries(metric.data_points).map(([end_date, data]) => {
      return {
        x: dayjs(end_date, 'M/D/YY').format('MMM DD'),
        y: Math.round(data.value),
        sprintId: data.sprint_id,
      };
    });
  };

  const dataLine: LineScatterDataLine = {
    dataPoints: chartData(),
    color: newCOLORS.aqua,
  };

  const header = metric ? <TitleHeadingTag>{metric.title}</TitleHeadingTag> : null;

  return (
    <MenuDrivenContainer header={header}>
      <div className={styles.projectMetricsDetailsHeader}>
        {subprojects && subprojects.length > 1 && (
          <SubprojectSelector
            subprojects={subprojects}
            setSelectedSubproject={navigateToSelectedObject}
            includeAggregate={true}
            defaultValue={subprojectId || ''}
          />
        )}
      </div>
      {metric ? (
        <ContentLayout ref={downloadRef}>
          <ContentLayoutHeader>
            <SubHeadingTag>Historical View</SubHeadingTag>
            <div ref={exceptionRef}>
              {
                <DownloadPNGButton
                  handleDownload={() => canvasHtmlDownload(metric.title, downloadRef, exceptionRef)}
                  exceptionRef={exceptionRef}
                />
              }
            </div>
          </ContentLayoutHeader>

          <ContentHeadingTag>{metric.description}</ContentHeadingTag>
          <LineScatterChart dataline={dataLine} desc={endpointDescriptionForKind(desc)} />

          <SubHeadingTag>Trends</SubHeadingTag>
          <ContentHeadingTag>
            This effort is averaging <SpanBold>{metric.average}</SpanBold>{' '}
            {getTrendsContentForDesc(endpointDescriptionForKind(desc))}, historically. Let's break that down:
          </ContentHeadingTag>
          <TrendsTrailingCards>
            <TrendsTrailingCard
              amount={metric.trailing_amounts?.three || null}
              average={metric.average}
              months={'three'}
              desc={endpointDescriptionForKind(desc)}
            />
            <TrendsTrailingCard
              amount={metric.trailing_amounts?.six || null}
              average={metric.average}
              months={'six'}
              desc={endpointDescriptionForKind(desc)}
            />
            <TrendsTrailingCard
              amount={metric.trailing_amounts?.twelve || null}
              average={metric.average}
              months={'twelve'}
              desc={endpointDescriptionForKind(desc)}
            />
          </TrendsTrailingCards>
        </ContentLayout>
      ) : null}
    </MenuDrivenContainer>
  );
};

const SubHeadingTag = styled(TitleHeadingTag)`
  font-size: x-large;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const ContentHeadingTag = styled(SubtitleHeadingTag)`
  padding-bottom: 5px;
  font-size: medium;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const ContentLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrendsTrailingCards = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SpanBold = styled.span`
  font-weight: bold;
`;
