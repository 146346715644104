import html2canvas from 'html2canvas';

/**
 * Method to generate a name of file to be downloaded in form of
 * "chartType_sprintName_sprintEnddate"
 * @param chartType string
 * @param sprintName string
 * @param endDate datetime string
 * @returns name string of the file to be downloaded
 */
const generateDownloadFileName = (chartType: string, sprintName: string, endDate: string) => {
  const enddate = new Date(endDate);

  const month = String(enddate.getUTCMonth() + 1).padStart(2, '0'); // Add leading zero to month if it is a single digit
  const day = String(enddate.getUTCDate()).padStart(2, '0'); // Add leading zero to day if it is a single digit

  const fileName = `${chartType}_${sprintName}_${month}_${day}`;
  return fileName;
};

/**
 * Method to faciliate the download of an svg as an image file
 * @param chartRef <ref object>
 * @param svgLayer <number>
 * @param fileName <string> name of file to be downloaded
 * @param chartName <string> Name of the chart type to be downloaded
 * @param chartHeaderTextSize <string> Header text size for the chart image to be downloaded
 * @param copyrightsTextSize <string> Copyrights text size for the chart image to be downloaded
 */
const handleSVGasImageDownload = (
  chartRef: React.RefObject<HTMLDivElement>,
  svgLayer = 0,
  fileName = 'Bloomfilter-chart',
  chartName: string,
  chartHeaderTextSize = '48',
  copyrightsTextSize = '24',
) => {
  const chart = chartRef.current as HTMLDivElement;
  const svg = chart.getElementsByTagName('svg')[svgLayer];
  const bbox = svg.getBBox();
  const padding = 10; // change the padding value to adjust the amount of white space to add

  const originalChartMargin = chart.style.margin;
  const originalChartPadding = chart.style.padding;

  // Set chart margin and padding zero
  chart.style.margin = '0px';
  chart.style.padding = '0px';

  // Add top left text to chart element
  const headerTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  headerTextElement.setAttribute('x', String(bbox.x));
  headerTextElement.setAttribute('y', String(bbox.y - 5 * padding));
  headerTextElement.setAttribute('font-family', 'Figtree');
  headerTextElement.setAttribute('font-size', chartHeaderTextSize);
  headerTextElement.setAttribute('font-weight', 'bold');
  headerTextElement.setAttribute('fill', 'black');
  headerTextElement.textContent = chartName;
  svg.appendChild(headerTextElement);

  // Add copyright text to chart element
  const copyrightText = `©${new Date().getFullYear()} Bloomfilter. All Rights Reserved.`;
  const copyrightTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  copyrightTextElement.setAttribute('x', String(bbox.x + bbox.width));
  copyrightTextElement.setAttribute('y', String(bbox.y + bbox.height + 5 * padding));
  copyrightTextElement.setAttribute('font-family', 'Figtree');
  copyrightTextElement.setAttribute('font-size', copyrightsTextSize);
  copyrightTextElement.setAttribute('font-weight', 'bold');
  copyrightTextElement.setAttribute('fill', 'black');
  copyrightTextElement.setAttribute('text-anchor', 'end');
  copyrightTextElement.textContent = copyrightText;
  svg.appendChild(copyrightTextElement);

  // add padding to the SVG element
  svg.setAttribute('width', (bbox.width + 10 * padding).toString());
  svg.setAttribute('height', (bbox.height + 20 * padding).toString());
  svg.setAttribute(
    'viewBox',
    `${bbox.x - 5 * padding} ${bbox.y - 10 * padding} ${bbox.width + 10 * padding} ${bbox.height + 20 * padding}`,
  );

  // Create canvas from chart element
  html2canvas(chart).then((canvas) => {
    // Create download link for the image
    const link = document.createElement('a');
    link.download = `${fileName}.png`;
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Remove copyright and top left text from chart element
    svg.removeChild(headerTextElement);
    svg.removeChild(copyrightTextElement);

    // Restore original SVG size and viewBox
    svg.setAttribute('width', bbox.width.toString());
    svg.setAttribute('height', bbox.height.toString());
    svg.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);

    // Restore original chart margin and padding
    chart.style.margin = originalChartMargin;
    chart.style.padding = originalChartPadding;
  });
};

/**
 * Method to facilitate the download of an html element as an image file, with an optional element to hide
 * @param downloadFilename <string> name of file to be downloaded
 * @param downloadRef <ref object>
 * @param exceptionRef <ref object> (optional)
 */
const canvasHtmlDownload = (
  downloadFilename: string,
  downloadRef: React.RefObject<HTMLDivElement>,
  exceptionRef?: React.RefObject<HTMLDivElement>,
) => {
  const downloadElement = downloadRef.current as HTMLDivElement;
  let initialExceptionElementDisplayProperty: string | null = null;

  // Only handle the exception element if the ref is provided
  if (exceptionRef && exceptionRef.current) {
    const exceptionElement = exceptionRef.current;
    initialExceptionElementDisplayProperty = exceptionElement.style.display;
    exceptionElement.style.display = 'none';
  }

  html2canvas(downloadElement).then((canvas) => {
    const link = document.createElement('a');
    link.download = `${downloadFilename}.png`;
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Restore the exception element's display property if it was modified
    if (exceptionRef && exceptionRef.current && initialExceptionElementDisplayProperty !== null) {
      exceptionRef.current.style.display = initialExceptionElementDisplayProperty;
    }
  });
};

export { canvasHtmlDownload, generateDownloadFileName, handleSVGasImageDownload };
