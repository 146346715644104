import dayjs from 'dayjs';
import { Measure, MeasureDataResponse } from './work-periods-client.type';

/**
 * Generates a mock object with random values for each measure within a given date range.
 *
 * @param {Measure[]} measures - An array of measures to generate mock values for.
 * @param {string} startDate - The start date of the date range.
 * @param {string} endDate - The end date of the date range.
 * @return {Record<Measure, Record<string, number>>} - A mock object with measure values for each date within the range.
 */
const generateMock = (measures: Measure[], startDate: string, endDate: string): MeasureDataResponse => {
  const mock = measures.reduce((acc, measure) => {
    const measureValue = generateRange(startDate, endDate).reduce((acc, date) => {
      return {
        ...acc,
        [date]: randomize(),
      };
    }, {});

    return {
      ...acc,
      [measure]: measureValue,
    };
  }, {} as MeasureDataResponse);

  return mock;
};

/**
 * Generates an array of dates between the start and end dates.
 *
 * @param {string} startDate - The start date in the format 'YYYY-MM-DD'.
 * @param {string} endDate - The end date in the format 'YYYY-MM-DD'.
 * @return {string[]} An array of dates between the start and end dates.
 */
const generateRange = (startDate: string, endDate: string): string[] => {
  const list = [];
  let current = dayjs(startDate);
  while (current.isBefore(endDate)) {
    list.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'day');
  }
  return list;
};

/**
 * Generates a random number between 1 and 100 (inclusive).
 *
 * @return {number} The randomly generated number.
 */
const randomize = (): number => {
  return Math.random() * (100 - 1) + 1;
};

/**
 * Sanitizes measure data response by handling undefined values and creating default empty objects.
 *
 * @param {MeasureDataResponse | undefined} data - The measure data response to sanitize
 * @param {string[]} measureNames - Array of measure names to include in default response
 * @return {MeasureDataResponse} Sanitized measure data response with empty objects for undefined values
 *
 * @description
 * Algorithm:
 * 1. If data exists:
 *    - Iterate through each [key, value] pair in data
 *    - Replace any null/undefined values with empty objects
 *    - Return the sanitized data object
 * 2. If data is undefined (e.g. no data returned from API or error):
 *    - Create a new object with each measure name as a key
 *    - Set empty object as value for each measure
 *    - Return this default response object
 */
const sanitizeMeasureDataResponse = (
  data: MeasureDataResponse | undefined,
  measureNames: string[],
): MeasureDataResponse => {
  if (data) {
    const sanitized = {} as MeasureDataResponse;

    for (const [key, value] of Object.entries(data)) {
      sanitized[key as Measure] = value && typeof value === 'object' ? value : {};
    }

    return sanitized;
  }

  const defaultMeasureResponse = {} as MeasureDataResponse;

  for (const measure of measureNames) {
    defaultMeasureResponse[measure as Measure] = {};
  }

  return defaultMeasureResponse;
};

export { generateMock, sanitizeMeasureDataResponse };
