import { styled } from '@linaria/react';
import { Group } from '@mantine/core';
import { IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { inkDark, skyLightest } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { getDataSourceIcon } from '../data-management.helpers';
import { getDataSourceName } from './data-sources.helpers';

type DataSourceTableHeaderProps = {
  type: IntegrationType;
  isCollapsed: boolean;
  onToggle: () => void;
};

export function DataSourceTableHeader({ type, isCollapsed, onToggle }: DataSourceTableHeaderProps) {
  return (
    <TableHeader
      onClick={onToggle}
      role="button"
      aria-expanded={!isCollapsed}
      aria-label={`${getDataSourceName(type)} section, click to ${isCollapsed ? 'expand' : 'collapse'}`}
    >
      <Group style={{ flex: 1 }}>
        <img src={getDataSourceIcon(type)} width={24} height={24} alt={getDataSourceName(type)} />
        <Text weight="bold">{getDataSourceName(type)}</Text>
      </Group>
      <Group>
        <Icon name={isCollapsed ? 'unfold_less' : 'unfold_more'} size={20} color={inkDark} />
      </Group>
    </TableHeader>
  );
}

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${skyLightest};
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${skyLightest}dd;
  }
`;
