import { Logger, LoggerImpl } from './logger.type';

const isLoggingEnabled = import.meta.env.VITE_GLOBAL_STATE_LOGGING === 'true';

/**
 * Custom logger implementation for the state actions.
 * Based on the implementation from the official docs - https://docs.pmnd.rs/zustand/guides/typescript#common-recipes
 * @param {function} f - The state creator function
 * @returns {function} - The state creator function augmented with logging capabilities
 */
const loggerImpl: LoggerImpl = (f) => (set, get, store) => {
  const typedSet = set as <A extends string>(
    partialState: object,
    replace?: boolean | undefined,
    action?: A | undefined,
  ) => void;

  const loggedSet: typeof typedSet = (...a) => {
    const actionName = a[2];
    console.groupCollapsed(
      `%cAction ${actionName} @ ${new Date().toLocaleTimeString()}`,
      'color: #9154E2; font-weight: bold;',
    );
    console.log('%cprev state', 'color: #9E9E9E; font-weight: bold;', get());
    console.log('%cpayload', 'color: #03A9F4; font-weight: bold;', a[0]);
    set(...(a as Parameters<typeof set>));
    console.log('%cnext state', 'color: #4CAF50; font-weight: bold;', get());
    console.groupEnd();
  };

  if (isLoggingEnabled) {
    const setState = store.setState;
    store.setState = (...a) => {
      const actionName = (a as Parameters<typeof typedSet>)[2];
      console.groupCollapsed(
        `%cAction ${actionName} @ ${new Date().toLocaleTimeString()}`,
        'color: #9154E2; font-weight: bold;',
      );
      console.log('%cprev state', 'color: #9E9E9E; font-weight: bold;', store.getState());
      console.log('%cpayload', 'color: #03A9F4; font-weight: bold;', a[0]);
      setState(...(a as Parameters<typeof setState>));
      console.log('%cnext state', 'color: #4CAF50; font-weight: bold;', store.getState());
      console.groupEnd();
    };
  }

  return f(isLoggingEnabled ? loggedSet : set, get, store);
};

const logger = loggerImpl as unknown as Logger;

export { isLoggingEnabled, logger };
