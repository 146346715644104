import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getRequestToken } from '../../helpers/storage/storage';
import { getOrganizations } from './organization-client';
import { OrganizationResponse } from './organization-client.type';

/**
 * Query manager for fetching organizations for the current user.
 *
 * @param {Partial<UseQueryOptions<OrganizationResponse[]>>} options - Optional query options for the useQuery hook.
 * @return {{ organizations: OrganizationResponse[] | undefined; query: QueryObserverResult<OrganizationResponse[]> }} An object containing the organizations data and the query result.
 */
const useOrganizations = (
  options?: Partial<UseQueryOptions<OrganizationResponse[]>>,
): { organizations: OrganizationResponse[] | undefined; query: QueryObserverResult<OrganizationResponse[]> } => {
  const query = useQuery({
    queryKey: ['organizations'],
    queryFn: getOrganizations,
    enabled: Boolean(getRequestToken()),
    // Cache organization data indefinitely throughout the user session
    // Can be overridden with options
    staleTime: Infinity,
    ...options,
  });

  return { organizations: query.data, query };
};

export { useOrganizations };
