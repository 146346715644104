import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSprint, fetchSprintAuditLog } from '../../api/sprints-client/sprints-client';
import { Sprint, SprintAuditLogEntry } from '../../api/sprints-client/sprints-client.type';
import { AuditLog } from '../../components/audit-log/audit-log';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const SprintAuditLog = () => {
  const { sprintId = '' } = useParams<{ sprintId: string }>();
  const [auditLog, setAuditLog] = useState<SprintAuditLogEntry[]>([]);
  const [sprint, setSprint] = useState<Sprint | null>(null);

  const auditLogQuery = useQuery({
    queryKey: ['sprintAuditLog', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Sprint ID is required');
      }
      return fetchSprintAuditLog(sprintId);
    },
    enabled: !!sprintId,
  });

  const sprintQuery = useQuery({
    queryKey: ['sprint', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Sprint ID is required');
      }
      return fetchSprint(sprintId);
    },
    enabled: !!sprintId,
  });

  useEffect(() => {
    if (auditLogQuery.data) {
      setAuditLog(auditLogQuery.data);
    }
  }, [auditLogQuery.data]);

  useEffect(() => {
    if (sprintQuery.data) {
      setSprint(sprintQuery.data);
    }
  }, [sprintQuery.data]);

  useEffect(() => {
    if (sprintQuery.error || auditLogQuery.error) {
      console.error('There was an issue fetching data:', sprintQuery.error || auditLogQuery.error);
    }
  }, [sprintQuery.error, auditLogQuery.error]);

  return (
    <MenuDrivenContainer header={sprint?.name ? `${sprint.name} Audit Log` : 'Audit Log'}>
      <BrandedLoadingOverlay visible={auditLogQuery.isPending} transitionDuration={300} variant="colored" />
      <AuditLog sprint={sprint} entries={auditLog} />
    </MenuDrivenContainer>
  );
};
