import { Subproject } from '../../../../api/integrations-client/integrations-client.type';

/**
 * Groups subprojects by their project ID and name
 * @param {Subproject[]} subprojects Array of subprojects to group
 * @returns {Record<string, { name: string; subprojects: Subproject[] }>} Object with project IDs as keys and objects containing project name and subprojects array as values
 */
const getProjectGroups = (subprojects: Subproject[]): Record<string, { name: string; subprojects: Subproject[] }> => {
  return subprojects.reduce(
    (acc, subproject) => {
      const projectId = subproject.project_id;
      const projectName = subproject.project_name;
      if (!acc[projectId]) {
        acc[projectId] = {
          name: projectName,
          subprojects: [],
        };
      }
      acc[projectId].subprojects.push(subproject);
      return acc;
    },
    {} as Record<string, { name: string; subprojects: Subproject[] }>,
  );
};

export { getProjectGroups };
