import { styled } from '@linaria/react';
import { Modal } from '@mantine/core';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 400px;
  margin: 0 auto;
`;

const StyledModal = styled(Modal)`
  .mantine-Modal-content {
    box-shadow: var(--box-shadow-big-soft);
    border-radius: 16px;
  }

  .mantine-Modal-header {
    padding-bottom: 24px;
  }

  .mantine-Modal-close {
    position: absolute;
    top: 24px;
    right: 24px;
    color: var(--sky-dark);
  }
`;

export { StyledForm, StyledModal };
