import { styled } from '@linaria/react';
import { Checkbox, Divider } from '@mantine/core';
import { formatCurrency } from '../../helpers/financial/financial';
import { newCOLORS } from '../../styles/colors';
import {
  SmallText,
  StandardText,
  StandardTextBold,
} from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { CostsVsOutputChartItemLabels, ShowCostsVsOutputChartLines } from './costs-vs-output.types';

type CostsVsOutputItemProps = {
  label: CostsVsOutputChartItemLabels;
  color: string;
  title: string;
  description?: string;
  last: number;
  average: number;
  showCostsVsOutputChartLines: ShowCostsVsOutputChartLines;
  updateShowCostsVsOutputChartLines: (checked: boolean, label: CostsVsOutputChartItemLabels) => void;
  currency: boolean;
  disabled: boolean;
};
function getProperty(label: string, trend = false): keyof ShowCostsVsOutputChartLines {
  const propertyName = `show${label}${trend ? 'Trend' : ''}`;
  return propertyName as keyof ShowCostsVsOutputChartLines;
}
export const CostsVsOutputItem = ({
  label,
  color,
  title,
  description,
  last,
  average,
  showCostsVsOutputChartLines,
  updateShowCostsVsOutputChartLines,
  currency,
  disabled,
}: CostsVsOutputItemProps) => {
  const propertyName = getProperty(label);
  const trendPropertyName = getProperty(label, true);

  const checked = showCostsVsOutputChartLines[propertyName] || showCostsVsOutputChartLines[trendPropertyName];
  return (
    <CostsVsOutputItemContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Divider orientation="vertical" size="md" style={{ marginRight: 4 }} color={color} />
        <Checkbox
          style={{ margin: 4 }}
          size="xs"
          checked={showCostsVsOutputChartLines[propertyName]}
          onChange={(event) => updateShowCostsVsOutputChartLines(event.currentTarget.checked, label)}
          disabled={disabled}
        />
        <Checkbox
          style={{ margin: 4, marginLeft: '17px' }}
          size="xs"
          checked={showCostsVsOutputChartLines[trendPropertyName]}
          onChange={(event) =>
            updateShowCostsVsOutputChartLines(
              event.currentTarget.checked,
              `${label}Trend` as CostsVsOutputChartItemLabels,
            )
          }
          disabled={disabled}
        />
        <div style={{ margin: 4 }}>
          <StandardText style={{ color: checked ? newCOLORS.black : newCOLORS.darkerGray }}>{title}</StandardText>
          {description && (
            <SmallText style={{ color: checked ? newCOLORS.darkGray : newCOLORS.darkerGray }}>
              ({description})
            </SmallText>
          )}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <StandardTextBold style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {currency ? formatCurrency(last) : last}
        </StandardTextBold>
        <StandardText style={{ width: 125, textAlign: 'right', color: checked ? newCOLORS.black : newCOLORS.gray }}>
          {currency ? formatCurrency(average) : average}
        </StandardText>
      </div>
    </CostsVsOutputItemContainer>
  );
};

const CostsVsOutputItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 8px 0px;
`;
