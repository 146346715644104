import { devtools } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { isLoggingEnabled, logger } from '../logger/logger';
import { State } from './ui-store.type';

const useUIStore = create<State>()(
  devtools(
    logger((_) => ({
      isHoveredLogo: false,
    })),
    {
      name: 'Bloomfilter - UI Store',
      enabled: isLoggingEnabled,
    },
  ),
);

export { useUIStore };
