import { styled } from '@linaria/react';
import { ButtonProps, Button as MantineButton, PolymorphicComponentProps } from '@mantine/core';
import {
  getBackgroundColor,
  getBackgroundColorHover,
  getBorderRadius,
  getColor,
  getColorHover,
  getPadding,
} from './button.helpers';

type Props = PolymorphicComponentProps<'button', ButtonProps> & {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  radius?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'outline' | 'subtle';
  style?: React.CSSProperties;
};

export const Button = ({ size = 'md', radius = 'md', variant = 'primary', style, ...props }: Props) => {
  return <StyledButton size={size} radius={radius} variant={variant} style={style} {...props} />;
};

const StyledButton = styled(MantineButton)<Props>`
  background-color: ${({ variant }) => getBackgroundColor(variant)};
  border-radius: ${({ radius }) => getBorderRadius(radius)};
  border: ${({ variant }) => (variant === 'outline' ? '1px solid var(--sky-base)' : 'none')};
  padding: ${({ size }) => getPadding(size)};
  color: ${({ variant }) => getColor(variant)};

  .material-icons,
  .material-icons-outlined {
    line-height: 0;
  }

  .mantine-Button-label {
    line-height: 0;
  }

  &:hover,
  &:active {
    background-color: ${({ variant }) => getBackgroundColorHover(variant)};
    border: ${({ variant }) => (variant === 'outline' ? '1px solid var(--secondary-base)' : 'none')};
    color: ${({ variant }) => getColorHover(variant)};
  }

  &:disabled {
    background-color: var(--sky-lighter);
    color: var(--sky-dark);
    border: none;
  }
`;
