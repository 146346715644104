import dayjs from 'dayjs';
import {
  SprintBurnsAndFlows,
  SprintMetricsFlowOfWorkPointDataPoint,
  SprintMetricsFlowOfWorkTaskDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import {
  WorkPeriodBurnsAndFlows,
  WorkPeriodFlowDataPointWithWeekend,
} from '../../containers/process-analysis/assessment-view/assessment-view.type';
import { Mode } from '../../containers/process-analysis/process-analysis.type';

/**
 * Gets the appropriate data points based on the mode and type of burns and flows data
 *
 * @param sprintBurnsAndFlows - The burns and flows data for either a sprint or work period
 * @param mode - The mode indicating whether this is for a sprint or work period view
 * @returns An array of data points specific to the mode and data type, or empty array if no match
 */
const getDataPoints = (
  sprintBurnsAndFlows: SprintBurnsAndFlows | WorkPeriodBurnsAndFlows,
  mode: Mode,
):
  | SprintMetricsFlowOfWorkTaskDataPoint[]
  | SprintMetricsFlowOfWorkPointDataPoint[]
  | WorkPeriodFlowDataPointWithWeekend[] => {
  if (mode === Mode.Sprint && 'flow_of_work_points_by_pace' in sprintBurnsAndFlows) {
    return sprintBurnsAndFlows.flow_of_work_points_by_pace.data_points || [];
  }

  if (mode === Mode.WorkPeriod && 'flows_points' in sprintBurnsAndFlows) {
    return (sprintBurnsAndFlows.flows_points.data_points || []) as WorkPeriodFlowDataPointWithWeekend[];
  }

  return [];
};

/**
 * Calculates areas representing weekends and holidays for display on a chart
 *
 * @template T - Type extending an object with date and weekend_or_holiday properties
 * @param {T[]} dataPoints - Array of data points containing date and weekend/holiday status
 * @param {number} height - The height value to use for the area segments
 * @returns {Array<Array<{x: string, y: number}>>} Array of area segments, where each segment is an array of coordinate points
 *                                                 representing a continuous weekend/holiday period
 */
const calculateWeekendAreas = <T extends { date: string; weekend_or_holiday: boolean }>(
  dataPoints: T[],
  height: number,
): { x: string; y: number }[][] => {
  const weekendHolidayVictoryAreaData = [];

  let oneSegmentData: { x: string; y: number }[] = [];
  let beforeDayStatus = false;

  dataPoints.forEach((point: T) => {
    if (point.weekend_or_holiday === true) {
      oneSegmentData.push({ x: dayjs(point.date).format('MM/DD'), y: height });
    }
    if (point.weekend_or_holiday === false && beforeDayStatus === true) {
      oneSegmentData.push({ x: dayjs(point.date).format('MM/DD'), y: height });
      weekendHolidayVictoryAreaData.push(oneSegmentData);
      oneSegmentData = [];
    }
    beforeDayStatus = point.weekend_or_holiday;
  });

  if (oneSegmentData.length) {
    weekendHolidayVictoryAreaData.push(oneSegmentData);
  }

  return weekendHolidayVictoryAreaData;
};

export { calculateWeekendAreas, getDataPoints };
