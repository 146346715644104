import { styled } from '@linaria/react';
import { Divider, Group, TextInput } from '@mantine/core';
import { useState } from 'react';
import { IntegrationType, Subproject } from '../../../../api/integrations-client/integrations-client.type';
import { ProjectsResponse } from '../../../../api/projects-client/projects-client.type';
import { redBase, skyBase, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { getDataSourceIcon, getDataSourceLabel } from '../data-management.helpers';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';
import { Table, TableColumn } from '../table';
import { useDeleteBoardSetting, useEditBoardSetting } from './board-settings-client.hooks';
import { BoardSetting } from './board-settings-client.types';
import { BoardSettingsMetadata } from './board-settings-metadata';
import { getProjectGroups } from './board-settings.helpers';

type TableRow = {
  id: string;
  name: string;
  projectName: string;
  dataSource: React.ReactNode | null;
  lastUpdated: string | null;
  isProject: boolean;
  parentId?: string;
};

type BoardSettingsPanelProps = {
  projects: ProjectsResponse[];
  subprojects: Subproject[];
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  selectedSubprojectId: string | null;
  setSelectedSubprojectId: (id: string | null) => void;
  selectedBoardSetting: BoardSetting | null;
};

export function BoardSettingsPanel({
  projects,
  subprojects,
  isEditing,
  setIsEditing,
  selectedSubprojectId,
  setSelectedSubprojectId,
  selectedBoardSetting,
}: BoardSettingsPanelProps) {
  const [search, setSearch] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState(selectedBoardSetting?.name || '');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { editBoardSetting, isEditing: isEditingBoardSetting } = useEditBoardSetting();
  const { deleteBoardSetting, isDeleting } = useDeleteBoardSetting();

  const handleNameEdit = () => {
    setIsEditingName(true);
    setEditedName(selectedBoardSetting?.name || '');
  };

  const handleNameSave = async () => {
    if (selectedBoardSetting && editedName.trim()) {
      await editBoardSetting(selectedBoardSetting.id, { name: editedName });
      setIsEditingName(false);
    }
  };

  const handleNameKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNameSave();
    } else if (e.key === 'Escape') {
      setIsEditingName(false);
      setEditedName(selectedBoardSetting?.name || '');
    }
  };

  const columns: TableColumn<TableRow>[] = [
    {
      key: 'name',
      label: 'Project / Board Name',
      width: '40%',
      sortable: true,
      render: (_value: TableRow[keyof TableRow], row: TableRow) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: row.isProject ? 0 : 12 }}>
          <Text weight={row.isProject ? 'bold' : 'medium'}>{row.name}</Text>
        </div>
      ),
    },
    {
      key: 'dataSource',
      label: 'Data Source',
      width: '30%',
      sortable: true,
      render: (_value: TableRow[keyof TableRow], row: TableRow) => row.dataSource,
    },
    { key: 'lastUpdated', label: 'Last Updated', width: '30%', sortable: true },
  ];

  const tableData: TableRow[] = [];

  const projectGroups = getProjectGroups(
    subprojects.filter((subproject) => subproject.subproject_settings_id === selectedBoardSetting?.id),
  );

  Object.entries(projectGroups).forEach(([projectId, projectSubprojects]) => {
    const project = projects?.find((p) => p.id === projectId);
    if (project) {
      tableData.push({
        id: project.id,
        name: project.name,
        projectName: project.name,
        isProject: true,
        dataSource: null,
        lastUpdated: null,
      });

      projectSubprojects.subprojects.forEach((subproject) => {
        tableData.push({
          id: subproject.id,
          name: subproject.name,
          projectName: subproject.project_name,
          isProject: false,
          parentId: project.id,
          dataSource: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img
                src={getDataSourceIcon(project.pm_tool as IntegrationType)}
                width={20}
                height={20}
                alt={getDataSourceLabel(project.pm_tool as IntegrationType)}
              />
              <Text>{getDataSourceLabel(project.pm_tool as IntegrationType)}</Text>
            </div>
          ),
          lastUpdated: new Date(subproject.last_data_fetch).toLocaleString(),
        });
      });
    }
  });

  const filteredData = tableData.filter((row) => {
    const searchTerm = search.toLowerCase();
    if (!searchTerm) return true;

    if (row.isProject) {
      const hasMatchingSubprojects = tableData.some(
        (subRow) => !subRow.isProject && subRow.parentId === row.id && subRow.name.toLowerCase().includes(searchTerm),
      );
      return row.name.toLowerCase().includes(searchTerm) || hasMatchingSubprojects;
    } else {
      const parentProject = tableData.find((pr) => pr.id === row.parentId);
      return (
        row.name.toLowerCase().includes(searchTerm) ||
        (parentProject && parentProject.name.toLowerCase().includes(searchTerm))
      );
    }
  });

  const handleRowClick = (row: TableRow) => {
    if (!row.isProject) {
      setSelectedSubprojectId(selectedSubprojectId === row.id ? null : row.id);
      setIsEditing(false);
    }
  };

  return (
    <DataManagementDropShadowContainer>
      {selectedBoardSetting && (
        <>
          <HeaderContainer>
            <Group gap="8px">
              {isEditingName ? (
                <TextInput
                  value={editedName}
                  onChange={(e) => setEditedName(e.currentTarget.value)}
                  onBlur={handleNameSave}
                  onKeyDown={handleNameKeyPress}
                  autoFocus
                  size="md"
                  radius="xl"
                  style={{ width: '300px', height: '24px', color: skyBase }}
                  disabled={isEditingBoardSetting}
                  rightSection={
                    <Icon
                      name="check_circle"
                      variant="outlined"
                      size={20}
                      color={skyDark}
                      style={{ cursor: isEditingBoardSetting ? 'not-allowed' : 'pointer' }}
                      onClick={handleNameSave}
                    />
                  }
                />
              ) : (
                <>
                  <Text weight="bold" size="large">
                    {selectedBoardSetting.name}
                  </Text>
                  <Icon name="edit" size={20} color={skyDark} style={{ cursor: 'pointer' }} onClick={handleNameEdit} />
                </>
              )}
            </Group>
            <Group>
              <TextInput
                placeholder="Search for Project or Board"
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value)}
                leftSection={<Icon name="search" size={16} color={skyDark} />}
                style={{ width: 250 }}
                radius="xl"
                size="xs"
              />
              {isEditing ? (
                <Button
                  variant="outline"
                  size="xs"
                  radius="xl"
                  leftSection={<Icon name="delete" size={16} color={redBase} />}
                  style={{ padding: '8px 16px', gap: '8px', borderColor: redBase, color: redBase }}
                  onClick={() => setIsDeleteModalOpen(true)}
                  disabled={false}
                >
                  <Text>Delete</Text>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="xs"
                  radius="xl"
                  leftSection={<Icon name="edit" variant="filled" size={16} color="white" />}
                  style={{ padding: '8px 16px', gap: '8px' }}
                  onClick={() => {
                    setIsEditing(true);
                    setIsEditingName(false);
                    setSelectedSubprojectId(null);
                  }}
                >
                  <Text>Edit Settings</Text>
                </Button>
              )}
            </Group>
          </HeaderContainer>
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => deleteBoardSetting(selectedBoardSetting.id)}
            title="Delete Board Setting"
            itemName={selectedBoardSetting.name}
            isDeleting={isDeleting}
            error={null}
          />
        </>
      )}
      <Divider style={{ marginTop: selectedBoardSetting ? 24 : 54, marginBottom: 24 }} />
      {selectedBoardSetting ? (
        <>
          <BoardSettingsMetadata selectedBoardSetting={selectedBoardSetting} isEditing={isEditing} />
          <Divider style={{ marginTop: 24 }} />
          {filteredData.length > 0 ? (
            <TableContainer>
              <Table
                data={filteredData}
                columns={columns}
                selectedRowId={selectedSubprojectId || undefined}
                onRowClick={handleRowClick}
                getRowId={(row) => row.id}
                stickyHeader
                isChildRow={(row) => !row.isProject}
                getParentId={(row) => row.parentId || ''}
                highlightParentRows
              />
            </TableContainer>
          ) : (
            <EmptyStateContainer>
              <Icon name="add_circle_outlined" size={40} color={skyDark} />
              <Text size="large" color={skyDark}>
                Start adding boards to your settings
              </Text>
            </EmptyStateContainer>
          )}
        </>
      ) : (
        <EmptyStateContainer>
          <Icon name="check_circle_outlined" size={40} color={skyDark} />
          <Text size="large" color={skyDark}>
            No board selected
          </Text>
        </EmptyStateContainer>
      )}
    </DataManagementDropShadowContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
