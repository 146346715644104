import { styled } from '@linaria/react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjectSprints } from '../../api/projects-client/projects-client';
import { ProjectSprintsResponse, Subproject } from '../../api/projects-client/projects-client.type';
import { SprintSummary } from '../../api/sprints-client/sprints-client.type';
import { DataFallbackMessage } from '../../components/data-fallback-message/data-fallback-message';
import { SprintHistoryListItem } from '../../components/sprint-history-list-item/sprint-history-list-item';
import { SubprojectSelector } from '../../components/subproject-selector/subproject-selector';
import { ProjectContext, UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { getSelectedSubprojectId } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const SprintHistory = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();
  const { user } = useContext(UserContext);
  const { project, subprojects } = useContext(ProjectContext);
  const [selectedSubproject, setSelectedSubproject] = useState<Subproject>();
  const [sprintSummaries, setSprintSummaries] = useState<SprintSummary[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    trackEvent(
      AnalyticsEventType.ProjectHistoryViewed,
      project ? { userContext: user, project: project } : { userContext: user },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subprojects) {
      setSelectedSubproject(subprojects.find((x: Subproject) => x.id == getSelectedSubprojectId()) ?? subprojects[0]);
    }
  }, [subprojects]);

  const queryResponse = useQuery({
    queryKey: ['project-board-sprints', project?.id],
    queryFn: () =>
      project?.id
        ? fetchProjectSprints(projectId)
        : Promise.reject('Could not fetch project sprints, no project selected'),
    enabled: !!project && !!project?.id && !sprintSummaries.length,
  } as UseQueryOptions<ProjectSprintsResponse, Error>);

  useEffect(() => {
    if (queryResponse.isSuccess) {
      const summaries: SprintSummary[] = queryResponse.data?.sprints ?? [];
      setSprintSummaries(summaries);
    } else if (queryResponse.isError && queryResponse.error) {
      const error = queryResponse.error as { response?: { data: string } };
      throw error?.response?.data || 'Error while fetching project sprints';
    }
  }, [queryResponse.isSuccess, queryResponse.isError, queryResponse.data, queryResponse.error]);

  const fallbackMessage = queryResponse.isPending
    ? 'Loading sprints...'
    : "This subproject doesn't have any sprints yet.";

  /** If any of the above api call fails the corresponding state objects are set to their default so that
   *  the entire page doesn't blow up. The below lines are a secondary check to ensure that incase of any
   *  uncaught failure we direct them blank page (Probably some error page in future) for now.
   */
  if (!project) {
    return <MenuDrivenContainer />;
  }

  const subtitle = 'Historical Trends';

  return (
    <MenuDrivenContainer>
      <div ref={ref}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
          <>
            <SprintHistoryHeader>
              <div>
                <TitleHeadingTag>
                  {subprojects && selectedSubproject
                    ? `${subprojects.find((x: Subproject) => x.id == selectedSubproject.id)?.name} Sprints`
                    : 'Sprints'}
                </TitleHeadingTag>
              </div>
              {subprojects && selectedSubproject && (
                <SubprojectSelector subprojects={subprojects} setSelectedSubproject={setSelectedSubproject} />
              )}
            </SprintHistoryHeader>
            {sprintSummaries?.length ? (
              <div style={{ backgroundColor: newCOLORS.white, padding: '1em' }}>
                <SprintHeadingTag>{subtitle}</SprintHeadingTag>
                <HistoryGrid>
                  {sprintSummaries
                    .filter((sprint: SprintSummary) => sprint.subproject_id === selectedSubproject?.id)
                    .map((summary, idx) => {
                      return <SprintHistoryListItem summary={summary} clickHandler={() => {}} key={idx} />;
                    })}
                </HistoryGrid>
              </div>
            ) : (
              <DataFallbackMessage text={fallbackMessage} />
            )}
          </>
        </div>
      </div>
    </MenuDrivenContainer>
  );
};

const SprintHeadingTag = styled.div`
  font-size: x-large;
`;

const HistoryGrid = styled.div`
  display: grid;
  width: min(100%, 100vw);
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 1em;
`;

const SprintHistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
