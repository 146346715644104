import { AxiosError, AxiosResponse } from 'axios';
import AxiosInstance from '../../../helpers/axios-instance/axios-instance';

const axiosInstance = AxiosInstance();

/**
 * Fetches risk analysis data for a specific portfolio and key
 * @param portfolioId - The ID of the portfolio to fetch risk analysis data for
 * @param key - The specific risk analysis data key/metric to retrieve
 * @returns Promise containing the risk analysis data of type T
 * @throws {AxiosError} When the API request fails
 * @throws {Error} When an unknown error occurs during the request
 */
async function getRiskAnalysisData<T = any>(portfolioId: string, key: string): Promise<T> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolioId}/risk-analysis/${key}/`;
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching risk analysis data.'));
    }
  }
}

export { getRiskAnalysisData };
