import { QueryObserverResult, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { getAllocationOfCostsChartData, getSpending } from './financials-client';
import {
  AllocationOfCostsChartRequest,
  AllocationOfCostsChartResponseByCategory,
  Spending,
} from './financials-client.type';

/**
 * Hook to fetch the allocation of cost measures data
 *
 * @param {string} portfolioId id to fetch the cost measures
 * @param {string} teamId id to fetch the cost measures
 * @param {string} subprojectId id to fetch the cost measures
 * @param {Date} startDate date to fetch the cost measures
 * @param {Date} endDate date to fetch the cost measures
 * @param options
 * @param successCallback
 * @returns {Object} Object containing the allocation of cost details
 * data and query result
 */
const useAllocationOfCostsMeasures = (
  { portfolioId, teamId, subprojectId, startDate, endDate }: AllocationOfCostsChartRequest,
  options?: UseQueryOptions<AllocationOfCostsChartResponseByCategory>,
  successCallback?: (data: AllocationOfCostsChartResponseByCategory) => void,
): {
  chartData: AllocationOfCostsChartResponseByCategory | undefined;
  query: UseQueryResult<AllocationOfCostsChartResponseByCategory>;
} => {
  const query = useQuery({
    queryKey: ['allocation-of-costs', portfolioId, teamId, subprojectId, startDate, endDate],
    queryFn: () =>
      (portfolioId || teamId) && startDate && endDate
        ? getAllocationOfCostsChartData(portfolioId, startDate, endDate, teamId, subprojectId)
        : Promise.reject('Unable to fetch allocation of costs; missing required parameters'),
    ...options,
  });

  if (query.isSuccess && query.data && successCallback) {
    successCallback(query.data);
  }

  return { chartData: query.data, query };
};

/**
 * Hook for fetching the spending data given the year and portfolioID
 *
 * @param {string} portfolioId to fetch the spending for
 * @param {number} year data for the selected year
 * @returns {Object} Object containing the spending data and query result
 */
const useSpending = ({
  portfolioId,
  year,
  options,
}: {
  portfolioId: string;
  year: number;
  options?: Partial<UseQueryOptions<Spending[]>>;
}): { data: Spending[] | undefined; query: QueryObserverResult<Spending[]> } => {
  const query = useQuery({
    queryKey: ['getSpending', portfolioId, year],
    queryFn: () => getSpending(portfolioId as string, year.toString()),
    ...options,
  });

  return { data: query.data, query };
};

export { useAllocationOfCostsMeasures, useSpending };
