import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { isLoggingEnabled, logger } from '../logger/logger';
import { State, Tab } from './data-management-store.type';

export interface DataManagementStore extends State {
  setActiveTab: (tab: Tab) => void;
}

const useDataManagementStore = create<DataManagementStore>()(
  devtools(
    persist(
      logger((set) => ({
        activeTab: Tab.DataSources,
        setActiveTab: (activeTab: Tab) => set({ activeTab }, false, '[Data Management Store] setActiveTab'),
      })),
      {
        name: 'data-management-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
    {
      name: 'Bloomfilter - Data Management Store',
      enabled: isLoggingEnabled,
    },
  ),
);

export { useDataManagementStore };
