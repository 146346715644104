import { ExternalService } from '../../../api/integrations-client/integrations-client.type';

export const integrationNameMapping = {
  [ExternalService.Jira]: 'Atlassian Jira Software Cloud',
  [ExternalService.JDC]: 'Atlassian Jira Software Data Center',
  [ExternalService.ADO]: 'Microsoft Azure',
  [ExternalService.Github]: 'GitHub',
  [ExternalService.Figma]: 'Figma',
  [ExternalService.Confluence]: 'Confluence',
  [ExternalService.Aha]: 'Aha',
  [ExternalService.Bitbucket]: 'Bitbucket',
  [ExternalService.Notion]: 'Notion',
  [ExternalService.Asana]: 'Asana',
  [ExternalService.Gitlab]: 'GitLab',
};
