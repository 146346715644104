import { styled } from '@linaria/react';
import { Flex, HoverCard } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../../api/user-client/user-client.type';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { logout } from '../../helpers/storage/storage';
import { baseBlack, inkLight, secondaryBase, skyDark } from '../../styles/design-tokens';
import { Avatar } from '../../ui-library/avatar/avatar';
import { Button } from '../../ui-library/button/button';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

const SHOW_DATA_MANAGEMENT = import.meta.env.VITE_FEATURE_FLAG_DATA_MANAGEMENT === 'true';

type Props = {
  user: UserInfo | null;
};

export function User({ user }: Props) {
  const navigate = useNavigate();

  return (
    <HoverCard position="bottom-end">
      <HoverCard.Target>
        <Avatar radius="xl" />
      </HoverCard.Target>
      {user && (
        <StyledDropdown>
          <Flex direction="column" style={{ padding: '4px 16px', width: 'auto' }}>
            <Text weight="bold" color={baseBlack}>
              {user.name}
            </Text>
            <Text weight="medium" color={skyDark}>
              {user.email}
            </Text>
          </Flex>
          <Divider />
          {SHOW_DATA_MANAGEMENT && (
            <>
              <LinkButton
                onClick={() => {
                  navigate('/application/data-management');
                }}
              >
                <Icon name="account_tree" variant="outlined" size={20} color={secondaryBase} />
                <Text lineHeight="tight" weight="medium">
                  Data Management
                </Text>
              </LinkButton>
              <Divider />
            </>
          )}
          <LinkButton
            onClick={() => {
              trackEvent(AnalyticsEventType.TermsAndConditionsViewed, { userContext: user });
              navigate('/application/legal/terms-and-conditions');
            }}
          >
            <Icon name="article" size={20} color={secondaryBase} />
            <Text lineHeight="tight" weight="medium">
              Terms and Conditions
            </Text>
          </LinkButton>
          <Divider />

          <StyledButton
            variant="outline"
            radius="xl"
            onClick={() => {
              trackEvent(AnalyticsEventType.LogoutTapped, { userContext: user });
              logout();
            }}
            leftSection={<Icon name="exit_to_app" size={16} color={inkLight} style={{ cursor: 'pointer' }} />}
          >
            <Text lineHeight="none" weight="medium">
              Sign Out
            </Text>
          </StyledButton>
        </StyledDropdown>
      )}
    </HoverCard>
  );
}

const StyledDropdown = styled(HoverCard.Dropdown)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-shadow: var(--box-shadow-big-soft);
  background-color: var(--base-white);
  border-radius: var(--border-radius-xtiny);
  box-sizing: border-box;
  width: 280px;
  min-width: 280px;
  padding: 24px 16px;
`;

const LinkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 4px 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 36px;
  padding: 8px 22px;
`;
