import { useCallback } from 'react';
import { BoardView } from '../views/board-view';
import { JDCReducerAction, JDCState } from './jdc.type';

type Props = {
  state: JDCState;
  dispatch: React.Dispatch<JDCReducerAction>;
};

export function Boards({ state, dispatch }: Props) {
  const boards = (state.project?.boards || []).map((board) => ({
    id: board.internal_id,
    name: board.name,
  }));

  const handleChange = useCallback(
    (selectedBoardId: string) => {
      const selectedBoard = (state.project?.boards || []).find((board) => board.internal_id === selectedBoardId);
      return dispatch({ type: 'board', payload: selectedBoard });
    },
    [dispatch, state.project?.boards],
  );

  return <BoardView boards={boards} selectedBoardId={state.board?.internal_id || null} onChange={handleChange} />;
}
