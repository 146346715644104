import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProjectsResponse } from '../projects-client/projects-client.type';
import { fetchPortfolioProjects } from './summary-client';

/**
 * Query manager for fetching portfolio projects.
 *
 * @param {string | undefined} portfolioId - The ID of the portfolio.
 * @param {UseQueryOptions<ProjectsResponse[]>} options - Additional options for the useQuery hook.
 * @return {{ projects: ProjectsResponse[], query: UseQueryResult<ProjectsResponse[]> }} - The fetched portfolio projects and the query.
 */
const usePortfolioProjects = (
  portfolioId: string | undefined,
  options?: Omit<UseQueryOptions<ProjectsResponse[]>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery({
    queryKey: ['portfolioProjects', portfolioId],
    queryFn: () => {
      if (!portfolioId) {
        throw new Error('Portfolio ID is required');
      }
      return fetchPortfolioProjects(portfolioId);
    },
    enabled: !!portfolioId,
    ...options,
  });

  return { projects: query.data, query };
};

export { usePortfolioProjects };
