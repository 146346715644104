import { Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../api/auth-client/auth-client';
import { checkValidEmail } from '../../../helpers';
import { baseWhite, inkLight, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { Layout } from '../layout';

export const ResetPasswordForm = () => {
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (checkValidEmail(value) ? null : 'Please provide a valid email.'),
    },
  });

  const resetPasswordMutation = useMutation({
    mutationKey: ['reset-password', form.values.email] as const,
    mutationFn: async (email: string) => {
      if (!email) {
        throw new Error('Email is required!');
      }
      return resetPassword(email);
    },
    onSuccess: () => {
      navigate('/reset-password-success');
    },
    onError: () => {
      navigate('/reset-password-success');
    },
  });

  const handleSubmit = async () => {
    try {
      await resetPasswordMutation.mutateAsync(form.values.email);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={32}>
          <Group gap={16}>
            <Icon name="key" size={32} />
            <H3>Forgot Your Password?</H3>
          </Group>
          <Text>
            Don’t worry! We’ll help you reset it quickly.
            <br />
            Enter the email associated with your Bloomfilter account.
          </Text>
          <Stack gap={24} role="form" aria-label="Forgot Password Form">
            <Divider />
            <TextInput
              label="Email"
              placeholder="Enter your email address"
              value={form.values.email}
              onChange={(event) => form.setFieldValue('email', event.currentTarget.value.trim())}
              error={form.errors.email}
              w="100%"
              autoComplete="email"
              required
            />
          </Stack>
          <Divider />
          <Stack gap={24} align="center">
            <Button
              type="submit"
              loaderProps={{ size: 'xs', color: secondaryBase }}
              w="100%"
              radius="xl"
              rightSection={<Icon name="arrow_forward" size={18} color={baseWhite} />}
            >
              Reset Password
            </Button>
            <Text size="small">Remember your password?</Text>
            <Button
              variant="outline"
              onClick={() => navigate('/')}
              w="100%"
              radius="xl"
              rightSection={<Icon name="login" size={18} color={inkLight} />}
            >
              Log in to Bloomfilter
            </Button>
          </Stack>
        </Stack>
      </form>
    </Layout>
  );
};
