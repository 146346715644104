import { UseMutationResult } from '@tanstack/react-query';

type SignUpPayload = {
  email: string;
  name?: string;
  password: string;
};

type LoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = {
  access?: string;
  refresh?: string;
  email?: string[];
  password?: string[];
  code?: string;
  detail?: string;
};

type RefreshResponse = {
  access: string;
  refresh: string;
};

type DomainCheckResponse = {
  url?: string;
  authentication_method: string;
  organization_id?: string;
};

type ResetPasswordResponse = number;
type SetPasswordResponse = number;

type OTPResponse = {
  message: string;
};

type LoginMutation = UseMutationResult<
  LoginResponse,
  { error: { detail: string } },
  { email: string | null; password: string | null }
>;

enum BackendError {
  AuthenticationFailed = 'If you are an first time user, please sign up',
  InvalidCredentials = 'No active account found with the given credentials',
  OTPNotVerified = 'The user is not OTP verified',
  UnexpectedError = 'An unexpected error occurred',
}

export { BackendError };
export type {
  DomainCheckResponse,
  LoginMutation,
  LoginRequest,
  LoginResponse,
  OTPResponse,
  RefreshResponse,
  ResetPasswordResponse,
  SetPasswordResponse,
  SignUpPayload,
};
