import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSpending } from '../../api/financials-client/financials-client';
import { Spending } from '../../api/financials-client/financials-client.type';
import { Team } from '../../api/projects-client/projects-client.type';
import { AddFinancialData } from '../../components/add-financial-data/add-financial-data';
import { FinancialsCharts } from '../../components/financials-charts/financials-charts';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../components/page-header-message/page-header-message';
import { BoardContext, TeamContext } from '../../contexts';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { getFinancialsEndDate, getFinancialsStartDate } from '../../helpers/storage/storage';
import { useGlobalStore } from '../../store/global-store/global-store';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { getMessageText, getYearsBetween } from './financials.helper';
import { FinancialsHeader } from './finanicals-header';

export const Financials = () => {
  useDocumentTitle('Financials - Bloomfilter');
  const navigate = useNavigate();
  const portfolio = useGlobalStore((state) => state.portfolio);
  const teams = useGlobalStore((state) => state.teams);
  const { team } = useContext(TeamContext);
  const { board } = useContext(BoardContext);
  const [nullExperience, setNullExperience] = useState(true);
  const [messageText, setMessageText] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(getFinancialsStartDate());
  const [endDate, setEndDate] = useState<Date | null>(getFinancialsEndDate());

  const getSpendingQuery = useQuery({
    queryKey: ['getSpending', portfolio?.id] as const,
    queryFn: async () => {
      if (!portfolio?.id) {
        throw new Error('Missing Portfolio ID');
      }
      return getSpending(portfolio.id, getYearsBetween());
    },
    enabled: !!portfolio?.id,
  });

  useEffect(() => {
    // Rules:
    //  Portfolio Has Spending
    //      Aggregate Selected - Show Chart
    //      Team Selected
    //          Team Has Spending - Show Chart
    //          Team Has No Spending - Navigate to Edit Financials
    //  Portfolio Has No Spending - Show Add Financials (that will subsequently navigate to Edit Financials)

    // We have any spending data for the portfolio
    if (getSpendingQuery.data && getSpendingQuery.data.length > 0) {
      // A team was selected and we have no spending data for that team
      if (team && team?.id !== 'aggregate' && !getSpendingQuery.data.some((item) => item.project_id === team?.id)) {
        // navigate to the upsert spending page
        navigate(`/application/financials/portfolio/${portfolio?.id}/add-edit-financials`, {
          state: { from: 'application' },
        });
      } else {
        // A team was selected and we have spending data
        // show the charts
        setNullExperience(false);
      }
    } else if (
      board &&
      board?.id !== 'aggregate' &&
      !getSpendingQuery.data?.some((item) => item.subproject_id === board?.id)
    ) {
      // navigate to the upsert spending page
      navigate(`/application/financials/portfolio/${portfolio?.id}/add-edit-financials`, {
        state: { from: 'application' },
      });
    } else {
      // we dont have spending data back yet or we have no spending data for the portfolio
      setNullExperience(true);
    }
  }, [getSpendingQuery.data, team, setNullExperience, navigate, portfolio, board]);

  // Below is the update the message in the header based on the data we have
  useEffect(() => {
    setMessageText(getMessageText(team as Team, getSpendingQuery.data as Spending[], teams.length || 0));
  }, [team, getSpendingQuery.data, teams, startDate, endDate]);

  return (
    <MenuDrivenContainer header={<FinancialsHeader nullExperience={nullExperience} />}>
      <BrandedLoadingOverlay visible={getSpendingQuery.isPending} transitionDuration={30} variant="colored" />
      {messageText ? <PageHeaderMessage message={messageText} color="yellow"></PageHeaderMessage> : null}
      <FinancialsContainer>
        {nullExperience ? (
          <AddFinancialData />
        ) : (
          <FinancialsCharts
            spendingQuery={getSpendingQuery}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
      </FinancialsContainer>
    </MenuDrivenContainer>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
