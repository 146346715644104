import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createOrganization } from '../../api/organization-client/organization-client';
import { useOrganizations } from '../../api/organization-client/organization-client.hooks';
import { usePortfolios } from '../../api/portfolio-client/portfolio-client.hooks';
import { editMe } from '../../api/user-client/user-client';
import { Brand } from '../../components/brand/brand';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { setPortfolio } from '../../store/global-store/global-store.actions';
import { CompanyInformation, CreateProfile, TeamInformation, TermsOfService } from './index';
import styles from './setup.module.css';
import { CreateOrgMutationVariables, EditMeMutationVariables, ReducerAction, ReducerState } from './setup.types';

type MenuItemProps = {
  title: string;
  currentTab: string;
  onClick: Function;
  disabled?: boolean;
};

function MenuItem({ title, currentTab, onClick, disabled }: MenuItemProps) {
  const selected = title === currentTab;
  return disabled ? (
    <li className={styles.disabledMenuItem}>{title}</li>
  ) : (
    <li className={`${styles.menuItem} ${selected ? styles.selectedMenuItem : ''}`} onClick={() => onClick(title)}>
      {title}
    </li>
  );
}

function setupReducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case 'user':
      return { ...state, user: { ...state.user, ...action.payload } };
    case 'company':
      return { ...state, company: { ...state.company, ...action.payload } };
  }
}

export function Setup() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('create profile');
  const [state, dispatch] = useReducer(setupReducer, { user: {}, company: {} });

  const STEPS = ['create profile', 'company information', 'terms of service', 'my team'];

  const handleBackNext = (buttonType: string) => {
    const currentStep = STEPS.indexOf(currentTab);
    const newStep = buttonType === 'next' ? currentStep + 1 : currentStep - 1;
    setCurrentTab(STEPS[newStep]);
  };

  const { portfolios } = usePortfolios();
  const { query: organizationsQuery } = useOrganizations();

  useEffect(() => {
    if (portfolios.length > 0) {
      setPortfolio(portfolios[0]);
    }
  }, [portfolios]);

  const createOrgMutation = useMutation({
    mutationKey: ['createOrg'] as const,
    mutationFn: async ({ userId, company }: CreateOrgMutationVariables) =>
      createOrganization({
        organization_name: company.name,
        organization_industry: company.industry,
        users: [userId],
        ...company,
      }),
    onSuccess: async () => {
      await organizationsQuery.refetch();
    },
  });

  const editMeMutation = useMutation({
    mutationKey: ['editMe'] as const,
    mutationFn: async ({ userId, user, company }: EditMeMutationVariables) => {
      const result = await editMe({ id: userId, phone_number: user.phoneNumber, ...user });
      return { data: result, company };
    },
    onSuccess: async ({ data, company }, variables) => {
      userContext.setUser(data);
      Sentry.setUser({ email: data.email || '' });
      await createOrgMutation.mutateAsync({ userId: variables.userId, company });
    },
  });

  const handleSubmit = async () => {
    if (!userContext.user) {
      return;
    }

    const { id: userId } = userContext.user;
    const { user = {}, company = {} } = state;

    // Update user first, and then onsuccess update org
    // We do this because there is a race condition on the backend
    // where we immediately read the user data after it is updated by
    // the call below
    await editMeMutation.mutateAsync({ userId, user, company });

    // mixpanel telemetry
    trackEvent(AnalyticsEventType.SetupFinished, { userContext: user });

    // proceed to the integrations page
    navigate('/application/integrations');
  };

  const profileStepComplete = state.user?.name && state.user?.timezone && state.user?.profession;
  const companyStepComplete = state.company?.name && state.company?.industry && state.company?.size;
  const subscriptionStepComplete = state.user?.preferredPayment;

  return (
    <div id={styles.pageContainer}>
      <header id={styles.header} className={styles.flexRowCenter}>
        <div className={styles.flexColumnCenter}>
          <Brand onClick={() => {}} />
          <menu id={styles.menu}>
            <MenuItem title="create profile" currentTab={currentTab} onClick={setCurrentTab} />
            <MenuItem
              title="company information"
              currentTab={currentTab}
              onClick={setCurrentTab}
              disabled={!profileStepComplete}
            />
            <MenuItem
              title="terms of service"
              currentTab={currentTab}
              onClick={setCurrentTab}
              disabled={!companyStepComplete}
            />
            <MenuItem
              title="my team"
              currentTab={currentTab}
              onClick={setCurrentTab}
              disabled={!subscriptionStepComplete}
            />
          </menu>
        </div>
      </header>
      <main id={styles.contentContainer}>
        {currentTab === 'create profile' ? (
          <CreateProfile user={state.user} dispatch={dispatch} onClick={handleBackNext} />
        ) : null}
        {currentTab === 'company information' ? (
          <CompanyInformation user={state.user} company={state.company} dispatch={dispatch} onClick={handleBackNext} />
        ) : null}
        {currentTab === 'terms of service' ? (
          <TermsOfService user={state.user} dispatch={dispatch} onClick={handleBackNext} />
        ) : null}
        {currentTab === 'my team' ? (
          <TeamInformation user={state.user} dispatch={dispatch} onClick={handleBackNext} onSubmit={handleSubmit} />
        ) : null}
      </main>
    </div>
  );
}
