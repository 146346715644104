enum WorkPeriodScope {
  ProcessAnalysis = 'process_analysis',
  Financials = 'financials',
}

type MeasureMetadata = {
  measure_name: Measure;
  measure_title: string;
  measure_group: string;
  measure_description: string;
  measure_units: MeasureUnits;
  is_zero_valid: boolean;
  is_custom?: boolean;
};

type MeasureDetail = {
  name: string;
  title: string;
  group: string;
  description: string;
  units: string | null;
  purpose: string;
  is_zero_valid: boolean;
  is_custom: boolean;
};

enum MeasureUnits {
  Hours = 'Hours',
  Days = 'Days',
  Number = 'Number',
  Tasks = 'Tasks',
  Points = 'Points',
  Percentage = 'Percentage',
}

enum WorkPeriodTense {
  Future = 'future',
  Past = 'past',
  Present = 'present',
}

enum Measure {
  AuditLog = 'audit_log',
  BulkStatusChanges = 'bulk_status_changes',
  Burns = 'burns',
  Complexity = 'complexity',
  Custom = 'custom',
  CycleTime = 'cycle_time',
  CycleTimeInconsistency = 'cycle_time_inconsistency',
  DeclinedChangeRequests = 'declined_change_requests',
  FlowByPace = 'flow_by_pace',
  FlowByPhase = 'flow_by_phase',
  HealthScores = 'health_scores',
  HistoricalBurns = 'historical_burns',
  InitiativesFocus = 'initiatives_focus',
  Independence = 'independence',
  LeadTime = 'lead_time',
  MissingDays = 'missing_days',
  MultiplePointChangesPerTask = 'multiple_point_changes_per_task',
  Quality = 'quality',
  PointsAcceptanceRate = 'points_acceptance_rate',
  ReactionTime = 'reaction_time',
  Readiness = 'readiness',
  ReviewTime = 'review_time',
  ScopeCreep = 'scope_creep',
  StaleTasks = 'stale_tasks',
  Strategy = 'strategy',
  TaskFlow = 'task_flow',
  TasksAcceptanceRate = 'tasks_acceptance_rate',
  TasksMovingBackwards = 'tasks_moving_backwards',
  TechDebt = 'tech_debt',
  Throughput = 'throughput',
  UntestedTasks = 'untested_tasks',
  Velocity = 'velocity',
  WorkPlanInconsistency = 'work_plan_inconsistency',
}

enum Transformer {
  CumulativeSingularValue = 'cumulative_singular_value',
  LastValueIndexed = 'last_value_indexed',
  SingleFractionalValue = 'single_fractional_value',
  SingleLastNonNull = 'single_last_not_null',
  TimeAllocationValuesOnly = 'time_allocation_values_only',
  ReturnFirstMetricOnly = 'return_first_metric_only',
}

enum EntityType {
  Portfolio = 'portfolio',
  Project = 'project',
  Subproject = 'subproject',
}

enum TimeAllocationType {
  Daily = 'daily',
  Weekly = 'weekly',
  BiWeekly = 'biweekly',
  Monthly = 'monthly',
}

type TimeAllocation = {
  id: string;
  name: string;
  start: string;
  end: string;
};

type WorkPeriod = {
  id: string;
  name: string;
  parent: string | null;
  subproject: string;
  external_id: string;
  start_date: string;
  end_date: string;
  time_tense: WorkPeriodTense;
};

type GetAvailableMeasuresPayload = {
  scope: WorkPeriodScope;
  portfolio_id?: string;
  project_id?: string;
  subproject_id?: string;
};

type GetTimeAllocationsPayload = {
  start_date: string;
  end_date: string;
  time_allocation_type: TimeAllocationType;
};

type AvailableMeasuresResponse = {
  [key in WorkPeriodScope]: {
    measures: MeasureMetadata[];
  };
};

type GetWorkPeriodsPayload = {
  subproject_id: string | null;
  parent?: string;
  external_id?: string;
  time_span?: string;
  time_tense?: string;
};

type GetWorkPeriodsMeasurePayload = {
  work_period_id?: string;
  subproject_id?: string;
  project_id?: string;
  portfolio_id?: string;
  start_date: string;
  end_date: string;
  measure_name: Measure[] | string[];
  time_allocation_type?: TimeAllocationType;
  transformer?: string;
  force_recalculation?: boolean;
  measure_filter?: string;
  is_custom?: boolean;
  enabled?: boolean;
  staleTime?: number;
};

type UseWorkPeriodsMeasurePayloadMultiple = {
  entity: EntityType;
  ids: string[];
  start_date: string;
  end_date: string;
  measure_name: Measure[] | string[];
  time_allocation_type?: TimeAllocationType;
  transformer?: string;
  force_recalculation?: boolean;
  is_custom?: boolean;
};

type UseWorkPeriodsMeasurePayloadSingular = {
  subproject_id?: string;
  work_period_id?: string;
  project_id?: string;
  portfolio_id?: string;
  start_date: string;
  end_date: string;
  measure_name: Measure[];
  time_allocation_type?: TimeAllocationType;
  transformer?: string;
  force_recalculation?: boolean;
  measure_filter?: string;
  queryKey?: readonly unknown[];
  enabled?: boolean;
  staleTime?: number;
  onError?: (error: Error) => void;
  is_custom?: boolean;
};

type FlowByPhaseData = {
  tasks_wip: number;
  points_wip: number;
  tasks_done: number;
  tasks_test: number;
  points_done: number;
  points_test: number;
  tasks_review: number;
  points_review: number;
  tasks_backlog: number;
  tasks_blocked: number;
  points_backlog: number;
  points_blocked: number;
  tasks_deployable: number;
  points_deployable: number;
};
type FlowByPhaseDataOverTime = Record<string, FlowByPhaseData>;

enum PaceStatus {
  OnTrack = 'On Track',
  AtRisk = 'At Risk',
  OffTrack = 'Off Track',
}
type FlowByPaceData = {
  date: string;
  points_complete: number;
  points_doing: number;
  points_ideal: number;
  points_original_plan: number;
  points_remaining: number;
  points_status: PaceStatus;
  points_total: number;
  tasks_complete: number;
  tasks_doing: number;
  tasks_ideal: number;
  tasks_original_plan: number;
  tasks_remaining: number;
  tasks_status: PaceStatus;
  tasks_total: number;
  weekend_or_holiday: boolean;
};
type FlowByPaceDataOverTime = Record<string, FlowByPaceData>;

type HealthScoreData = {
  health_scores: {
    score_by_tasks: number;
    score_by_points: number;
  };
};

type BurnsData = {
  date: string;
  tasks_total: number;
  tasks_complete: number;
  points_total: number;
  points_complete: number;
  weekend_or_holiday: boolean;
};
type BurnsDataOverTime = Record<string, BurnsData>;

type HistoricalBurnsData = {
  date: string;
  tasks: { average: number; y: number; y0: number };
  points: { average: number; y: number; y0: number };
};
type HistoricalBurnsDataOverTime = Record<string, HistoricalBurnsData>;

type MeasureValuesOverTime = Record<string, number>;
type MeasureDataResponse = Record<Measure, MeasureValuesOverTime>;
type MeasureDataByEntity = Record<string, MeasureDataResponse>;
type CustomMeasureDataResponse = Record<string, MeasureValuesOverTime>;
type CustomMeasureDataByEntity = Record<string, CustomMeasureDataResponse>;

export {
  EntityType,
  Measure,
  MeasureUnits,
  PaceStatus,
  TimeAllocationType,
  Transformer,
  WorkPeriodScope,
  WorkPeriodTense,
};
export type {
  AvailableMeasuresResponse,
  BurnsData,
  BurnsDataOverTime,
  CustomMeasureDataByEntity,
  CustomMeasureDataResponse,
  FlowByPaceData,
  FlowByPaceDataOverTime,
  FlowByPhaseData,
  FlowByPhaseDataOverTime,
  GetAvailableMeasuresPayload,
  GetTimeAllocationsPayload,
  GetWorkPeriodsMeasurePayload,
  GetWorkPeriodsPayload,
  HealthScoreData,
  HistoricalBurnsData,
  HistoricalBurnsDataOverTime,
  MeasureDataByEntity,
  MeasureDataResponse,
  MeasureMetadata,
  MeasureDetail,
  MeasureValuesOverTime,
  TimeAllocation,
  UseWorkPeriodsMeasurePayloadMultiple,
  UseWorkPeriodsMeasurePayloadSingular,
  WorkPeriod,
};
