import { styled } from '@linaria/react';
import { newCOLORS } from '../../../styles/colors';
import { secondaryLight, skyLightest } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from './data-management-header';

type PanelListProps<T> = {
  title: string;
  items: T[];
  selectedItem: T | null;
  onSelect: (item: T) => void;
  onAddNew: () => void;
  isLoading?: boolean;
  getItemId: (item: T) => string;
  getItemDisplayText: (item: T) => string;
};

export function PanelList<T>({
  title,
  items,
  selectedItem,
  onSelect,
  onAddNew,
  isLoading = false,
  getItemId,
  getItemDisplayText,
}: PanelListProps<T>) {
  return (
    <PanelListContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          {title}
        </Text>
        <Button
          variant="primary"
          size="xs"
          radius="xl"
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={onAddNew}
          loading={isLoading}
          disabled={isLoading}
        >
          <Icon size={16} name="add_circle_outlined" color={newCOLORS.white} />
          <Text>Add New</Text>
        </Button>
      </HeaderContainer>
      <Divider style={{ marginTop: 24, marginBottom: 16 }} />
      <ItemListContainer>
        {items.map((item) => (
          <PanelItem
            key={getItemId(item)}
            isSelected={selectedItem ? getItemId(selectedItem) === getItemId(item) : false}
            onClick={() => onSelect(item)}
          >
            <Text>{getItemDisplayText(item)}</Text>
          </PanelItem>
        ))}
      </ItemListContainer>
    </PanelListContainer>
  );
}

const PanelListContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
`;

const ItemListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const PanelItem = styled.div<{ isSelected: boolean }>`
  border-radius: 32px;
  border: 1px solid ${(props) => (props.isSelected ? secondaryLight : 'transparent')};
  background: ${(props) => (props.isSelected ? skyLightest : 'transparent')};
  display: flex;
  height: 36px;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
  &:hover {
    background: ${skyLightest};
  }
  margin-bottom: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
