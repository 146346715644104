import { styled } from '@linaria/react';
import { Group, TextInput } from '@mantine/core';
import { useState } from 'react';
import { IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { skyDark } from '../../../../styles/design-tokens';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { Table, TableColumn } from '../table';
import { DataSourceTableHeader } from './data-source-table-header';
import { createIntegrationTypeData, filterTableData } from './data-sources-panel.helpers.tsx';
import { DataSourcesPanelProps, DataSourceTableRow } from './data-sources-panel.types';

export function DataSourcesPanel({
  selectedDataSource,
  integrationEntries,
  selectedProjectId,
  selectedSubprojectId,
  setSelectedProjectId,
  setSelectedSubprojectId,
}: DataSourcesPanelProps) {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [search, setSearch] = useState('');
  const [collapsedSections, setCollapsedSections] = useState<Set<string>>(new Set());

  const integrationTypeData = createIntegrationTypeData(integrationEntries, expandedRows);

  const handleDropdownClick = (row: DataSourceTableRow) => {
    if (row.isBoard) return;
    setSelectedProjectId(null);
    setSelectedSubprojectId(null);
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(row.id)) {
        newSet.delete(row.id);
      } else {
        newSet.add(row.id);
      }
      return newSet;
    });
  };

  const handleRowClick = (row: DataSourceTableRow) => {
    if (row.isBoard) {
      setSelectedProjectId(null);
      setSelectedSubprojectId(selectedSubprojectId === row.id ? null : row.id);
    } else {
      setSelectedSubprojectId(null);
      setSelectedProjectId(selectedProjectId === row.id ? null : row.id);
    }
  };

  const toggleSection = (type: string) => {
    setCollapsedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(type)) {
        newSet.delete(type);
      } else {
        newSet.add(type);
      }
      return newSet;
    });
  };

  const columns: TableColumn<DataSourceTableRow>[] = [
    {
      key: 'projectName',
      label: 'Project Key-Name',
      width: '30%',
      render: (_value: DataSourceTableRow[keyof DataSourceTableRow], row: DataSourceTableRow) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 8 }}>
          {!row.isBoard && (
            <div
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleDropdownClick(row);
              }}
              style={{ display: 'flex', cursor: 'pointer' }}
            >
              <Icon name={row.isExpanded ? 'expand_more' : 'chevron_right'} size={20} />
            </div>
          )}
          <Text weight="medium">{row.projectName}</Text>
        </div>
      ),
      sortable: true,
    },
    { key: 'totalBoards', label: 'Total Boards', width: '15%', sortable: true },
    { key: 'owner', label: 'Owner', width: '15%', sortable: true },
    { key: 'lastUpdated', label: 'Last Updated', width: '20%', sortable: true },
    {
      key: 'upToDate',
      label: 'Up to Date',
      width: '15%',
      sortable: true,
      render: (_value: DataSourceTableRow[keyof DataSourceTableRow], row: DataSourceTableRow) => row.upToDate,
    },
  ];

  const renderTable = (data: DataSourceTableRow[]) => {
    const tableData = filterTableData(data, search);
    return (
      <Table
        data={tableData}
        columns={columns}
        selectedRowId={selectedProjectId || selectedSubprojectId || undefined}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        stickyHeader
        isChildRow={(row) => Boolean(row.isBoard)}
        getParentId={(row) => row.parentId || ''}
      />
    );
  };

  return (
    <DataManagementDropShadowContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          Data Sources
        </Text>
        <Group>
          <TextInput
            placeholder="Search for Project or Board"
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            leftSection={<Icon name="search" size={16} color={skyDark} />}
            style={{ width: 250 }}
            radius="xl"
            size="xs"
          />
        </Group>
      </HeaderContainer>
      <Divider style={{ marginTop: 24 }} />
      <TablesContainer>
        {selectedDataSource ? (
          <TableSection>
            <DataSourceTableHeader
              type={selectedDataSource}
              isCollapsed={collapsedSections.has(selectedDataSource)}
              onToggle={() => toggleSection(selectedDataSource)}
            />
            {!collapsedSections.has(selectedDataSource) && (
              <TableContainer>{renderTable(integrationTypeData[selectedDataSource] || [])}</TableContainer>
            )}
          </TableSection>
        ) : (
          Object.entries(integrationTypeData).map(([type, data]) => (
            <TableSection key={type}>
              <DataSourceTableHeader
                type={type as IntegrationType}
                isCollapsed={collapsedSections.has(type)}
                onToggle={() => toggleSection(type)}
              />
              {!collapsedSections.has(type) && (
                <TableContainer>{renderTable(data as DataSourceTableRow[])}</TableContainer>
              )}
              {Object.keys(integrationTypeData).indexOf(type) !== Object.keys(integrationTypeData).length - 1 && (
                <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              )}
            </TableSection>
          ))
        )}
      </TablesContainer>
    </DataManagementDropShadowContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  margin-top: 16px;
`;

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;
