import { useGlobalStore } from './global-store';

/**
 * Hook to get the current organization ID from the global store
 *
 * @returns {string | null} The organization ID if available, null otherwise
 */
const useOrgId = (): string | null => {
  const organization = useGlobalStore((state) => state.organization);

  return organization?.id ?? null;
};

export { useOrgId };
