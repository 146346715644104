import { Radio } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { UserInfo } from '../../../api/user-client/user-client.type';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { trackEvent } from '../../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../../helpers/analytics-event/analytics-event.type';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';
import styles from '../views/views.module.css';
import { GithubReducerAction, GithubState } from './github.type';

type OrganizationsProps = {
  state: GithubState;
  dispatch: Dispatch<GithubReducerAction>;
  user: UserInfo | null;
};

/** The view for selecting organization during the github integration workflow.
 *
 * @param props: { state: GithubState, dispatch: Dispatch<GithubReducerAction> }
 * @returns JSX.Element
 */
export function Organizations({ state, dispatch, user }: OrganizationsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<string[]>([]);
  const { redirect, setRedirect } = useRedirect();
  const { query: integrationsQuery } = useContext(IntegrationsContext);
  const { options } = state;

  useEffect(() => {
    // If the user cancels Jira auth (or if there is an error), navigate back to the integrations page
    const error = searchParams.get('error');
    const validParams = searchParams.get('code') && searchParams.get('state');
    const notInitialized = !validParams && !state.initialized;

    if (error || notInitialized) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate, state.initialized]);

  useEffect(() => {
    setOrganizations(Object.keys(options?.organizationWithRepos) ?? []);
  }, [options]);

  const completeOauthQuery = useQuery({
    queryKey: ['oauthGithub', user] as const,
    queryFn: async () => {
      const code = searchParams.get('code');
      const tempStates = searchParams.get('state')?.split(',');
      const state = tempStates ? tempStates[0] : '';
      const orgId = tempStates ? tempStates[1] : '';

      setSearchParams(searchParams);

      if (!user?.id || !orgId || !code) {
        throw new Error('Missing required parameters');
      }

      const data = await completeOauth(ExternalService.Github, user.id.toString(), orgId, code, state);

      return data;
    },
    enabled: !!searchParams.get('code') && !!user,
  });

  const handleSuccess = useCallback(
    (data: Record<string, unknown>) => {
      const orgs = Object.keys(data);
      setOrganizations(orgs);
      dispatch({ type: 'options', payload: { organizationWithRepos: data } });
      dispatch({ type: 'init' });
      trackEvent(AnalyticsEventType.GithubIntegrationTapped, { userContext: user });
    },
    [dispatch, user],
  );

  useEffect(() => {
    if (completeOauthQuery.isSuccess && completeOauthQuery.data) {
      handleSuccess(completeOauthQuery.data);
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.data, handleSuccess]);

  const handleSettled = useCallback(() => {
    searchParams.delete('code');
    searchParams.delete('state');
    setSearchParams(searchParams);

    if (redirect) {
      completeReauth();
      navigate(redirect);
      integrationsQuery.refetch();
      setRedirect(null);
    }
  }, [redirect, navigate, integrationsQuery, setRedirect, searchParams, setSearchParams]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess || completeOauthQuery.isError) {
      handleSettled();
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.isError, handleSettled]);

  /** Handles the change of the org selection.
   *
   * @param org: string - the selected org
   */
  function handleChange(org: string) {
    try {
      dispatch({ type: 'organization', payload: org });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return (
    <div id={styles.viewContainer}>
      <BrandedLoadingOverlay visible={organizations && completeOauthQuery.isLoading} variant="colored" />
      {!completeOauthQuery.isLoading && organizations.length === 0 ? (
        <PageHeaderMessage
          message="We couldn't find any organizations for your account."
          color="yellow"
          navigationMessage="Make sure you've installed our Github app for your organization."
          navigationPath="https://github.com/marketplace/bloomfilter-platform"
        />
      ) : (
        <>
          {!redirect && (
            <>
              <h4>Select a organization whose repository you would like to integrate with:</h4>
              <div id={styles.grid}>
                {(organizations || []).map((org: string) => (
                  <div
                    key={org}
                    className={
                      org === state.organization
                        ? `${styles.checkboxContainer} ${styles.checkboxContainerChecked}`
                        : styles.checkboxContainer
                    }
                    onClick={() => handleChange(org)}
                  >
                    <Radio
                      color="red.5"
                      styles={{ label: { fontWeight: 600, marginLeft: '30px' } }}
                      value={org}
                      checked={org === state.organization}
                    />
                    <span className={styles.checkboxLabel}>{org}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
