import { getRequestToken } from '../../helpers/storage/storage';

/**
 * Creates an agent websocket connection
 * @param organization_id: the id of the organization to connect to
 * @returns {WebSocket} - a websocket connection
 */
function createAgentWebsocket(organization_id: string): WebSocket {
  let baseURL = import.meta.env.VITE_APP_API_URL + '/ws/agents';
  baseURL = baseURL.replace('http', 'ws').replace('https', 'wss');
  const url = baseURL + '/' + organization_id + `/?token=${getRequestToken()}`;
  return new WebSocket(url);
}

/**
 * Ask the agent a question via websocket
 * @param websocket: WebSocket - an active websocket connection
 * @param message: String - the string to send to the agent
 * @param thread_id: String - an active thread id if one exists
 * @returns {void}
 */
function askAgent(webSocket: WebSocket | undefined, message: string, thread_id: string): void {
  webSocket?.send(
    JSON.stringify({
      message,
      thread_id,
    }),
  );
}

/**
 * Get the chat histories from the agent
 * @param webSocket: WebSocket - an active websocket connection
 * @returns {void}
 */
function getChatHistories(webSocket: WebSocket | undefined): void {
  webSocket?.send(
    JSON.stringify({
      type: 'fetch_histories',
    }),
  );
}

/**
 * Delete a chat history from the agent
 * @param webSocket: WebSocket - an active websocket connection
 * @param id: String - the id of the chat history to delete
 * @returns {void}
 */
function deleteChatHistory(webSocket: WebSocket | undefined, id: string): void {
  webSocket?.send(
    JSON.stringify({
      type: 'delete_history',
      thread_id: id,
    }),
  );
}

/**
 * Edit the description of a chat history
 * @param webSocket: WebSocket - an active websocket connection
 * @param id: String - the id of the chat history to edit
 * @param description: String - the new description of the chat history
 * @returns {void}
 */
function editChatHistoryDescription(webSocket: WebSocket | undefined, id: string, description: string): void {
  webSocket?.send(
    JSON.stringify({
      type: 'edit_history_description',
      thread_id: id,
      description,
    }),
  );
}

export { askAgent, createAgentWebsocket, deleteChatHistory, editChatHistoryDescription, getChatHistories };
