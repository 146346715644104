import { Icon } from '@iconify/react/dist/iconify.js';
import { icons } from '../../assets/icons/icons';

import { styled } from '@linaria/react';
import { Collapse, Loader, Tooltip } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCostByInitiativeChartData } from '../../api/financials-client/financials-client';
import { CostByInitiativeResponse } from '../../api/financials-client/financials-client.type';
import { ChartContainer } from '../../containers/financials/financials.styled';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import { SmallerHeading, StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { DEFAULT_COLORS } from '../allocation-of-costs/allocation-of-costs';

import { BoardContext } from '../../contexts/index.tsx';
import { DownloadPNGButton } from '../download-png-button';
import { getScopeNameSuffix } from '../edit-financial-data/edit-financial-data.helper';
import { FinancialsScope } from '../financials-scope/financials-scope.type';
import { CostByInitiativeChart } from './cost-by-initiative-chart';
import { CostByInitiativeItemList } from './cost-by-initiative-item-list';
import {
  initShowCostByInitiativeChartBar,
  onAddInitiativeClick,
  updateShowCostByInitiativeChartBar,
} from './cost-by-initiative.helpers';
import { ShowCostByInitiativeChartBar } from './cost-by-initiative.type';

export const CostByInitiative = ({ startDate, endDate, portfolio, team, queryEnabled }: FinancialsScope) => {
  const [opened, { toggle }] = useDisclosure(true);
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const [chartData, setChartData] = useState<CostByInitiativeResponse[] | null>(null);

  const { board } = useContext(BoardContext);

  const [availableColors, setAvailableColors] = useState<string[]>(Object.assign([], DEFAULT_COLORS));
  const [showCostByInitiativeChartBar, setShowCostByInitiativeChartBar] = useState<ShowCostByInitiativeChartBar[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const errorRef = useClickOutside(() => setShowError(false));

  const navigate = useNavigate();

  useEffect(() => {
    setAvailableColors(Object.assign([], DEFAULT_COLORS));
  }, [startDate, endDate, portfolio, team]);

  const { teamId = '', subprojectId = '' } = useParams<{
    portfolioId: string;
    teamId: string;
    subprojectId: string;
  }>();

  const getCostByInitiativeDataQuery = useQuery<CostByInitiativeResponse[]>({
    queryKey: ['getCostByInitiativeData', startDate, endDate, portfolio, teamId, subprojectId] as const,
    queryFn: async () => {
      if (!portfolio || !startDate || !endDate) {
        throw new Error('Missing required parameters');
      }
      const data = await getCostByInitiativeChartData(portfolio.id, startDate, endDate, teamId, subprojectId);
      if (data) {
        setChartData(data);
        initShowCostByInitiativeChartBar(data, setShowCostByInitiativeChartBar, availableColors);
      }
      return data;
    },
    enabled: queryEnabled && !!(portfolio && startDate && endDate),
  });

  const updateShowChartLines = (checked: boolean, id: string, currentColor: string) => {
    updateShowCostByInitiativeChartBar(
      checked,
      id,
      currentColor,
      availableColors,
      setShowError,
      setAvailableColors,
      setShowCostByInitiativeChartBar,
    );
  };

  return (
    <CostByInitiativeContainer ref={downloadRef}>
      <CollapseHeader>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <CollapseIcon>
            {opened ? (
              <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            ) : (
              <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            )}
          </CollapseIcon>
          <SmallerHeading>Cost by Initiative{getScopeNameSuffix(team, portfolio, board)}</SmallerHeading>
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <div ref={exceptionRef}>
            {opened && (
              <DownloadPNGButton
                handleDownload={() => canvasHtmlDownload('Cost by initiative', downloadRef, exceptionRef)}
                exceptionRef={exceptionRef}
              />
            )}
          </div>
        </div>
      </CollapseHeader>
      <Collapse in={opened} ref={errorRef}>
        <CollapseContent>
          {showError && (
            <StandardText style={{ paddingLeft: '10px', color: 'red' }}>
              Chart can only display 12 initiatives at a time
            </StandardText>
          )}
          {getCostByInitiativeDataQuery.isFetchedAfterMount ? (
            (chartData == null || chartData?.length === 0) && getCostByInitiativeDataQuery.isSuccess ? (
              <CostByInitiativeEmptyStateContainer>
                <img style={{ paddingTop: '125px' }} src={icons.iconWindowGraph} height={100} />
                <StandardText style={{}}>
                  <LinkedText onClick={() => onAddInitiativeClick(navigate)}>Add an initiative</LinkedText> or update
                  the date range to see data.
                </StandardText>
              </CostByInitiativeEmptyStateContainer>
            ) : (
              <CostByInitiativeDataContainer>
                <CostByInitiativeItemBox>
                  <CostByInitiativeItemHeader>
                    <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                      TOTAL{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Total dollars for the date range selected'}
                        style={{ textAlign: 'left' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </div>
                    <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                      AVERAGE{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Total dollars divided by number of months from the range selected'}
                        style={{ textAlign: 'left' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </div>
                  </CostByInitiativeItemHeader>
                  <CostByInitiativeItemList
                    data={chartData || []}
                    showCostByInitiativeChartLines={showCostByInitiativeChartBar}
                    updateShowChartLines={updateShowChartLines}
                  />
                </CostByInitiativeItemBox>
                <ChartContainer>
                  <CostByInitiativeChart
                    chartData={chartData}
                    showCostByInitiativeChartBar={showCostByInitiativeChartBar}
                  />
                </ChartContainer>
              </CostByInitiativeDataContainer>
            )
          ) : (
            <LoaderContainer>
              <Loader color={newCOLORS.indigo} size="lg" />
            </LoaderContainer>
          )}
        </CollapseContent>
      </Collapse>
    </CostByInitiativeContainer>
  );
};

const DATA_CONTAINER_HEIGHT = '400px';

const CostByInitiativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const CostByInitiativeDataContainer = styled.div`
  display: flex;
  padding: 12px;
  gap: 1em;
  height: ${DATA_CONTAINER_HEIGHT};
`;

const CostByInitiativeItemBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const CostByInitiativeItemHeader = styled.div`
  width: 400px;
  display: flex;
  justify-content: end;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 12px;
  gap: 1em;
  height: 425px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
`;

const CostByInitiativeEmptyStateContainer = styled.div`
  padding: 12px;
  gap: 1em;
  height: ${DATA_CONTAINER_HEIGHT};
  text-align: center;
`;

const LinkedText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
