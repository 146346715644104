import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { ProjectsResponse } from '../projects-client/projects-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/portfolios/';

/**
 * Fetches a list of projects for the current user.
 * @returns ProjectsResponse[](data)
 */
const fetchPortfolioProjects = async (id: string): Promise<ProjectsResponse[]> => {
  const apiURL = baseURL + `${id}/projects/`;
  return await axiosInstance
    .get(apiURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data || 'Error while fetching portfolio projects');
    });
};

export { fetchPortfolioProjects };
