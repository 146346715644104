import { Flex, ScrollArea } from '@mantine/core';
import { baseWhite } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { Legal } from '../../onboarding/sign-up/terms-and-conditions/legal';
import { StyledModal } from './add-new-integration.styled';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddNewIntegrationModalTerms = ({ isOpen, onClose }: Props) => {
  return (
    <StyledModal
      opened={isOpen}
      onClose={onClose}
      size="lg"
      padding={40}
      centered
      title={
        <Flex align="center" gap={16}>
          <Icon name="description" size={32} />
          <Text size="large" lineHeight="none" weight="bold">
            Terms & Conditions
          </Text>
        </Flex>
      }
    >
      <Flex direction="column" gap={32}>
        <Divider />
        <ScrollArea h={615} scrollbarSize={8}>
          <Legal />
        </ScrollArea>
        <Divider />
        <Button
          fullWidth
          radius="xl"
          rightSection={<Icon name="cancel" color={baseWhite} size={18} />}
          onClick={onClose}
        >
          Close
        </Button>
      </Flex>
    </StyledModal>
  );
};
