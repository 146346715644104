import { ChartDataset, Point, TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  Measure,
  MeasureDataByEntity,
  MeasureDataResponse,
  MeasureMetadata,
  MeasureUnits,
} from '../../../api/work-periods-client/work-periods-client.type';
import { Position } from '../../../components/costs-vs-output/costs-vs-output.types';
import { round } from '../../../helpers/math-helpers/math-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterUnwantedMeasures,
  useAvailableMeasureUnits,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { newCOLORS } from '../../../styles/colors';
import { title } from '../measure-comparison/measure-comparison-chart';
import {
  calculateTrend,
  getChartLabels,
  sortMeasureDataOverTime,
} from '../measure-comparison/measure-comparison.helpers';
import { createLine, sortObjectByDateKeys } from '../process-analysis-chart.helpers';
import { getTitle } from '../process-analysis.helpers';
import { Entities } from '../process-analysis.type';

type ChartLine = ChartDataset<'line', (number | Point | null)[]>;

export function KeyMeasuresChart({
  tableData,
  measure,
  entitiesWithColor,
  entityTrendsWithColor,
}: {
  tableData: MeasureDataByEntity;
  measure: Measure;
  entitiesWithColor: Entities | null;
  entityTrendsWithColor: Entities | null;
}) {
  const { portfolios = [], teams = [] } = useGlobalStore();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const timeAllocation = useTimeAllocation();
  const unitsByMeasureName = useAvailableMeasureUnits();
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures).filter(filterUnwantedMeasures);

  const defaultEntity = Object.keys(tableData).find(
    (entity) => Object.keys(tableData[entity][measure] || {}).length > 0,
  );

  const lines: ChartLine[] = [];

  Object.entries(entitiesWithColor || {}).forEach(([entity, color]) => {
    const entityData = tableData[entity];
    const measureData = sortMeasureDataOverTime(entityData?.[measure as Measure] || {});
    const line = {
      label: getTitle(portfolios, teams, activeTab, entity) || '',
      data: Object.values(measureData || {}),
      fill: false,
      borderColor: color,
      tension: 0.1,
      spanGaps: true,
      borderDash: [],
      pointRadius: 3,
      pointHitRadius: 5,
      pointHoverRadius: 5,
      pointBackgroundColor: newCOLORS.white,
    };
    lines.push(line);
  });

  const chartData = {
    labels: getChartLabels(defaultEntity ? tableData[defaultEntity] : ({} as MeasureDataResponse), timeAllocation),
    datasets: lines ? [...lines] : [],
  };

  Object.entries(entityTrendsWithColor || {}).forEach(([entity, color]) => {
    const entityData = tableData[entity];
    const sortedTrendData = sortObjectByDateKeys(entityData?.[measure as Measure] || {});
    addTrendDataset(
      chartData,
      getTitle(portfolios, teams, activeTab, entity) || '',
      Object.values(sortedTrendData).filter((v): v is number => typeof v === 'number'),
      color,
      measure,
      availableMeasures,
    );
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: true,
    cubicInterpolationMode: 'monotone',
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dates',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'left' as Position,
        title: {
          display: true,
          text: measure in unitsByMeasureName ? unitsByMeasureName[measure] : 'Units',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        callbacks: {
          title,
          label: (tooltipItem: TooltipItem<'line'>) => getLabel(tooltipItem, measure, unitsByMeasureName),
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
}

function addTrendDataset(
  chartData: { labels: string[]; datasets: ChartLine[] },
  label: string,
  data: number[],
  color: string,
  measure: Measure,
  availableMeasures: MeasureMetadata[],
) {
  const filteredData = data.filter((value) => value !== null);
  const xAxis = Array.from({ length: filteredData.length }, (_value, index) => index);

  if (xAxis.length > 1 && filteredData.length > 1) {
    const measureData = xAxis.reduce(
      (acc, x) => {
        acc[x.toString()] = filteredData[x];
        return acc;
      },
      {} as { [key: string]: number },
    );

    const measureMetadata = availableMeasures.find((m) => m.measure_name === measure);
    const line = calculateTrend(measureData, measureMetadata?.is_zero_valid ?? true);
    if (line) {
      const { slope, intercept } = line;
      const trendData = xAxis.map((x) => slope * x + intercept);

      chartData.datasets
        ? chartData.datasets.push(createLine(label, trendData, color, true))
        : (chartData.datasets = [createLine(label, trendData, color, true)]);
    }
  }
}

/**
 * Formats the tooltip label for a chart data point with appropriate units
 *
 * @param {TooltipItem<'line'>} tooltipItem - The chart.js tooltip item containing the data point
 * @param {Measure} measure - The measure type being displayed
 * @param {MeasureUnitMap} measureUnitMap - Map of measures to their units
 * @returns {string} Formatted label string with value and unit
 */
function getLabel(tooltipItem: TooltipItem<'line'>, measure: Measure, measureUnitMap: MeasureUnitMap): string {
  const unit = measure in measureUnitMap ? measureUnitMap[measure] : '';
  const formattedValue = typeof tooltipItem.raw === 'number' ? round(tooltipItem.raw, 1) : null;

  return unit === MeasureUnits.Percentage ? `${formattedValue}%` : `${formattedValue} ${unit.toLocaleLowerCase()}`;
}
