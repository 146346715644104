import { Edge, Node as ReactFlowNode } from 'reactflow';
import { GraphData, ProcessMapping, Stages, WorkflowStatus } from '../../../api/process-client/process-client.type';
import { newCOLORS } from '../../../styles/colors';
import { findByName } from '../process.helpers';

const MAX_STROKE_WIDTH = parseInt(import.meta.env.VITE_APP_PROCESS_MAP_MAX_EDGE_STROKE_WIDTH || '20');

/**
 * Generates an array of edges representing the connections between stages in a graph.
 *
 * @param {Stages[]} stages - an array of stages
 * @param {GraphData} graphData - the data of the graph
 * @param {ProcessMapping} mapping - The process mapping object.
 * @param {boolean} isPercentage - Whether the task count should be displayed as a percentage.
 * @returns {Edge[]} an array of edges representing the connections between stages
 */
const getStageEdges = (
  stages: Stages[],
  graphData: GraphData,
  mapping: ProcessMapping,
  isPercentage: boolean,
): Edge[] => {
  return stages
    .filter((_, index) => index !== stages.length - 1)
    .map((sourceStage, index) => {
      const destinationStage = stages[index + 1];
      const edgeData = getStageEdgeData(graphData, sourceStage, destinationStage, isPercentage);

      const workflowMapping = {
        [sourceStage]: mapping.bf_to_customer[sourceStage],
        [destinationStage]: mapping.bf_to_customer[destinationStage],
      };

      const color = sourceStage === Stages.Blocked ? newCOLORS.purple2 : newCOLORS.black2;

      return {
        id: `edge-stage-${index}`,
        type: 'stageEdge',
        source: `node-stage-${sourceStage}`,
        target: `node-stage-${destinationStage}`,
        data: {
          value: edgeData.taskCount,
          sourceStage,
          destinationStage,
          mapping: workflowMapping,
        },
        style: { stroke: color, strokeWidth: edgeData.strokeWidth },
      };
    });
};

/**
 * Calculates the task count and stroke width for a given source and destination stage in the graph data.
 *
 * @param {GraphData} graphData - The graph data containing nodes, edges, and total task count.
 * @param {Stages} source - The source stage.
 * @param {Stages} destination - The destination stage.
 * @param {boolean} isPercentage - Whether the task count should be displayed as a percentage.
 * @returns {Record<string, number>} An object containing the task count and stroke width.
 */
const getStageEdgeData = (
  { nodes, edges, task_count: totalTaskCount }: GraphData,
  source: Stages,
  destination: Stages,
  isPercentage: boolean,
): Record<string, string | number> => {
  const sourceNode = findByName(nodes, source);
  const destinationNode = findByName(nodes, destination);

  if (!sourceNode || !destinationNode) {
    return { taskCount: 0, strokeWidth: 1 };
  }
  let taskCount = 0;
  if (!isPercentage) {
    taskCount = edges[sourceNode.order]?.[destinationNode.order] || 0;
  } else {
    const task_count = edges[sourceNode.order]?.[destinationNode.order] || 0;
    taskCount = parseFloat(((task_count / totalTaskCount) * 100).toFixed(2));
  }
  const strokeWidth = getStrokeWidth(taskCount, totalTaskCount);

  return {
    taskCount: isPercentage ? `${taskCount}%` : taskCount,
    strokeWidth,
  };
};

/**
 * Calculates the stroke width based on the task count and total task count.
 *
 * @param {number} taskCount - The number of tasks completed.
 * @param {number} totalTaskCount - The total number of tasks.
 * @returns {number} The stroke width.
 */
const getStrokeWidth = (taskCount: number, totalTaskCount: number): number => {
  if (totalTaskCount <= 0 || taskCount <= 0) {
    return 0;
  }

  if (taskCount > totalTaskCount) {
    return MAX_STROKE_WIDTH;
  }

  const taskRatio = taskCount / totalTaskCount;

  return taskRatio * MAX_STROKE_WIDTH;
};

/**
 * Calculates the radius of the animated dot based on the stroke width.
 *
 * When the stroke width is defined, the radius is always at least 2,
 * even if the stroke width is very small.
 *
 * @param {String | number | undefined} strokeWidth - The stroke width of the edge.
 * @returns {number} The radius of the animated dot.
 */
const getAnimatedDotRadius = (strokeWidth: string | number | undefined): number => {
  if (!strokeWidth) {
    return 0;
  }

  if (typeof strokeWidth === 'string') {
    strokeWidth = parseInt(strokeWidth) as number;
    return Math.max(2, strokeWidth);
  }

  return Math.max(2, strokeWidth as number);
};

/**
 * Generates an array of workflow edges based on the provided workflow nodes and graph data.
 *
 * @param {ReactFlowNode[]} workflowNodes - The array of workflow nodes.
 * @param {GraphData} graphData - The graph data.
 * @returns {Edge[]} - An array of workflow edges.
 */
const getWorkflowEdges = (workflowNodes: ReactFlowNode[], { task_count }: GraphData): Edge[] => {
  return workflowNodes
    .map((node) => {
      const strokeWidth = getStrokeWidth(node.data.value, task_count);

      const incoming = {
        id: `edge-${node.id}-in`,
        type: 'workflowEdgeIn',
        source: node.data.source,
        target: node.id,
        sourceHandle: node.data.status === WorkflowStatus.Backwards ? 'left' : 'right',
        targetHandle: node.data.status === WorkflowStatus.Backwards ? 'bottom' : 'top',
        style: { stroke: newCOLORS.purple2, strokeWidth },
        data: {
          status: node.data.status,
        },
      };

      const outgoing = {
        id: `edge-${node.id}-out`,
        type: 'workflowEdgeOut',
        source: node.id,
        target: node.data.target,
        sourceHandle: node.data.status === WorkflowStatus.Backwards ? 'top' : 'bottom',
        targetHandle: node.data.status === WorkflowStatus.Backwards ? 'left' : 'right',
        style: { stroke: newCOLORS.purple2, strokeWidth },
        data: {
          status: node.data.status,
        },
      };

      return [incoming, outgoing];
    })
    .flat();
};

export { getAnimatedDotRadius, getStageEdgeData, getStageEdges, getStrokeWidth, getWorkflowEdges };
