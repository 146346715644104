import { ComboboxItemGroup } from '@mantine/core';
import { useEffect } from 'react';

/**
 * Sets the document title to the specified title and restores it to the
 * global title when the component is unmounted.
 *
 * @param {string} title - The new title for the document.
 * @returns {void}
 */
const useDocumentTitle = (title: string): void => {
  const globalTitle = 'Bloomfilter';

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = globalTitle;
    };
  }, [title]);
};

/**
 * Method to get the date of the last day of the previous month.
 *
 * @returns the date of the last day of the previous month.
 */
const getRecentlyCompletedMonth = (): Date => {
  const currentDate = new Date();
  const firstDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  return new Date(firstDayCurrentMonth.getTime() - 24 * 60 * 60 * 1000);
};

/**
 * Removes duplicate items from an array based on their id property.
 *
 * @template T Type of array items, must contain an id property of type string
 * @param {T[]} items - Array of items to deduplicate
 * @returns {T[]} New array with duplicate items removed
 */
const deduplicateItems = <T extends { id: string }>(items: T[]): T[] => {
  const uniqueIds = new Set<string>();

  return items.filter((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });
};

/**
 * Deduplicates items across multiple ComboboxItemGroup objects, keeping only the first occurrence of each unique item.
 *
 * @param {ComboboxItemGroup[]} groups - Array of ComboboxItemGroup objects to deduplicate
 * @returns {ComboboxItemGroup[]} New array of ComboboxItemGroup objects with duplicates removed and empty groups filtered out
 */
const deduplicateComboboxGroups = (groups: ComboboxItemGroup[]): ComboboxItemGroup[] => {
  const uniqueItemIds = new Set<string>();
  const seenItemIds = new Set<string>();

  // First pass - collect unique item IDs
  groups.forEach((group) => {
    group.items.forEach((item) => {
      if (typeof item !== 'string') {
        uniqueItemIds.add(item.value);
      }
    });
  });

  // Rebuild groups keeping only first occurrence of each unique item
  return groups
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => {
        if (typeof item === 'string') return false;

        if (uniqueItemIds.has(item.value) && !seenItemIds.has(item.value)) {
          seenItemIds.add(item.value);
          return true;
        }

        return false;
      }),
    }))
    .filter((group) => group.items.length > 0);
};

export { deduplicateComboboxGroups, deduplicateItems, getRecentlyCompletedMonth, useDocumentTitle };
