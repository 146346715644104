import { Divider, Flex } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  configureJDC,
  getBoardStatusesJDC,
  getIntegrationsJDC,
  setStatusMapping,
} from '../../../api/integrations-client/integrations-client';
import { useBoardStatusesJDC, useIntegrationsJDC } from '../../../api/integrations-client/integrations-client.hooks';
import { ExternalService, Methodology } from '../../../api/integrations-client/integrations-client.type';
import { ConfigurationState } from '../../../api/projects-client/projects-client.type';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { GlobalContext, UserContext } from '../../../contexts';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { getUTCTime } from '../../../helpers/timezone/timezone';
import { ErrorSource, GlobalMessageCategory, GlobalMessageState } from '../../global-message/global-message.type';
import { IntegrationsContext } from '../context/integrations.context';
import { StepProps } from '../integrations.type';
import { SettingsView } from '../views/settings-view';
import { StatusView } from '../views/status-view';
import { SuccessView } from '../views/success-view';
import { SummaryView } from '../views/summary-view';
import styles from '../views/views.module.css';
import { Boards } from './boards';
import { JDCReducer } from './jdc.reducer';
import { JDCState } from './jdc.type';
import { Projects } from './projects';

const initialState: JDCState = {
  initialized: false,
  project: null,
  board: null,
  bloomfilterStatuses: null,
  settings: {
    devTeamSize: '',
    methodology: Methodology.Scrum,
    pointBugs: false,
    requireLabels: false,
    projectUrl: '',
    workingHoursStart: '09:00',
    workingHoursEnd: '17:00',
  },
  options: {
    integrations: [],
    statuses: [],
  },
};

export function JDC({ activeStep = 0, setActiveStep }: StepProps) {
  const { user } = useContext(UserContext);
  const { setGlobalMessage } = useContext(GlobalContext);
  const { initJDC } = useContext(IntegrationsContext);
  const organizationId = useOrganizationId();
  const [state, dispatch] = useReducer(JDCReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!initJDC) {
      navigate('/application/integrations', { replace: true });
    }
  }, [navigate, initJDC]);

  const { data, query: integrationsQuery } = useIntegrationsJDC({
    queryKey: ['integrations-jdc', organizationId] as const,
    queryFn: async () => {
      if (!organizationId) {
        throw new Error('Organization ID is required');
      }
      return getIntegrationsJDC(organizationId);
    },
    enabled: !!organizationId,
  });

  const { data: boardStatusesData } = useBoardStatusesJDC({
    queryKey: ['board-statuses-jdc', state.board?.internal_id ?? null] as const,
    queryFn: async () => {
      if (!state.board?.internal_id) {
        throw new Error('Board ID is required');
      }
      return getBoardStatusesJDC(state.board.internal_id);
    },
    enabled: !!state.board?.internal_id,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.length > 0) {
      dispatch({ type: 'init', payload: { options: { integrations: data } } });
    } else {
      navigate('/application/integrations', { replace: true });
      setGlobalMessage({
        type: GlobalMessageCategory.APP_ERROR,
        source: ErrorSource.JDC,
        state: GlobalMessageState.ERROR,
      });
    }
  }, [data, navigate, setGlobalMessage]);

  useEffect(() => {
    if (boardStatusesData) {
      dispatch({
        type: 'options',
        payload: {
          statuses: boardStatusesData.statuses,
        },
      });
    }
  }, [boardStatusesData]);

  const submitConfiguration = useMutation({
    mutationKey: ['configure-jdc', state, user] as const,
    mutationFn: () => {
      const { board, settings } = state;
      const { devTeamSize, pointBugs, requireLabels, workingHoursStart, workingHoursEnd } = settings;
      if (!board) {
        throw new Error('Invalid project board data');
      }

      return configureJDC(
        board.internal_id,
        Number(devTeamSize),
        pointBugs,
        requireLabels,
        getUTCTime(workingHoursStart),
        getUTCTime(workingHoursEnd),
        ConfigurationState.InitiallyConfigured,
      );
    },
    onSuccess: () => {
      saveProjectStatusesMutation.mutate();
    },
  });

  const saveProjectStatusesMutation = useMutation({
    mutationKey: ['status-mapping', state, user] as const,
    mutationFn: () => {
      const { project, board, bloomfilterStatuses, settings } = state;
      if (!project || !board || !bloomfilterStatuses || !settings || !organizationId) {
        throw new Error('Invalid project board data');
      }

      return setStatusMapping(
        ExternalService.JDC,
        project,
        board,
        organizationId,
        bloomfilterStatuses.backlogStatuses,
        bloomfilterStatuses.inProgressStatuses,
        bloomfilterStatuses.blockedStatuses,
        bloomfilterStatuses.inReviewStatuses,
        bloomfilterStatuses.inQAStatuses,
        bloomfilterStatuses.readyForDeploymentStatuses,
        bloomfilterStatuses.doneStatuses,
        settings.projectUrl,
        ConfigurationState.InitiallyConfigured,
      );
    },
  });

  const views = [
    <Projects state={state} dispatch={dispatch} key="projects" />,
    <Boards state={state} dispatch={dispatch} key="boards" />,
    <StatusView state={state} dispatch={dispatch} integration={ExternalService.Jira} key="statuses" />,
    <SettingsView key="settings" state={state} dispatch={dispatch} />,
    <SummaryView state={state} setActiveStep={setActiveStep} integration={ExternalService.Jira} key="summary" />,
    <SuccessView key="success" />,
  ];

  const handleChangeView = (handler: (current: number) => number) => {
    setTimeout(() => window.scrollTo(0, 0));
    setActiveStep(handler(activeStep));
  };

  function isNextButtonDisabled(activeStep: number) {
    switch (activeStep) {
      case 0:
        return !state.project;
      case 1:
        return !state.board;
      case 3:
        return state.settings.devTeamSize === '';
      default:
        return false;
    }
  }

  function handleSubmit() {
    submitConfiguration.mutate();
    nextStep();
  }

  const nextStep = () => handleChangeView((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => handleChangeView((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      <BrandedLoadingOverlay visible={integrationsQuery.isFetching} transitionDuration={30} variant="colored" />
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 5 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 4 ? nextStep : handleSubmit}
                size="lg"
              >
                {activeStep < 4 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
}
