import { ReactNode, createContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchUserInfo } from '../api/user-client/user-client';
import { UserInfo } from '../api/user-client/user-client.type';
import { getRequestToken, logout } from '../helpers/storage/storage';
import { UserContextType } from './user.type';

export const UserContext = createContext({} as UserContextType);

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserInfo | null>(null);

  const userQuery = useQuery({
    queryKey: ['user'] as const,
    queryFn: async () => {
      if (!getRequestToken()) {
        throw new Error('No request token available');
      }
      return fetchUserInfo();
    },
    enabled: !user && Boolean(getRequestToken()),
  });

  useEffect(() => {
    if (userQuery.data) {
      setUser(userQuery.data);
    }
  }, [userQuery.data]);

  useEffect(() => {
    if (userQuery.isError) {
      logout();
    }
  }, [userQuery.isError]);

  return <UserContext.Provider value={{ user, setUser, query: userQuery }}>{children}</UserContext.Provider>;
}
