import { styled } from '@linaria/react';
import { Table, Text } from '@mantine/core';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { RiskAnalysisProject, RiskAnalysisTask } from './risk-analysis.client.type';

export function RiskAnalysisProjectList({ tasks }: { tasks: RiskAnalysisTask[] }) {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const projects = portfolio?.projects ?? [];

  const projectRiskScores: RiskAnalysisProject[] = projects.map((project) => {
    const projectTasks = tasks.filter((task) => task.project.id === project.id && task.flora_annotations?.risk_score);
    if (projectTasks.length > 0) {
      const totalRiskScore = projectTasks.reduce((sum, task) => sum + (task.flora_annotations?.risk_score ?? 0), 0);
      return {
        id: project.id,
        name: project.name,
        average_risk_score: totalRiskScore / projectTasks.length,
      };
    }
    return {
      id: project.id,
      name: project.name,
      average_risk_score: null,
    };
  });

  projectRiskScores.sort((a, b) => (b.average_risk_score ?? 0) - (a.average_risk_score ?? 0));
  return (
    <ChartContainer>
      <Table striped highlightOnHover>
        <Table.Tbody>
          {projectRiskScores.map((project) =>
            project.average_risk_score ? (
              <Table.Tr key={project.id}>
                <Table.Td>
                  <Text fw={500}>{project.name}</Text>
                </Table.Td>
                <Table.Td ta="right">{project.average_risk_score?.toFixed(2) ?? 'N/A'}</Table.Td>
              </Table.Tr>
            ) : null,
          )}
        </Table.Tbody>
      </Table>
    </ChartContainer>
  );
}

const ChartContainer = styled.div`
  height: 300px;
  max-height: 300px;
  margin: 0 auto;
  margin-top: 1rem;
  overflow: auto;
`;
