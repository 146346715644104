import { styled } from '@linaria/react';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import { icons } from '../../assets/icons/icons';
import { formatDate } from '../../helpers/timezone/timezone';
import { DateTemplate } from '../../helpers/timezone/timezone.type';
import { newCOLORS } from '../../styles/colors';

export type TaskItemProps = {
  task: MinifiedTask;
};

export const TaskItem = ({ task }: TaskItemProps) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const cleanDescription = (text: string): string => {
    const maxDescriptionLength = 120;
    const re = RegExp('{color[a-zA-Z0-9_:#]{0,10}}|h[0-9].|\\*', 'g');
    text = text.replaceAll(re, '');
    const splitDescription = text?.split('\n', 1);
    let cutDescription = splitDescription && splitDescription.length > 0 ? splitDescription[0] : '';
    if (cutDescription && cutDescription.length > maxDescriptionLength) {
      cutDescription = cutDescription.slice(0, maxDescriptionLength);
      cutDescription = cutDescription.substring(
        0,
        Math.min(cutDescription.length, cutDescription.lastIndexOf(' ') + 1),
      );
    }
    return cutDescription;
  };

  return (
    <RelatedTaskCardTag>
      <TaskHeadingGroup
        onClick={() => {
          window.open(task.url, '_blank', 'noopener, noreferrer');
        }}
      >
        <TaskHeading>{task.name}</TaskHeading>
        <img alt="icon" src={icons.iconShare} />
      </TaskHeadingGroup>
      <TaskDescriptionGroup>
        <TaskSubheading>
          {task.type[0]?.toUpperCase() + task.type.slice(1)} |{' '}
          {task.points === null || task.points === undefined ? '-' : task.points} Story Points | Created{' '}
          {formatDate(task.created_date, timezone, DateTemplate.MMDDYYYY)}
        </TaskSubheading>
        {task.description ? <TaskDescription>{cleanDescription(task.description)} ...</TaskDescription> : null}
      </TaskDescriptionGroup>
    </RelatedTaskCardTag>
  );
};

const RelatedTaskCardTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  & + & {
    padding-top: 8px;
    border-top: 1px solid RGBA(0, 0, 0, 0.2);
  }
`;

const CommonText = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const TaskHeadingGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  cursor: pointer;
`;

const TaskDescriptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.65;
`;

const TaskHeading = styled(CommonText)`
  color: ${newCOLORS.darkPurple};
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px; /* 140% */
`;

const TaskSubheading = styled(CommonText)`
  color: ${newCOLORS.darkGray};
  text-align: left;
  font-size: 12px;
  font-weight: 300;
`;

const TaskDescription = styled(CommonText)`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-weight: 300;
  line-height: 21px; /* 140% */
  padding-top: 8px;
  webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;
