import { styled } from '@linaria/react';
import { ExternalService, IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { inkLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { availableIntegrations as availableIntegrationsData } from '../../../integrations/integrations-list/integrations-list.data';
import { upcomingIntegrations } from '../../../integrations/integrations-list/upcoming-integrations.data';
import { integrationViews } from '../../../integrations/integrations.data';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { IntegrationCard } from './integration-card';

type IntegrationsPanelProps = {
  setDisplayIntegrations: (display: boolean) => void;
};

export function IntegrationsPanel({ setDisplayIntegrations }: IntegrationsPanelProps) {
  const availableIntegrations = integrationViews.filter((integration) => integration.enabled);

  const descriptions: Record<ExternalService, string | undefined> = {
    [ExternalService.ADO]: availableIntegrationsData.find((integration) => integration.name === IntegrationType.ADO)
      ?.description,
    [ExternalService.Jira]: availableIntegrationsData.find((integration) => integration.name === IntegrationType.JIRA)
      ?.description,
    [ExternalService.JDC]: availableIntegrationsData.find((integration) => integration.name === IntegrationType.JIRA)
      ?.description,
    [ExternalService.Github]: availableIntegrationsData.find(
      (integration) => integration.name === IntegrationType.GITHUB,
    )?.description,
    [ExternalService.Figma]: undefined,
    [ExternalService.Gitlab]: undefined,
    [ExternalService.Confluence]: undefined,
    [ExternalService.Aha]: undefined,
    [ExternalService.Bitbucket]: undefined,
    [ExternalService.Notion]: undefined,
    [ExternalService.Asana]: undefined,
  };

  return (
    <DataManagementDropShadowContainer style={{ overflowY: 'auto' }}>
      <HeaderContainer>
        <Text weight="bold" size="large">
          Available Integrations
        </Text>
        <Button
          variant="outline"
          radius="xl"
          size="sm"
          onClick={() => setDisplayIntegrations(false)}
          leftSection={<Icon name="cancel" variant="outlined" color={inkLight} size={16} />}
        >
          Cancel
        </Button>
      </HeaderContainer>
      <IntegrationsContainer>
        {availableIntegrations.map((integration) => (
          <IntegrationCard
            key={integration.name}
            icon={integration.name}
            title={integration.title}
            description={descriptions[integration.name as ExternalService] || ''}
            isUpcoming={false}
          />
        ))}
      </IntegrationsContainer>
      <Text weight="bold" size="large" style={{ marginBottom: 30, marginTop: 30 }}>
        Upcoming Integrations
      </Text>
      <IntegrationsContainer>
        {upcomingIntegrations.map((integration) => (
          <IntegrationCard key={integration.title} {...integration} isUpcoming={true} />
        ))}
      </IntegrationsContainer>
    </DataManagementDropShadowContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;

const IntegrationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 40px;
`;
