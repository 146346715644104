import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchSprint, fetchSprintBurnsAndFlows } from '../../api/sprints-client/sprints-client';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import { fetchTasks } from '../../api/tasks-client/tasks-client';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { newCOLORS } from '../../styles/colors';
import { TitleHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { SidebarContext } from '../side-bar/side-bar.context';
import { TaskList } from './task-list';
import { sortTasks } from './task-list-header';
import { makeTitle } from './tasks.helpers';

export const Tasks = () => {
  const { sprintId } = useParams<{ sprintId: string }>();
  const [searchParams] = useSearchParams();
  const { viewAreaWidth } = useContext(SidebarContext);
  const [tasks, setTasks] = useState<MinifiedTask[]>([]);
  const day = searchParams.get('day');
  const filter = searchParams.get('filter');

  const sprintQuery = useQuery({
    queryKey: ['sprint', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Sprint ID is required');
      }
      return fetchSprint(sprintId);
    },
    enabled: !!sprintId,
  });

  const burnsAndFlowsQuery = useQuery({
    queryKey: ['sprintBurnsAndFlows', sprintId] as const,
    queryFn: async () => {
      if (!sprintId) {
        throw new Error('Sprint ID is required');
      }
      return fetchSprintBurnsAndFlows(sprintId);
    },
    enabled: !!sprintId,
  });

  const tasksQuery = useQuery({
    queryKey: ['tasks', sprintId, day, filter] as const,
    queryFn: async () => {
      if (!sprintId || !day || !filter) {
        throw new Error('Required parameters missing');
      }
      return fetchTasks({ sprintId, filter, day });
    },
    enabled: !!sprintId && !!day && !!filter,
  });

  useEffect(() => {
    if (tasksQuery.data) {
      const data = tasksQuery.data as unknown as MinifiedTask[];
      const processedTasks = sortTasks('default', data);
      setTasks(processedTasks);
    }
  }, [tasksQuery.data]);

  useEffect(() => {
    if (sprintQuery.error || burnsAndFlowsQuery.error || tasksQuery.error) {
      console.error(
        'There was an issue fetching data:',
        sprintQuery.error || burnsAndFlowsQuery.error || tasksQuery.error,
      );
    }
  }, [sprintQuery.error, burnsAndFlowsQuery.error, tasksQuery.error]);

  return (
    <MenuDrivenContainer>
      <BrandedLoadingOverlay visible={tasksQuery.isPending} transitionDuration={30} variant="colored" />
      <TasksContainer>
        <HeaderContainer viewAreaWidth={viewAreaWidth}>
          <TitleHeadingTag>
            {makeTitle(sprintQuery.data ?? null, day, burnsAndFlowsQuery.data ?? null, filter)}
          </TitleHeadingTag>
        </HeaderContainer>
        <RelatedTasksContainer>
          <TaskList tasks={tasks} setTasks={setTasks} />
        </RelatedTasksContainer>
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const HeaderContainer = styled.div<{ viewAreaWidth: number }>`
  display: flex;
  flex-direction: ${(props) => (props.viewAreaWidth >= 1200 ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RelatedTasksContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  margin-top: 16px;
  border-radius: 4px;
  background: ${newCOLORS.white};
  box-shadow: 0px 2px 3px 0px ${newCOLORS.lightGray};
`;
