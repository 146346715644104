import { styled } from '@linaria/react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMeasureDetail } from '../../api/work-periods-client/work-periods-client.hooks';
import type { MeasureDetail } from '../../api/work-periods-client/work-periods-client.type';
import { MenuDrivenContainer } from '../../containers/menu-driven-container/menu-driven-container';
import { TasksTable } from '../../containers/tasks-table/tasks-table';
import { TaskRecord } from '../../containers/tasks-table/tasks-table.type';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { BrandedLoadingOverlay } from '../loader/branded-loader';
import { MeasureInfoBox } from '../measure-info-box/measure-info-box';
import { PageHeaderMessage } from '../page-header-message/page-header-message';
import { Title } from '../title/title';
import { formatTasks, makeTitle, useFinancialsTasksScope } from './financials-tasks.helpers';
import { useAllocationOfCostsTasks, useCostByInitiativeTasks, useCostVsOutputTasks } from './financials-tasks.hooks';

export const FinancialsTasks = () => {
  useDocumentTitle('Task Details - Bloomfilter');
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure');

  const useTasksQuery =
    measure === 'cost_allocation'
      ? useAllocationOfCostsTasks
      : measure === 'cost_by_initiative'
        ? useCostByInitiativeTasks
        : useCostVsOutputTasks;

  const scope = useFinancialsTasksScope(portfolioId);
  const { projectId, subprojectId, date, factor, factorOption, startDate, endDate } = scope;

  const { data: tasksQueryData = [], query: tasksQuery } = useTasksQuery({
    portfolioId,
    projectId,
    subprojectId,
    date,
    factor,
    factorOption,
    startDate,
    endDate,
  });

  const showLocalError = !tasksQuery.isPending && !tasksQueryData.length;

  const header = <Title title={makeTitle(scope)} />;

  const measureName = searchParams.get('measure');
  const [_, setLoadingMeasure] = useState(false);
  const [measureError, setMeasureError] = useState<string | null>(null);
  const [measureDetails, setMeasureDetails] = useState<MeasureDetail | null>(null);

  const { data } = useMeasureDetail({
    measureName: measureName as string,
    options: {
      enabled: !!measureName && !window.location.pathname.includes('/process/task-details'),
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (data) {
      setLoadingMeasure(true);
      try {
        setMeasureDetails(data);
      } catch (error) {
        setMeasureError('Failed to load measure details');
      } finally {
        setLoadingMeasure(false);
      }
    }
  }, [data]);

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={tasksQuery.isPending} transitionDuration={30} variant="colored" />
      {!window.location.pathname.includes('/process/task-details') && (
        <>
          {measureError && <PageHeaderMessage message={measureError} color="red" />}
          {measureDetails && <MeasureInfoBox measure={measureDetails} />}
        </>
      )}
      <BrandedLoadingOverlay visible={tasksQuery.isPending} transitionDuration={30} variant="colored" />
      <TasksContainer>
        {showLocalError && <PageHeaderMessage message="There are no related tasks for this selection." color="red" />}
        <TasksTable tasks={formatTasks(tasksQueryData as TaskRecord[])} />
      </TasksContainer>
    </MenuDrivenContainer>
  );
};

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
