import { styled } from '@linaria/react';
import {
  ActiveElement,
  CategoryScale,
  ChartData,
  ChartDataset,
  ChartEvent,
  Chart as ChartJS,
  ChartType,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import {
  SprintMetricsBurnFlowOfWork,
  SprintMetricsFlowOfWorkPointDataPoint,
  SprintMetricsFlowOfWorkTaskDataPoint,
} from '../../api/sprints-client/sprints-client.type';
import { createWeekendBackgroundPlugin } from '../../helpers/chart-plugins/weekend-background-element';
import { newCOLORS } from '../../styles/colors';
import { HeadingTag } from '../../styles/shared-styled-components';
import { ViewByType } from '../burn-flow/burn-flow-chart.type';
import { WorkDoneEnum, WorkOnTrackProps } from './work-on-track.type';

const enumMapping: { [key: string]: number } = {
  'off track': 0,
  'at risk': 1,
  'on track': 2,
};

const getWorkDoneTypeFromColor = (color: string): WorkDoneEnum => {
  switch (color) {
    case newCOLORS.lightYellow:
      return WorkDoneEnum.atRisk;
    case newCOLORS.coral:
      return WorkDoneEnum.offTrack;
    default:
      return WorkDoneEnum.onTrack;
  }
};

// weekend or holiday data
let weekendOrHolidays: boolean[] = [];

const getWeekendOrHolidayStatus = (burn_commitment: SprintMetricsBurnFlowOfWork) => {
  weekendOrHolidays = [];
  for (const i in burn_commitment.data_points) {
    weekendOrHolidays.push(burn_commitment.data_points[i].weekend_or_holiday);
  }
};

type AdditionalDataProperties = {
  totalPoints: number[];
};
type WorkOnTrackChartDataset<T extends ChartType> = ChartDataset<T> & AdditionalDataProperties;

export const formatData = (data: SanitizedData): ChartData<'line'> => {
  const dataset: ChartData<'line'> = {
    labels: data.labels,
    datasets: [
      {
        label: 'Work Done, on track',
        order: 5,
        stack: 'Stack 5',
        data: data.done,
        fill: false,
        pointBorderColor: data.doneColors,
        pointBackgroundColor: data.doneColors,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 5,
        borderWidth: 2,
        borderColor: newCOLORS.lightGray,
        backgroundColor: newCOLORS.lightGray,
        totalPoints: data.doneValues,
      } as WorkOnTrackChartDataset<'line'>,
    ],
  };

  return dataset;
};

const getDoneColors = (dataPoint: number, stdDev: number, idealPoint: number): string => {
  let color = newCOLORS.green;
  if (idealPoint - 0.5 * stdDev > dataPoint) {
    color = newCOLORS.lightYellow;
  }
  if (idealPoint - 1.0 * stdDev > dataPoint) {
    color = newCOLORS.coral;
  }
  return color;
};

type SanitizedData = {
  labels: string[];
  done: (number | null)[];
  doneColors: string[];
  doneValues: (number | null)[];
};

const sanitizeData = (
  rawData: SprintMetricsBurnFlowOfWork,
  start_date: string,
  data_type: ViewByType,
): SanitizedData => {
  const sanitizedData = {} as SanitizedData;
  const inputData = rawData.data_points;
  const done = [];
  const doneColors = [];

  const today = new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit' }).replace(',', '/');
  const currentYear = new Date().getFullYear();
  const sprintStartYear = new Date(start_date).getFullYear();

  const taskDataPoints = inputData as SprintMetricsFlowOfWorkTaskDataPoint[];
  const pointDataPoints = inputData as SprintMetricsFlowOfWorkPointDataPoint[];

  for (const i in data_type === ViewByType.tasks ? taskDataPoints : pointDataPoints) {
    if (
      currentYear > sprintStartYear ||
      (currentYear === sprintStartYear &&
        (data_type === ViewByType.tasks ? taskDataPoints[i].date : pointDataPoints[i].date) <= today)
    ) {
      done.push(data_type === ViewByType.tasks ? taskDataPoints[i].tasks_complete : pointDataPoints[i].points_complete);
      doneColors.push(
        getDoneColors(
          data_type === ViewByType.tasks ? taskDataPoints[i].tasks_complete : pointDataPoints[i].points_complete,
          rawData.std_dev,
          data_type === ViewByType.tasks
            ? taskDataPoints[i].tasks_complete_ideal
            : pointDataPoints[i].points_complete_ideal,
        ),
      );
    } else {
      doneColors.push('' as const);
    }
  }

  sanitizedData['labels'] = rawData.labels;
  sanitizedData['doneValues'] = done;
  sanitizedData['done'] = doneColors.map((value) => {
    return enumMapping[getWorkDoneTypeFromColor(value)];
  });
  sanitizedData['doneColors'] = doneColors;
  return sanitizedData;
};

export const WorkOnTrack = ({ burn_commitment, start_date, data_type }: WorkOnTrackProps) => {
  useEffect(() => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
  }, []);
  const data = sanitizeData(burn_commitment, start_date, data_type);
  const formattedData = formatData(data);
  const { projectId, sprintId } = useParams<{ projectId: string; sprintId: string }>();
  getWeekendOrHolidayStatus(burn_commitment);
  const navigate = useNavigate();

  const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Day of Sprint',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Status',
          font: {
            size: 16,
          },
        },
        ticks: {
          callback: (value: string | number) => {
            return Object.keys(enumMapping).find((key: string) => enumMapping[key] === Number(value));
          },
          stepSize: 1,
          font: {
            size: 14,
          },
        },
      },
    },
    onClick: (_: ChartEvent, elements: ActiveElement[]) => {
      const isOnElement = elements.length > 0;

      if (isOnElement) {
        const params = {
          day: `${elements[0].index}`,
          filter: 'done',
        };
        navigate({
          pathname: `/application/project/${projectId}/sprint-assessment/${sprintId}/tasks`,
          search: `?${createSearchParams(params)}`,
        });
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      let cursorStyle = 'default';

      const isOnElement = elements.length > 0;
      if (isOnElement) {
        cursorStyle = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursorStyle;
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 2,
          boxHeight: 12,
          pointStyle: 'rect',
          generateLabels: () => {
            const items = [
              {
                text: 'Off Track',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.coral,
                strokeStyle: newCOLORS.coral,
                hidden: false,
                index: 0,
              },
              {
                text: 'At Risk',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.lightYellow,
                strokeStyle: newCOLORS.lightYellow,
                hidden: false,
                index: 1,
              },
              {
                text: 'On Track',
                fontColor: newCOLORS.darkGray,
                fillStyle: newCOLORS.green,
                strokeStyle: newCOLORS.green,
                hidden: false,
                index: 2,
              },
            ];
            return items;
          },
        },
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItems: TooltipItem<'line'>) => {
            const dataset: WorkOnTrackChartDataset<'line'> = tooltipItems.chart.data
              .datasets[0] as WorkOnTrackChartDataset<'line'>;
            return `Work Done: ${dataset.totalPoints[tooltipItems.dataIndex]}`;
          },
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  return (
    <ChartContainer>
      <CenterHeadingTag>Is work on track to match pace?</CenterHeadingTag>
      <div style={{ height: '200px', display: 'flex' }}>
        <div style={{ width: '99%' }}>
          <Line
            options={defaultChartOptions}
            data={formattedData}
            plugins={[createWeekendBackgroundPlugin(weekendOrHolidays) as any]}
          />
        </div>
      </div>
    </ChartContainer>
  );
};
const ChartContainer = styled.div`
  margin-bottom: 25px;
`;
const CenterHeadingTag = styled(HeadingTag)`
  text-align: center;
`;
