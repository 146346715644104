import { IntegrationType } from '../../../../api/integrations-client/integrations-client.type';
import { ProjectsResponse } from '../../../../api/projects-client/projects-client.type';
import { Text } from '../../../../ui-library/typography/typography';
import { getDataSourceIcon, getDataSourceLabel } from '../data-management.helpers';
import { ProjectTableRow } from './portfolio-panel';

/**
 * Maps a project to a table row
 * @param {ProjectsResponse} project The project to map to a table row
 * @returns {ProjectTableRow} The table row for the project
 */
function mapProjectToTableRow(project: ProjectsResponse): ProjectTableRow {
  return {
    id: project.id,
    projectKey: project.name,
    taskCount: project.task_count?.toLocaleString() || 'N/A',
    contributorCount: project.contributor_count?.toLocaleString() || 'N/A',
    dataSource: (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img
          src={getDataSourceIcon(project.pm_tool as IntegrationType)}
          width={20}
          height={20}
          alt={getDataSourceLabel(project.pm_tool as IntegrationType)}
        />
        <Text>{getDataSourceLabel(project.pm_tool as IntegrationType)}</Text>
      </div>
    ),
    owner: project.pm || 'N/A',
    isActive: true,
  };
}

export { mapProjectToTableRow };
