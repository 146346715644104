import { useCallback } from 'react';
import { ProjectView } from '../views/project-view';
import { ADOReducerAction, ADOState } from './ado.type';

type Props = {
  state: ADOState;
  dispatch: React.Dispatch<ADOReducerAction>;
};

export function Projects({ state, dispatch }: Props) {
  const { options } = state;

  const handleChange = useCallback(
    (projectId: string) => {
      const project = (options?.projects || []).find((p) => p.id === projectId);
      dispatch({ type: 'project', payload: project });
      dispatch({
        type: 'organization',
        payload: options?.organizations?.find((org) => org.id === project?.organizationId) || null,
      });
      dispatch({
        type: 'options',
        payload: { teams: options?.allTeams.filter((team) => team.projectId === project?.id) },
      });
    },
    [dispatch, options],
  );

  return (
    <ProjectView
      projects={state.options?.projects || []}
      selectedProjectId={state.project?.id || null}
      onChange={handleChange}
    />
  );
}
