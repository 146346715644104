import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { RiskAnalysis } from './risk-analysis/risk-analysis';

export function Adherence() {
  return (
    // We changed the name to Risk Analysis as it is the only tab for now, in the future change it to Adherence
    <MenuDrivenContainer header="Risk Analysis">
      <RiskAnalysis />
    </MenuDrivenContainer>
  );
}
