import { TaskType } from '../../containers/tasks-table/tasks-table.type';

enum GraphType {
  Common = 'common',
  Super = 'super',
}

type GraphNode = {
  name: string;
  order: number;
};

type GraphEdge = Record<string, number>;

type GraphPath = {
  [key: string]: number;
};

type GraphData = {
  task_count: number;
  nodes: GraphNode[];
  edges: Record<string, GraphEdge>;
  common_paths: GraphPath[];
};

enum Stages {
  Blocked = 'blocked',
  Backlog = 'backlog',
  InProgress = 'wip',
  InReview = 'review',
  InTest = 'test',
  Deployable = 'deployable',
  Done = 'done',
}

enum WorkflowStatus {
  Expected = 'Expected',
  Skipped = 'Skipped',
  Backwards = 'Backwards',
  Completed = 'Completed',
}

type ProcessGraphConfig = Partial<{
  teamId: string;
  boardId: string;
  taskType: TaskType;
  epicId: string;
  graphType: GraphType;
  startDate: string;
  endDate: string;
}>;

type ProcessTasksConfig = {
  teamId: string | null;
  boardId: string | null;
  taskType: TaskType | null;
  epicId: string | null;
  startDate?: string;
  endDate?: string;
  mapping?: Record<Stages, string[]>;
};

type ProcessTask = {
  id: string;
  title: string;
  name: string;
  description: string;
  points: number;
  type: string;
  created_date: string;
  url: string;
  project_id: string;
  epic_key: string;
  epic: {
    id: string;
    name: string;
  } | null;
  initiatives: {
    id: string;
    name: string;
  }[];
  transitions?: {
    status: string;
    changed_by: string;
    changed_at: string;
  }[];
};

type ProcessTasksData = {
  tasks: ProcessTask[];
  task_count: number;
  arrived: Record<string, string[]>;
  departed: Record<string, string[]>;
  returned: Record<string, string[]>;
};

type ProcessMapping = {
  bf_happy_path: string[];
  customer_to_bf: Record<string, string | null>;
  bf_to_customer: Record<string, string[]>;
};

export { GraphType, Stages, WorkflowStatus };
export type {
  GraphData,
  GraphNode,
  ProcessGraphConfig,
  ProcessMapping,
  ProcessTask,
  ProcessTasksConfig,
  ProcessTasksData,
};
