import { ExtendedInitiative, Initiative } from '../../../api/initiative-client/initiative-client.type';
import { newCOLORS } from '../../../styles/colors';

const palette = Object.keys(newCOLORS).filter(
  (color) =>
    !['black', 'darkerGray', 'gray', 'lightGray', 'lighterGray', 'lightishGray', 'white', 'lightBlue'].includes(color),
);

const DEFAULT_COLORS = [
  newCOLORS.tangerine,
  newCOLORS.lightPurple,
  newCOLORS.magenta,
  newCOLORS.blue,
  newCOLORS.darkYellow,
  newCOLORS.violet,
  newCOLORS.yellow,
  newCOLORS.pink,
  newCOLORS.purple,
  newCOLORS.green,
  newCOLORS.orangeRed,
  newCOLORS.indigo,
];

/**
 * Assigns randomized colors to the entities like epics and teams
 * @param entities - Array of entities
 * @returns Array of entities augmented with colors
 */
const getColoredEntities = (entities: { id: string; name: string }[]) => {
  if (entities.length <= DEFAULT_COLORS.length) {
    return entities.map((entity, index) => ({
      ...entity,
      color: DEFAULT_COLORS[index],
    }));
  }

  let colorsCache = [] as typeof palette;
  return entities.map((entity) => {
    const availableColors = palette.filter((color) => !colorsCache.includes(color));
    const colorIndex: number = Math.floor(availableColors.length * Math.random());
    const color = newCOLORS[availableColors[colorIndex] as keyof typeof newCOLORS];

    if (colorsCache.length === palette.length) {
      colorsCache = [];
    }

    colorsCache.push(color);

    return {
      ...entity,
      color,
    };
  }, []);
};

/**
 * Formats labels received from the backend from "2023-01" to "Jan '23"
 * @param dates - Array of dates from the backend
 * @returns Array of formatted dates
 */
const getDateLabels = (dates: string[]) => {
  return dates.map((date) => {
    const [year, month] = date.split('-');
    const monthStr = new Date(0, Number(month) - 1).toLocaleString('default', { month: 'short' });

    return `${monthStr} '${year.toString().slice(-2)}`;
  });
};

/**
 * Formats the select options in the initiative dropdown with selected initiative on top and rest of
 * the initiatives sorted alphabetically
 * @param data - list of initiatives
 * @param initiative - selected initiative
 * @returns - list of sorted initiatives
 */
const getSelectOptions = (
  data: Initiative[] | undefined = [],
  initiative: ExtendedInitiative,
): { value: string; label: string }[] => {
  const selectedOption = data.find((item) => item.id === initiative.id);
  const filteredData = data.filter((item) => item.id !== initiative.id).sort((a, b) => a.name.localeCompare(b.name));

  const sortedOptions = filteredData.map(({ id, name }) => ({ value: id, label: name }));

  if (selectedOption) {
    const { id, name } = selectedOption;
    const selected = { value: id, label: name };
    return [selected, ...sortedOptions];
  }

  return sortedOptions;
};

export { getColoredEntities, getDateLabels, getSelectOptions };
