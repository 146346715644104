import { AxiosError, AxiosResponse } from 'axios';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import {
  ExtendedInitiative,
  FocusData,
  Initiative,
  InitiativeTaskResponse,
  InitiativeTasksFilters,
  PreSaveInitiative,
} from '../initiative-client/initiative-client.type';

const axiosInstance = AxiosInstance();

/**
 * Fetches array of initiatives for a given portfolio id
 * @param portfolio_id - portfolio id
 * @returns Array of initiatives
 */
async function getInitiatives(portfolio_id: string): Promise<Initiative[]> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/`;
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching initiatives.'));
    }
  }
}

/**
 * Fetches array of initiatives for a given team id
 * @param teamId - team id
 * @returns Array of initiatives
 */
async function getTeamInitiatives(teamId: string): Promise<Initiative[]> {
  try {
    const apiURL = import.meta.env.VITE_APP_API_URL + `/api/projects/${teamId}/initiatives/`;
    const response: AxiosResponse = await axiosInstance.get(apiURL);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while fetching team initiatives.'));
    }
  }
}

/**
 * Fetches initiative object for a given portfolio and initiative ids
 * @param portfolio_id - portfolio id
 * @param initiative_id - initiative id
 * @returns Initiative object
 */
async function getInitiative(portfolio_id: string, initiative_id: string): Promise<ExtendedInitiative> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/${initiative_id}/`;
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error(`An unknown error occurred while fetching initiative ${initiative_id}`));
    }
  }
}

/**
 * Method to create a new initiative
 * @param portfolio_id portfolio id ot attach the initiative to
 * @param initiative initiative object to create
 * @returns created initiative object
 */
async function createInitiative(portfolio_id: string, initiative: PreSaveInitiative): Promise<Initiative> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/`;
    const response: AxiosResponse = await axiosInstance.post(url, initiative);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while creating initiative.'));
    }
  }
}

/**
 * Method to update the given initiative
 * @param portfolio_id portfolio id ot attach the initiative to
 * @param initiative updated initiative object
 * @returns updated initiative object
 */
async function updateInitiative(portfolio_id: string, initiative: PreSaveInitiative): Promise<Initiative> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/${initiative.id}/`;
    const response: AxiosResponse = await axiosInstance.put(url, initiative);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error(`An unknown error occurred while updating initiative ${initiative.id}`));
    }
  }
}

/**
 * Method to delete the given initiative
 * @param portfolio_id portfolio id of the initiative
 * @param initiative_id initiative id to delete
 * @returns status code 204 if successful
 */
async function deleteInitiative(portfolio_id: string, initiative_id: string): Promise<void> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/${initiative_id}/`;
    const response: AxiosResponse = await axiosInstance.delete(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while deleting portfolio.'));
    }
  }
}

/**
 * Fetches contributing teams focus data for an initiative
 * @param portfolio_id - portfolio id
 * @param initiative_id - initiative id
 * @returns Focus data object
 */
async function getInitiativeFocus(portfolio_id: string, initiative_id: string): Promise<FocusData> {
  try {
    const url =
      import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/${initiative_id}/focus/`;
    const response: AxiosResponse = await axiosInstance.get(url);
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(
        new Error(`An unknown error occurred while fetching focus data for initiative ${initiative_id}`),
      );
    }
  }
}

/**
 * Method to get initiative tasks
 * @param portfolioId portfolio id of the initiative
 * @param initiativeId initiative id
 * @param filter filter to apply
 * @param date date to filter by
 * @param epicId epic id to filter by
 * @param projectId project id to filter by
 * @returns array of InitiativeTaskResponse objects
 */
async function getInitiativeTasks({
  portfolioId,
  initiativeId,
  filter,
  date,
  epicId,
  projectId,
}: {
  portfolioId: string;
  initiativeId: string;
  filter: InitiativeTasksFilters;
  date: string | undefined;
  epicId: string | undefined;
  projectId: string | undefined;
}): Promise<InitiativeTaskResponse[]> {
  try {
    const url = new URL(
      import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolioId}/initiatives/${initiativeId}/tasks/`,
    );
    const params = new URLSearchParams(url.search);
    params.append('filter', filter);
    if (date) {
      params.append('date', date);
    }
    if (epicId) {
      params.append('epic_id', epicId);
    }
    if (projectId) {
      params.append('project_id', projectId);
    }
    url.search = params.toString();
    const response: AxiosResponse = await axiosInstance.get(url.toString());
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error(`An unknown error occurred while fetching tasks for initiative ${initiativeId}`));
    }
  }
}

/**
 * Searches for initiatives in a portfolio by name
 * @param portfolio_id - portfolio id
 * @param query - search query string
 * @returns Array of matching initiatives
 */
async function searchInitiatives(portfolio_id: string, query: string): Promise<Initiative[]> {
  try {
    const url = import.meta.env.VITE_APP_API_URL + `/api/portfolios/${portfolio_id}/initiatives/search/`;
    const response: AxiosResponse = await axiosInstance.get(url, { params: { q: query } });
    return response.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(new Error('An unknown error occurred while searching initiatives.'));
    }
  }
}

export {
  createInitiative,
  deleteInitiative,
  getInitiative,
  getInitiativeFocus,
  getInitiatives,
  getInitiativeTasks,
  getTeamInitiatives,
  searchInitiatives,
  updateInitiative,
};
