import { Box, CheckIcon, Combobox, Group, MultiSelectProps, Pill, PillsInput, useCombobox } from '@mantine/core';
import { deduplicateItems } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';

export function PortfolioMultiselect(props: Omit<MultiSelectProps, 'data'>) {
  const { value: portfolioIds = [], onChange: handlePortfolioSelected } = props;
  const currentOrganization = useGlobalStore((state) => state.organization);
  const portfolios = useGlobalStore((state) =>
    deduplicateItems(state.portfolios.filter((portfolio) => portfolio.organization.id === currentOrganization?.id)),
  );

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const handleValueSelect = (val: string) => {
    if (val === portfolioIds[0]) {
      return;
    }

    handlePortfolioSelected?.(
      portfolioIds.includes(val) ? portfolioIds.filter((v) => v !== val) : [...portfolioIds, val],
    );
  };

  const handleValueRemove = (val: string) => handlePortfolioSelected?.(portfolioIds.filter((v) => v !== val));

  const values = portfolioIds.map((item, index) => (
    <Pill key={item} withRemoveButton={index !== 0} onRemove={() => handleValueRemove(item)}>
      {portfolios.find(({ id }) => id === item)?.name}
    </Pill>
  ));

  const options = portfolios.map(({ id, name }) => (
    <Combobox.Option value={id} key={id} active={portfolioIds.includes(id)}>
      <Group gap="sm" style={{ flexWrap: 'nowrap' }}>
        {portfolioIds.includes(id) ? (
          <Box w={12}>
            <CheckIcon size={12} color={newCOLORS.gray} />
          </Box>
        ) : null}
        <Box style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{name}</Box>
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput
          pointer
          onClick={() => combobox.toggleDropdown()}
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          style={{ minWidth: 200 }}
        >
          <Pill.Group>
            {values.length > 0 ? values : []}

            <Combobox.EventsTarget>
              <PillsInput.Field type="hidden" onBlur={() => combobox.closeDropdown()} />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
