import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Checkbox, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Measure } from '../../../api/work-periods-client/work-periods-client.type';
import { icons } from '../../../assets/icons/icons';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { newCOLORS } from '../../../styles/colors';
import { DeleteIcon } from '../initiative-sections/initiative-completion';
import { RowStart, TD } from '../key-measures/key-measures-over-time';
import { MeasureLabel } from '../measure-label';
import { formatValue } from './measure-comparison.helpers';
import { MeasureRowDetailInfo, MeasureRowProps } from './measure-comparison.type';

export function MeasureRow({
  title,
  measure,
  description,
  color,
  average,
  trend,
  forecast,
  selectedMeasures,
  selectedTrends,
  setSelectedMeasures,
  setSelectedTrends,
  removeSelection,
  isZeroValid,
}: MeasureRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const detailInfo: MeasureRowDetailInfo[] = [
    {
      icon: 'material-symbols:bar-chart-4-bars-rounded',
      label: 'Average',
      value: formatValue(average),
      tooltipMessageZeroValid: 'Average of all chart values for this measure',
      tooltipMessageNotZeroValid:
        'Average of all chart values for this measure, not including zero values as they are not a valid value for this measure',
    },
    {
      icon: 'material-symbols:trending-up-rounded',
      label: 'Trend Slope',
      value: formatValue(trend),
      tooltipMessageZeroValid: 'Trend slope of all chart values for this measure',
      tooltipMessageNotZeroValid:
        'Trend slope of all chart values for this measure, not including zero values as they are not a valid value for this measure',
    },
    {
      icon: 'material-symbols:waterfall-chart-rounded',
      label: 'Forecast',
      value: formatValue(forecast),
      tooltipMessageZeroValid: 'Forecast of the next value for this measure based on the trend slope',
      tooltipMessageNotZeroValid:
        'Forecast of the next value for this measure based on the trend slope, not including zero values as they are not a valid value for this measure',
    },
  ];

  return (
    <>
      <tr>
        <RowStart color={color}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icon
              icon={isExpanded ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one'}
              width={16}
              height={16}
              color={color}
              onClick={() => setIsExpanded(!isExpanded)}
            />
            <MeasureLabel label={title} tooltip={description} />
          </div>
        </RowStart>
        <TD>
          <Checkbox
            color={findClosestMantineColor(color)}
            checked={selectedMeasures.includes(measure as Measure)}
            onChange={(event) => setSelectedMeasures(event)}
            size="xs"
          />
        </TD>
        <TD>
          <Checkbox
            color={findClosestMantineColor(color)}
            checked={selectedTrends.includes(measure as Measure)}
            onChange={(event) => setSelectedTrends(event)}
            size="xs"
          />
        </TD>
        {removeSelection && (
          <TD>
            <DeleteIcon src={icons.iconDelete} onClick={() => removeSelection(measure as Measure)} alt="delete" />
          </TD>
        )}
      </tr>
      {isExpanded && (
        <tr>
          <ExpandedCell colSpan={removeSelection ? 4 : 3}>
            <DetailsContainer>
              {detailInfo.map((info) => (
                <DetailRow key={info.label}>
                  <Tooltip
                    label={isZeroValid ? info.tooltipMessageZeroValid : info.tooltipMessageNotZeroValid}
                    w={300}
                    multiline
                  >
                    <DetailLabel>
                      <Icon icon={info.icon} width={16} height={16} style={{ marginRight: '8px' }} />
                      {info.label}:
                    </DetailLabel>
                  </Tooltip>
                  <DetailValue>{info.value}</DetailValue>
                </DetailRow>
              ))}
            </DetailsContainer>
          </ExpandedCell>
        </tr>
      )}
      <tr style={{ height: '4px' }}></tr>
    </>
  );
}

const DetailsContainer = styled.div`
  padding: 8px 8px 8px 8px;
  background-color: ${newCOLORS.lighterGray};
  border-radius: 4px;
  margin: 4px 0;
  width: 100%;
  box-sizing: border-box;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 16px;
  padding: 0 8px;
`;

const DetailLabel = styled.span`
  color: ${newCOLORS.darkGray};
  font-weight: bold;
  font-size: 14px;
  width: 140px;
  display: flex;
  align-items: center;
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  width: 100px;
  text-align: right;
  margin-right: 18px;
`;

const ExpandedCell = styled.td`
  padding: 0;
  width: 100%;
  box-sizing: border-box;
`;
