import { styled } from '@linaria/react';
import { Fragment } from 'react';
import { Sprint } from '../../api/sprints-client/sprints-client.type';
import { extractLinks } from '../../helpers/string-helpers/string-helpers';
import { LinkWord } from '../../helpers/string-helpers/string-helpers.type';
import { newCOLORS } from '../../styles/colors';
import { ContentTag, HeadingTag } from '../../styles/shared-styled-components';

export const SprintGoalContainer = ({ sprint }: { sprint: Sprint }) => {
  return (
    <Fragment>
      <BackgroundBox>
        <LargeHeadingTag>Background</LargeHeadingTag>
        <CenterContentTag>
          {sprint.goal
            ? extractLinks(sprint.goal).map((linkWord: LinkWord, index: number) =>
                linkWord.link ? (
                  <a key={index} href={linkWord.word} target="_blank" rel="noopener noreferrer">
                    {linkWord.word}
                  </a>
                ) : (
                  <span key={index}>{linkWord.word} </span>
                ),
              )
            : 'The product team did not describe a goal for this sprint'}
        </CenterContentTag>
      </BackgroundBox>
    </Fragment>
  );
};

const BackgroundBox = styled.div`
  text-align: center;
  background-color: ${newCOLORS.white};
  padding: 16px 32px;
`;

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const CenterContentTag = styled(ContentTag)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: auto;
`;
