import dayjs from 'dayjs';
import { IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { iconsIntegrations } from '../../integrations/assets';

/**
 * Gets the icon URL for a given integration type
 * @param {IntegrationType} source The integration type to get the icon for
 * @returns {string} The URL of the icon image for the integration type, or empty string if not found
 */
const getDataSourceIcon = (source: IntegrationType): string => {
  const sourceIcons: Record<IntegrationType, string> = {
    [IntegrationType.JIRA]: iconsIntegrations.iconJiraCloud,
    [IntegrationType.ADO]: iconsIntegrations.iconADO,
    [IntegrationType.JDC]: iconsIntegrations.iconJiraDataCenter,
    [IntegrationType.GITHUB]: iconsIntegrations.iconGithub,
  };
  return sourceIcons[source] || '';
};

/**
 * Gets a human-readable label for a given integration type
 * @param {IntegrationType} source The integration type to get the label for
 * @returns {string} The display label for the integration type, or empty string if not found
 */
const getDataSourceLabel = (source: IntegrationType): string => {
  const sourceLabels: Record<IntegrationType, string> = {
    [IntegrationType.JIRA]: 'Jira',
    [IntegrationType.ADO]: 'ADO',
    [IntegrationType.JDC]: 'Jira Data Center',
    [IntegrationType.GITHUB]: 'Github',
  };
  return sourceLabels[source] || '';
};

/**
 * Formats a time string to the local timezone
 * @param {string | undefined} time The time string to format
 * @returns {string} The formatted time string, or empty string if not found
 */
const formatTimeToLocal = (time: string | undefined) => {
  if (!time) return '';
  return dayjs.utc(`2000-01-01T${time}`).local().format('HH:mm');
};

/**
 * Formats a time string from local timezone to UTC
 * @param {string | undefined} time The local time string to format
 * @returns {string} The UTC formatted time string, or empty string if not found
 */
const formatTimeToUTC = (time: string | undefined) => {
  if (!time) return '';
  return dayjs(`2000-01-01T${time}`).utc().format('HH:mm');
};

/**
 * Formats a time string to the display format
 * @param {string | undefined} time The time string to format
 * @returns {string} The formatted time string, or empty string if not found
 */
const formatTimeToDisplay = (time: string | undefined) => {
  if (!time) return '';
  return dayjs(`2000-01-01T${time}`).format('h:mm A');
};

export { formatTimeToDisplay, formatTimeToLocal, formatTimeToUTC, getDataSourceIcon, getDataSourceLabel };
