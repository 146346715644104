import { Divider, Group, PasswordInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { setPassword } from '../../../api/auth-client/auth-client';
import { baseWhite, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { Layout } from '../layout';
import { checkPassword } from '../sign-up/create-account/create-account.helpers';
import { ValidationError } from '../sign-up/sign-up.type';
import { ResetPasswordError } from './reset-password.type';

export const CreatePasswordForm = () => {
  const navigate = useNavigate();

  const { email, token } = useParams();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => checkPassword(value),
      confirmPassword: (value, values) => (value === values.password ? null : ValidationError.PasswordMismatch),
    },
  });

  const { password, confirmPassword } = form.values;

  const canResetPassword = email && token && password && confirmPassword && password === confirmPassword;

  const resetPassword = useMutation({
    mutationKey: ['reset-password', { email, token, password }] as const,
    mutationFn: async ({ password }: { password: string }) => {
      if (!canResetPassword) {
        throw new Error('Unable to reset password');
      }
      if (!email || !token) {
        throw new Error('Email and token are required');
      }
      return setPassword(email, token, password);
    },
    onSuccess: () => {
      navigate('/create-password-success');
    },
    onError: (error: Error) => {
      form.setFieldError('password', ResetPasswordError.ServerError);
      Sentry.captureException(error);
    },
  });

  const handleSubmit = async () => {
    if (!token) {
      form.setFieldError('password', ResetPasswordError.InvalidToken);
      return;
    }

    try {
      await resetPassword.mutateAsync({ password });
    } catch (error) {
      form.setFieldError('password', ResetPasswordError.ServerError);
      Sentry.captureException(error);
    }
  };

  return (
    <Layout>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={32}>
          <Group gap={16}>
            <Icon name="password" size={32} />
            <H3>Create New Password</H3>
          </Group>
          <Text>Choose a new password to complete the reset process.</Text>
          <Stack gap={24} role="form" aria-label="Create New Password Form">
            <Divider />
            <PasswordInput
              label="New Password"
              placeholder="Enter your new password"
              value={form.values.password}
              onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
              error={form.errors.password}
              w="100%"
              autoComplete="new-password"
              required
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm your new password"
              value={form.values.confirmPassword}
              onChange={(event) => form.setFieldValue('confirmPassword', event.currentTarget.value)}
              error={form.errors.confirmPassword}
              w="100%"
              autoComplete="new-password"
              onPaste={(e) => e.preventDefault()}
              required
            />
          </Stack>
          <Divider />
          <Button
            type="submit"
            loaderProps={{ size: 'xs', color: secondaryBase }}
            w="100%"
            radius="xl"
            rightSection={<Icon name="arrow_forward" size={18} color={baseWhite} />}
          >
            Confirm and Save Password
          </Button>
        </Stack>
      </form>
    </Layout>
  );
};
