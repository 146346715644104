import { styled } from '@linaria/react';
import { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { WorkflowStatus } from '../../../api/process-client/process-client.type';
import { newCOLORS } from '../../../styles/colors';
import { ProcessContext } from '../context/process.context';
import { getSearchParams } from '../process.helpers';
import { useProcessScope } from '../process.hooks';

const WorkflowNode = memo(({ data }: NodeProps) => {
  return (
    <>
      <WorkflowNodeComponent data={data} />
      <Handle type="target" position={Position.Top} id="top" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
    </>
  );
});

const WorkflowNodeComponent = ({ data }: { data: NodeProps['data'] }) => {
  const navigate = useNavigate();
  const { team, board, taskType, epic, startDate, endDate } = useProcessScope();
  const { setWorkflow } = useContext(ProcessContext);

  return (
    <Container
      status={data.status}
      onClick={() => {
        const workflow = {
          source: data.sourceStage,
          destination: data.destinationStage,
          status: data.status,
          mapping: data.mapping,
        };

        setWorkflow(workflow);

        const params = getSearchParams(
          workflow,
          team?.id || null,
          board?.id || null,
          taskType,
          epic?.id || null,
          startDate,
          endDate,
        );

        if (params) {
          navigate({
            pathname: `/application/process/task-details`,
            search: `?${params}`,
          });
        }
      }}
    >
      {data.value}
    </Container>
  );
};

export { WorkflowNode };

const Container = styled.div<{ status: WorkflowStatus }>`
  width: 38px;
  height: 14px;
  border-radius: 10px;
  border: 1px solid ${newCOLORS.purple2};
  background: ${({ status }: { status: WorkflowStatus }) => getBackground(status)};
  color: ${({ status }: { status: WorkflowStatus }) =>
    status === WorkflowStatus.Backwards ? newCOLORS.purple2 : newCOLORS.white};
  text-align: center;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

/**
 * Retrieves the background color associated with a given workflow status.
 *
 * @param {WorkflowStatus} status - The status of the workflow.
 * @returns {string} The background color associated with the given status.
 */
const getBackground = (status: WorkflowStatus): string => {
  const backgrounds = {
    [WorkflowStatus.Backwards]: newCOLORS.white,
    [WorkflowStatus.Skipped]: newCOLORS.purple2,
  } as Record<WorkflowStatus, string>;

  return backgrounds[status];
};
