import { styled } from '@linaria/react';
import { useState } from 'react';
import {
  getIntegrationEntries,
  useIntegrationEntries,
} from '../../../../api/integrations-client/integrations-client.hooks';
import { IntegrationEntry } from '../../../../api/integrations-client/integrations-client.type';
import { useProjects } from '../../../../api/projects-client/projects-client.hooks';
import { BrandedLoadingOverlay } from '../../../../components/loader/branded-loader';
import { useOrganizationId } from '../../../../helpers/auth-helpers/auth.hooks';
import { SubprojectInfoPanel } from '../subproject-info-panel';
import { useBoardSettings } from './board-settings-client.hooks';
import { BoardSettingsList } from './board-settings-list';
import { BoardSettingsPanel } from './board-settings-panel';
import { EditBoardSettingsPanel } from './edit-board-settings-panel';

export function BoardSettings() {
  const organizationId = useOrganizationId();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSubprojectId, setSelectedSubprojectId] = useState<string | null>(null);
  const [selectedBoardSettingId, setSelectedBoardSettingId] = useState<string | null>(null);
  const { data: boardSettings = [], query: boardSettingsQuery } = useBoardSettings({ organization_id: organizationId });

  const { projects } = useProjects({
    queryKey: ['projects', organizationId] as const,
    organizationId,
  });

  const { data: integrationEntries = [], query: integrationEntriesQuery } = useIntegrationEntries<IntegrationEntry[]>({
    queryKey: ['integration-entries', organizationId] as const,
    queryFn: async () => {
      if (!organizationId) {
        throw new Error('Organization ID is required');
      }
      return getIntegrationEntries(organizationId);
    },
    select: (data) => data.filter((integration) => integration.projects.length > 0),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const selectedBoardSetting = boardSettings.find((boardSetting) => boardSetting.id === selectedBoardSettingId);
  const allSubprojects = integrationEntries.flatMap((entry) => entry.subprojects);
  const selectedSubproject = allSubprojects.find((subproject) => subproject.id === selectedSubprojectId);
  const selectedSubprojectIntegration = selectedSubproject
    ? integrationEntries.find((entry) => entry.subprojects.some((sub) => sub.id === selectedSubproject.id))
    : null;
  return (
    <BoardSettingsContainer>
      <BrandedLoadingOverlay
        visible={integrationEntriesQuery.isFetching || boardSettingsQuery.isLoading}
        transitionDuration={30}
        variant="colored"
      />
      <BoardSettingsList
        boardSettings={boardSettings}
        selectedBoardSetting={selectedBoardSetting ?? null}
        setSelectedBoardSettingId={setSelectedBoardSettingId}
        setIsEditing={setIsEditing}
      />
      <BoardSettingsPanel
        projects={projects || []}
        subprojects={allSubprojects}
        selectedBoardSetting={selectedBoardSetting ?? null}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        selectedSubprojectId={selectedSubprojectId}
        setSelectedSubprojectId={setSelectedSubprojectId}
      />
      {isEditing && selectedBoardSetting && (
        <EditBoardSettingsPanel
          selectedBoardSetting={selectedBoardSetting}
          subprojects={allSubprojects}
          setIsEditing={setIsEditing}
        />
      )}
      {selectedSubprojectId && selectedSubproject && (
        <SubprojectInfoPanel
          subproject={selectedSubproject}
          setSelectedSubprojectId={setSelectedSubprojectId}
          integrationSource={selectedSubprojectIntegration?.integration_type ?? null}
        />
      )}
    </BoardSettingsContainer>
  );
}

const BoardSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;
