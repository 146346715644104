import { useCallback } from 'react';
import {
  askAgent,
  createAgentWebsocket,
  deleteChatHistory,
  editChatHistoryDescription,
  getChatHistories,
} from '../../api/agents-client/agents-websockets';
import { ChatHistory } from './insights.type';

/**
 * Custom hook for managing WebSocket connections and chat interactions with an AI agent
 * @param orgId - Organization ID for the current user
 * @param useAI - Boolean flag indicating if AI features are enabled
 * @param threadId - Current chat thread ID
 * @param setThreadId - Function to update the thread ID
 * @param setChatHistories - Function to update the chat history list
 * @param setIsActiveQuestion - Function to toggle the active question state
 * @param setIsWaitingForFirstResponse - Function to toggle waiting for first response state
 * @param setMessages - Function to update the messages array
 * @param handleNewChat - Function to reset chat state for a new conversation
 * @returns {Object} Object containing:
 *   - runAgent: Function to initiate a chat with the AI agent by sending a question
 *   - fetchChatHistories: Function to retrieve the list of previous chat histories
 *   - handleDeleteHistory: Function to delete a specific chat history thread
 *   - handleEditHistory: Function to edit the description of a chat history thread
 */

const useWebsocketHandlers = (
  orgId: string | null,
  useAI: boolean,
  threadId: string,
  setThreadId: (id: string) => void,
  setChatHistories: React.Dispatch<React.SetStateAction<ChatHistory[]>>,
  setIsActiveQuestion: React.Dispatch<React.SetStateAction<boolean>>,
  setIsWaitingForFirstResponse: React.Dispatch<React.SetStateAction<boolean>>,
  setMessages: React.Dispatch<React.SetStateAction<string[]>>,
  handleNewChat: () => void,
) => {
  /**
   * Initiates a chat interaction with the AI agent by sending a question
   * @param question - The question or message to send to the AI agent
   * @returns {Function} Cleanup function to remove event listeners and close websocket connection
   */
  const runAgent = useCallback(
    (question: string) => {
      if (orgId && useAI) {
        const agentWebsocket = createAgentWebsocket(orgId as string);
        setIsActiveQuestion(true);
        setIsWaitingForFirstResponse(true);

        const messageHandler = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.is_update) {
            if (data.text.length > 0) {
              setIsWaitingForFirstResponse(false);
            }
            if (!threadId) {
              setThreadId(data.thread_id);
            }
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1] += data.text;
              return updatedMessages;
            });
          }
          if (data.is_done) {
            setIsWaitingForFirstResponse(false);
            setIsActiveQuestion(false);
            setMessages((m) => [...m, '']);
            agentWebsocket.close();
          }
        };

        const openHandler = () => {
          askAgent(agentWebsocket, question, threadId);
        };

        agentWebsocket.addEventListener('message', messageHandler);
        agentWebsocket.addEventListener('open', openHandler);

        return () => {
          agentWebsocket.removeEventListener('message', messageHandler);
          agentWebsocket.removeEventListener('open', openHandler);
          agentWebsocket.close();
        };
      }
    },
    [orgId, useAI, threadId, setThreadId, setMessages, setIsActiveQuestion, setIsWaitingForFirstResponse],
  );

  /**
   * Fetches chat histories from the AI agent through a websocket connection.
   * When successful, updates the chat histories state with the received data.
   *
   * @returns {Function} Cleanup function to remove event listeners and close websocket connection
   */
  const fetchChatHistories = useCallback(() => {
    if (orgId && useAI) {
      const agentWebsocket = createAgentWebsocket(orgId as string);

      const messageHandler = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.type === 'chat_histories') {
          setChatHistories(data.histories);
        }
        agentWebsocket.close();
      };

      const openHandler = () => {
        getChatHistories(agentWebsocket);
      };

      agentWebsocket.addEventListener('message', messageHandler);
      agentWebsocket.addEventListener('open', openHandler);

      return () => {
        agentWebsocket.removeEventListener('message', messageHandler);
        agentWebsocket.removeEventListener('open', openHandler);
        agentWebsocket.close();
      };
    }
  }, [orgId, useAI, setChatHistories]);

  /**
   * Handles the deletion of a chat history by its ID.
   *
   * @param id - The unique identifier of the chat history to delete
   * @returns {Function} Cleanup function to remove event listeners and close websocket connection
   */
  const handleDeleteHistory = useCallback(
    (id: string) => {
      if (orgId && useAI) {
        const agentWebsocket = createAgentWebsocket(orgId as string);

        const messageHandler = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.type === 'delete_success') {
            setChatHistories((prev) => prev.filter((history) => history.id !== id));
            if (threadId === id) {
              handleNewChat();
            }
            agentWebsocket.close();
          }
        };

        const openHandler = () => {
          deleteChatHistory(agentWebsocket, id);
        };

        agentWebsocket.addEventListener('message', messageHandler);
        agentWebsocket.addEventListener('open', openHandler);

        return () => {
          agentWebsocket.removeEventListener('message', messageHandler);
          agentWebsocket.removeEventListener('open', openHandler);
          agentWebsocket.close();
        };
      }
    },
    [orgId, useAI, threadId, handleNewChat, setChatHistories],
  );

  /**
   * Handles editing the description of a chat history.
   *
   * @param id - The unique identifier of the chat history to edit
   * @param newDescription - The new description to set for the chat history
   * @returns {Function} Cleanup function to remove event listeners and close websocket connection
   */
  const handleEditHistory = useCallback(
    (id: string, newDescription: string) => {
      if (orgId && useAI) {
        const agentWebsocket = createAgentWebsocket(orgId as string);

        const messageHandler = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.type === 'edit_success') {
            setChatHistories((prev) =>
              prev.map((history) => (history.id === id ? { ...history, description: newDescription } : history)),
            );
            agentWebsocket.close();
          }
        };

        const openHandler = () => {
          editChatHistoryDescription(agentWebsocket, id, newDescription);
        };

        agentWebsocket.addEventListener('message', messageHandler);
        agentWebsocket.addEventListener('open', openHandler);

        return () => {
          agentWebsocket.removeEventListener('message', messageHandler);
          agentWebsocket.removeEventListener('open', openHandler);
          agentWebsocket.close();
        };
      }
    },
    [orgId, useAI, setChatHistories],
  );

  return {
    runAgent,
    fetchChatHistories,
    handleDeleteHistory,
    handleEditHistory,
  };
};

export { useWebsocketHandlers };
