import dayjs from 'dayjs';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { Tab, WorkPeriodType } from '../../containers/process-analysis/process-analysis.type';
import { getRecentlyCompletedMonth } from '../../helpers/general-helpers';
import { isLoggingEnabled, logger } from '../logger/logger';
import { MeasureSelection, State } from './process-analysis-store.type';

export interface ProcessAnalysisStore extends State {
  setMeasureSelections: (tab: Tab, selections: MeasureSelection) => void;
}

const useProcessAnalysisStore = create<ProcessAnalysisStore>()(
  devtools(
    persist(
      logger((set) => ({
        initialized: false,
        availableMeasures: [],
        activeTab: Tab.Portfolios,
        portfolioIds: [],
        teamIds: [],
        boardIds: [],
        timeAllocations: [],
        workPeriodBoardId: null,
        workPeriodType: WorkPeriodType.Defined,
        workPeriods: [],
        workPeriod: null,
        startDate: dayjs(getRecentlyCompletedMonth()).startOf('month').toISOString(),
        endDate: dayjs(getRecentlyCompletedMonth()).toISOString(),
        localMessage: null,
        isDirtyPortfolios: false,
        isDirtyTeams: false,
        isDirtyBoards: false,
        isDirtyWorkPeriods: false,
        measureSelections: {
          [Tab.Portfolios]: {
            measures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedMeasures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedTrends: [],
          },
          [Tab.Teams]: {
            measures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedMeasures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedTrends: [],
          },
          [Tab.Boards]: {
            measures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedMeasures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedTrends: [],
          },
          [Tab.WorkPeriods]: {
            measures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedMeasures: [
              Measure.Velocity as Measure,
              Measure.Throughput as Measure,
              Measure.CycleTime as Measure,
              Measure.ReactionTime as Measure,
              Measure.LeadTime as Measure,
            ],
            selectedTrends: [],
          },
        },
        setMeasureSelections: (tab: Tab, selections: MeasureSelection) =>
          set(
            (state) => {
              const currentMeasureSelections = Object.entries(state.measureSelections).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [key]: { ...value },
                }),
                {} as Record<Tab, MeasureSelection>,
              );

              return {
                ...state,
                measureSelections: {
                  ...currentMeasureSelections,
                  [tab]: selections,
                },
              };
            },
            false,
            '[Process Analysis Store] setMeasureSelections',
          ),
      })),
      {
        name: 'process-analysis-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) =>
                ![
                  'initialized',
                  'localMessage',
                  'isDirtyPortfolios',
                  'isDirtyTeams',
                  'isDirtyBoards',
                  'isDirtyWorkPeriods',
                ].includes(key),
            ),
          ),
      },
    ),
    {
      name: 'Bloomfilter - Process Analysis Store',
      enabled: isLoggingEnabled,
    },
  ),
);

export { useProcessAnalysisStore };
