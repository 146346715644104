import { useContext, useEffect } from 'react';
import { useIntegrationUrl } from '../../../api/integrations-client/integrations-client.hooks';
import { ExternalService, IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { UserContext } from '../../../contexts';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { useRedirect } from '../integrations.hooks';

const services = {
  [IntegrationType.JIRA]: ExternalService.Jira,
  [IntegrationType.JDC]: ExternalService.JDC,
  [IntegrationType.GITHUB]: ExternalService.Github,
  [IntegrationType.ADO]: ExternalService.ADO,
};

/**
 * Custom hook to handle re-authentication for a particular integration.
 *
 * @param {IntegrationType} integration - The type of integration to authenticate.
 * @returns {{ authenticate: () => void }} An object with an authenticate function.
 */
const useIntegrationAuth = (integration: IntegrationType): { authenticate: () => void } => {
  const { user } = useContext(UserContext);
  const organizationId = useOrganizationId();
  const { url, query } = useIntegrationUrl({
    queryKey: ['integrationUrl', services[integration]] as const,
    enabled: false,
  });

  const { setRedirect } = useRedirect();

  useEffect(() => {
    if (url) {
      const receivedUrl = new URL(url);
      const params = new URLSearchParams(receivedUrl.search);

      params.set('state', `${url},${organizationId}`);
      const updatedQueryString = params.toString();

      setRedirect('/application/integrations-list');

      window.location.href = `${receivedUrl.origin}${receivedUrl.pathname}?${updatedQueryString}`;
    }
  }, [url, user, setRedirect, organizationId]);

  return { authenticate: query.refetch };
};

export { useIntegrationAuth };
