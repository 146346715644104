import { WorkloadDistributionData } from './workload-distribution.type';

/**
 * Type guard to check if data matches WorkloadDistributionData type
 * @param data - Unknown data to type check
 * @returns True if data matches WorkloadDistributionData structure, false otherwise
 */

function isWorkloadDistributionData(data: unknown): data is WorkloadDistributionData {
  return (
    data !== null &&
    typeof data === 'object' &&
    'workload_distribution_capacity' in data &&
    typeof (data as any).workload_distribution_capacity === 'object'
  );
}

export { isWorkloadDistributionData };
