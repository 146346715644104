import { styled } from '@linaria/react';
import { useContext } from 'react';
import {
  Project,
  ProjectMetricsDetailsEndpointDescription,
  Subproject,
  SubprojectMetricsResponse,
} from '../../api/projects-client/projects-client.type';
import { HistoricalSprintDataKind } from '../../api/sprints-client/sprints-client.type';
import { ProjectMetricsDetailsHistoryState } from '../../containers/project-metrics-details/project-metrics-details.type';
import { UserContext } from '../../contexts/user';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { newCOLORS } from '../../styles/colors';
import { BarChartStdDev } from '../bar-chart-with-std-dev/bar-chart-with-std-dev';
import { ProjectStatisticsGridBox } from '../project-statistics-grid-box/project-statistics-grid-box';

type ProjectStatisticsGridProps = {
  metricsResponse: SubprojectMetricsResponse;
  project: Project;
  subproject?: Subproject;
  navigate: any;
};

export const ProjectStatisticsGrid = ({
  metricsResponse,
  project,
  subproject,
  navigate,
}: ProjectStatisticsGridProps) => {
  const { user } = useContext(UserContext);
  // Changing isScrum to true for now to keep things running smoothly (old version depending on deprecated field)
  const isScrum = true;

  const endpointDescriptionForKind = (kind: HistoricalSprintDataKind): string => {
    switch (kind) {
      case HistoricalSprintDataKind.velocity:
        return ProjectMetricsDetailsEndpointDescription.Velocity;
      case HistoricalSprintDataKind.throughput:
        return ProjectMetricsDetailsEndpointDescription.Throughput;
      case HistoricalSprintDataKind.leadTime:
        return ProjectMetricsDetailsEndpointDescription.LeadTime;
      case HistoricalSprintDataKind.reactionTime:
        return ProjectMetricsDetailsEndpointDescription.ReactionTime;
      case HistoricalSprintDataKind.cycleTime:
        return ProjectMetricsDetailsEndpointDescription.CycleTime;
      default:
        return '';
    }
  };

  const dimensionProps = (kind: HistoricalSprintDataKind) => {
    return {
      userContext: user,
      project,
      statistic: kind,
    };
  };

  const cycleTimeClick = () => {
    trackEvent(AnalyticsEventType.StatisticTapped, dimensionProps(HistoricalSprintDataKind.cycleTime));
    const state: ProjectMetricsDetailsHistoryState = { project: project, subproject: subproject };
    const route = subproject
      ? `/application/project/${project.id}/subproject/${subproject.id}/metrics/cycle`
      : `/application/project/${project.id}/metrics/cycle`;
    navigate(route, { state });
  };

  const handleClick = (kind: HistoricalSprintDataKind) => {
    trackEvent(AnalyticsEventType.StatisticTapped, dimensionProps(kind));
    const desc = endpointDescriptionForKind(kind);
    const state: ProjectMetricsDetailsHistoryState = { project: project, subproject: subproject };
    const route = subproject
      ? `/application/project/${project.id}/subproject/${subproject.id}/metrics/${desc}`
      : `/application/project/${project.id}/metrics/${desc}`;
    navigate(route, { state });
  };

  return (
    <OuterRow>
      <OuterColumn>
        <InnerRow>
          <InnerColumn>
            {isScrum && metricsResponse.velocity && (
              <ProjectStatisticsGridBox
                clickHandler={handleClick}
                color={newCOLORS.blue}
                data={metricsResponse.velocity}
              />
            )}
            {!isScrum && (
              <ProjectStatisticsGridBox
                clickHandler={handleClick}
                color={newCOLORS.darkPurple}
                data={metricsResponse.cycle_times}
              />
            )}
            <ProjectStatisticsGridBox
              clickHandler={handleClick}
              color={newCOLORS.orange}
              data={metricsResponse.lead_times}
            />
          </InnerColumn>
          <InnerColumn>
            <ProjectStatisticsGridBox
              clickHandler={handleClick}
              color={newCOLORS.green}
              data={metricsResponse.throughput}
            />
            <ProjectStatisticsGridBox
              clickHandler={handleClick}
              color={newCOLORS.darkPurple}
              data={metricsResponse.reaction_times}
            />
          </InnerColumn>
        </InnerRow>
      </OuterColumn>
      <OuterColumn>
        {isScrum && (
          <BarChartStdDevWrapper onClick={() => cycleTimeClick()}>
            <BarChartStdDev historicalSprintData={metricsResponse.cycle_times} />
          </BarChartStdDevWrapper>
        )}
      </OuterColumn>
    </OuterRow>
  );
};

const OuterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  @media (max-width: 1300px) {
    flex-direction: column;
    row-gap: 2rem;
  }
  @media (max-width: 375px) {
    row-gap: 8rem;
  }
  @media (min-width: 530px) and (max-width: 548px) {
    row-gap: 4rem;
  }
  @media (min-width: 549px) and (max-width: 558px) {
    row-gap: 6rem;
  }
  @media (min-width: 559px) and (max-width: 568px) {
    row-gap: 7rem;
  }
`;

const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  @media (max-width: 568px) {
    flex-direction: column;
    row-gap: 2rem;
  }
`;

const OuterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 2rem;
`;

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  row-gap: 2rem;
`;

const BarChartStdDevWrapper = styled.span`
  cursor: pointer;
  background-color: #fff;
  padding: 1em;
`;
