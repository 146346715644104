import { Divider, Flex } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useReducer } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setProjectBoard, setStatusMapping } from '../../../api/integrations-client/integrations-client';
import { useBoardStatuses, useCompleteOauth } from '../../../api/integrations-client/integrations-client.hooks';
import { ExternalService, Methodology } from '../../../api/integrations-client/integrations-client.type';
import { ConfigurationState } from '../../../api/projects-client/projects-client.type';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { UserContext } from '../../../contexts/user';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { getUTCTime } from '../../../helpers/timezone/timezone';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';
import { StepProps } from '../integrations.type';
import { SettingsView } from '../views/settings-view';
import { StatusView } from '../views/status-view';
import { SuccessView } from '../views/success-view';
import { SummaryView } from '../views/summary-view';
import styles from '../views/views.module.css';
import { adoReducer } from './ado.reducer';
import { ADOResponse, ADOState } from './ado.type';
import { Boards } from './boards';
import { Projects } from './projects';

const initialState: ADOState = {
  initialized: false,
  project: null,
  team: null,
  organization: null,
  bloomfilterStatuses: null,
  systemAccessId: null,
  board: null,
  settings: {
    devTeamSize: '',
    methodology: Methodology.Scrum,
    pointBugs: false,
    requireLabels: false,
    projectUrl: '',
    workingHoursStart: '09:00',
    workingHoursEnd: '17:00',
  },
  options: {
    projects: [],
    teams: [],
    allTeams: [],
    organizations: [],
    statuses: [],
  },
};

export const ADO = ({ activeStep = 0, setActiveStep }: StepProps) => {
  const { user } = useContext(UserContext);
  const { query: integrationsQuery } = useContext(IntegrationsContext);
  const organizationId = useOrganizationId();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(adoReducer, initialState);

  const { redirect, setRedirect } = useRedirect();

  useEffect(() => {
    const error = searchParams.get('error');
    const validParams = searchParams.get('code') && searchParams.get('state');
    const notInitialized = !validParams && !state.initialized;

    if (error || notInitialized) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate, state.initialized]);

  const { data, query: completeOauthQuery } = useCompleteOauth({
    queryKey: [
      'complete-oauth',
      ExternalService.ADO,
      user?.id ?? null,
      organizationId ?? null,
      searchParams.get('code'),
      searchParams.get('state'),
    ] as const,
    enabled: !!user?.id && !!organizationId && !!searchParams.get('code') && !!searchParams.get('state'),
  });

  useEffect(() => {
    if (data) {
      const { organizations, projects, teams, system_access_id } = data as ADOResponse;
      dispatch({
        type: 'init',
        payload: {
          systemAccessId: system_access_id,
          options: { projects, teams, organizations, allTeams: teams, statuses: [] },
        },
      });

      searchParams.delete('code');
      searchParams.delete('state');
      searchParams.delete('RESPONSE_TYPE');
      searchParams.delete('STATE');
      searchParams.delete('SCOPE');
      searchParams.delete('REDIRECT_URI');
      setSearchParams(searchParams);

      if (redirect) {
        completeReauth();
        navigate(redirect);
        integrationsQuery.refetch();
        setRedirect(null);
      }
    }
  }, [data, searchParams, setSearchParams, redirect, setRedirect, integrationsQuery, navigate]);

  const { data: boardStatusesData } = useBoardStatuses({
    queryKey: [
      'board-statuses',
      {
        service: ExternalService.ADO,
        project_id: state.project?.id ?? null,
        organization_name: state.organization?.name ?? null,
        system_access_id: state.systemAccessId ?? null,
        team_id: state.team?.id ?? null,
      },
    ] as const,
    enabled: !!state.project?.id && !!state.organization?.name && !!state.systemAccessId && !!state.team?.id,
  });

  useEffect(() => {
    if (boardStatusesData) {
      dispatch({
        type: 'settings',
        payload: {
          projectUrl: boardStatusesData.project_url,
        },
      });
      dispatch({
        type: 'options',
        payload: {
          statuses: boardStatusesData.statuses,
        },
      });
    }
  }, [boardStatusesData]);

  const saveProjectBoardsMutation = useMutation({
    mutationKey: ['setProjectBoard', state, user] as const,
    mutationFn: () => {
      const { project, team, settings } = state;
      const { devTeamSize, requireLabels, pointBugs, workingHoursStart, workingHoursEnd } = settings;
      if (!project || !team || !settings || !organizationId) {
        throw new Error('Invalid project board data');
      }

      return setProjectBoard(
        project,
        team,
        ExternalService.ADO,
        Number(devTeamSize),
        Methodology.Scrum,
        organizationId,
        requireLabels,
        pointBugs,
        getUTCTime(workingHoursStart),
        getUTCTime(workingHoursEnd),
        ConfigurationState.InitiallyConfigured,
      );
    },
    onSuccess: () => {
      saveProjectStatusesMutation.mutate();
    },
  });

  const saveProjectStatusesMutation = useMutation({
    mutationKey: ['setStatusMapping', state, user] as const,
    mutationFn: () => {
      const { project, team, bloomfilterStatuses, settings } = state;
      if (!project || !team || !bloomfilterStatuses || !settings || !organizationId) {
        throw new Error('Invalid project board data');
      }

      return setStatusMapping(
        ExternalService.ADO,
        project,
        team,
        organizationId,
        bloomfilterStatuses.backlogStatuses,
        bloomfilterStatuses.inProgressStatuses,
        bloomfilterStatuses.blockedStatuses,
        bloomfilterStatuses.inReviewStatuses,
        bloomfilterStatuses.inQAStatuses,
        bloomfilterStatuses.readyForDeploymentStatuses,
        bloomfilterStatuses.doneStatuses,
        settings.projectUrl,
        ConfigurationState.InitiallyConfigured,
      );
    },
  });

  const views = [
    <Projects state={state} dispatch={dispatch} key="projects" />,
    <Boards state={state} dispatch={dispatch} key="boards" />,
    <StatusView state={state} dispatch={dispatch} key="status" integration={ExternalService.ADO} />,
    <SettingsView state={state} dispatch={dispatch} key="settings" />,
    <SummaryView state={state} setActiveStep={setActiveStep} key="summary" integration={ExternalService.ADO} />,
    <SuccessView key="success" />,
  ];

  const isNextButtonDisabled = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return !state.project;
      case 1:
        return !state.team;
      case 3:
        return state.settings.devTeamSize === '';
      default:
        return false;
    }
  };

  const handleChangeView = (handler: (current: number) => number) => {
    setTimeout(() => window.scrollTo(0, 0));
    setActiveStep(handler(activeStep));
  };

  const nextStep = () => handleChangeView((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => handleChangeView((current: number) => (current > 0 ? current - 1 : current));

  const handleSubmit = () => {
    saveProjectBoardsMutation.mutate();
    nextStep();
  };

  return (
    <Flex direction="column">
      <BrandedLoadingOverlay visible={completeOauthQuery.isFetching} transitionDuration={30} variant="colored" />
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 5 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 4 ? nextStep : handleSubmit}
                size="lg"
              >
                {activeStep < 4 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
};
